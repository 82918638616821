import React, { Fragment } from 'react';
import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS, LOCAL_STORAGE_KEYS, AUTH } from '../../static/constants';
import { showToasterMessage, addToLocalStorage, getDaysBetweenDates , isValidDateRange} from '../../utils';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import reactCookie from 'react-cookie';
const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const verifyCredentials = (payload, history, props) => {
  var loginData;
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    dispatch({ type: AUTH.HIDE_RESEND_ACTIVATION });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/user/login`, {email: payload.email, password: payload.password, relationshipEmployeeId: payload.relationshipEmployeeId,source:payload.source}
      )
      .then(res => {
        loginData = res.data;
        return axios
          .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/user/getUserPermission?relationshipId=${res.data.userInfo.relationshipId}&userId=${res.data.userInfo.userId}`
          )
      })
      .then(res => {
        const result = loginData;
        let tokenInfo = JSON.parse(result.tokenInfo);
        const permissionData = {};

        if ((loginData.defaultCompany || {}).hasAccountActivated) {
          if (loginData.defaultCompany.isFreeTrial && loginData.defaultCompany.freeDays && loginData.defaultCompany.planCode) {
            _trialRemainingDaysAlert(loginData.defaultCompany, props);
          } 
           if (loginData.defaultCompany.currentTermEnd && !isValidDateRange(new Date(), loginData.defaultCompany.currentTermEnd)) {
            _subscriptionExprireAlert(loginData.defaultCompany, props);
          }
          else {
            let userInfo = loginData.userInfo;

            // if (userInfo && userInfo.jobRoleName === 'Teacher' && !userInfo.formId) {
            //   props.showModal({
            //     maskClosable: true,
            //     isClosable: false,
            //     keyboard: false,
            //     modalBody: <Fragment>
            //       <div className="warning-message">
            //         Admin has not assigned any forms. Please contact admin.
            //       </div>
            //       <br />
            //       <div className="button-action" style={{
            //         // display: 'flex',
            //         // justifyContent: 'space-between',
            //         borderTop: '1px solid #e5e5e5',
            //         paddingTop: '15px',
            //         textAlign: 'center'
            //       }}>

            //         <Button className="ant-btn-primary"
            //           onClick={() => {
            //             props.hideModal();
            //           }}>  <FormattedMessage id='ok' defaultMessage='' /> </Button>
            //       </div>
            //     </Fragment>,
            //     hideFooter: true
            //   })
            // }

            //if no plan selected by user before signup
            // if (!loginData?.defaultCompany?.planCode) {
            //   history.push({
            //     pathname: "/pricingsetup",
            //     state: ({
            //       loginData: loginData,
            //       // planCode: "starter-us",
            //       clientUserAccountId: loginData?.userInfo?.userId
            //     } || {})
            //   });
            // } else {
              permissionData.primary = res.data.primary || [];
              permissionData.secondary = res.data.secondaryPermission || {};
              addToLocalStorage(LOCAL_STORAGE_KEYS.EXPIRE_TIME, tokenInfo.expires_in);
              addToLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, tokenInfo.access_token);
              addToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, tokenInfo.refresh_token);
              addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(loginData));
              addToLocalStorage(LOCAL_STORAGE_KEYS.PERMISSION_DATA, JSON.stringify(permissionData));
              addToLocalStorage(LOCAL_STORAGE_KEYS.DEFAULT_SCHOOL_FORM, userInfo.formId);
              addToLocalStorage(LOCAL_STORAGE_KEYS.EMAIL, userInfo.email);
              dispatch({ type: AUTH.SET_PERMISSIONS, data: permissionData });

              // dashboard redirect in case of school or ERP
              if ((loginData?.defaultCompany?.industryName || '').toLowerCase() === 'education') {
                history.push('/admin/school-management/dashboard');
              }
              else {
                history.push('/admin/dashboard');
              }
       //     }
          }
        } else {
          dispatch({ type: AUTH.SHOW_RESEND_ACTIVATION });
          history.push({
            pathname: '/activation-pending',
            state: {
              username: payload.email
            }
          })
          // showToasterMessage({messageType: 'error', description: "Your account has not been activated yet. Check your email to your activate your account. If you have not received a confirmation email, please check your spam folder."});
        }
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const getPermissions = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/activateCustomerRelationship?token=${payload.token}`)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: ((res || {}).data || {}).message || 'Congratulations, your account has been activated. You can sign in.' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        window.location.reload();
        props.history.push('/')
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
          props.history.push('/')
        }
      })
  }
}

export const activateCustomerAccount = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/activateCustomerRelationship?token=${payload.token}`)
      .then(res => {
        if(!res?.data?.hasAccountActivated){
          throw new Error("Some server error occured in account activation process");
        }
        showToasterMessage({ messageType: 'success', description: ((res || {}).data || {}).message || 'Congratulations, your account has been activated. You can sign in' })
        props.history.push('/')
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
        props.history.push('/')
      })
  }
}

export const setIsAlifApp = (isAlifApp) => {
  return dispatch => {
    addToLocalStorage(COMMON_ACTIONS.IS_ALIF_APP, isAlifApp);
    dispatch({ type: COMMON_ACTIONS.IS_ALIF_APP, data: isAlifApp });
  }
}

const cookieOptions = {
  domain: '.'+(config.ROOT_DOMAIN || 'alpide.com'), // Replace with your desired domain
  path: '/', // Replace with the desired path
  expires: new Date(Date.now() + 86400 * 1000), // Replace with the desired expiration time
};
const handleRemoveCookie = () => {
  reactCookie.remove(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, cookieOptions);
  reactCookie.remove(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, cookieOptions);
  reactCookie.remove(LOCAL_STORAGE_KEYS.EXPIRE_TIME, cookieOptions);
};

const _trialRemainingDaysAlert = (companyInfo, props) =>{

  let days = getDaysBetweenDates(companyInfo.accountActivationDate, new Date());
  console.log(" ::::::::::   ", days)
  props.showModal({
    maskClosable: true,
    isClosable: false,
    keyboard: false,
    hideTitle: true,
    wrapClassName: 'invalid-plan-modal',
    modalBody: <Fragment>
      <div className="warning-message">
      {days <= companyInfo.freeDays ? `${(companyInfo.freeDays - days)} days remaining in free trial.`: `Your trial has expired.`}
      </div>
      <br />
      <div className="button-action" style={{
        // display: 'flex',
        // justifyContent: 'space-between',
        borderTop: '1px solid #e5e5e5',
        paddingTop: '15px',
        textAlign: 'center',
      }}>

        <Button className="ant-btn-primary"
          onClick={() => {
            props.hideModal();
          }}>  <FormattedMessage id='ok' defaultMessage='' /> </Button>
      </div>
    </Fragment>,
    hideFooter: true
  })
}
const _subscriptionExprireAlert = (companyInfo, props) =>{

  props.showModal({
    maskClosable: true,
    isClosable: false,
    keyboard: false,
    hideTitle: true,
    wrapClassName: 'invalid-plan-modal',
    modalBody: <Fragment>
      <div className="warning-message">
      {`Your subscription has expired.`}
      </div>
      <br />
      <div className="button-action" style={{
        borderTop: '1px solid #e5e5e5',
        paddingTop: '15px',
        textAlign: 'center'
      }}>

        <Button className="ant-btn-primary"
          onClick={() => {
            props.hideModal();
          }}>  <FormattedMessage id='ok' defaultMessage='' /> </Button>
      </div>
    </Fragment>,
    hideFooter: true
  })
}

export const getLogedInUserInfo = (payload, history, props) => {
  var loginData;
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    dispatch({ type: AUTH.HIDE_RESEND_ACTIVATION });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/user/getLogedInUserInfo`, payload
      )
      .then(res => {
        loginData = res.data;
        return axios
          .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/user/getUserPermission?relationshipId=${res.data.userInfo.relationshipId}&userId=${res.data.userInfo.userId}`
          )
      })
      .then(res => {
        // const result = loginData;
        // let tokenInfo = JSON.parse(result.tokenInfo);
        const permissionData = {};
        permissionData.primary = res.data.primary || [];
        permissionData.secondary = res.data.secondaryPermission || {};
        if ((loginData.defaultCompany || {}).hasAccountActivated) {
          if (loginData.defaultCompany.isFreeTrial && loginData.defaultCompany.freeDays && loginData.defaultCompany.planCode) {
            _trialRemainingDaysAlert(loginData.defaultCompany, props);
          } 
           if (loginData.defaultCompany.currentTermEnd && !isValidDateRange(new Date(), loginData.defaultCompany.currentTermEnd)) {
            _subscriptionExprireAlert(loginData.defaultCompany, props);
          }
          else {

            let userInfo = loginData.userInfo;
            if (userInfo && userInfo.jobRoleName === 'Teacher' && !userInfo.formId) {
              props.showModal({
                maskClosable: true,
                isClosable: false,
                keyboard: false,
                modalBody: <Fragment>
                  <div className="warning-message">
                    Admin has not assigned any forms. Please contact admin.
                  </div>
                  <br />
                  <div className="button-action" style={{
                    // display: 'flex',
                    // justifyContent: 'space-between',
                    borderTop: '1px solid #e5e5e5',
                    paddingTop: '15px',
                    textAlign: 'center'
                  }}>

                    <Button className="ant-btn-primary"
                      onClick={() => {
                        props.hideModal();
                      }}>  <FormattedMessage id='ok' defaultMessage='' /> </Button>
                  </div>
                </Fragment>,
                hideFooter: true
              })
            }
            // if (!loginData?.defaultCompany?.planCode) {
            //   history.push({
            //     pathname: "/pricingsetup",
            //     state: ({
            //       loginData: loginData,
            //       permissionData: permissionData,
            //       // planCode: "starter-us",
            //       clientUserAccountId: loginData?.userInfo?.userId
            //     } || {})
            //   });
            // }
            // else {
              // permissionData.primary = res.data.primary || [];
              // permissionData.secondary = res.data.secondaryPermission || {};
              // addToLocalStorage(LOCAL_STORAGE_KEYS.EXPIRE_TIME, tokenInfo.expires_in);
              // addToLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, tokenInfo.access_token);
              // addToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, tokenInfo.refresh_token);
              addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(loginData));
              addToLocalStorage(LOCAL_STORAGE_KEYS.PERMISSION_DATA, JSON.stringify(permissionData));
              addToLocalStorage(LOCAL_STORAGE_KEYS.DEFAULT_SCHOOL_FORM, userInfo.formId);
              dispatch({ type: AUTH.SET_PERMISSIONS, data: permissionData });
              dispatch({ type: COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO, data: loginData.defaultCompany });

              dispatch({ type: AUTH.SESSION_TYPE, data: 'valid' });
              ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
              // setTimeout(()=>{history.push('/admin/dashboard')}, 5000);
              setTimeout(() => {
                if ((loginData.defaultCompany.industryName || '').toLowerCase() === 'education') {
                  history.push('/admin/school-management/dashboard');
                }
                else {
                  history.push('/admin/dashboard');
                }
              }, 5000);
         //   }
          }
        } else {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({ type: AUTH.SHOW_RESEND_ACTIVATION });
          history.push({
            pathname: '/activation-pending',
            state: {
              username: payload.email
            }
          })
          // showToasterMessage({messageType: 'error', description: "Your account has not been activated yet. Check your email to your activate your account. If you have not received a confirmation email, please check your spam folder."});
        }
      })
      .catch((err) => {

        dispatch({ type: AUTH.SESSION_TYPE, data: 'invalid' });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}