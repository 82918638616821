import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import { fetchSuppliers,resetPaginatedData } from "../../Listing/action";
import PurchaseInvoiceComponent from "../../../../components/supplier/invoice/StandardPurchaseInvoice";
import LedgerDrawer from "../../../../containers/drawer/ledgers/PurchaseInvoiceLedger";
import {
  fetchPriceList,
  fetchPaymentTerms,
  addPaymentTerm,
  deletePaymentTerm,
  fetchProjects,
  fetchStates,
  fetchTaxes,
  fetchUOM,
  addUOM,
  deleteUOM,
  fetchExpenseTypes,
  addExpenseTypes,
  deleteExpenseTypes,
  fetchProducts,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  deleteProject,
  deleteAddress,
  deleteTax,
  deletePriceList,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  deleteContact,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchRelationshipTaxIdentifications,
  deleteRelationshipTaxIdentifications,
  deleteSupplierTaxIdentifications,
  fetchSupplierSummary,
  fetchSupplierDetailsBySupplierId,
  resetSupplierDetail,
  getRelationshipAddress,
  getItemByUPC,
} from "../../../../actions/commonActions";
import {
  createPurchaseInvoice,
  fetchPurchasedOrderForInvoice,
  resetPoInvoiceData,
  getNextTxNumber,
  fetchIDForInvoice,
  resetIDInvoiceData,
  createPurchaseInvoiceDraft,checkSupplierInvoiceNumber, deleteDocument
} from "./action";
import {
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
} from "../../../../utils";
import {
  LEDGER_ACTION_LIST,
  CONSTANTS,
  LEDGER_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
} from "../../../../static/constants";
import { GRID_PREFERENCE_CONSTANTS } from "../../../../static/agGridConstants";
import PurchaseInvoiceDetail from "../../../modal/modalBody/supplier/PurchaseInvoiceDetail";
import { deleteSuppliers } from "../../Listing/action";
import {
  fetchPurchaseInvoices,
  getPurchaseInvoicesCount,
  deleteInvoice,
  fetchPurchaseInvoiceDrafts,getPurchaseInvoiceDraftCount
} from "../Listing/action";
import {
  fetchRemarksTemplate,
  resetRemarksData,
} from "../../../modal/modalBody/settings/RemarksTemplate/action";
import {
  fetchPurchasedInvoiceDetail,
  resetPurchasedInvoiceDetail,
} from "../../../modal/modalBody/supplier/PurchaseInvoiceDetail/action";
import { fetchAddresses } from "../../Listing/ShowAddress/action";
import { fetchAllContacts } from "../../Listing/ShowConact/action";
import { fetchAllLedgerAccounts } from "./../../../finance/LedgerAccounts/action";
import TransportDataDrawer from "../../../../containers/drawer/transportData/index";
import LedgerAccountsDrawer from "../../../drawer/finance/ledgers/index"
import SettingPurchaseDrawer from "../../../drawer/supplier/settingPurchaseDrawer";

class PurchaseInvoice extends Component {
  constructor(props) {
    super(props);
    const roundingOffSettings = JSON.parse(props.companyInfo.roundingOffSettings || "{}");
    const defaultPurchaseLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASES,
      }) || {};
    const defaultTaxLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASE_TAX,
      }) || {};
    const defaultDiscountLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.DISCOUNT_RECIEVED,
      }) || {};
    const defaultExpenseLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.SUPPLIER_EXPENSE,
      }) || {};
    const defaultCreditorLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.CREDITORS,
      }) || {};
    const allRelationshipBillingAddress =
      filter(props.companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
      }) || [];
    const allRelationshipShippingAddress =
      filter(props.companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
      }) || [];
    const relationshipBillingAddress =
      allRelationshipBillingAddress.find((x) => x.isDefault) || {};
    const relationshipShippingAddress =
      allRelationshipShippingAddress.find((x) => x.isDefault) || {};
   // const placeOfSupply = (this.props.supplierBillingAddress || {}).stateName;
    const placeOfSupply = (relationshipBillingAddress|| {}).stateName;
    // const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      displayName:"drawer.settingDrawer.headingNamePurchaseInvoice",
      viewName:"PuchaseInvoice",
      docName:"Purchase Invoice",
      tabDocumentOptions: 
      { txType: CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE, label: 'Purchase Invoice', numberExample: 'INV-1', numberInfo: 'INV-SupplierNumber-DDMMYY' },
      settingPurchaseDrawerVisible:false,


      hideAddNew:true,
      isbankReco: props.isbankReco,
      bankRecoBalance: props.bankRecoBalance,
      bankTxData: props.bankTxData,
      invoiceDraftData: props.supplierInvoiceData,
      supplierInvoiceDraftId: props.supplierInvoiceDraftId,
      stateSupplierId: props.supplierId,
      isClone: props.clone,
      //docName: "Purchase Request",
      pageNumber: props.pageNumber || 1,
      supplier: undefined,
      priceListName: undefined,
      pageSize: 25,
      allRelationshipBillingAddress,
      allRelationshipShippingAddress,
      relationshipBillingAddress,
      relationshipShippingAddress,
      placeOfSupply,
      ledgerAccountsDrawerVisible: false,
      invoiceDate: new Date(),
      invoiceDueDate: new Date(),
      ledgerDrawerVisible: false,
      purchaseLedgerId: defaultPurchaseLedger.ledgerAccountId,
      purchaseCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseDiscountLedgerId: defaultDiscountLedger.ledgerAccountId,
      discountCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      taxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      purchaseExpenseCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      igstPurchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      igstTaxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      cgstPurchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      cgstTaxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      sgstPurchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      sgstTaxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      reference: "",
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      txPreferences: { txType: "purchaseInvoice", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.RATE,
        GRID_PREFERENCE_CONSTANTS.AMOUNT,
        GRID_PREFERENCE_CONSTANTS.AMOUNT_WITHOUT_TAX,
        "sno",
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        rate: true,
        amount: true,
        description: true,
        uom: true,
        hsn: (props.companyInfo || {}).countryName === "India",
        basePrice: true,
        specialDiscount: true,
        discount: true,
        discountAmount: true,
        tax: true,
        ledgerAccount: true,
        taxableAmount: true,
      },
      rootExpenseList: [{}],
      valueSet:false,
      validSupplierInvNumber:true,
      isItemDiscountAplicable:false,

    roundingOffSettings,
    roundingOffLedgerId: roundingOffSettings.roundingOffPurchaseLedgerAccountId,
    roundingOffLedgerName: roundingOffSettings.roundingOffPurchaseLedgerAccountName,
    roundingOffCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
    roundingOffCreditorLedgerName: defaultCreditorLedger.ledgerAccountName,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      poMasterId: this.props.poMasterId,
      supplierId: this.props.supplierId,
      inboundDeliveryMasterId: this.props.inboundDeliveryMasterId,
      invoiceMasterId: this.props.invoiceMasterId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25,filterData:true
    };
    this.props.resetPoInvoiceData();
    this.props.resetIDInvoiceData();
    this.props.resetPurchasedInvoiceDetail();
    payload.poMasterId &&
      fetchDataIfNeeded(
        "fetchPurchasedOrderForInvoice",
        "poInvoiceData",
        this.props,
        payload
      );
    payload.inboundDeliveryMasterId &&
      fetchDataIfNeeded(
        "fetchIDForInvoice",
        "idInvoiceData",
        this.props,
        payload
      );
    this.props.resetSupplierDetail();
    payload.supplierId && this.props.fetchSupplierDetailsBySupplierId(payload);
    fetchPaginationDataIfNeeded(
      "fetchSupplierSummary",
      "supplierSummary",
      this.props,
      payload,
      true
    );
    // fetchPaginationDataIfNeeded('fetchSuppliers', 'suppliers', this.props, payload, true);
    this.props.resetPaginatedData();
    fetchDataIfNeeded("fetchPriceList", "priceList", this.props, payload);
    fetchDataIfNeeded("fetchProjects", "projectList", this.props, payload);
    fetchDataIfNeeded("fetchPaymentTerms", "paymentTerms", this.props, payload);
    fetchDataIfNeeded("fetchStates", "states", this.props, 1);
    fetchDataIfNeeded("fetchProducts", "products", this.props, payload);
    fetchDataIfNeeded("fetchTaxes", "taxes", this.props, payload);
    fetchDataIfNeeded("fetchUOM", "uomList", this.props, payload);
    fetchDataIfNeeded("fetchExpenseTypes", "expenseTypes", this.props, payload);
    fetchDataIfNeeded("fetchDocuments", "documentList", this.props, payload);
    if (!this.props.update) {
      fetchDataIfNeeded("getNextTxNumber", "nextTxNumber", this.props, payload);
    }
    fetchDataIfNeeded(
      "fetchUserPreferences",
      "allPreferences",
      this.props,
      payload
    );
    this.props.fetchRemarksTemplate({
      relationshipId: companyInfo.relationshipId,
      docName: "Purchase Invoice",
    });
    fetchDataIfNeeded(
      "fetchRelationshipTaxIdentifications",
      "allRelationshipTaxIdentificatins",
      this.props,
      payload
    );
    payload.invoiceMasterId &&
      fetchDataIfNeeded(
        "fetchPurchasedInvoiceDetail",
        "invoiceData",
        this.props,
        payload
      );
    fetchDataIfNeeded(
      "fetchAllLedgerAccounts",
      "allLedgerAccounts",
      this.props,
      payload
    );
  }

  componentWillReceiveProps(props) {
    if (
      !this.state.selectedRelationshipTaxIdentifications &&
      props.allRelationshipTaxIdentificatins &&
      props.allRelationshipTaxIdentificatins.length
    ) {
      const selectedList =
        (props.allRelationshipTaxIdentificatins &&
          filter(props.allRelationshipTaxIdentificatins, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedRelationshipTaxIdentifications = selectedList.map((obj) => {
        return obj.relationshipTaxIdentificationId;
      });
      this.setState({
        selectedRelationshipTaxIdentifications:
          selectedRelationshipTaxIdentifications,
      });
    }

    if (
      props.documentList &&
      props.documentList.length &&
      !this.state.docName
    ) {
      props.documentList.forEach((document) => {
        if (
          document.txType ===
          CONSTANTS_TRANSACTIONS_KEY[
          CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE
          ] &&
          document.isDefault
        ) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName,
          });
        }
      });
    }
    if (
      !this.state.supplierRemarks &&
      props.remarksData &&
      props.remarksData.isFetched &&
      !this.state.isRemarksSet
    ) {
      this.setState({
        supplierRemarks: props.remarksData.content || "",
        isRemarksSet: true,
        footer: props.remarksData.footer || "",
      });
      setTimeout(() => {
        props.resetRemarksData();
      }, 500);
    }
    if (props.boRelationshipAddress && props.boRelationshipAddress.length) {
      const allRelationshipBillingAddress = [];
      const allRelationshipShippingAddress = [];
      props.boRelationshipAddress.forEach((e) => {
        if (e.locationType === "RelationshipBillingAddress") {
          allRelationshipBillingAddress.push(e);
        } else {
          allRelationshipShippingAddress.push(e);
        }
      });
      this.setState({
        allRelationshipBillingAddress,
        allRelationshipShippingAddress,
      });
    } else {
      const allRelationshipBillingAddress =
        filter(props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }) || [];
      const allRelationshipShippingAddress =
        filter(props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
        }) || [];
      this.setState({
        allRelationshipBillingAddress,
        allRelationshipShippingAddress,
      });
    }

    if (
      // props.taxes &&
      // props.taxes.length &&
      props.isTaxesLoaded &&
      props.poInvoiceData &&
      props.poInvoiceData.poMasterId
    ) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
          0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 &&
          (
            find(item.customerSalesOrderCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 &&
          taxPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
      };
      props.poInvoiceData.supplierPurchaseOrderDetailList.map((ele) => {
        const tax =
          find(ele.supplierPOCOATxList, (ele) => {
            return ele.txType === LEDGER_TYPE.TYPE_TAX;
          }) || {};

        const appliedTax =
          find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};

        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          version: ele.version,
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName
            ? ele.itemName +
            ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantityToInvoice,
          discount: (
            find(ele.supplierPOCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent,
          tax: tax.amountPercent || 0,
          taxApplied: appliedTax.taxNameDisplay,
          taxName: appliedTax.taxName,
          taxId: appliedTax.taxSingleRateId,
          quantityOrdered: ele.quantity,
          quantityInvoiced: ele.quantityInvoiced,
          rate: ele.anItemPurchasePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          parentDetailsId: ele.poDetailsId,
        });
      });

      const rootDiscount =
        find(props.poInvoiceData.supplierPOCOATxList || [], {
          txType: LEDGER_TYPE.TYPE_DISCOUNT,
        }) || {};
      const expenseAmount =
        (
          find(props.poInvoiceData.supplierPOCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      const forceHideTxColumn = [];
      const additionalInfoList =
        props.poInvoiceData.additionalInfoList[0] || {};

      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      const customerName = props.poInvoiceData.customerName;
      this.setState({
        isPoConversionToInvoice: true,
        version: props.poInvoiceData.version,
        supplier: {
          id: props.poInvoiceData.supplierId,
          supplierId: props.poInvoiceData.supplierId,
          name: props.poInvoiceData.supplierName,
          gstNumber: props.poInvoiceData.gstNumber,
          panNumber: props.poInvoiceData.panNumber,
        },
        gstinNo: props.poInvoiceData.gstinNo,
        relationshipBillingAddress: find(props.poInvoiceData.boLocationPOList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }),
        supplierBillingAddress: find(props.poInvoiceData.boLocationPOList, {
          locationType: CONSTANTS.BILLING_ADDRESS,
        }),
        selectedContact:
          (props.poInvoiceData.additionalInfoList || [])[0] || {},
        customerPaymentTerm: props.paymentTerms.length
          ? find(props.paymentTerms, {
            paymentTermId: props.poInvoiceData.paymentTermId,
          }) || {}
          : {},
        poMasterId: props.poInvoiceData.poMasterId,
        purchaseOrderId: props.poInvoiceData.poId,
        customerInquiryNumber: props.poInvoiceData.customerInquiryNumber,
        customerPONumber: props.poInvoiceData.customerPONumber,
        customerRFQNumber: props.poInvoiceData.customerRFQNumber,
        docName: props.poInvoiceData.documentName,
        customerId: props.poInvoiceData.customerId,
        customerName: props.poInvoiceData.customerName,
        customer: { customerId: props.poInvoiceData.customerId, customerName },
        supplierPo: props.poInvoiceData.poNumber,
        reference: props.poInvoiceData.reference,
        projectMasterId: props.poInvoiceData.projectMasterId,
        projectName: props.poInvoiceData.projectName,
        projectNumber: props.poInvoiceData.projectNumber,
        customerPo: props.poInvoiceData.customerPONumber,
        placeOfSupply: props.poInvoiceData.placeOfSupply,
        supplierName: props.poInvoiceData.supplierName,
        supplierRemarks: props.poInvoiceData.remarksSupplier,
        internalRemarks: props.poInvoiceData.remarksInternal,
        footer: props.poInvoiceData.footer,
        invoiceDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        supplierPaymentTerm: {
          paymentTermId: props.poInvoiceData.paymentTermId,
          termName: props.poInvoiceData.paymentTermName,
          numberOfDays: props.poInvoiceData.paymentTermDays,
        },
        isRootDiscount: rootDiscount.txType ? true : false,
        rootDiscountPercent: rootDiscount.amountPercent || 0,
        expenseId: props.poInvoiceData.expenseId,
        rootDiscountAmount: Number(rootDiscount.amount || 0),
        expenseAmount: expenseAmount,
        isRootExpense: expenseAmount ? true : false,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        rfqNumber: props.poInvoiceData.rfqNumber,
        rfqMasterId: props.poInvoiceData.rfqMasterId,
        supplierQuoteNumber: props.poInvoiceData.supplierQuoteNumber,
        selectedRelationshipTaxIdentifications:
          additionalInfoList.relationshipTaxIdentifications
            ? JSON.parse(additionalInfoList.relationshipTaxIdentifications).map(
              (obj) => {
                return obj.relationshipTaxIdentificationId;
              }
            )
            : this.state.selectedRelationshipTaxIdentifications,
        ewayBillNo: props.poInvoiceData.ewayBillNo,
      });

      props.resetPoInvoiceData();
    }

    if (
      // props.taxes &&
      // props.taxes.length &&
      props.isTaxesLoaded && 
      props.idInvoiceData &&
      props.idInvoiceData.inboundDeliveryMasterId
    ) {
      const detailList = [];
      const inheritedTxColumn = [];

      const _emptyColumnCheck = (item, taxPercent) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
          0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 &&
          (
            find(item.customerSalesOrderCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 &&
          taxPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
      };
      props.idInvoiceData.inboundDeliveryDetailsList.map((ele) => {
        const tax =
          find(ele.supplierIDCOATxList, (ele) => {
            return ele.txType === LEDGER_TYPE.TYPE_TAX;
          }) || {};

        const appliedTax =
          find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};

        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName
            ? ele.itemName +
            ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.qtyReceived,
          discount: (
            find(ele.supplierIDCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent,
          tax: tax.amountPercent || 0,
          taxApplied: appliedTax.taxNameDisplay,
          taxId: appliedTax.taxSingleRateId,
          taxName: appliedTax.taxName,
          quantityOrdered: ele.quantity,
          quantityInvoiced: ele.quantityInvoiced,
          rate: ele.anItemPurchasePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          inboundDeliveryNumber: props.idInvoiceData.inboundDeliveryNumber,
          inboundDeliveryMasterId: props.idInvoiceData.inboundDeliveryMasterId,
          parentDetailsId: ele.parentDetailsId,
        });
      });
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }

      const rootDiscount =
        find(props.idInvoiceData.supplierIDCOATxList || [], {
          txType: LEDGER_TYPE.TYPE_DISCOUNT,
        }) || {};
      const expenseAmount =
        (
          find(props.idInvoiceData.supplierIDCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      const selectedContact =
        (props.idInvoiceData.deliveryAdditionalInfoList || [])[0] || {};
      const supplier = this.state.supplier || {};
      let selectedSupplierTaxIdentifications = [];
      if (selectedContact.taxIdentifications) {
        let taxIdentificationsList = JSON.parse(
          selectedContact.taxIdentifications
        );
        selectedSupplierTaxIdentifications = taxIdentificationsList.map(
          (obj, i) => {
            return obj.supplierTaxIdentificationId;
          }
        );
      }
      const customerName = props.idInvoiceData.customerName;

      this.setState({
        isIDConversion: true,
        selectedSupplierTaxIdentifications,
        gstinNo: props.idInvoiceData.gstinNo,
        supplier: {
          ...supplier,
          id: props.idInvoiceData.supplierId,
          supplierId: props.idInvoiceData.supplierId,
          name: props.idInvoiceData.supplierName,
          gstNumber: props.idInvoiceData.gstNumber,
          panNumber: props.idInvoiceData.panNumber,
        },
        relationshipBillingAddress: find(
          props.idInvoiceData.boLocationInboundDeliveryList,
          { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }
        ),
        supplierBillingAddress: find(
          props.idInvoiceData.boLocationInboundDeliveryList,
          { locationType: CONSTANTS.BILLING_ADDRESS }
        ),
        selectedContact:
          (props.idInvoiceData.deliveryAdditionalInfoList || [])[0] || {},
        customerPaymentTerm: props.paymentTerms.length
          ? find(props.paymentTerms, {
            paymentTermId: props.idInvoiceData.paymentTermId,
          }) || {}
          : {},
        poMasterId: props.idInvoiceData.poMasterId,
        purchaseOrderId: props.idInvoiceData.poId,
        customerInquiryNumber: props.idInvoiceData.customerInquiryNumber,
        customerPONumber: props.idInvoiceData.customerPONumber,
        customerRFQNumber: props.idInvoiceData.customerRFQNumber,
        customerId: props.idInvoiceData.customerId,
        customerName: props.idInvoiceData.customerName,
        customer: { customerId: props.idInvoiceData.customerId, customerName },
        supplierPONumber: props.idInvoiceData.supplierPONumber,
        supplierPo: props.idInvoiceData.poNumber,
        reference: props.idInvoiceData.reference,
        projectMasterId: props.idInvoiceData.projectMasterId,
        projectName: props.idInvoiceData.projectName,
        projectNumber: props.idInvoiceData.projectNumber,
        customerPo: props.idInvoiceData.customerPONumber,
        placeOfSupply: props.idInvoiceData.placeOfSupply,
        supplierName: props.idInvoiceData.supplierName,
        supplierRemarks: props.idInvoiceData.remarksSupplier,
        internalRemarks: props.idInvoiceData.remarksInternal,
        footer: props.idInvoiceData.footer,
        invoiceDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        supplierPaymentTerm: {
          paymentTermId: props.idInvoiceData.paymentTermId,
          termName: props.idInvoiceData.paymentTermName,
          numberOfDays: props.idInvoiceData.paymentTermDays,
        },
        isRootDiscount: rootDiscount.amountPercent ? true : false,
        rootDiscountPercent: rootDiscount.amountPercent || 0,
        expenseId: props.idInvoiceData.expenseId,
        rootDiscountAmount: Number(rootDiscount.amount || 0),
        expenseAmount: expenseAmount,
        isRootExpense: expenseAmount ? true : false,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        rfqNumber: props.idInvoiceData.rfqNumber,
        rfqMasterId: props.idInvoiceData.rfqMasterId,
        inboundDeliveryNumber: props.idInvoiceData.inboundDeliveryNumber,
        inboundDeliveryMasterId: props.idInvoiceData.inboundDeliveryMasterId,
        supplierQuoteNumber: props.idInvoiceData.supplierQuoteNumber,
        ewayBillNo: props.idInvoiceData.ewayBillNo,
      });

      props.resetIDInvoiceData();
    }

    if (
      // props.taxes &&
      // props.taxes.length &&
      props.isTaxesLoaded && 
      props.invoiceData &&
      props.invoiceData.invoiceMasterId && props.allLedgerAccounts?.length
    ) {

      const detailList = [];
      const relationshipBillingAddress = find(props.invoiceData.boLocationPurchaseInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {}
      const taxForLedger = props.invoiceData.supplierInvoiceDetailsList ? props.invoiceData.supplierInvoiceDetailsList[0]?.supplierInvoiceCOATxList?.filter(item => item.txType === LEDGER_TYPE.TYPE_TAX) : []
      const isPlaceOfSupplySameAsRelationship = relationshipBillingAddress.stateName === props.invoiceData?.placeOfSupply
      taxForLedger.map((item) => {
        if (props.companyInfo.countryName === "India" && !isPlaceOfSupplySameAsRelationship) {
          if (item.accountingEntry === "debit") {
            this.setState({
              igstPurchaseTaxLedgerId: item.ledgerAccountId
            })

          } else {
            this.setState({
              igstTaxCreditorsLedgerId: item.ledgerAccountId
            })
          }

        } else if (props.companyInfo.countryName === "India" && isPlaceOfSupplySameAsRelationship) {
          if (item.accountingEntry === "debit") {
            if (item.taxName === "CGST") {
              this.setState({
                cgstPurchaseTaxLedgerId: item.ledgerAccountId,
              })
            } else {
              this.setState({
                sgstPurchaseTaxLedgerId: item.ledgerAccountId
              })

            }

          } else {
            if (item.taxName === "CGST") {
              this.setState({
                cgstTaxCreditorsLedgerId: item.ledgerAccountId,
              })
            } else {
              this.setState({
                sgstTaxCreditorsLedgerId: item.ledgerAccountId
              })

            }

          }

        }
      })
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
          0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 &&
          (
            find(item.customerSalesOrderCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 &&
          taxPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
      };
      props.invoiceData.supplierInvoiceDetailsList.map((ele) => {
        const tax =
          find(ele.supplierInvoiceCOATxList, (ele) => {
            return ele.txType === LEDGER_TYPE.TYPE_TAX;
          }) || {};

        const appliedTax =
          find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};

        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName
            ? ele.itemName +
            ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          version: ele.version,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          discount:ele.discountPercentage,
          // : (
          //   find(ele.supplierInvoiceCOATxList, {
          //     txType: LEDGER_TYPE.TYPE_DISCOUNT,
          //   }) || {}
          // ).amountPercent,
          tax:  tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2:tax.amountPercent,
          taxApplied: appliedTax.taxNameDisplay,
          taxId: appliedTax.taxSingleRateId,
          taxName: appliedTax.taxName,
          quantityOrdered: ele.qtyOrderedFromPO,
          quantityInvoiced: ele.quantityInvoiced,
          qtyBeforeUpdate: ele.quantity,
          rate: ele.itemPurchasedPrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          inboundDeliveryNumber: props.invoiceData.inboundDeliveryNumber,
          inboundDeliveryMasterId: props.invoiceData.inboundDeliveryMasterId,
          parentDetailsId: props.update ? ele.parentDetailsId : 0,
          invoiceDetailsId: props.update ? ele.invoiceDetailsId : 0,
          sNo: ele.sNo
        });
      });
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }

      const rootDiscount =
        find(props.invoiceData.supplierInvoiceCOATxList || [], {
          txType: LEDGER_TYPE.TYPE_DISCOUNT,
        }) || {};
      const expenseAmount =
        (
          find(props.invoiceData.supplierInvoiceCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      const selectedContact =
        (props.invoiceData.additionalInfoList || [])[0] || {};
      const supplier = this.state.supplier || {};
      let selectedSupplierTaxIdentifications = [];
      const additionalInfoList = props.invoiceData.additionalInfoList[0] || {};
      if (selectedContact.taxIdentifications) {
        let taxIdentificationsList = JSON.parse(
          selectedContact.taxIdentifications
        );
        selectedSupplierTaxIdentifications = taxIdentificationsList.map(
          (obj, i) => {
            return obj.supplierTaxIdentificationId;
          }
        );
      }
      let rootExpenseList = [];
      let   rootDiscountList = [];

      // props.invoiceData.supplierInvoiceCOATxList.map((ledgerObj) => {
      //   if (ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE && ledgerObj.accountingEntry === "credit") {
      //       rootExpenseList.push({
      //           // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
      //           chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
      //           expenseLedgerAccountName: ledgerObj.ledgerAccountName,
      //           expenseAmount: ledgerObj.amount,  
      //           selectedExpenseLedger: {
      //               taxSingleRatePercent: ledgerObj.taxId,
      //               taxSingleRateId: ledgerObj.taxRate
      //           }
      //       });
      //   }
      // });
      // above code changed to below to remove warnigns
      for (let ledgerObj of props.invoiceData?.supplierInvoiceCOATxList || []) {
        if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE &&
          ledgerObj.accountingEntry === "debit"
        ) {
           let ledger = 
            find(props.allLedgerAccounts || [], {
              chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            }) || {}
          
          rootExpenseList.push({
            // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
            chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            ledgerAccountName: ledgerObj.ledgerAccountName,
            expenseLedgerAccountTaxRate:ledger.taxSingleRatePercent,
            expenseAmount: ledgerObj.amount,
            selectedExpenseLedger: {
              ...ledger
            },
          });
        }
      }

      for (let ledgerObj of props.invoiceData?.supplierInvoiceCOATxList || []) {
        if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT &&
          ledgerObj.accountingEntry === "credit"
        ) {
          let ledger = 
          find(props.allLedgerAccounts || [], {
            chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
          }) || {}
          rootDiscountList.push({
            // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
            chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            isTaxable:ledgerObj.isTaxable,
            ledgerAccountName: ledger.ledgerAccountName,
            expenseAmount: ledgerObj.amount,
            rootDiscountPercent:ledgerObj.amountPercent,
            rootDiscountAmount:ledgerObj.amount,
            selectedExpenseLedger: {
              ...ledger
            },
          });
        }
      }
      this.setState({
        update: props.update,
        rootExpenseList:rootExpenseList,
        rootDiscountList:rootDiscountList,
        valueSet:true,
        supplierInvoiceNumberReplica:props.update? props.invoiceData.supplierInvoiceNumber:null,
        invoiceMasterId: props.update ? props.invoiceData.invoiceMasterId : 0,
        invoiceNumber: props.update ? props.invoiceData.invoiceNumber : 0,
        selectedSupplierTaxIdentifications,
        isCashPurchase: props.invoiceData.isCashInvoice ? true : false,
        supplier: {
          ...supplier,
          id: props.invoiceData.supplierId,
          supplierId: props.invoiceData.supplierId,
          name: props.invoiceData.supplierName,
        },
        gstinNo: props.invoiceData.gstinNo,
        relationshipBillingAddress: find(
          props.invoiceData.boLocationPurchaseInvoiceList,
          { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }
        ),
        supplierBillingAddress: find(
          props.invoiceData.boLocationPurchaseInvoiceList,
          { locationType: CONSTANTS.BILLING_ADDRESS }
        ),
        selectedContact: (props.invoiceData.additionalInfoList || [])[0] || {},
        customerPaymentTerm: props.paymentTerms.length
          ? find(props.paymentTerms, {
            paymentTermId: props.invoiceData.paymentTermId,
          }) || {}
          : {},
        poMasterId: props.invoiceData.poMasterId,
        purchaseOrderId: props.invoiceData.poId,
        isItemDiscountAplicable:props.invoiceData?.isItemDiscountAplicable,
        supplierPo: props.invoiceData.poNumber,
        supplierInvoiceNumber:props.update? props.invoiceData.supplierInvoiceNumber:null,
        reference: props.invoiceData.referenceNumber,
        invoiceDate: new Date(props.invoiceData.invoiceDate),
        invoiceDueDate: new Date(props.invoiceData.invoiceDueDate),
        projectMasterId: props.invoiceData.projectMasterId,
        projectName: props.invoiceData.projectName,
        projectNumber: props.invoiceData.projectNumber,
        customerPo: props.invoiceData.customerPONumber,
        placeOfSupply: props.invoiceData.placeOfSupply,
        supplierName: props.invoiceData.supplierName,
        supplierRemarks: props.invoiceData.remarksSupplier,
        internalRemarks: props.invoiceData.remarksInternal,
        footer: props.invoiceData.footer,
        invoiceDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        supplierPaymentTerm: {
          paymentTermId: props.invoiceData.paymentTermId,
          termName: props.invoiceData.paymentTermName,
          numberOfDays: props.idInvoiceData.invoiceData,
        },
        isRootDiscount: rootDiscount.txType ? true : false,
        rootDiscountPercent: rootDiscount.amountPercent || 0,
        expenseId: props.invoiceData.expenseId,
        rootDiscountAmount: Number(rootDiscount.amount || 0),
        expenseAmount: expenseAmount,
        isRootExpense: expenseAmount ? true : false,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        rfqNumber: props.invoiceData.rfqNumber,
        rfqMasterId: props.invoiceData.rfqMasterId,
        inboundDeliveryNumber: props.invoiceData.inboundDeliveryNumber,
        inboundDeliveryMasterId: props.invoiceData.inboundDeliveryMasterId,
        conversionIDToPIRefList:
          props.invoiceData.conversionIDToPIRefList || [],
        conversionRefList: props.invoiceData.conversionRefList || [],
        supplierPaymentsList: props.update
          ? props.invoiceData.supplierPaymentsList
          : [],
        oldPayload: props.update ? props.invoiceData : {},
        isPOConversion:
          props.update && props.invoiceData.poMasterId ? true : false,
        isIDConversion:
          props.update && props.invoiceData.isIDConversion ? true : false,
        docName: props.invoiceData.documentName,
        supplierQuoteNumber: props.invoiceData.supplierQuoteNumber,
        docDetailList: props.invoiceData.docDetailList,
        version: props.invoiceData.version,
        selectedRelationshipTaxIdentifications:
          additionalInfoList.relationshipTaxIdentifications
            ? JSON.parse(additionalInfoList.relationshipTaxIdentifications).map(
              (obj) => {
                return obj.relationshipTaxIdentificationId;
              }
            )
            : this.state.selectedRelationshipTaxIdentifications,

        ewayBillNo: props.invoiceData.ewayBillNo,
        
      });
      if (props.update) {
        this.setState({ nextTxNumber: props.invoiceData.invoiceNumber });
      }
      props.resetPurchasedInvoiceDetail();
    }

    if (
      // props.taxes &&
      // props.taxes.length &&
      props.isTaxesLoaded &&
      this.state.supplierInvoiceDraftId &&
      this.state.invoiceDraftData
    ) {
      const detailList = [];
      const relationshipBillingAddress = find(this.state.invoiceDraftData.boLocationPurchaseInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {}
      const taxForLedger = this.state.invoiceDraftData.supplierInvoiceDetailsList ? this.state.invoiceDraftData.supplierInvoiceDetailsList[0]?.supplierInvoiceCOATxList?.filter(item => item.txType === LEDGER_TYPE.TYPE_TAX) : []
      const isPlaceOfSupplySameAsRelationship = relationshipBillingAddress.stateName === this.state.invoiceDraftData?.placeOfSupply
      taxForLedger.map((item) => {
        if (props.companyInfo.countryName === "India" && !isPlaceOfSupplySameAsRelationship) {
          if (item.accountingEntry === "debit") {
            this.setState({
              igstPurchaseTaxLedgerId: item.ledgerAccountId
            })

          } else {
            this.setState({
              igstTaxCreditorsLedgerId: item.ledgerAccountId
            })
          }

        } else if (props.companyInfo.countryName === "India" && isPlaceOfSupplySameAsRelationship) {
          if (item.accountingEntry === "debit") {
            if (item.taxName === "CGST") {
              this.setState({
                cgstPurchaseTaxLedgerId: item.ledgerAccountId,
              })
            } else {
              this.setState({
                sgstPurchaseTaxLedgerId: item.ledgerAccountId
              })

            }

          } else {
            if (item.taxName === "CGST") {
              this.setState({
                cgstTaxCreditorsLedgerId: item.ledgerAccountId,
              })
            } else {
              this.setState({
                sgstTaxCreditorsLedgerId: item.ledgerAccountId
              })

            }

          }

        }
      })
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
          0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 &&
          (
            find(item.customerSalesOrderCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 &&
          taxPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
      };
      this.state.invoiceDraftData.supplierInvoiceDetailsList.map((ele) => {
        const tax =
          find(ele.supplierInvoiceCOATxList, (ele) => {
            return ele.txType === LEDGER_TYPE.TYPE_TAX;
          }) || {};
          const appliedTax =
          find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};

        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName
            ? ele.itemName +
            ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          discount :ele.discountPercentage,
          qty: ele.quantity,
          discount: (
            find(ele.supplierInvoiceCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent,
          tax: tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2:tax.amountPercent,
          taxApplied: appliedTax.taxNameDisplay,
          taxId: appliedTax.taxSingleRateId,
          taxName: appliedTax.taxName,
          quantityOrdered: ele.qtyOrderedFromPO,
          quantityInvoiced: ele.quantityInvoiced,
          rate: ele.itemPurchasedPrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          inboundDeliveryNumber:
            this.state.invoiceDraftData.inboundDeliveryNumber,
          inboundDeliveryMasterId:
            this.state.invoiceDraftData.inboundDeliveryMasterId,
          parentDetailsId: props.update ? ele.parentDetailsId : 0,
        });
      });
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }

      const rootDiscount =
        find(this.state.invoiceDraftData.supplierInvoiceCOATxList || [], {
          txType: LEDGER_TYPE.TYPE_DISCOUNT,
        }) || {};
      const expenseAmount =
        (
          find(this.state.invoiceDraftData.supplierInvoiceCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      const selectedContact =
        (this.state.invoiceDraftData.additionalInfoList || [])[0] || {};
      const supplier = this.state.supplier || {};
      let selectedSupplierTaxIdentifications = [];
      if (selectedContact.taxIdentifications) {
        let taxIdentificationsList = JSON.parse(
          selectedContact.taxIdentifications
        );
        selectedSupplierTaxIdentifications = taxIdentificationsList.map(
          (obj, i) => {
            return obj.supplierTaxIdentificationId;
          }
        );
      }

      setTimeout(() => {
        if (this.state.invoiceDraftData) {
          this.setState({
            update: props.update,
            invoiceMasterId: props.update
              ? this.state.invoiceDraftData.invoiceMasterId
              : 0,
            invoiceNumber: props.update
              ? this.state.invoiceDraftData.invoiceNumber
              : 0,
            selectedSupplierTaxIdentifications,
            supplier: {
              ...supplier,
              id: this.state.invoiceDraftData.supplierId,
              supplierId: this.state.invoiceDraftData.supplierId,
              name: this.state.invoiceDraftData.supplierName,
            },
            relationshipBillingAddress: find(
              this.state.invoiceDraftData.boLocationPurchaseInvoiceList,
              { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }
            ),
            supplierBillingAddress: find(
              this.state.invoiceDraftData.boLocationPurchaseInvoiceList,
              { locationType: CONSTANTS.BILLING_ADDRESS }
            ),
            selectedContact:
              (this.state.invoiceDraftData.additionalInfoList || [])[0] || {},
            customerPaymentTerm: props.paymentTerms.length
              ? find(props.paymentTerms, {
                paymentTermId: this.state.invoiceDraftData.paymentTermId,
              }) || {}
              : {},
              gstinNo: props.invoiceDraftData?.gstinNo,
        isItemDiscountAplicable:props.invoiceDraftData?.isItemDiscountAplicable,
            poMasterId: this.state.invoiceDraftData.poMasterId,
            purchaseOrderId: this.state.invoiceDraftData.poId,
            supplierPo: this.state.invoiceDraftData.poNumber,
            reference: this.state.invoiceDraftData.referenceNumber,
            projectMasterId: this.state.invoiceDraftData.projectMasterId,
            projectName: this.state.invoiceDraftData.projectName,
            projectNumber: this.state.invoiceDraftData.projectNumber,
            customerPo: this.state.invoiceDraftData.customerPONumber,
            placeOfSupply: this.state.invoiceDraftData.placeOfSupply,
            supplierName: this.state.invoiceDraftData.supplierName,
            supplierRemarks: this.state.invoiceDraftData.remarksSupplier,
            internalRemarks: this.state.invoiceDraftData.remarksInternal,
            supplierInvoiceNumber:
              this.state.invoiceDraftData.supplierInvoiceNumber,
            footer: this.state.invoiceDraftData.footer,
            invoiceDetailsList: detailList,
            supplierPaymentTerm: {
              paymentTermId: this.state.invoiceDraftData.paymentTermId,
              termName: this.state.invoiceDraftData.paymentTermName,
              numberOfDays: this.state.invoiceDraftData.paymentTermDays,
            },
            isRootDiscount: rootDiscount.txType ? true : false,
            rootDiscountPercent: rootDiscount.amountPercent || 0,
            invoiceDate: this.state.invoiceDraftData.invoiceDate
              ? new Date(this.state.invoiceDraftData.invoiceDate)
              : null,
            invoiceDueDate: this.state.invoiceDraftData.invoiceDueDate
              ? new Date(this.state.invoiceDraftData.invoiceDueDate)
              : null,
            expenseId: this.state.invoiceDraftData.expenseId,
            rootDiscountAmount: Number(rootDiscount.amount || 0),
            expenseAmount: expenseAmount,
            isRootExpense: expenseAmount ? true : false,
            inheritedTxColumn: inheritedTxColumn,
            forceHideTxColumn: forceHideTxColumn,
            tableDataReRendered: true,
            rfqNumber: this.state.invoiceDraftData.rfqNumber,
            rfqMasterId: this.state.invoiceDraftData.rfqMasterId,
            inboundDeliveryNumber:
              this.state.invoiceDraftData.inboundDeliveryNumber,
            inboundDeliveryMasterId:
              this.state.invoiceDraftData.inboundDeliveryMasterId,
            conversionIDToPIRefList:
              this.state.invoiceDraftData.conversionIDToPIRefList || [],
            conversionRefList:
              this.state.invoiceDraftData.conversionRefList || [],
            supplierPaymentsList: this.state.update
              ? this.state.invoiceDraftData.supplierPaymentsList
              : [],
            invoiceDraftData: null,
            docName: this.state.invoiceDraftData.documentName,
            isCashPurchase: this.state.invoiceDraftData.isCashInvoice
              ? true
              : false,
            supplierQuoteNumber:
              this.state.invoiceDraftData.supplierQuoteNumber,
          });
        }
      });
    }

    if (
      this.state.stateSupplierId &&
      props.supplierDetail &&
      props.supplierDetail.supplierId &&
      props.paymentTerms &&
      props.paymentTerms.length
    ) {
      const supplierObj = props.supplierDetail || {};
      const supplierPaymentTerm = find(props.paymentTerms, {
        paymentTermId: supplierObj.paymentTermId,
      });
      const allSupplierBillingAddress = filter(supplierObj.boLocationList, {
        locationType: CONSTANTS.BILLING_ADDRESS,
      });
      const defaultBillingAddress = find(allSupplierBillingAddress, {
        isDefault: 1,
      });
      const selectedContact =
        (supplierObj.boContactList || []).find((x) => x.isPrimaryContact) || {};
      const selectedList =
        (supplierObj &&
          filter(supplierObj.supplierTaxIdentificationsList, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedSupplierTaxIdentifications = selectedList.map((obj) => {
        return obj.supplierTaxIdentificationId;
      });

      const isPlaceOfSupplySameAsRelationship =
        (defaultBillingAddress || {}).stateName === this.state.placeOfSupply;
      this.setState({
        stateSupplierId: null,
        supplierAddresses:
          (supplierObj.boLocationList || []).filter(
            (x) => x.locationType === CONSTANTS.TYPE_SHIPPING_ADDRESS
          ) || [],
        supplier: {
          id: supplierObj.supplierId,
          supplierId: supplierObj.supplierId,
          name: supplierObj.supplierStoreName,
          supplierTaxIdentificationsList:
            supplierObj.supplierTaxIdentificationsList,
        },
        supplierPaymentTerm:
          this.state.supplierPaymentTerm || supplierPaymentTerm,
        supplierBillingAddress: defaultBillingAddress,
        selectedContact,
        boContactList: supplierObj.boContactList || [],
        selectedSupplierTaxIdentifications,
        allBillingAddress: allSupplierBillingAddress,
        isPlaceOfSupplySameAsRelationship,
      });
    }
    if (props.addresses) {
      if (
        props.addresses.BillingAddress &&
        props.addresses.BillingAddress.length
      ) {
        this.setState({
          allBillingAddress: props.addresses.BillingAddress,
        });
      }
    }
    if (props.supplierContactList && props.supplierContactList.length) {
      this.setState({
        boContactList: props.supplierContactList,
      });
    }
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences =
        find(props.allPreferences, {
          txType: "purchaseInvoice",
          templateName: "standard",
        }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnList || []
          : [];
        txColumns = txColumns.length
          ? txColumns
          : (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          );
        const txColumnSetting = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnSetting || {}
          : {};
        const isColumnFitTable =
          Object.keys(txColumnSetting).length ||
            this.state.isColumnFitTable === false
            ? false
            : true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences,
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          ),
        });
      }
    }
  }

  openInvoiceDetails = (data) => {
    const invoicePayload = {
      supplierId: data.supplierId,
      invoiceMasterId: data.invoiceMasterId,
    };
    this.props.pushModalToStack({
      modalBody: (
        <PurchaseInvoiceDetail
          {...this.props}
          invoicePayload={invoicePayload}
        />
      ),
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 25,
      updateAllOptions,
      updateFetching,
    };
    this.props.fetchProducts(payload);
  }

  render() {
    return (
      <Fragment>
        {this.state.settingPurchaseDrawerVisible && 
          <SettingPurchaseDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
            updateDrawerState={(data) => { this.setState(data) }}
            />
        }

        <LedgerDrawer
          {...this.state}
          {...this.props}
          updateDrawerState={(data) => { this.setState(data) }}
          updateState={(data) => {
            this.setState(data);
          }}
        />
        {this.state.transportDataDrawerVisible && (
          <TransportDataDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}
        {this.props.ledgerAccountsDrawerVisible || this.state.ledgerAccountsDrawerVisible && (
          <LedgerAccountsDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
            updateDrawerState={(data) => { this.setState(data) }}
          />
        )}
        <PurchaseInvoiceComponent
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching);
          }}
          {...this.props}
          {...this.state}
          updateDrawerState={(data) => { this.setState(data) }}
          openInvoiceDetails={this.openInvoiceDetails}
          updateState={(data) => {
            this.setState(data);
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    priceList: state.common.priceList,
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    supplierAddresses: state.supplier.supplierAddresses,
    paymentTerms: state.common.paymentTerms,
    states: state.common.states,
    projectList: state.common.projectList,
    uomList: state.common.uomList,
    taxes: state.common.taxes,
    expenseTypes: state.common.expenseTypes,
    products: state.salesInvoice.products,
    poInvoiceData: state.purchaseInvoice.poInvoiceData,
    documentList: state.common.documentList,
    nextTxNumber: state.purchaseInvoice.nextTxNumber,
    allPreferences: state.common.allPreferences,
    remarksData: state.settings.remarksData,
    permissions: state.auth.permissions,
    idInvoiceData: state.purchaseInvoice.idInvoiceData,
    allRelationshipTaxIdentificatins:
      state.common.allRelationshipTaxIdentificatins,
    invoiceData: state.purchaseInvoice.purchaseInvoiceDetail,
    supplierSummary: state.supplier.supplierSummary,
    supplierDetail: state.common.supplierDetail,
    addresses: state.supplier.addresses,
    supplierContactList: state.supplier.contacts,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    saveLoading: state.common.saveLoading,
    suppliers: state.supplier.suppliers,
    detailLoading: state.common.detailLoading,
    isTaxesLoaded: state.common.isTaxesLoaded,

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPriceList,
      fetchProjects,
      fetchPaymentTerms,
      addPaymentTerm,
      deletePaymentTerm,
      fetchStates,
      fetchProducts,
      fetchTaxes,
      fetchUOM,
      addUOM,
      deleteUOM,
      fetchExpenseTypes,
      addExpenseTypes,
      deleteExpenseTypes,
      showModal,
      hideModal,
      createPurchaseInvoice,
      pushModalToStack,
      popModalFromStack,
      deleteSuppliers,
      deleteProject,
      deleteAddress,
      deleteTax,
      deletePriceList,
      fetchPurchasedOrderForInvoice,
      resetPoInvoiceData,
      fetchDocuments,
      addDocumentName,
      deleteDocumentName,
      getNextTxNumber,
      deleteContact,
      resetRemarksData,
      fetchUserPreferences,
      saveUserPrefrences,
      fetchRemarksTemplate,
      fetchIDForInvoice,
      resetIDInvoiceData,
      fetchRelationshipTaxIdentifications,
      deleteRelationshipTaxIdentifications,
      deleteSupplierTaxIdentifications,
      fetchPurchasedInvoiceDetail,
      resetPurchasedInvoiceDetail,
      createPurchaseInvoiceDraft,
      fetchPurchaseInvoices,
      getPurchaseInvoicesCount,
      deleteInvoice,
      fetchSupplierSummary,
      fetchSupplierDetailsBySupplierId,
      resetSupplierDetail,
      fetchAddresses,
      fetchAllContacts,
      getRelationshipAddress,
      fetchSuppliers,
      getItemByUPC,
      fetchAllLedgerAccounts,checkSupplierInvoiceNumber,
      resetPaginatedData,
      deleteDocument,
      fetchPurchaseInvoiceDrafts,
      getPurchaseInvoiceDraftCount
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoice);
