import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, PROJECT_ACTION_LIST } from '../../../static/constants';
//import {  formatDateForBackend } from '../../../utils';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const getAllProject = (payload) =>{
  return dispatch => {
    //dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
   // dispatch({ type: PROJECT_ACTION_LIST.RESET_TRANSACTION_IN_ALPIDE });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/projectMaster/getProjectDetails?relationshipId=${payload.relationshipId}&startDate=${payload.startDate || ''}&endDate=${payload.endDate || ''}`)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
         dispatch({
          type: PROJECT_ACTION_LIST.PROJECT_LIST,
          data: data
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PROJECT_ACTION_LIST.PROJECT_LIST;
        }
      })
  }
}

// export const getProjectsCount = (payload) =>{
//   return dispatch => {
//     return axios
//       .get(`${config.PLAID_BASE_URL}${config.rootContextPlaid}/plaid/countTransactionInAlpide?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//          dispatch({
//           type: PROJECT_ACTION_LIST.PROJECT_COUNT,
//           data: res.data
//         });
//       })
//       .catch((err = {}) => {
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = PROJECT_ACTION_LIST.TRANSACTION_COUNT_IN_ALPIDE;
//         }
//       })
//   }
// }

export const resetProjectList = () => {
  return dispatch => {
    dispatch({ type: PROJECT_ACTION_LIST.RESET_PROJECT_LIST });
  }
}

export const fetchProjects = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/projectMaster/getAllProjects?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {

        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: PROJECT_ACTION_LIST.PROJECT_DETAIL_LIST, data: data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = PROJECT_ACTION_LIST.PROJECT_DETAIL_LIST;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  };
};



export const getProjectById = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/projectMaster/getProjectById?relationshipId=${payload.relationshipId}&projectMasterId=${payload.projectMasterId || 0}`
      )
      .then((res) => {

        
        dispatch({ type: PROJECT_ACTION_LIST.PROJECT_BY_ID, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = PROJECT_ACTION_LIST.PROJECT_BY_ID;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  };
};


export const resetProjectById = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });


        
        dispatch({ type: PROJECT_ACTION_LIST.PROJECT_BY_ID, data: {} });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
  };
};


export const getAllProjectWithSubProject = (payload) =>{
  return dispatch => {
    //dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
   // dispatch({ type: PROJECT_ACTION_LIST.RESET_TRANSACTION_IN_ALPIDE });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/projectMaster/getAllProjectsWithSubProject?relationshipId=${payload.relationshipId}&startDate=${payload.startDate || ''}&endDate=${payload.endDate || ''}`)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      
         dispatch({
          type: PROJECT_ACTION_LIST.PROJECT_WITH_SUBPROJECT,
          data: res.data
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PROJECT_ACTION_LIST.PROJECT_WITH_SUBPROJECT;
        }
      })
  }
}