import { Col, Collapse, Drawer, Row, Icon, Divider, Radio } from "antd";
import React, { useCallback, useState, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import "./index.scss"
import { Dropdown } from '../../../general/Dropdown';
import { MultipleSelectDropdown } from "../../../general/MultipleSelectDropdown";
import Search from "antd/lib/transfer/search";

 const StatusCategoryModal = React.memo(({ crmLeadStatusList, parentData, pageNumber, updateDrawerState, hideModal, callBack }) => {
  const [searchText, setSearchText] = useState('');
  console.log("dddddddd", crmLeadStatusList);

  const handleCategoryChange = useCallback((e) => {
    if (callBack) {
      callBack(e.target.value)
    }
    // updateDrawerState({ parentData: e.target.value, statusName: e.target.value.statusName, statusName: e.target.value.statusName });
    hideModal();
  }, [updateDrawerState, hideModal]);

  const renderSubcategories = useCallback((subLeadStatus, depth = 1) => {
    const indentStyle = {
      paddingLeft: `${depth * 10}px`,
    };
    return subLeadStatus?.map((subcategory, i) => (
      <div
        key={`${subcategory.statusName}-${i}`}
        className="radio-button-Wrapper"
        style={indentStyle}
      >
        <Radio value={subcategory}>
          <div className="parent-category-sub-wrapper">
            {subcategory.statusName}
          </div>
        </Radio>
        {subcategory.subLeadStatus?.length > 0 &&
          renderSubcategories(subcategory.subLeadStatus, depth + 1)}
      </div>
    ));
  }, []);

  const filteredProjectList = useMemo(() => {
    const currentPageList = crmLeadStatusList || [];
    if (!searchText) return currentPageList;

    return currentPageList.filter(category =>
      category.statusName.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [crmLeadStatusList, searchText]);

  return (
    <>
      <div className="searchBarArea">
        <Search
          placeholder="Search Category..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className="categoryArea">
        <Radio.Group
          onChange={handleCategoryChange}
          value={parentData}
        >
          {filteredProjectList.map((category, i) => (
            <div className="subcategory" key={`${category.statusName}-${i}`}>
              <Radio value={category}>
                <strong>{category.statusName}</strong>
              </Radio>
              {category.subLeadStatus?.length > 0 &&
                renderSubcategories(category.subLeadStatus)}
            </div>
          ))}
        </Radio.Group>
      </div>
    </>
  );
});
const CategoryModal = React.memo(({ crmLeadSourceList, parentData, pageNumber, updateDrawerState, hideModal }) => {
  const [searchText, setSearchText] = useState('');

  const handleCategoryChange = useCallback((e) => {
    updateDrawerState({ parentData: e.target.value });
    hideModal();
  }, [updateDrawerState, hideModal]);

  const renderSubcategories = useCallback((subLeadSource, depth = 1) => {
    const indentStyle = {
      paddingLeft: `${depth * 10}px`,
    };
    return subLeadSource?.map((subcategory, i) => (
      <div
        key={`${subcategory.leadSourceName}-${i}`}
        className="radio-button-Wrapper"
        style={indentStyle}
      >
        <Radio value={subcategory}>
          <div className="parent-category-sub-wrapper">
            {subcategory.leadSourceName}
          </div>
        </Radio>
        {subcategory.subLeadSource?.length > 0 &&
          renderSubcategories(subcategory.subLeadSource, depth + 1)}
      </div>
    ));
  }, []);

  const filteredProjectList = useMemo(() => {
    const currentPageList = crmLeadSourceList || [];
    if (!searchText) return currentPageList;

    return currentPageList.filter(category =>
      category.leadSourceName.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [crmLeadSourceList, searchText]);

  return (
    <>
      <div className="searchBarArea">
        <Search
          placeholder="Search Category..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className="categoryArea">
        <Radio.Group
          onChange={handleCategoryChange}
          value={parentData}
        >
          {filteredProjectList.map((category, i) => (
            <div className="subcategory" key={`${category.leadSourceName}-${i}`}>
              <Radio value={category}>
                {category.leadSourceName}
              </Radio>
              {category.subLeadSource?.length > 0 &&
                renderSubcategories(category.subLeadSource)}
            </div>
          ))}
        </Radio.Group>
      </div>
    </>
  );
});

const LeadWorkflowCreateComp = (props) => {
  const rowGutter = [24, 16];
  const { Panel } = Collapse;

  const closeDrawer = () => {
    props.updateState({
      leadSourceListDrawer: false,
    });
  };
  const customPanelStyle = {
    background: '#fff',
    borderRadius: 4,
    marginBottom: 24,
    border: 0,
    overflow: 'hidden',
  };
  const getHeaderForCollapse = (i) => {
    switch (i) {
      case 1:
        return (<div style={{ display: "flex", gap: '12px' }}>
          <div style={{ fontSize: '14px', fontWeight: "700", color: "#0075FF" }}>
            01
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontWeight: "600" }}>
              Lead Capture Stage

            </div>
            <div style={{ fontSize: "12px" }}>
              Gather leads with streamlined capture processes.
            </div>
          </div>

        </div>)
        break;

      case 2:
        return (<div style={{ display: "flex", gap: '12px' }}>
          <div style={{ fontSize: '14px', fontWeight: "700", color: "#0075FF" }}>
            02
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontWeight: "600" }}>
              Lead Tracking Stage

            </div>
            <div style={{ fontSize: "12px" }}>
              Track lead progress for informed decision-making.
            </div>
          </div>

        </div>)
        break;
      case 3:
        return (<div style={{ display: "flex", gap: '12px' }}>
          <div style={{ fontSize: '14px', fontWeight: "700", color: "#0075FF" }}>
            03
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontWeight: "600" }}>
              Lead Qualification Stage

            </div>
            <div style={{ fontSize: "12px" }}>
              Streamline lead qualification for targeted sales engagement.
            </div>
          </div>
        </div>)
        break;

      case 4:
        return (<div style={{ display: "flex", gap: '12px' }}>
          <div style={{ fontSize: '14px', fontWeight: "700", color: "#0075FF" }}>
            04
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontWeight: "600" }}>
              Lead Conversion Stage

            </div>
            <div style={{ fontSize: "12px" }}>
              Mange lead conversion for business success.
            </div>
          </div>

        </div>)
        break;

      default:
        break;
    }
  }
  const onChange = (e, val) => {
    props.updateDrawerState({
      captureStage: { ...props.captureStage, [val]: e.target.value }
    })
  };


  const handlePopUp = (callBack) => {
    const modalData = {
      title: "Confirmation",
      modalBody: <StatusCategoryModal callBack={callBack} {...props} />,
      width: 500,
      handleSubmit: () => {
      },
    };
    props.showModal(modalData);
  }
  return (
    <Drawer
      title={<FormattedMessage id={props.crmLeadSourceId ? "leads.leadForm.formMeta.leadSource.drawer.title.edit" : "leads.leadForm.formMeta.leadSource.drawer.title.create"} />}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.leadSourceListDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />

          <CustomButton
            intlId="button.save.label"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              props.updateDrawerState({ submitted: true });

              if (!props.workflowName || !props.campaignId) {
                return;
              }

              let listTosave = [];

              let v = props.listToadd?.forEach((item) => {
                if (item === 'captureStage' || item === 'qualificationStage') {
                  if (Object.keys(props[item] || {}).length) {
                    listTosave.push({ ...props[item], type: item, relationshipId: props.companyInfo.relationshipId });
                  }
                } else {
                  listTosave.push(...props[item]);
                }
              });

              // Filter out any empty objects
              listTosave = listTosave.filter(obj => Object.keys(obj).length > 1);

              const payload = {
                relationshipId: props.companyInfo.relationshipId,
                description: props.description,
                workflowName: props.workflowName,
                crmWorkflowTriggerSet: listTosave,
                campaignId: props.campaignId,
                campaignName: props.campaignName,
                workflowMasterId: props.workflowMasterId || 0,
                isActive: props.isActive
              };

              props.createLeadWorkFlow(payload, props);
              closeDrawer();
            }}

            key={1}
          />
        </>
      }
    >
      <Row gutter={rowGutter}>

        <Col span={24}>
          <div className="i-label">Workflow Name</div>
          <TextBox
            placeholder="Source Name"
            type="text"
            maxLength={20}
            value={props.workflowName}
            onChange={(e) => {
              props.updateDrawerState({ workflowName: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.workflowName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={24}>
          <div className="i-label">Description</div>
          <TextBox
            placeholder="Source Name"
            type="text"
            maxLength={120}
            value={props.description}
            onChange={(e) => {
              props.updateDrawerState({ description: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.description }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={24}>
          <div className='i-label'>
            Lead Campaign
          </div>
          <Dropdown
            items={(props.crmCampaignList[props.pageNumber || 1] || [])}
            valueKeyName='campaignName'
            value={props.campaignName}
            optionKeyName='campaignId'
            onSelect={
              (campaignId, obj) => {
                props.updateDrawerState({
                  campaignId,
                  campaignName: obj.name
                });
              }}
            placeholder={<FormattedMessage id='common.select.placeholder' />}
            allowClear={true}
            onClear={() => {
              props.updateDrawerState({ campaignId: undefined, campaignName: undefined })
            }}
          />

        </Col>



        {props.campaignId
          ?
          (props.crmCampaignList[props.pageNumber || 1] || []).find((item) => item.campaignId === props.campaignId)
            ?
            <Col className="detail-para" span={24}>This Campaign is associated with Project -
              {`${(props.crmCampaignList[props.pageNumber || 1] || []).find((item) => item.campaignId === props.campaignId).projectName}`} </Col> : "" : ""}

        <Col span={24}>
          <div className="workflow-collaps">

            <Collapse
              bordered={false}
              defaultActiveKey={['3']}
              expandIconPosition="right"
            >
              <Panel header={getHeaderForCollapse(1)} key="1" style={customPanelStyle}>
                <div>
                  <div className="heading">Workflow</div>
                  <div>When a lead is captured through a web form, social media, or other channels.</div>
                  <div>
                    <div>Trigger 1: Lead Routing Rules</div>
                    <div>Lead automatically routes to</div>
                    <Radio.Group onChange={(e) => onChange(e, "routTo")} value={props.captureStage?.routTo}>
                      <Radio value={"both"}>Both</Radio>
                      <Radio value={"team"}>Team</Radio>
                      <Radio value={"member"}>Member</Radio>
                    </Radio.Group>
                  </div>
                  <div>
                    <div>Select Team</div>
                    <div>
                      <Col span={8}>
                        <div className='i-label'>Assign To Team<span className='required'>*</span></div>
                        <MultipleSelectDropdown
                          items={props.teamList[1] || []}
                          placeholder={'Assign To Team'}
                          mode='multiple'
                          onDeSelect={(teamId, options) => {
                            let tempList = props.captureStage?.crmLeadTeamAssignedList || [];
                            let temp = props.captureStage?.selectedTeam || [];
                            const empExits = tempList.find((obj) => Number(obj.teamId) === Number(teamId))
                            const teamExits = temp.find((obj) => obj === options.name)
                            if (empExits) {
                              tempList = tempList.filter((obj) => Number(obj.teamId) !== Number(teamId));
                              props.updateDrawerState({ captureStage: { ...props.captureStage, crmLeadTeamAssignedList: tempList } });
                            }
                            if (teamExits) {
                              temp = temp.filter((obj) => obj !== options.name);
                              props.updateDrawerState({ captureStage: { ...props.captureStage, crmLeadTeamAssignedList: tempList, selectedTeam: temp } });

                              const payload = {
                                relationshipId: props.companyInfo?.relationshipId,
                                teamNames: temp?.join(",")
                              };
                              props.getTeamMembersByTeamName(payload);
                            }


                          }}
                          valueKeyName='teamName'
                          selectedValue={(props.captureStage?.crmLeadTeamAssignedList || []).map((obj) => { return obj.teamId })}
                          optionKeyName='teamId'
                          onSelect={(teamId, options) => {
                            let tem = props.captureStage?.selectedTeam || [];
                            let tempList = props.captureStage?.crmLeadTeamAssignedList || [];
                            tempList.push({ teamId: Number(options.key), relationshipId: props.companyInfo.relationshipId });
                            tem.push(options.name);
                            props.updateDrawerState({ captureStage: { ...props.captureStage, crmLeadTeamAssignedList: tempList, selectedTeam: tem } });
                            const payload = {
                              relationshipId: props.companyInfo?.relationshipId,
                              teamNames: tem?.join(",")
                            };
                            props.getTeamMembersByTeamName(payload);
                          }}
                        />
                      </Col>

                      {props.employeeByTeamName?.length ? <Col span={8}>
                        <div className='i-label'>Assign Team Member<span className='required'>*</span></div>
                        <MultipleSelectDropdown
                          items={props.employeeByTeamName || []}
                          placeholder={'Assign to'}
                          mode='multiple'
                          onDeSelect={(empId, options) => {
                            let tempList = props.captureStage?.crmLeadEmpAssignedList || [];
                            const empExits = tempList.find((obj) => Number(obj.relEmpId) === Number(empId))
                            if (empExits) {
                              tempList = tempList.filter((obj) => Number(obj.relEmpId) !== Number(empId));
                              props.updateDrawerState({ captureStage: { ...props.captureStage, crmLeadEmpAssignedList: tempList } });
                            }
                          }}
                          valueKeyName='fullName'
                          valueKeyName2='hierarchyName'
                          selectedValue={(props.crmLeadEmpAssignedList || []).map((obj) => { return obj.relEmpId })}
                          optionKeyName='relationshipEmployeeId'
                          onSelect={(empId, options) => {
                            let tempList = props.captureStage.crmLeadEmpAssignedList || [];
                            tempList.push({ relEmpId: Number(options.key), relationshipId: props.companyInfo.relationshipId });
                            props.updateDrawerState({ captureStage: { ...props.captureStage, crmLeadEmpAssignedList: tempList } });
                          }}
                        />
                      </Col> : ""}
                    </div>


                  </div>
                  <div>
                    <div>Trigger 2: Lead Distribution Rules</div>
                    <div>Lead distribute among</div>
                    <Radio.Group onChange={(e) => onChange(e, "distributeTo")} value={props.captureStage?.distributeTo}>
                      <Radio value={"evenly"}>Evenly</Radio>
                      <Radio value={"roundRobin"}>Round-Robin</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </Panel>
              <Divider></Divider>

              <Panel header={getHeaderForCollapse(2)} key="2" style={customPanelStyle}>
                <div>
                  <div className="heading">Workflow</div>
                  <div>Leads progress through the sales pipeline, and their interactions.</div>
                  {props.trackingStage?.map((item, index) => {
                    return (
                      <div key={index}>
                        <div>Trigger {index + 1}: Activity-Based Assignment</div>
                        <div>Leads with recent activities conditions</div>
                        <Row gutter={[10, 10]}>

                          <Col span={12}>
                            <div className="i-label">
                              When lead status is equal to<span className="required">*</span>
                            </div>
                            {/* <Dropdown
                              items={props.crmLeadStatusList || []}
                              valueKeyName="statusName"
                              optionKeyName="leadStatusId"
                              value={item.leadStageName || ""}
                              placeholder={"Select Source"}
                              onSelect={(id, options) => {
                                let val = [...props.trackingStage];
                                val[index] = {
                                  ...val[index],
                                  leadStageName: options.name,
                                  leadStageId: options.value,
                                };
                                props.updateDrawerState({
                                  trackingStage: val,
                                });
                              }}
                            /> */}

                            <>

                              <div onClick={() => {
                                const callBack = (data) => {
                                  console.log("dddddddddddddddddd", data);

                                  let val = [...props.trackingStage];
                                  val[index] = {
                                    ...val[index],
                                    leadStageName: data.statusName,
                                    leadStageId: data.leadStatusId,
                                  };
                                  props.updateDrawerState({
                                    trackingStage: val,
                                  });
                                }

                                handlePopUp(callBack)
                              }}>
                                <TextBox
                                  value={
                                    item.leadStageName || ""
                                  }
                                  placeholder={'Enter Parent Category'}
                                />
                              </div>

                            </>
                            {console.log("sdiuvsdjvbsjvsbjsbvsjkvbsjvjkbsdvjbdsvjdsv", props)}
                          </Col>

                          <Col span={12}>
                            <div className="i-label">
                              Take Action<span className="required">*</span>
                            </div>
                            <Dropdown
                              items={["Email", "SMS", "WhatsApp", "Move to"]}
                              value={item.selectedAction || ""}
                              placeholder={"Select Action"}
                              onSelect={(id, options) => {
                                let val = [...props.trackingStage];
                                val[index] = {
                                  ...val[index],
                                  selectedAction: options.name,
                                };
                                props.updateDrawerState({
                                  trackingStage: val,
                                });
                              }}
                            />
                          </Col>
                          {item.selectedAction === "Move to" ? (
                            <Col span={12}>
                              <div className="i-label">
                                Move to<span className="required">*</span>
                              </div>
                              {/* <Dropdown
                                items={props.crmLeadStatusList || []}
                                valueKeyName="statusName"
                                optionKeyName="leadStatusId"
                                value={item.leadStageTargetName || ""}
                                placeholder={"Select Target"}
                                onSelect={(id, options) => {
                                  let val = [...props.trackingStage];
                                  val[index] = {
                                    ...val[index],
                                    leadStageTargetName: options.name,
                                    leadStageTargetId: options.value,
                                  };
                                  props.updateDrawerState({
                                    trackingStage: val,
                                  });
                                }}
                              /> */}

                              <>

                                <div onClick={() => {
                                  const callBack = (data) => {

                                    let val = [...props.trackingStage];
                                    val[index] = {
                                      ...val[index],
                                      leadStageTargetName: data.statusName,
                                      leadStageTargetId: data.leadStatusId,
                                    };
                                    props.updateDrawerState({
                                      trackingStage: val,
                                    });



                                  }

                                  handlePopUp(callBack)
                                }}>
                                  <TextBox
                                    value={
                                      item.leadStageTargetName || ""
                                    }
                                    placeholder={'Enter Parent Category'}
                                  />
                                </div>

                              </>
                            </Col>
                          ) : item.selectedAction?.length && item.selectedAction !== "Move to" ? (
                            <Col span={12}>
                              <div className="i-label">
                                Select Template<span className="required">*</span>
                              </div>
                              <Dropdown
                                items={["Template"]}

                                // value={item.leadStageTargetName || ""}
                                placeholder={"Select Target"}
                                onSelect={(id, options) => {
                                  // let val = [...props.trackingStage];
                                  // val[index] = {
                                  //   ...val[index],
                                  //   leadStageTargetName: options.name,
                                  //   leadStageTargetId: options.value,
                                  // };
                                  // props.updateDrawerState({
                                  //   trackingStage: val,
                                  // });
                                }}
                              />
                            </Col>
                          ) : ""}


                        </Row>
                      </div>
                    );
                  })}
                  <div
                    style={{ cursor: "pointer", color: "blue", width: "100px" }}
                    onClick={() => {
                      let stage = [...props.trackingStage];
                      stage.push({ type: "trackingStage", relationshipId: props.companyInfo.relEmpId });
                      props.updateDrawerState({
                        trackingStage: stage,
                      });
                    }}
                  >
                    Add New
                  </div>
                </div>
              </Panel>

              <Divider></Divider>

              <Panel header={getHeaderForCollapse(3)} key="3" style={customPanelStyle}>
                <div>
                  <div>Leads requiring review before lead to be qualified for assessment.</div>
                  <div>
                    <div>Trigger 1: Manual Approval</div>
                    <div>Lead Approval required</div>
                    <Radio.Group onChange={(e) => {
                      props.updateDrawerState({
                        qualificationStage: { ...props.qualificationStage, approveRequired: e.target.value }
                      })
                    }} value={props.qualificationStage?.approveRequired}>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </div>
                  {props.qualificationStage?.approveRequired ? <Row gutter={[10, 10]}>
                    <Col span={12}>
                      <div className="i-label">
                        Source<span className="required">*</span>
                      </div>

                      <>

                        <div onClick={() => {
                          const callBack = (data) => {

                            props.updateDrawerState({
                              qualificationStage: { ...props.qualificationStage, leadStageName: data.statusName, leadStageId: data.leadStatusId },
                            });
                          }

                          handlePopUp(callBack)
                        }}>
                          <TextBox
                            value={
                              props.qualificationStage?.leadStageName || ""
                            }
                            placeholder={'Enter Parent Category'}
                          />
                        </div>

                      </>
                    </Col>

                  </Row> : ""}
                  {props.qualificationStage?.approveRequired && props.qualificationStage?.leadStageName ? <div>
                    <div>Select Team</div>

                    <Col span={8}>
                      <div className='i-label'>Assign To Team<span className='required'>*</span></div>
                      <MultipleSelectDropdown
                        items={props.teamList[1] || []}
                        placeholder={'Assign To Team'}
                        mode='multiple'
                        onDeSelect={(teamId, options) => {
                          let tempList = props.qualificationStage?.crmLeadTeamAssignedList || [];
                          let temp = props.qualificationStage?.selectedTeam || [];
                          const empExits = tempList.find((obj) => Number(obj.teamId) === Number(teamId))
                          const teamExits = temp.find((obj) => obj === options.name)
                          if (empExits) {
                            tempList = tempList.filter((obj) => Number(obj.teamId) !== Number(teamId));
                            props.updateDrawerState({ qualificationStage: { ...props.qualificationStage, crmLeadTeamAssignedList: tempList } });
                          }
                          if (teamExits) {
                            temp = temp.filter((obj) => obj !== options.name);
                            props.updateDrawerState({ qualificationStage: { ...props.qualificationStage, crmLeadTeamAssignedList: tempList, selectedTeam: temp } });

                            const payload = {
                              relationshipId: props.companyInfo?.relationshipId,
                              teamNames: temp?.join(",")
                            };
                            props.getTeamMembersByTeamName(payload);
                          }


                        }}
                        valueKeyName='teamName'
                        selectedValue={(props.qualificationStage?.crmLeadTeamAssignedList || []).map((obj) => { return obj.teamId })}
                        optionKeyName='teamId'
                        onSelect={(teamId, options) => {
                          let tem = props.qualificationStage?.selectedTeam || [];
                          let tempList = props.qualificationStage?.crmLeadTeamAssignedList || [];
                          tempList.push({ teamId: Number(options.key), relationshipId: props.companyInfo.relationshipId });
                          tem.push(options.name);
                          props.updateDrawerState({ qualificationStage: { ...props.qualificationStage, crmLeadTeamAssignedList: tempList, selectedTeam: tem } });
                          const payload = {
                            relationshipId: props.companyInfo?.relationshipId,
                            teamNames: tem?.join(",")
                          };
                          props.getTeamMembersByTeamName(payload);
                        }}
                      />
                    </Col>

                    {props.employeeByTeamName?.length ? <Col span={8}>
                      <div className='i-label'>Assign Team Member<span className='required'>*</span></div>
                      <MultipleSelectDropdown
                        items={props.employeeByTeamName || []}
                        placeholder={'Assign to'}
                        mode='multiple'
                        onDeSelect={(empId, options) => {
                          let tempList = props.qualificationStage?.crmLeadEmpAssignedList || [];
                          const empExits = tempList.find((obj) => Number(obj.relEmpId) === Number(empId))
                          if (empExits) {
                            tempList = tempList.filter((obj) => Number(obj.relEmpId) !== Number(empId));
                            props.updateDrawerState({ qualificationStage: { ...props.qualificationStage, crmLeadEmpAssignedList: tempList } });
                          }
                        }}
                        valueKeyName='fullName'
                        valueKeyName2='hierarchyName'
                        selectedValue={(props.crmLeadEmpAssignedList || []).map((obj) => { return obj.relEmpId })}
                        optionKeyName='relationshipEmployeeId'
                        onSelect={(empId, options) => {
                          let tempList = props.qualificationStage.crmLeadEmpAssignedList || [];
                          tempList.push({ relEmpId: Number(options.key), relationshipId: props.companyInfo.relationshipId });
                          props.updateDrawerState({ qualificationStage: { ...props.qualificationStage, crmLeadEmpAssignedList: tempList } });
                        }}
                      />
                    </Col> : ""}

                  </div> : ""}
                </div>
              </Panel>
              <Divider></Divider>

              <Panel header={getHeaderForCollapse(4)} key="4" style={customPanelStyle}>
                <div>
                  <div className="heading">Workflow</div>
                  <div>Leads that have been qualified and are ready for conversion into opportunities or customers.</div>
                  {props.conversionStage?.map((item, index) => {
                    return (
                      <div key={index}>
                        <div>Trigger {index + 1}:Conversion Creation</div>
                        <div>Leads meet the criteria for creating sales opportunities</div>
                        <Row gutter={[10, 10]}>

                          <Col span={12}>
                            <div className="i-label">
                              When Lead Status Equal To<span className="required">*</span>
                            </div>
                            <>

                              <div onClick={() => {
                                const callBack = (data) => {
                                  let val = [...props.conversionStage];
                                  val[index] = {
                                    ...val[index],
                                    leadStageName: data.statusName,
                                    leadStageId: data.leadStatusId,
                                  };
                                  props.updateDrawerState({
                                    conversionStage: val,
                                  });
                                }

                                handlePopUp(callBack)
                              }}>
                                <TextBox
                                  value={
                                    item.leadStageName || ""
                                  }
                                  placeholder={'Enter Parent Category'}
                                />
                              </div>

                            </>
                          </Col>

                          <Col span={12}>
                            <div className="i-label">
                              Take Action<span className="required">*</span>
                            </div>
                            <Dropdown
                              items={["Convert To"]}
                              value={item.selectedAction || ""}
                              placeholder={"Select Action"}
                              onSelect={(id, options) => {
                                let val = [...props.conversionStage];
                                val[index] = {
                                  ...val[index],
                                  selectedAction: options.name,
                                };
                                props.updateDrawerState({
                                  conversionStage: val,
                                });
                              }}
                            />
                          </Col>
                          {item.selectedAction === "Convert To" ? (
                            <Col span={12}>
                              <div className="i-label">
                                Move to<span className="required">*</span>
                              </div>
                              <Dropdown
                                items={["Customer"]}

                                value={item.converTo || ""}
                                placeholder={"Select Target"}
                                onSelect={(id, options) => {
                                  let val = [...props.conversionStage];
                                  val[index] = {
                                    ...val[index],
                                    converTo: options.value,
                                  };
                                  props.updateDrawerState({
                                    conversionStage: val,
                                  });
                                }}
                              />
                            </Col>
                          ) : ""}


                        </Row>
                      </div>
                    );
                  })}
                  <div
                    style={{ cursor: "pointer", color: "blue", width: "100px" }}
                    onClick={() => {
                      let stage = [...props.conversionStage];
                      stage.push({ type: "conversionStage" });
                      props.updateDrawerState({
                        conversionStage: stage,
                      });
                    }}
                  >
                    Add New
                  </div>
                </div>
              </Panel>
              <Divider></Divider>

            </Collapse>
          </div>
        </Col>


      </Row>
    </Drawer>
  );
};

export default LeadWorkflowCreateComp;
