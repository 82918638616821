import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Tabs } from 'antd';
import CustomerContact from './customer';
import SupplierContact from './supplier';
import EmployeeContact from './employee';
import AllContacts from './allContacts';
import PageBreadcrumb from '../../PageBreadcrumb';
import Subscriber from './subscriber';
import InquiryListComp from './inquiry';


const { TabPane } = Tabs;

class CommContact extends Component {
  state = {
    buttonDropDown: false
  };

  toggleDropdown = () => {
    const { buttonDropDown } = this.state;
    this.setState({
      buttonDropDown: !buttonDropDown
    })
  };

  render() {

    const breadCrumbList = [
      {
        name: <FormattedMessage id="sidebar.menuItem.communication" defaultMessage='' />
      },
      {
        name: <FormattedMessage id="sidebar.menuItem.contact" defaultMessage='' />
      }
    ];


    const callTabApi = (key, props) => {
      const payload = {
        relationshipId: props.companyInfo.relationshipId,
        pageNumber: 1,
        pageSize: 25,
      };

      if (key === '1') {

      } else if (key === '2') {
        props.fetchCustomerContacts(payload);
      } else if (key === '3') {
        props.fetchSupplierContacts(payload);
      }
      else if (key === '4') {
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
        };
        props.fetchAllEmployees(payload)
      }


    }


    return (
      <>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              Contacts
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right">
          </div>
        </div>
        <div className="view-container">
          <Tabs type="line"
            onChange={(key) => {
              callTabApi(key, this.props);
            }}
          >
            <TabPane
              tab="All"
              key="1"
            >
              <AllContacts {...this.props} />
            </TabPane>
            <TabPane
              tab="Customer"
              key="2"
            >
              <CustomerContact {...this.props} />
            </TabPane>
            <TabPane
              tab="Supplier"
              key="3"
            >
              <SupplierContact {...this.props} />
            </TabPane>
            <TabPane
              tab="Team"
              key="4"
            >
              <EmployeeContact {...this.props} />
            </TabPane>
            <TabPane
              tab="Subscriber"
              key="5"
            >
              <Subscriber {...this.props} />
            </TabPane>

            <TabPane
              tab="Inquiry"
              key="6"
            >
              <InquiryListComp {...this.props} />
            </TabPane>
          </Tabs>
        </div>
      </>
    )
  }
}

export default injectIntl(CommContact);
