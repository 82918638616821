import { Checkbox, Col, Drawer, Row } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../../general/CustomButton";
import { TextBox } from "../../../../general/TextBox";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { Value } from "sass";

const AdditionalFieldDrawe = (props) => {
  const rowGutter = [24, 16];

  const closeDrawer = () => {
    props.updateDrawerState({
      addNewField: false,
    });
  };
  return (
    <Drawer
      title={"Create Field"}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.addNewField}

      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />

          <CustomButton
            intlId="button.save.label"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {

              if (props.data?.index !== undefined) {
                // Updating an existing field
                const ans = [...props.leadFormValues]; // Copy the current fields
                ans[props.data?.index] = {
                  ...ans[props.data?.index],  // Retain other properties
                  label: props.label,
                  placeholder: props.placeholder,
                  isRequired: props.isRequired,
                };
            
                props.updateState({ leadFormValues: ans });
                props.updateDrawerState({ leadFormValues: ans });
              } else {
                // Adding a new field
                const newField = {
                  label: props.label,
                  key: (props.leadFormValues?.length || 0) + 1,
                  placeholder: props.placeholder,
                  isRequired: props.isRequired,
                };
            
                const ans = [...(props.leadFormValues || []), newField];
                props.updateState({ leadFormValues: ans });
                props.updateDrawerState({ leadFormValues: ans });
              }
            
              closeDrawer();
            }}
            
          // key={1}
          />
        </>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">Field Name</div>
          <TextBox
            placeholder="Field Name"
            type="text"
            maxLength={20}
            value={props.label}
            onChange={(e) => {
              props.updateState({ label: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.label }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Placeholder</div>
          <TextBox
            placeholder="Enter the value"
            type="text"
            maxLength={20}
            value={props.placeholder}
            onChange={(e) => {
              props.updateState({ placeholder: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.placeholder }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
      <Col span={24}>
      <div className="req-checkbox">
                <Checkbox
                    checked={props.isRequired || false}
                    onChange={(e) => {
                        props.updateState({ isRequired: e.target.checked});
                    }}
                >
                    Required
                </Checkbox>
            </div>
            <div className="i-label">
                 It will make this field as a mandatory field and customer won't be able to skip it.
            </div>
      </Col>
      </Row>
    </Drawer>
  );
};

export default AdditionalFieldDrawe;
