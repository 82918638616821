import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DashboardComponent from '../../components/dashboard/index';

import { fetchIndustries, showModal, hideModal, pushModalToStack, popModalFromStack } from '../../actions/commonActions'
import { fetchDataIfNeeded } from '../../utils';
import moment from "moment";
import {
    fetchSalesData, fetchPurchaseData, fetchFastestMovingProducts, fetchTopCustomerByPayment, fetchTopSupplierByPayment, fetchTopSupplierByPurchase,
    fetchTopCustomerBySales, fetchCustomerUnpaidInvoices, fetchSupplierUnpaidInvoices,
    fetchCashflow, fetchTotalExpense, fetchRecentPayment, fetchOrgMember
} from './action';
import { fetchUnpaidInvoices } from '../modal/modalBody/customer/UnpaidInvoices/action';
import { fetchAllLedgerAccounts } from '../finance/LedgerAccounts/action';
import { CONSTANTS } from '../../static/constants';
import { getAllFundraisingCampaign } from '../drawer/fundraising/pledges/action';
import { getAllEventTemplate } from '../communication/EventListing/action';
import { fetchBusinessExpensesAll } from '../finance/BusinessExpense/Listing/action';
import { FormattedMessage } from 'react-intl';

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            salesSelectedDate: moment().startOf('month').format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) + "-" + moment().endOf('month').format(CONSTANTS.DISPLAY_DATE_FORMAT_FY),
            purchaseSelectedDate: moment().startOf('month').format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) + "-" + moment().endOf('month').format(CONSTANTS.DISPLAY_DATE_FORMAT_FY)
        }
    }

    componentWillMount() {
        this.props.updateHeaderState({
            collapsed: false
        });
    }

    componentDidMount() {
        this.props.updateHeaderState({
            collapsed: true
        });
        this.props.updateHeaderState({pageHeading: <FormattedMessage id='sidebar.menuItem.dashboard' defaultMessage='' />})
        this.props.updateHeaderState({breadCrumbList : [
        // {
        //     name: <FormattedMessage id='sidebar.menuItem.dashboard' defaultMessage='' />
        // }
        ]})
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId };
        fetchDataIfNeeded('fetchIndustries', 'industries', this.props, payload);
        this.props.fetchSalesData({ companyInfo, relationshipId: companyInfo.relationshipId, selectedRange: "thisMonth" });
        this.props.fetchPurchaseData({ companyInfo, relationshipId: companyInfo.relationshipId, selectedRange: "thisMonth" });
   this.props.fetchUnpaidInvoices({relationshipId: companyInfo.relationshipId , pageNumber:1, pageSize:25})
        this.props.fetchFastestMovingProducts({ companyInfo, relationshipId: companyInfo.relationshipId });
        this.props.fetchTopCustomerByPayment({ companyInfo, relationshipId: companyInfo.relationshipId });
        this.props.fetchTopSupplierByPayment({ companyInfo, relationshipId: companyInfo.relationshipId });
        this.props.fetchTopCustomerBySales({ companyInfo, relationshipId: companyInfo.relationshipId });
        this.props.fetchTopSupplierByPurchase({ companyInfo, relationshipId: companyInfo.relationshipId });

       this.props.fetchCustomerUnpaidInvoices({ companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 0 });
        this.props.fetchSupplierUnpaidInvoices({ companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 0 });
        this.props.fetchAllLedgerAccounts({ companyInfo, relationshipId: companyInfo.relationshipId });
        this.props.fetchCashflow({ relationshipId: companyInfo.relationshipId });
        this.props.fetchTotalExpense({ relationshipId: companyInfo.relationshipId })
        this.props.fetchRecentPayment({ relationshipId: companyInfo.relationshipId });
        this.props.getAllFundraisingCampaign({ relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 10 });
        this.props.fetchOrgMember({ relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 10 });
        this.props.getAllEventTemplate({ relationshipId: companyInfo.relationshipId, templateType: "event" });
        this.props.fetchBusinessExpensesAll({ relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 10 });
        
    }

    render() {
        return <DashboardComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }

}

const mapStateToProps = (state) => {
    return {
        common: state.common,
        companyInfo: state.common.companyInfo,
        salesData: state.dashboard.salesData,
        purchaseData: state.dashboard.purchaseData,
        topPurchaseSupplier: state.dashboard.topPurchaseSupplier,
        topSalesCustomer: state.dashboard.topSalesCustomer,
        topPaymentSupplier: state.dashboard.topPaymentSupplier,
        topPaymentCustomer: state.dashboard.topPaymentCustomer,
        fastestMovingProducts: state.dashboard.fastestMovingProducts,
        customerUnpaidInvoices: state.dashboard.customerUnpaidInvoices,
        supplierUnpaidInvoices: state.dashboard.supplierUnpaidInvoices,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
        customerCashflow: state.customerPayment.customerCashflow,
        totalRelationshipExpense: state.businessExpense.totalRelationshipExpense,
        recent10Payments: state.customerPayment.recent10Payments,
        allCampaignList: state.fundraisingReducer.allCampaignList,
        memberCount: state.customer.memberCount,
        eventTemplateList: state.communicationReducer.eventTemplateList,
        allExpensesList: state.businessExpense.businessExpensesList,
        isAlifApp: state.common.isAlifApp,
        permissions: state.auth.permissions
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchIndustries,
    fetchFastestMovingProducts,
    fetchTopCustomerByPayment,
    fetchTopSupplierByPayment,
    fetchTopSupplierByPurchase,
    fetchTopCustomerBySales,
    fetchSalesData,
    fetchPurchaseData,
    fetchCustomerUnpaidInvoices,
    fetchSupplierUnpaidInvoices,
    fetchAllLedgerAccounts,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    fetchCashflow,
    fetchTotalExpense,
    fetchRecentPayment,
    getAllFundraisingCampaign,
    fetchOrgMember,
    getAllEventTemplate,
    fetchBusinessExpensesAll,
    fetchUnpaidInvoices
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
