

import React from "react";
import ReactApexChart from "react-apexcharts"
import { fixedDecimalNumber } from "../../../../utils";

class TopCustomerSales extends React.Component {
    constructor(props) {
        super(props);

        const dataArr = [25000, 20000, 10000, 0, 15000];
        // Object.values(this.props.topSalesCustomer || {}).forEach((e)=>{
        //     dataArr.push(Math.round(e));
        // });
       
        this.state = {

            series: [{
                name: 'Amount ('+(this.props.companyInfo ||{}).currencyCode+')',
                data: dataArr
            }],
            options: {
                chart: {
                    height: 250,
                    type: 'bar',
                    events: {
                        click: function (chart, w, e) {
                        }
                    }
                },
               // colors: colors,
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        distributed: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: ['Total Due', 'Recived', 'Remaining', 'Other Income', 'Expenses'],
                    labels: {
                        style: {
                            //colors: colors,
                            fontSize: '12px'
                        }
                    }
                }
            },


        };
    }

    getSeriesData =(props) => {
        //const dataArr = [props.totalRevenueForecast, props.totalInvoiceGenerated, props.totalAmountReceived, props.totalInvoiceDue, props.totalExpenseDue];
        //  Object.values(props.totalStudentCountByGrade || {}).forEach((e)=>{
        //      dataArr.push(Math.round(Number(e)));
        //  });
         return  [{
            name: 'Amount ('+(props.companyInfo ||{}).currencyCode+')',
            data: [fixedDecimalNumber(props.totalInvoiceGenerated),
                (fixedDecimalNumber(props.totalFeesPaid)),
                    (fixedDecimalNumber(props.totalFeesDue)), 
                     Number(props.totalExpenseDue).toFixed(2)]
        }];
    } 
    
    getOptionsData =(props) => {
        // const categoriesArr = [];
        //  Object.values(props.totalStudentCountByGrade || {}).forEach((e)=>{
        //     categoriesArr.push('Grade-'+e);
        //  });
         return  {
            chart: {
                height: 250,
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                    }
                }
            },
           // colors: colors,
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: ['Invoice Generated', 'Amount Received', 'Balance Due', 'Expense'],
                labels: {
                    style: {
                        //colors: colors,
                        fontSize: '12px'
                    }
                }
            }
        };
    } 

    render() {
        return (


            <div id="chart">
                <ReactApexChart  options={this.getOptionsData(this.props)} series={this.getSeriesData(this.props)}  type="bar" height={350} />
            </div>


        );
    }
}

export default TopCustomerSales;