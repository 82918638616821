import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import PageBreadcrumb from "../../PageBreadcrumb";
import { checkACLPermission, getMomentDateForUIReadOnly, sortTable } from "../../../utils";
import { Menu, Switch, Button, Tabs } from "antd";
import DropdownAction from "antd/lib/dropdown";
import { ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from "../../../static/constants";
import PageBreadcrumb from "../../PageBreadcrumb";
import LeadAllNotesList from "./allNotes"
import LeadMyNotesList from "./myNotes"

const LeadNotesListComp = (props) => {
  const { dir = 0 } = props;
  const { TabPane } = Tabs;

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
    },
    {
      name: <FormattedMessage id="heading.lead.leadForm" defaultMessage="" />,
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItems.FormMeta" defaultMessage="" />
      ),
    },
    {
      name: <FormattedMessage id="lead.leadSource.label" defaultMessage="" />,
    },
  ];

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("leadSource-table"), cellIndex, 1 - dir);
  };

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            props.updateState({
              leadSourceListDrawer: true,
              ...item,
            });
          }}
        >
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item>

        <Menu.Item
          key="2"
          value="delete"
          onClick={() => {
            props.showModal({
              modalBody: "Are you sure you want to delete?",
              handleSubmit: () => {
                props.deleteCrmLeadSource(item, props);
              },
            });
          }}
        >
          <i className={ICONS.DELETE} /> Delete
        </Menu.Item>
      </Menu>
    );
  };
  const callTabApi = (key, props) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: 0,
      createdByEmpId: props.companyInfo?.relationshipEmployees?.relationshipEmployeeId,
      pageSize: 25,
    };
// fetchCallLogsByRid,fetchCallLogsByUserId
    if (key === 'all_notes') {
      // will do something
      props.getAllLeadNotes(payload);
      props.updateState({
        isMyLeads: false,
      })
    } else if (key === 'my_notes') {
      //will do something
      props.getAllLeadNotesByUserId(payload);
      props.updateState({
        isMyLeads: true,
      })
    }


  }

  const { permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = {
    allTask:checkACLPermission(
      primaryPerm,
      MODULE_CODED_VALUES.CRM_LEAD_NOTES,
      PERMISSION_VALUES.CREATE
    )
    // cn add more like this and handel all the tab
  }
  return (
    // <div className="lead-list">
<>
<div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            Leads Notes
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">

        </div>
      </div>
    <div className="view-container">
  {permissionsData.allTask?  <Tabs type="line"
          onChange={(key) => {
            callTabApi(key, props);
          }}
        >
          <TabPane
            tab="All Notes"
            key="all_notes"
          >
            <LeadAllNotesList {...props} />

          </TabPane>
          <TabPane
            tab="MY Notes"
            key="my_notes"
          >
            <LeadMyNotesList {...props} />

          </TabPane>

        </Tabs>:
        
        <LeadMyNotesList {...props} />
        
        }
    </div>
</>
  );
};

export default injectIntl(LeadNotesListComp);
