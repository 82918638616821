import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {showModal, pushModalToStack, popModalFromStack, hideModal} from '../../../../actions/commonActions';
import LeadStageDrawerComp from '../../../../components/drawer/leads/leadStageList';
import {createLeadStage} from './action';
import { getAllLeadStage } from '../../../leads/LeadStageList/action';
class LeadSourceDrawer extends Component {
    constructor(props) {
        super(props);
        let parentData = {}
        let addAsSubcategory = false;
        
        if(Object.keys(this.props.parentLeadStageItem || {})?.length){
            parentData =  this.props.parentLeadStageItem;
             addAsSubcategory = true;
        }
        this.state = {
            ...(this.props.leadStageItem || {})
        ,parentData
        ,addAsSubcategory
        };
      }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};

        const payload = {
          relationshipId: companyInfo.relationshipId,
          pageNumber: 0,
          pageSize: 100,
        }
        this.props.getAllLeadStage(payload);

    }


    render() {
        return <LeadStageDrawerComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      relationshipId: state.common.companyInfo.relationshipId,
      crmLeadStageList: state.crm.crmLeadStatusList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal, 
    pushModalToStack,
    popModalFromStack,
    createLeadStage,getAllLeadStage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadSourceDrawer);