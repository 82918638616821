import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import { MultipleSelectDropdown } from '../../../../general/MultipleSelectDropdown';
import './index.scss';
import * as filter from 'lodash.filter';
import {showToasterMessage} from '../../../../../utils';
import * as find from 'lodash.find';

const AssignFormToTeacher = (props) => {
    const getFormSettingPermissionList = (props) => {
        const objData = find((props.registrationFormData.orgRegFormSettingPermissionList || []), { relEmployeeId: (props.userInfo || {}).relationshipEmployeeId }) || {};
        let permList = [{
            relationshipId: (props.companyInfo || {}).relationshipId,
            registrationFormSettingId: (props.registrationFormData || {}).registrationFormSettingId,
            relEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
            registrationFormSettingPermissionsId: objData.registrationFormSettingPermissionsId || 0,
            clientUserAccountId: (props.userInfo || {}).userId,
        }];
   
        props.userPermissions.forEach(function (userId) {
            if (userId !== (props.userInfo || {}).relationshipEmployeeId) {
                const permObj = find((props.registrationFormData.orgRegFormSettingPermissionList || []), { relEmployeeId: userId }) || {};
                const empObj = find((props.allCompanyUsers || []), { relationshipEmployeeId: userId }) || {};

                permList.push({
                    relationshipId: (props.companyInfo || {}).relationshipId,
                    registrationFormSettingId: (props.registrationFormData || {}).registrationFormSettingId,
                    relEmployeeId: userId,
                    registrationFormSettingPermissionsId: permObj.registrationFormSettingPermissionsId || 0,
                    clientUserAccountId: empObj.userId,
                })
            }
        });
        return permList;
    }
  
    return (
        <div className="modal-dialog ">
            <div className="modal-content full-width">
                <Row type='flex' justify='center' className=" pt10 text-right ant-legacy-form-item-control">
                    <Col span={4} justify='center' className=" pr10 "> <FormattedMessage id='allowedUser.text' />  </Col>
                    <Col span={18} style={{ textAlign: 'initial' }}>
                        <MultipleSelectDropdown
                            items={props.allCompanyUsers || []}
                            placeholder={props.intl.formatMessage({ id: 'allowedUser.text' })}
                            mode='multiple'
                            onDeSelect={(value) => {
                                if (props.userInfo.relationshipEmployeeId !== value) {
                                    let userPermissions = props.userPermissions;
                                    userPermissions = filter(userPermissions, (relationshipEmployeeId) => {
                                        return relationshipEmployeeId !== value;
                                    });
                                    props.updateState({ userPermissions: userPermissions });
                                }
                            }}
                            valueKeyName='firstName'
                            lastNameKey='lastName'
                            addressLineKey1='email'
                            optionKeyName='relationshipEmployeeId'
                            disabledId={props.userInfo.relationshipEmployeeId}
                            selectedValue={props.userPermissions || []}
                            onSelect={(relationshipEmployeeId) => {
                                let userPermissions = props.userPermissions || [];
                                if (userPermissions.indexOf(relationshipEmployeeId) === -1) {
                                    userPermissions.push(relationshipEmployeeId);
                                } else {
                                    userPermissions = userPermissions.filter((el) => {
                                        return el !== relationshipEmployeeId
                                    });
                                }
                                props.updateState({
                                    userPermissions
                                });
                            }}

                        />
                    </Col>
                </Row>
            </div>
            <div>

                <hr className="mt10"></hr>
                <Row justify='center'>
                    <button type="button" className="ant-btn ant-btn-primary mt20" onClick={() => {
                        if (!props.userPermissions || !props.userPermissions.length) {
                            return showToasterMessage({
                                messageType: 'error', description: 'User(s) required'
                            });
                        }
                        const modalData = {
                            modalBody: 'Are you sure you want to submit?',
                            handleSubmit: () => {
                                props.saveOrUpdateFormPermission(getFormSettingPermissionList(props), props);
                                // props.saveOrUpdateFormPermission(payload, props);
                                props.popModalFromStack();
                            }
                          };
                          props.pushModalToStack(modalData);
                        

                    }}><FormattedMessage id='forgot.btn.save' defaultMessage='' /></button>
                </Row>
            </div>
        </div>
    );

}


export default AssignFormToTeacher;
