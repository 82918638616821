import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { SALES_INVOICE_ACTION_LIST, COMMON_ACTIONS, CONSTANTS } from '../../../../static/constants';
import { fixedDecimalNumber, getMomentDateForUIReadOnly, showToasterMessage } from '../../../../utils';

const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');





export const generateCommunicationTemplate = (payload, props) => {
  
   
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/communicationSetting/save`, payload)
        .then(res => {
        console.log(res ,"response")

        if(res.status === 200){
          props.updateDrawerState({
            communicationTemplateDrawerVisiable: false
          })
          props.getAllCommunicationTemplate({relationshipId: payload.relationshipId})
          
          return showToasterMessage({
            description: "Save data Successfully",
            messageType: 'success'
          })


        }
       
          
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
          
        })
    }
  }
 

 
