import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pushModalToStack, popModalFromStack, fetchAllEmployees } from './../../../../actions/commonActions';
import TimeTableDrawerComp from '../../../../components/drawer/schoolManagement/timeTable';
import { getClassList } from "../../../schoolManagement/class/action";
import { saveTimeTable ,getTeacherByClassId} from "./action";

class NewTimeTableDrawerCont extends Component {
  constructor(props) {
    super(props);
    this.state = {
        current: 0,
        pageNumber: 1,
        pageSize: 1000,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const userInfo = this.props.userInfo || {};
    const payload = {
        relationshipId: companyInfo.relationshipId,
        relationshipEmployeeId: userInfo.relationshipEmployeeId,
        pageNumber: 1,
        pageSize: 1000
    };
    this.props.getClassList(payload);
    this.props.fetchAllEmployees(payload);
  }

  render() {
    return (
      <TimeTableDrawerComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    classList: state.school.classList,
    teacherList : state.common.employees,
    listLoading: state.common.listLoading,
    classTeacherList:state.school.classTeacherList
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushModalToStack,
      popModalFromStack,
      getClassList,
      saveTimeTable,
      fetchAllEmployees,
      getTeacherByClassId
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NewTimeTableDrawerCont);
