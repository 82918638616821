import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  getMomentDateForUIReadOnly,
  sortTable,
} from "../../../utils";
import {Menu ,Button,Switch} from "antd";
import DropdownAction from "antd/lib/dropdown";
import { ICONS } from "../../../static/constants";
import moment from "moment-timezone";
// import LeadStageDrawer from "../../drawer/leads/leadStageList";
// import { checkACLPermission, getMomentDateForUIReadOnly } from '../../../../utils';
// import { checkACLPermission } from "../../../utils";
// import { MODULE_CODED_VALUES,PERMISSION_VALUES } from "../../../static/constants";
const LeadStageListComp = (props) => {
  const {
    dir=0,
  } = props;
  // const { permissions } = this.props;

  // const primaryPerm = (permissions && permissions.primary) || [];

  // const breadCrumbList = [
  //   {
  //     name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
  //   },
  //   {
  //     name: <FormattedMessage id="heading.lead.leadForm" defaultMessage="" />,
  //   },
  //   {
  //     name: <FormattedMessage id="sidebar.menuItems.FormMeta" defaultMessage="" />,
  //   },
  //   {
  //     name: <FormattedMessage id="lead.leadStage.label" defaultMessage="" />,
  //   }
  // ];

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("leadStage-table"), cellIndex, 1 - dir);
  };

  const actionMenu = (item,parent) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item key="1" value="edit" onClick={() => {
          props.updateState({
            leadStageListDrawer: true,
            leadStageItem : item,
            parentLeadStageItem : parent,
          })
        }}>
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item>

        <Menu.Item key="2" value="delete" onClick={() => {
          props.showModal({
            modalBody: "Are you sure you want to delete?",
            handleSubmit: () => {
              props.deleteCrmLeadStage(item, props);
            }
          })
        }}>
          <i className={ICONS.DELETE} /> Delete
        </Menu.Item>
      </Menu>
    );
  };
  const [childOnList, setChildOnList] = useState([]);

  const isChildOn = (id) => childOnList.includes(id);

  const toggleChild = (id) => {
      setChildOnList((prevList) =>
          prevList.includes(id) ? prevList.filter((itemId) => itemId !== id) : [...prevList, id]
      );
  };
  const getSubcategories = (subcategories = [], parentIndex = '0',item) => {
    return subcategories.map((subcategory, i) => {
        const index = `${parentIndex}.${i + 1}`;
        const isSubcategoryOpen = isChildOn(subcategory.leadStatusId);

        const nestingLevel = parentIndex.toString().split('.').length;
        console.log(nestingLevel, "level")
        const marginLeft = 30 + (nestingLevel) * 10;

        return (
            <React.Fragment key={index}>
                <tr>
                <td> {parentIndex}.{i+1}</td>

                    <td>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: `${marginLeft}px` }}>
                            {subcategory?.subLeadStatus && subcategory.subLeadStatus.length > 0 && (
                                <span

                                    onClick={() => toggleChild(subcategory.leadStatusId)}
                                    style={{ marginRight: '10px', marginLeft: '-20px' }}
                                >
                                    {isSubcategoryOpen ? <i className="fi fi-br-minus"></i> : <i className="fi fi-br-plus"></i>}
                                </span>
                            )}
                            <div>{subcategory.statusName}</div>
                        </div>
                    </td>
                    {/* <td className="text-center">
                        <span>{(subcategory?.subLeadStatus || [])?.length}</span>
                    </td> */}
                    <td className="text-center" >
                      {getMomentDateForUIReadOnly({
                              date: subcategory.dateCreated,
                            })}
                        {/* {moment(subcategory?.createdDate).format('DD/MM/YYYY')} */}
                    </td>
                <td>
                {/* <Switch
                            checked={subcategory.isActive === 1}
                            onClick={(checked, e) => {
                              props.createLeadStage(
                                { ...subcategory, isActive: checked ? 1 : 0 },
                                props
                              );
                            }}
                          /> */}
                </td>

                    <td className="text-center">
                        <div className="action-icon">
                            <DropdownAction
                                overlay={actionMenu(subcategory,item)}
                                trigger={["click"]}
                            >
                                <i className={ICONS.MORE} />
                            </DropdownAction>
                        </div>

                    </td>


                </tr>
                {isSubcategoryOpen && getSubcategories(subcategory.subLeadStatus, index,subcategories)}
            </React.Fragment>
        );
    });
};
  return (
    <>
      

      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="leads.leadForm.formMeta.leadStage.table.heading"
                defaultMessage=""
              />
            </div>
          </div>

          <div className="right-actions">
          {/* {checkACLPermission(
                  primaryPerm,
                  MODULE_CODED_VALUES.CUSTOMER_ACTIVITY,
                  PERMISSION_VALUES.CREATE
                ) ?   */}
                  <Button
                    style={{backgroundColor:"#1cb961", color: '#ffffff'}}
                    className="create-action-btn"
                    onClick={() => {
                      // handleAddUpdateActivity({});
                      props.updateState({leadStageListDrawer: true, leadStageItem: {}})
                    }}
                  >
                    <i className={ICONS.ADD} style={{margin: '4px 5px 0px 0px'}} /> <FormattedMessage id="button.create.label" defaultMessage="" />
                  </Button>
                  {/* :''
                } */}
          </div>
        </div>
        <div className="table-container" style={{height: 'calc(100% - 59px)'}}>
          <table id="leadStage-table">
            <thead>
              <tr>
                <th>
                  S.No.
                </th>
                <th onClick={sortColumn}>
                  Stage Name <i className={ICONS.SORT}></i>
                </th>
                {/* <th onClick={sortColumn}>
                  Source Code <i className={ICONS.SORT}></i>
                </th> */}
                <th onClick={sortColumn}>
                  Date Created <i className={ICONS.SORT}></i>
                </th>
                <th onClick={sortColumn}>
                    Status <i className={ICONS.SORT}></i>
                  </th>
                <th width= '6%'>
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {(props.crmLeadStageList || []).map((rowData, i) => {
                return (<>
                
                  <tr key={"crm" + rowData.leadStatusId}>
                          
                    <td>{i+1}</td>
                    <td>
                                                    <div className="plus-minus-icon">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {rowData.subLeadStatus && rowData.subLeadStatus.length > 0 ? (
                                                                <>
                                                                    <span

                                                                        onClick={() => toggleChild(rowData.leadStatusId)}
                                                                    >
                                                                        {isChildOn(rowData.leadStatusId) ? <i class="fi fi-br-minus"></i> : <i class="fi fi-br-plus"></i>}
                                                                    </span>
                                                                    <div style={{ marginLeft: "10px" }}>{rowData?.statusName}</div>
                                                                </>

                                                            )
                                                                :
                                                                <div style={{ marginLeft: "22px" }}>{rowData?.statusName}</div>
                                                            }

                                                        </div>
                                                    </div>
                    </td>
                    {/* <td>{e.source_code || "-"}</td> */}
                    <td>
                    {rowData.dateCreated
                          ? getMomentDateForUIReadOnly({
                              date: rowData.dateCreated,
                            })
                          : "-"}
                      </td>
                    <td>
                        <div className="status-toggle">
                          <Switch
                            checked={rowData.isActive === 1}
                            onClick={(checked, e) => {
                              props.createLeadStage(
                                { ...rowData, isActive: checked ? 1 : 0 },
                                props
                              );
                            }}
                          />
                        </div>
                      </td>
                    <td style={{paddingLeft: '50px'}}>
                      <DropdownAction
                        overlay={actionMenu(rowData)}
                        trigger={["click"]}
                      >
                        <i className={ICONS.MORE} />
                      </DropdownAction>
                    </td>
                  </tr>
                {isChildOn(rowData.leadStatusId) && getSubcategories(rowData.subLeadStatus, i + 1,rowData)}
                </>);
                // })
              })}

            </tbody>
          </table>
        </div>

      </div>
    </>
  );
};

export default injectIntl(LeadStageListComp);
