import { Radio } from "antd";
import Search from "antd/lib/transfer/search";
import React, { useState } from "react";
import { useCallback,useMemo } from "react";

export const StatusCategoryModal = React.memo(({ crmLeadStatusList, parentData, pageNumber, updateState, hideModal,callBack,stopUpdate }) => {
    const [searchText, setSearchText] = useState('');
  
    const handleCategoryChange = useCallback((e) => {
      console.log("sdcsdcsdcsdcsdcscscscsc",callBack);
      
      if(callBack){
        callBack(e.target.value)
      }
      if(!stopUpdate){
        updateState({ parentData: e.target.value, statusName: e.target.value.statusName, statusName: e.target.value.statusName });

      }
      hideModal();
    }, [updateState, hideModal]);
  
    const renderSubcategories = useCallback((subLeadStatus, depth = 1) => {
      const indentStyle = {
        paddingLeft: `${depth * 10}px`,
      };
      return subLeadStatus?.map((subcategory, i) => (
        <div
          key={`${subcategory.statusName}-${i}`}
          className="radio-button-Wrapper"
          style={indentStyle}
        >
          <Radio value={subcategory}>
            <div className="parent-category-sub-wrapper">
              {subcategory.statusName}
            </div>
          </Radio>
          {subcategory.subLeadStatus?.length > 0 &&
            renderSubcategories(subcategory.subLeadStatus, depth + 1)}
        </div>
      ));
    }, []);
  
    const filteredProjectList = useMemo(() => {
      const currentPageList = crmLeadStatusList || [];
      if (!searchText) return currentPageList;
  
      return currentPageList.filter(category =>
        category.statusName.toLowerCase().includes(searchText.toLowerCase())
      );
    }, [crmLeadStatusList, searchText]);
  
    return (
      <>
        <div className="searchBarArea">
          <Search
            placeholder="Search Category..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="categoryArea">
          <Radio.Group
            onChange={handleCategoryChange}
            value={parentData}
          >
            {filteredProjectList.map((category, i) => (
              <div className="subcategory" key={`${category.statusName}-${i}`}>
                <Radio value={category}>
                  <strong>{category.statusName}</strong>
                </Radio>
                {category.subLeadStatus?.length > 0 &&
                  renderSubcategories(category.subLeadStatus)}
              </div>
            ))}
          </Radio.Group>
        </div>
      </>
    );
  });