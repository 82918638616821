import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LeadProfileComp from '../../../../../components/modal/modalBody/leads/profile';
import { pushModalToStack,popModalFromStack } from '../../../../../actions/commonActions';
import {fetchLeadActivityTrack, fetchLeadTasks,saveCallLogs} from './action';
import LeadTaskDrawer from '../../../../drawer/leads/leadTask';
import { formatDateForBackendPlain } from '../../../../../utils';

class LeadProfile extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            pageNumber: 1,
            pageSize: 25,
            activitiesRef: false,
            // activeTabKey:"activityTab"
        }
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const { leadData ={}} = this.props;
        const payload = {
            relationshipId: companyInfo.relationshipId,
            crmLeadId: leadData.crmLeadId,
        }
        const callLogPayload = {
            crmLeadId:this.props.leadData?.crmLeadId,
            startTime:formatDateForBackendPlain(new Date()),
            relationshipId:companyInfo.relationshipId,
            leadName:this.props.leadData?.leadName,
            createdByUserId:companyInfo?.relationshipEmployees?.relationshipEmployeeId,
            createdByUserName:companyInfo?.relationshipEmployees?.fullName,
        }
        this.props.saveCallLogs(callLogPayload);
        this.props.fetchLeadActivityTrack(payload);
        const fullName = (leadData.crmLeadDetailsList || []).find(obj=> obj.label === 'Full Name').value;
        const mobileNo = (leadData.crmLeadDetailsList || []).find(obj=> obj.label === 'Mobile No.').value;
        const email = (leadData.crmLeadDetailsList || []).find(obj=> obj.label === 'Email').value;

        
            this.props.fetchLeadTasks(payload);

        this.setState({
            leadData : {...leadData, fullName, mobileNo, email,activityTab:leadData.activityTab}
        })
    }

    componentWillReceiveProps(props){
        if(Object.keys(props.currCallLogData)?.length){      
            props.updateStateLayer({
              currCallLogData:props.currCallLogData,
              endTime:null
            })

            
          }
        if(props.activitiesRef){
            const payload = {
                relationshipId: props.companyInfo?.relationshipId,
                crmLeadId: props.leadData?.crmLeadId,
            }
            this.props.fetchLeadActivityTrack(payload);
            this.setState({activitiesRef: false});
        }
    }

    render() {
        return <>
        <LeadTaskDrawer {...this.state} {...this.props}  updateModalState={(data) => { this.setState(data) }} />
            <LeadProfileComp {...this.state} {...this.props}  updateModalState={(data) => { this.setState(data) }} />
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        userInfo: state.common.userInfo,
        searchedCustomers: state.customer.customers[1],
        allLeadList: state.crm.allLeadList,
        allLeadListCount: state.crm.allLeadListCount,
        leadAssignList: state.common.employees,
        allLeadNotesList: state.crm.crmLeadNotesList,
        allLeadReminderList: state.crm.crmLeadReminderList,
        crmLeadStatusList: state.crm.crmLeadStatusList,
        crmLeadFormList: state.crm.crmLeadFormList,
        leadActivityTrackList: state.crm.leadActivityTrackList,
        leadTasksList: state.crm.leadTasksList,
        currCallLogData:state.crm.currCallLogData,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
pushModalToStack,
popModalFromStack,
fetchLeadActivityTrack,
fetchLeadTasks,saveCallLogs
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (LeadProfile);