import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import {  sortTable, exportExcel } from '../../../utils';
import { Menu, Skeleton, Button, Empty, Input } from 'antd';
import { CONSTANTS, ICONS } from '../../../static/constants';
import * as find from "lodash.find";
import { FileExcelOutlined } from "@ant-design/icons";
import * as debounce from "lodash.debounce";
import DropdownAction from "antd/lib/dropdown";


const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { Search } = Input;

const InquiryListComp = (props) => {
    const { subscribers, pageSize, updateState, dir = 0, pageNumber, companyInfo, selectedItems = [] } = props;
    const sortColumn = (e) => {
        sortTable(document.getElementById('contacts-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
    }
    const handleSearchedText = (searchedText, props) => {
        let searchResult1 = [];

        if (searchedText.length) {
            searchResult1 = subscribers.filter((user) => {
                return (
                    (user?.firstName && user.firstName.toLowerCase().includes(searchedText.toLowerCase())) ||
                    (user?.emailAddress && user.emailAddress.toLowerCase().includes(searchedText.toLowerCase())) ||
                    (user?.cellPhone && user.cellPhone.includes(searchedText))
                );
            });

            props.updateState({
                searchResult1: [...searchResult1],
                selectedType: props.selectedType
            });
        };
    }
let data =[
    {
        id:1,
        "relationshipId": 129,
        "leadName": "Sophia Harris",
        "leadSourceId": 210,
        "leadSourceName": "Website",
        "leadStatusId": 269,
        "statusName": "New",
        "crmLeadDetailsList": [
            {
                "isRequired": true,
                "label": "Full Name",
                "placeholder": "Full Name",
                "type": "text",
                "value": "Sophia Harris"
            },
            {
                "isRequired": true,
                "label": "Email",
                "placeholder": "Email",
                "type": "text",
                "value": "sophia.harris@yopmail.com"
            },
            {
                "isRequired": true,
                "label": "Mobile No.",
                "placeholder": "Mobile No.",
                "type": "number",
                "value": "347 7654321"
            },
            {
                "type": "multiline_Input",
                "label": "How can we help ?",
                "placeholder": "Enter Text",
                "isRequired": true,
                "id": "14ff2b02-7ad4-4cba-b134-5031331a5844",
                "value": "Need Assistance"
            }
        ],
        "crmLeadEmpAssignedList": [
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 345,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 10:33:47",
                "workflowTriggerOneId": 0
            },
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 280,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 10:33:47",
                "workflowTriggerOneId": 0
            },
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 286,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 10:33:47",
                "workflowTriggerOneId": 0
            },
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 350,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 10:33:47",
                "workflowTriggerOneId": 0
            }
        ],
        "statusColorForUICell": "",
        "createdByEmpId": 270,
        "updatedByEmpId": 270,
        "crmLeadFormSettingId": 29,
        "formName": "Enquiry Form",
        "crmLeadTeamAssignedList": [
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "workflowTriggerId": 0,
                "teamId": 24,
                "teamName": "Tiger",
                "teamCode": "T2",
                "dateCreated": "2024-09-05 10:33:47"
            }
        ],
        "isWalkInLead": 0
    },
    {
        id:2,

        "relationshipId": 129,
        "leadName": "David Clark",
        "leadSourceId": 210,
        "leadSourceName": "Website",
        "leadStatusId": 269,
        "statusName": "New",
        "crmLeadDetailsList": [
            {
                "isRequired": true,
                "label": "Full Name",
                "placeholder": "Full Name",
                "type": "text",
                "value": "David Clark"
            },
            {
                "isRequired": true,
                "label": "Email",
                "placeholder": "Email",
                "type": "text",
                "value": "david.clark@yopmail.com"
            },
            {
                "isRequired": true,
                "label": "Mobile No.",
                "placeholder": "Mobile No.",
                "type": "number",
                "value": "347 1234567"
            },
            {
                "type": "multiline_Input",
                "label": "How can we help ?",
                "placeholder": "Enter Text",
                "isRequired": true,
                "id": "14ff2b02-7ad4-4cba-b134-5031331a5844",
                "value": "Talk About Offers"
            }
        ],
        "crmLeadEmpAssignedList": [
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 345,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 10:33:47",
                "workflowTriggerOneId": 0
            },
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 280,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 10:33:47",
                "workflowTriggerOneId": 0
            },
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 286,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 10:33:47",
                "workflowTriggerOneId": 0
            },
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 350,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 10:33:47",
                "workflowTriggerOneId": 0
            }
        ],
        "statusColorForUICell": "",
        "createdByEmpId": 270,
        "updatedByEmpId": 270,
        "crmLeadFormSettingId": 29,
        "formName": "Enquiry Form",
        "crmLeadTeamAssignedList": [
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "workflowTriggerId": 0,
                "teamId": 24,
                "teamName": "Tiger",
                "teamCode": "T2",
                "dateCreated": "2024-09-05 10:33:47"
            }
        ],
        "isWalkInLead": 0
    },
    {
        id:3,

        "relationshipId": 129,
        "leadName": "Samantha Wilson",
        "leadSourceId": 210,
        "leadSourceName": "Website",
        "leadStatusId": 269,
        "statusName": "New",
        "crmLeadDetailsList": [
            {
                "isRequired": true,
                "label": "Full Name",
                "placeholder": "Full Name",
                "type": "text",
                "value": "Samantha Wilson"
            },
            {
                "isRequired": true,
                "label": "Email",
                "placeholder": "Email",
                "type": "text",
                "value": "samantha.wilson@yopmail.com"
            },
            {
                "isRequired": true,
                "label": "Mobile No.",
                "placeholder": "Mobile No.",
                "type": "number",
                "value": "612 654321"
            },
            {
                "type": "dropdown",
                "label": "Visa Services",
                "options": [
                    {
                        "value": "Dubai Visa",
                        "key": "Dubai Visa"
                    },
                    {
                        "value": "European Visa",
                        "key": "European Visa"
                    },
                    {
                        "value": "Ticket Services",
                        "key": "Ticket Services"
                    }
                ],
                "placeholder": "Select Services",
                "isRequired": true,
                "id": "786d317f-1f89-46c9-9302-d0ff84e17a0a",
                "value": "European Visa"
            },
            {
                "type": "dropdown",
                "label": "Visa Type",
                "options": [
                    {
                        "value": "48 Hours Transit Visa",
                        "key": "48 Hours Transit Visa"
                    },
                    {
                        "value": "96 Hours Transit Visa",
                        "key": "96 Hours Transit Visa"
                    },
                    {
                        "value": "30 Days Tourist Visa",
                        "key": "30 Days Tourist Visa"
                    },
                    {
                        "value": "60 Days Tourist Visa",
                        "key": "60 Days Tourist Visa"
                    },
                    {
                        "value": "90 Days Tourist Visa",
                        "key": "90 Days Tourist Visa"
                    },
                    {
                        "value": "30 Days Multi Entry Tourist Visa",
                        "key": "30 Days Multi Entry Tourist Visa"
                    },
                    {
                        "value": "60 Days Multi Entry Tourist Visa",
                        "key": "60 Days Multi Entry Tourist Visa"
                    }
                ],
                "placeholder": "Select Type",
                "isRequired": true,
                "id": "57b8d861-2808-4d3a-8ee8-69a941db148c",
                "value": "30 Days Tourist Visa"
            }
        ],
        "crmLeadEmpAssignedList": [
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 349,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 11:45:37",
                "workflowTriggerOneId": 0
            },
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 347,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 11:45:37",
                "workflowTriggerOneId": 0
            },
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 348,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 11:45:37",
                "workflowTriggerOneId": 0
            },
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 346,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 11:45:37",
                "workflowTriggerOneId": 0
            }
        ],
        "statusColorForUICell": "",
        "createdByEmpId": 270,
        "updatedByEmpId": 270,
        "crmLeadFormSettingId": 28,
        "formName": "Enquiry Form",
        "crmLeadTeamAssignedList": [
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "workflowTriggerId": 0,
                "teamId": 23,
                "teamName": "Panther",
                "teamCode": "P2",
                "dateCreated": "2024-09-05 11:45:37"
            }
        ],
        "isWalkInLead": 0
    },
    {
        id:4,

        "relationshipId": 129,
        "leadName": "Daniel Martin",
        "leadSourceId": 210,
        "leadSourceName": "Website",
        "leadStatusId": 269,
        "statusName": "New",
        "crmLeadDetailsList": [
            {
                "isRequired": true,
                "label": "Full Name",
                "placeholder": "Full Name",
                "type": "text",
                "value": "Daniel Martin"
            },
            {
                "isRequired": true,
                "label": "Email",
                "placeholder": "Email",
                "type": "text",
                "value": "daniel.martin@yopmail.com"
            },
            {
                "isRequired": true,
                "label": "Mobile No.",
                "placeholder": "Mobile No.",
                "type": "number",
                "value": "612 123456"
            },
            {
                "type": "dropdown",
                "label": "Visa Services",
                "options": [
                    {
                        "value": "Dubai Visa",
                        "key": "Dubai Visa"
                    },
                    {
                        "value": "European Visa",
                        "key": "European Visa"
                    },
                    {
                        "value": "Ticket Services",
                        "key": "Ticket Services"
                    }
                ],
                "placeholder": "Select Services",
                "isRequired": true,
                "id": "786d317f-1f89-46c9-9302-d0ff84e17a0a",
                "value": "European Visa"
            },
            {
                "type": "dropdown",
                "label": "Visa Type",
                "options": [
                    {
                        "value": "48 Hours Transit Visa",
                        "key": "48 Hours Transit Visa"
                    },
                    {
                        "value": "96 Hours Transit Visa",
                        "key": "96 Hours Transit Visa"
                    },
                    {
                        "value": "30 Days Tourist Visa",
                        "key": "30 Days Tourist Visa"
                    },
                    {
                        "value": "60 Days Tourist Visa",
                        "key": "60 Days Tourist Visa"
                    },
                    {
                        "value": "90 Days Tourist Visa",
                        "key": "90 Days Tourist Visa"
                    },
                    {
                        "value": "30 Days Multi Entry Tourist Visa",
                        "key": "30 Days Multi Entry Tourist Visa"
                    },
                    {
                        "value": "60 Days Multi Entry Tourist Visa",
                        "key": "60 Days Multi Entry Tourist Visa"
                    }
                ],
                "placeholder": "Select Type",
                "isRequired": true,
                "id": "57b8d861-2808-4d3a-8ee8-69a941db148c",
                "value": "30 Days Tourist Visa"
            }
        ],
        "crmLeadEmpAssignedList": [
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 349,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 11:45:37",
                "workflowTriggerOneId": 0
            },
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 347,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 11:45:37",
                "workflowTriggerOneId": 0
            },
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 348,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 11:45:37",
                "workflowTriggerOneId": 0
            },
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "relEmpId": 346,
                "clientUserAccountId": 0,
                "dateCreated": "2024-09-05 11:45:37",
                "workflowTriggerOneId": 0
            }
        ],
        "statusColorForUICell": "",
        "createdByEmpId": 270,
        "updatedByEmpId": 270,
        "crmLeadFormSettingId": 28,
        "formName": "Enquiry Form",
        "crmLeadTeamAssignedList": [
            {
                "relationshipId": 129,
                "crmLeadId": 0,
                "workflowTriggerId": 0,
                "teamId": 23,
                "teamName": "Panther",
                "teamCode": "P2",
                "dateCreated": "2024-09-05 11:45:37"
            }
        ],
        "isWalkInLead": 0
    }]
    const onSearchedInputChange = debounce((searchedText, props) => {
        handleSearchedText(searchedText, props);
    }, 100);

  

 

 

    const exportExcelData = (selectedContactList, props) => {
        const dataList = (selectedContactList || []).map((item) => {
            return {
                "Full Name": (item.firstName || "") + " " + (item.lastName || ""),
                "Email": item.emailAddress || "",
                "Phone Number": item.cellPhone || "",

            };
        });
        exportExcel(dataList, "Subscriber");
    };
    const downloadMenu = () => {
        return (
            <Menu className="row-action-dropdown">
                <Menu.Item key="0" value="title">
                    Download as
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item
                    key="1"
                    value="excel"
                    onClick={() => {
                        if (selectedItems.length) {
                            exportExcelData(selectedItems);
                        } else {
                            const modalData = {
                                title: (
                                    <div className="title">
                                        <i className="fa fa-ban mr15" />
                                        Validation Failed
                                    </div>
                                ),
                                modalBody: (
                                    <Fragment>
                                        <div className="warning-message">
                                            No subscriber selected. Please select subscriber before
                                            downloading.
                                        </div>
                                        <div className="button-action">
                                            <Button
                                                className="ant-btn-default"
                                                onClick={() => props.popModalFromStack()}
                                            >
                                                OK
                                            </Button>
                                        </div>
                                    </Fragment>
                                ),
                                hideFooter: true,
                                wrapClassName: "validation-failed-modal",
                            };
                            props.pushModalToStack(modalData);
                        }
                    }}
                >
                    <FileExcelOutlined />
                    Excel File
                </Menu.Item>
                {/* <Menu.Item
                    key="2"
                    value="pdf"
                    onClick={() => {
                        const customerList = [];
                        selectedItems.forEach((data) => {
                            // let data = obj.data;
                            let address =
                                find(data.boLocationList, {
                                    isDefault: 1,
                                    locationType: "BillingAddress",
                                }) || {};
                            let contact =
                                data.boContactList &&
                                find(data.boContactList, { isPrimaryContact: 1 });
                            customerList.push({
                                ...data,
                                defaultAddress: address,
                                defaultContact: contact,
                            });
                        });
                        if (customerList.length) {
                            props.generatePDF({
                                companyInfo: props.companyInfo,
                                fileName: "Customers.pdf",
                                transactionName: "customerListing",
                                customerList: customerList,
                            });
                        } else {
                            const modalData = {
                                title: (
                                    <div className="title">
                                        <i className="fa fa-ban mr15" />
                                        Validation Failed
                                    </div>
                                ),
                                modalBody: (
                                    <Fragment>
                                        <div className="warning-message">
                                            No customer selected. Please select customer before
                                            downloading.
                                        </div>
                                        <div className="button-action">
                                            <Button
                                                className="ant-btn-default"
                                                onClick={() => props.popModalFromStack()}
                                            >
                                                OK
                                            </Button>
                                        </div>
                                    </Fragment>
                                ),
                                hideFooter: true,
                                wrapClassName: "validation-failed-modal",
                            };
                            props.pushModalToStack(modalData);
                        }
                    }}
                >
                    <FilePdfOutlined />
                    PDF File
                </Menu.Item> */}
            </Menu>
        );
    };

    const handelConvertToLead = (e)=>{
        props.updateState({
            converted:[...props.converted,e.id]
        })
        props.saveOrUpdateLeads(e, props);
    
    }
    const actionMenu = (e) => {
        return (
            <Menu className="row-action-dropdown">
    
              <Menu.Item key="0" value="title">
                Actions
              </Menu.Item>
    
              <Menu.Divider />
              <Menu.Item key="3" onClick={()=>
                {
                handelConvertToLead(e);
                }
              }>
                <i className={ICONS.SHARE} />
                Convert To Lead
              </Menu.Item>
    
            </Menu >
        )
      };
      console.log(data?.filter((item)=>!props.converted?.includes(item.id)),"c",props);
      
    return (
        <>
            <div className="view-container">
                <div className="view-container-actions">
                    <div className="left-actions">
                        <div className="table-heading">Inquiry List</div>

                    </div>

                    <div className="right-actions">
                        <Search
                            placeholder="Search"
                            value={props.searchedText}
                            onChange={(e) => {
                                props.updateState({ searchedText: e.target.value, pageNumber: 1 });
                                onSearchedInputChange(e.target.value, props);
                            }}
                            onSearch={(searchedText) => {
                                props.updateState({ searchedText, pageNumber: 1 });
                                onSearchedInputChange(searchedText, props);
                                handleSearchedText(searchedText, props);
                            }}
                        />


                        {/* <Dropdown overlay={downloadMenu()} trigger={["click"]}>
                            <span>
                                <DownloadOutlined /> &nbsp;{" "}
                                <FormattedMessage
                                    id="common.action.dropdown.downloadAs"
                                    defaultMessage=""
                                />
                            </span>
                        </Dropdown> */}
                    </div>
                </div>
                <Skeleton loading={props.listLoading} paragraph={{ rows: 17 }} active>
                    <div className='table-container table-h-profile'
                        style={{ height: "calc(100% - 144px)" }}
                    >
                        <table id='contacts-table'>
                            <thead>
                                <tr>
                                <th onClick={sortColumn}>S.NO</th>
                                <th onClick={sortColumn}><FormattedMessage id='contact.fullName' defaultMessage="" /></th>
                                    <th onClick={sortColumn}>Form Name</th>
                                    <th onClick={sortColumn}><FormattedMessage id='contact.email' defaultMessage="" /></th>
                                    <th onClick={sortColumn}><FormattedMessage id='contact.mobile' defaultMessage="" /></th>
                                    {/* <th onClick={sortColumn}>Subscriber</th> */}
                                    <th onClick={sortColumn}>Source</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.filter((item)=>!props.converted?.includes(item.id))?.map((e, i) => {
                                    let name = e.crmLeadDetailsList?.find(e=>e.label == "Full Name").value;
                                    let phone = e.crmLeadDetailsList?.find(e=>e.label == "Mobile No.").value;
                                    let email = e.crmLeadDetailsList?.find(e=>e.label == "Email").value;
                                            return <tr key={i}>

                                                <td>{i+1}</td>
                                                <td>{name}</td>
                                                <td>{e.formName}</td>
                                                <td>
                                                    {email}
                                                </td>
                                                
                                                <td>
                                                    {phone}
                                                </td>
                                              
                                                {/* <td>{e.isSubscribe ? "yes" : "No"}</td>    */}
                                                <td>{e.leadSourceName}</td>
                                                <td style={{paddingLeft: '50px'}}>
                        <DropdownAction overlay={actionMenu(e)} trigger={['click']}>
                          <i className={ICONS.MORE} />
                        </DropdownAction>
                      </td>
                                            </tr>
                                        }) ||
                                    <tr key="empty-data-box">
                                        <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                            <Empty />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </Skeleton>
                {/* <div className="footer">
                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            // total={contacts.length}
                            showTotal={showTotal}
                            defaultPageSize={25}
                            showSizeChanger
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuickJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber });
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props);
                            }}
                        />
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default InquiryListComp;