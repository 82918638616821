import { PROJECT_ACTION_LIST } from '../static/constants';

const initialState = {
  projects: {
    1: []
  },
  projectsDetails: {
    1: []
  },
  projectsCount: 0,
  projectdetail:{},
  projectWithSubProject:[]
}

const ProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_ACTION_LIST.PROJECT_LIST:
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PROJECT_ACTION_LIST.PROJECT_COUNT:
      return { ...state, projectsCount: action.data };
    case PROJECT_ACTION_LIST.RESET_PROJECT_LIST:
      return { ...state, projects: initialState.projects };
    case PROJECT_ACTION_LIST.PROJECT_DETAIL_LIST:
      return {
        ...state,
        projectsDetails: {
        ...state.projectsDetails,
        [action.data.pageNo || 1]: action.data.list
          }
        };

    case PROJECT_ACTION_LIST.PROJECT_BY_ID:
      return { ...state, projectdetail: action.data };


      case PROJECT_ACTION_LIST.PROJECT_WITH_SUBPROJECT:
        return { ...state, projectWithSubProject: action.data};  
    default:
      return state;
  }
};

export default ProjectReducer;