import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {  showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../actions/commonActions';
import { fetchAllCategoriesWithSubCategories , postCategory} from './action';
import CatgeoryCreateDrawerComp from '../../../../components/drawer/eStore/CatgeoryCreate';
class CatgeoryCreateDrawer extends Component {

  constructor(props){
    super(props);
    console.log(props ,"CHECKINGGG")
    const { isUpdate ,categoryData} = props;
    this.state = {
      
      parentCategory:(isUpdate&& categoryData?.parentId ) ?  categoryData: '',
      addAsSubcategory: (isUpdate&& categoryData?.parentId ) ? true : false,
      categoryName: isUpdate ? categoryData?.categoryName :'',
    }
}
 
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: 25,
    };
   
    this.props.fetchAllCategoriesWithSubCategories(payload)
    
    
  }
  componentWillReceiveProps(nextProps) {
  
  }

  render() {
    return (
      // <ContactCompo {...this.props} {...this.state} />
      <CatgeoryCreateDrawerComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }}/>

    )
  }
}

const mapStateToProps = (state) => {
  return {
   blogCategoryList: state.eStore.blogCategoryList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchAllCategoriesWithSubCategories,
  postCategory
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CatgeoryCreateDrawer);
