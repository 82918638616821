import React, { Component, Fragment } from "react";
// import { Form } from "@ant-design/compatible";
import { QuestionCircleTwoTone, EditOutlined } from "@ant-design/icons";

import "@ant-design/compatible/assets/index.css";
import { Row, Col, DatePicker, Tooltip,Skeleton } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment-timezone";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import { Dropdown } from "../../../../general/Dropdown";
import { TextBox } from "../../../../general/TextBox";
import {
  CONSTANTS,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
} from "../../../../../static/constants";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";
import { showToasterMessage } from "../../../../../utils";
import { MultipleSelectDropdown } from "../../../../general/MultipleSelectDropdown";
import { DetailDropdown } from "../../../../general/DetailDropdown";
// import LoadingIcon from "../../../../../assets/images/loading.gif";

const format = CONSTANTS.TABLE_DATE_FORMAT;
class PurchaseRequestDetails extends Component {
  constructor(props) {
    super(props);
    this.companyInfo = this.props.companyInfo || {}
  }
  handleNewTaxIdentificationAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = function (taxIdentification) {
      if (props.customer && props.customer.customerTaxIdentificationsList) {
        let list = props.customer.customerTaxIdentificationsList || [];
        list.push(taxIdentification)
        props.updateState({ customer: { ...props.customer, customerTaxIdentificationsList: list } })
      }
    }
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      }
    };
    showModal(data);
  }
  handleNewAddressAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    if (modalType !== MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS && (!formData || !formData.customerId)) {
      return showToasterMessage({ messageType: 'error', description: 'Please Select Customer' });
    }
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
      payload.formData = { addressType: 'relationship', locationType: 'RelationshipBillingAddress' };
    }
    payload.formData.callback = function (address) {
      props.hideModal(data);
      if (address.locationType === 'BillingAddress' || address.locationType === 'ShippingAddress') {
        props.fetchAddresses({
          customerId: address.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
          locationType: address.locationType
        });
      } else {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId
        });
      }
    }
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      keyboard:false,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      }
    };
    showModal(data);
  }

  handleNewProjectAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: (
        <FormattedMessage id="addItem.text.project" defaultMessage="Project" />
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.PROJECT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewEmployeeAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.employee" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.EMPLOYEE,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="modal.commmon.submit"
          // values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {/* {labelName} ? */}
      </span>
    );

    let addFunc;
    let payload = {
      text: text,
      relationshipId: companyInfo.relationshipId,
    };

    switch (modalType) {
      case MODAL_TYPE.DEPARTMENT: {
        addFunc = props.addDepartment;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        addFunc = props.addDocumentName;
        payload.txType =
          CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST];
        break;
      }
      default: {
        addFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc(payload);
        props.hideModal();
        props.popModalFromStack();

      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage=""
            />
            <h6>{(this.props.companyInfo || {}).storeName} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.UOM: {
        title = (
          <FormattedMessage id="addItem.text.department" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        title = (
          <FormattedMessage id="addItem.text.documentName" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            // showModal(data);
          }
        },
      };
      props.pushModalToStack(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage={``}
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.DEPARTMENT: {
        deleteFunc = props.deleteDepartment;
        break;
      }
      case MODAL_TYPE.EMPLOYEE: {
        deleteFunc = props.deleteEmployee;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        deleteFunc = props.deleteDocumentName;
        break;
      }
      case MODAL_TYPE.PROJECT: {
        deleteFunc = props.deleteProject;
        break;
      }
      default: {
        deleteFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
        props.hideModal();
        if (
          modalType === MODAL_TYPE.DOCUMENT_NAME &&
          payload.id === this.props.documentNameId
        ) {
          const selectedObj =
            find(this.props.documentList || [], {
              txType:
                CONSTANTS_TRANSACTIONS_KEY[
                CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST
                ],
              isDefault: 1,
            }) || {};
          this.props.updateState({
            documentNameId: selectedObj.documentNameId,
            docName: selectedObj.docName,
          });
        }
      },
    };
    showModal(modalData);
  };


  render() {
    const { intl } = this.props;

    const getAddressInfoFragment = () => {
      let adress = this.props.companyInfo.boLocationList?.filter((obj) => {
        return (obj.locationType === CONSTANTS.RELATIONSHIP_BILLING_ADDRESS && obj.isDefault === 1)
      })

      const fragmentObj = <Fragment>

        {/* {
          adress[0]?.streetAddress1
            ? <div className='billing-address'>{adress[0]?.streetAddress1}
            </div>
            : ''
        }
        {
          adress[0]?.streetAddress2
            ? <div className='billing-address'>{adress[0]?.streetAddress2}
            </div>
            : ''
        }
        {
          adress[0]?.stateName
            ? <div className='billing-address'>
              {
                `${adress[0]?.cityName || ''}${adress[0]?.stateName ? ', ' + adress[0]?.stateName : ''}${adress[0]?.zipCode ? ', ' + adress[0].zipCode : ''}`
              }
            </div>
            : ''
        } */}
<div className="billing-address first-caps">
        {adress[0].streetAddress1 || adress[0].streetAddress2 ? (
          <div>
            {adress[0].streetAddress1} {adress[0].streetAddress2}{", "}
          </div>
        ) : ""}
        {adress[0].cityName || adress[0].stateName || adress[0].zipCode ? (
          <div>
            {adress[0].cityName} {adress[0].stateName}{adress[0].stateName && adress[0].zipCode ? ", " : ""}{adress[0].zipCode}
          </div>
        ) : ""}
        {adress[0].countryName ? (
          <div>{adress[0].countryName}</div>
        ) : ""}
      </div>
      </Fragment>

      return fragmentObj;
    }
    return (
      <Fragment>
        <Row>
          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>
            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Organization Information
              </Col>
              <Col span={24}>
                <div className='details-heading-subheading'>

                  <FormattedMessage id="sales.order.create.organization.name" defaultMessage='' />
                </div>
              </Col>
              <Col span={24}>
                <div className='deatils-field-value'>

                  {this.props.companyInfo.storeName}
                </div>
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">Address</div>
              </Col>

              <Col span={24}>
                <div style={{ height: '96px', paddingTop: '10px' }}>
                  {getAddressInfoFragment()}

                </div>


              </Col>

              {this.props.customerId ? (
                <div className="date-picker-container">
                  <>
                    <Col span={24}>
                      <div className="details-heading-subheading">
                        <FormattedMessage
                          id="supplier.rfq.form.customer.label"
                          defaultMessage=""
                        />
                      </div>
                    </Col>
                    <Col span={24}>
                      <span className="deatils-field-value">
                        {this.props.customerName || ""}
                      </span>
                    </Col>
                  </>
                </div>
              ) : (
                ""
              )}
              <Col span={24}>
                <div className='details-heading-subheading'>

                  <FormattedMessage id='customer.form.taxIdentification.label' defaultMessage='' />
                  <Tooltip placement="right" title={this.props.intl.formatMessage(
                    {
                      id: 'tooltip.taIdentification',
                      defaultMessage: ''
                    }
                  )} trigger="click">
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>

                <MultipleSelectDropdown
                  items={this.props.allRelationshipTaxIdentificatins || []}
                  placeholder="Tax Identification(s)"
                  mode='multiple'

                  onDeSelect={(value) => {
                    let { selectedRelationshipTaxIdentifications } = this.props
                    selectedRelationshipTaxIdentifications = filter(selectedRelationshipTaxIdentifications, (taxId) => {
                      return taxId !== value;
                    });
                    this.props.updateState({ selectedRelationshipTaxIdentifications });
                  }}
                  valueKeyName='taxIdentificationType'
                  valueKeyName2='taxIdentificationNumber'
                  selectedValue={(this.props.selectedRelationshipTaxIdentifications || []).length ? (this.props.selectedRelationshipTaxIdentifications || []) : []}
                  optionKeyName='relationshipTaxIdentificationId'
                  onSelect={(relationshipTaxIdentificationId) => {
                    let selectedRelationshipTaxIdentifications = this.props.selectedRelationshipTaxIdentifications || [];
                    if (selectedRelationshipTaxIdentifications.indexOf(relationshipTaxIdentificationId) === -1) {
                      selectedRelationshipTaxIdentifications.push(relationshipTaxIdentificationId);
                    } else {
                      selectedRelationshipTaxIdentifications = selectedRelationshipTaxIdentifications.filter((el) => {
                        return el !== selectedRelationshipTaxIdentifications
                      });
                    }
                    this.props.updateState({
                      selectedRelationshipTaxIdentifications
                    });
                  }}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION, payload);
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      taxIdentificationNumber: payload.textEntered,
                      submittedOnce: false,
                    }
                    this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                  }}
                />

              </Col>
              {/* <Row>
                                        <Col span={10} className='detail-column'>
                                            <div >
                                                <FormattedMessage id='supplier.purchaseRequest.form.department.label' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={14}>
                                            <Dropdown
                                                style={{ width: '100%' }}
                                                items={this.props.departments || []}
                                                valueKeyName='departmentName'
                                                optionKeyName='departmentId'
                                                placeholder='Select Department'
                                                value={this.props.department}
                                                canAddNew={true}
                                                canDelete={true}
                                                allowClear={true}
                                                onClear={() => {
                                                    this.props.updateState({ department: undefined })
                                                }}
                                                deleteClickHandler={(payload) => {
                                                    this.deleteClickHandler(this.props, MODAL_TYPE.DEPARTMENT, payload);
                                                }}
                                                onAddNewClick={(payload) => {
                                                    payload.formData = {
                                                        textBoxValue: payload.textEntered,
                                                        submittedOnce: false
                                                    }
                                                    this.handleNewItemAddition(this.props, payload, MODAL_TYPE.DEPARTMENT)
                                                }}
                                                onSelect={(department) => {
                                                    const selectedDepartMent = find(this.props.departments, {departmentId: Number(department)})
                                                    this.props.updateState({
                                                        department: selectedDepartMent.departmentName
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row> */}

              {/* <div className='date-picker-container'>
                                    <Row>
                                        <Col span={10} className='detail-column'>
                                            <div >
                                                <FormattedMessage id='common.status' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={14}>
                                            <span>
                                                Open
                                            </span>
                                        </Col>
                                    </Row>
                                </div> */}

              {/* <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.reason.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "reason.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  value={this.props.reason}
                  onChange={(e) => {
                    this.props.updateState({
                      reason: e.target.value,
                    });
                  }}
                />
              </Col> */}
            </Row>
          </Skeleton>
          </Col>

          <Col span={1}></Col>

          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>
            
            <Row gutter={[0, 3]}>
              <Col span={24}>
                <Col span={24}>
                  <div className="details-heading">Requestor Information</div>
                </Col>
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.RequestBy.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={14}>
                <div className="deatils-field-value">
                  {this.props.userInfo.firstName || ""}{" "}
                  {this.props.userInfo.middleName || ""}{" "}
                  {this.props.userInfo.lastName || ""}
                </div>
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  Address
                </div>
              </Col>

              <Col span={24}>
                <span>{
                  !this.props.addressHide && this.props.relationshipBillingAddress ?
                    <>

                      <div className='detail-address-value'>

                        <div style={{ height: '96px', paddingTop: '10px' }}>
                          {

                            getAddressInfoFragment2(this.props.relationshipBillingAddress || {}, {}, this.props.companyInfo, this.props)
                          }
                        </div>
                        <div onClick={() => {

                          this.props.updateState({
                            addressHide: true,
                          })
                        }}>
                          <EditOutlined />
                        </div>
                      </div>
                    </>
                    :
                    <Dropdown
                      style={{ width: "100%" }}
                      value={
                        (this.props.relationshipBillingAddress || {})
                          .streetAddress1
                      }
                      items={this.props.allRelationshipBillingAddress || []}
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      isFavouriteKeyName="isDefault"                     
                      canAddNew={true}
                      canDelete={true}
                      allowClear={true}
                      onClear={() => {
                        this.props.updateState({
                          relationshipBillingAddress: undefined,
                        });
                      }}
                      //permissions={this.props.permissions}
                      //moduleName={MODULE_CODED_VALUES.SUPPLIER_BILLING_ADDRESS}
                      //operations={[PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE]}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
                        };
                        this.handleNewAddressAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS
                        );
                      }}
                      onSelect={(locationId) => {
                        this.props.updateState({
                          relationshipBillingAddress: (
                            this.props.allRelationshipBillingAddress || []
                          ).find((x) => x.locationId === locationId),
                          addressHide: false,
                        });
                      }}
                    />
                }

                </span>

              </Col>
            </Row>
         </Skeleton>
          </Col>

          <Col span={1} />
          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

            <Row gutter={[0, 3]}>
              <Col span={24}>
                <div className="details-heading">Supplier Information</div>
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.supplierName.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                {/* <Dropdown
                  style={{ width: "100%" }}
                  items={this.props.employees}
                  valueKeyName="fullName"
                  placeholder="Select Owner"
                  value={this.props.owner}
                  optionKeyName="relationshipEmployeeId"
                  canAddNew={true}
                  canDelete={true}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({ owner: undefined });
                  }}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.EMPLOYEE,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    // payload.formData = {
                    //     firstName: payload.textEntered,
                    //     submittedOnce: false
                    // }
                    // this.handleNewEmployeeAddition(this.props, payload, MODAL_TYPE.EMPLOYEE)
                    this.props.updateHeaderState({
                      company: {
                        ...this.props.company,
                        employeeDrawerVisible: true,
                      },
                    });
                  }}
                  onSelect={(selectedValue) => {
                    const selectedObj = find(this.props.employees || [], {
                      relationshipEmployeeId: Number(selectedValue),
                    });
                    this.props.updateState({
                      owner: selectedObj.fullName,
                    });
                  }}
                /> */}
                {/* <DetailDropdown
              items={(this.props.suppliers[this.props.pageNumber] || [])}
              valueKeyName="supplierStoreName"
              style={{ width: "200px" }}
              optionKeyName="supplierId"
              // value={customerName}
             
             
              placeholder={intl.formatMessage({
                id: 'supplier.purchaseOrder.form.supplierName.placeholder', defaultMessage: ''
              })}
              onSelect={
                (supplierId) => {
                  const supplierObj = find(this.props.supplierSummary[this.props.pageNumber], { supplierId: supplierId }) || {};
                  const supplierPaymentTerm = find(this.props.paymentTerms, { paymentTermId: Number(supplierObj.paymentTermId) }) || {};
                  this.props.updateState({
                    supplierPaymentTerm,
                    stateSupplierId: supplierId,
                    supplierObj: supplierObj
                  });
                  this.props.fetchSupplierDetailsBySupplierId({
                    relationshipId: this.props.companyInfo.relationshipId,
                    supplierId: supplierId,
                    pageNumber: this.props.pageNumber || 1,
                    pageSize: this.props.pageSize || 100
                  });
                }}
            /> */}
                 <DetailDropdown
                  // items={this.props.supplierSummary[this.props.pageNumber] || []}
                  items={(this.props.suppliers[this.props.pageNumber] || [])}
                  valueKeyName='supplierStoreName'
                  optionKeyName='supplierId'
                  value={(this.props.supplierObj || {}).supplierName}
                  placeholder={intl.formatMessage({
                    id: 'supplier.purchaseOrder.form.supplierName.placeholder', defaultMessage: ''
                  })}
                  canAddNew={true}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({
                      boContactList: undefined,
                      selectedContact: undefined,
                      supplierAddresses: undefined,
                      supplier: undefined,
                      supplierPaymentTerm: undefined,
                      supplierBillingAddress: undefined,
                      poDueDate: undefined
                    })
                  }}
                  onSearch={(searchedText) => {
                    this.props.fetchSuppliers({
                        searchedText: searchedText,
                        pageNumber: 1,
                        companyInfo:this.props.companyInfo,
                        hideSpinner: true,
                        pageSize: 100,
                        relationshipId: (this.props.companyInfo || {}).relationshipId,filterData:true
                    })
                }}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.props.deleteSuppliers({ groupSupplierIds: [payload.id], relationshipId: (this.props.companyInfo || {}).relationshipId })
                  }}
                  onAddNewClick={(payload) => {
                    this.props.updateHeaderState({
                      supplier: {
                        supplierDrawerVisible: true,
                        supplierName: payload.textEntered,
                      }
                    })
                  }}
                  onSelect={
                    (supplierId) => {
                      const supplierObj = find(this.props.supplierSummary[this.props.pageNumber], { supplierId: supplierId }) || {};
                      const supplierPaymentTerm = find(this.props.paymentTerms, { paymentTermId: Number(supplierObj.paymentTermId) }) || {};
                      this.props.updateState({
                        supplierPaymentTerm,
                        stateSupplierId: supplierId,
                        supplierObj: supplierObj
                      });
                      this.props.fetchSupplierDetailsBySupplierId({
                        relationshipId: this.props.companyInfo.relationshipId,
                        supplierId: supplierId,
                        pageNumber: this.props.pageNumber || 1,
                        pageSize: this.props.pageSize || 100
                      });
                    }
                  }
                /> 
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.priority.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  items={CONSTANTS.PRIORITIES}
                  value={this.props.priority}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({ priority: undefined });
                  }}
                  placeholder="Select Priority"
                  onSelect={(priority) => {
                    this.props.updateState({
                      priority,
                    });
                  }}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.refNumber.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "supplier.purchaseRequest.form.refNumber.placeholder",
                    defaultMessage: "",
                  })}
                  maxLength={20}
                  type="text"
                  countStyle={{ right: '0px', top: '1px', height: '32px', backgroundColor: "#f3f3f3", display: 'flex', alignItems: 'center', padding: '4px', }}
                  value={this.props.reference}
                  onChange={(e) => {
                    this.props.updateState({
                      reference: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            </Skeleton>
          </Col>
          <Col span={1}></Col>
          <Col span={6} className='order-value-info'>
          <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Request Information
              </Col>

              {/* <Col span={24} >
                        <div className="details-heading-subheading">

                    <FormattedMessage
                            id="supplier.purchaseRequest.form.purchaseRequestNumber.label"
                            defaultMessage=""
                          />
                      <Tooltip
                        placement="right"
                        title={
                          <FormattedMessage
                            id="system.generatedPurchase.RequestNumber"
                            defaultMessage=""
                          />
                        }trigger="click"
                      >
                        <QuestionCircleTwoTone />
                      </Tooltip>
                        </div>
                    </Col>
                    <Col span={24}>
                      {this.props.nextTxNumber ? (
                        <span>{this.props.nextTxNumber}</span>
                      ) : (
                        <img
                          src={LoadingIcon}
                          alt="Invoice"
                          style={{
                            height: "74px",
                            "margin-top": "-25px",
                            "margin-bottom": "-25px",
                          }}
                        />
                      )}
                    </Col> */}

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.purchaseCreatedDate.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  key={`${this.props.purchaseRequestDate}`}
                  defaultValue={
                    this.props.purchaseRequestDate
                      ? moment(new Date(this.props.purchaseRequestDate), format)
                      : moment(new Date(), format)
                  }
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      purchaseRequestDate: selectedDate,
                    });
                    // this.props.getNextTxNumber({date: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                  }}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.expirationDate.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  disabledDate={(current) => current && current.isBefore(moment().startOf('day'))}
                  defaultValue={
                    this.props.prExpirationDate
                      ? moment(new Date(this.props.prExpirationDate), format)
                      : moment(new Date(), format)
                  }
                  key={`${this.props.prExpirationDate}`}
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      prExpirationDate: selectedDate,
                    });
                  }}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.salesOrder.form.documentDropdown.label"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={
                      <FormattedMessage
                        id="documentTitle.tooltip"
                        defaultMessage=""
                      />
                    }
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  items={filter(this.props.documentList || [], (document) => {
                    return (
                      document.txType ===
                      CONSTANTS_TRANSACTIONS_KEY[
                      CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST
                      ]
                    );
                  })}
                  placeholder="Document name"
                  value={this.props.docName}
                  canAddNew={true}
                  canDelete={true}
                  // allowClear={true}
                  // onClear={() => {
                  //     this.props.updateState({ documentNameId: undefined, docName: undefined })
                  // }}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.DOCUMENT_NAME,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      textBoxValue: payload.textEntered,
                      submittedOnce: false,
                      maxLength: 30,
                    };
                    this.handleNewItemAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.DOCUMENT_NAME
                    );
                  }}
                  onSelect={(selectedValue) => {
                    const selectedObj = find(this.props.documentList || [], {
                      documentNameId: Number(selectedValue),
                    });
                    this.props.updateState({
                      documentNameId: selectedValue,
                      docName: selectedObj.docName,
                    });
                  }}
                  valueKeyName="docName"
                  optionKeyName="documentNameId"
                  isLockKeyName="isSystemGenerated"
                />
              </Col>

              {this.props.customerInquiryNumber &&
                this.props.customerInquiryNumber !== "0" ? (
                <>
                  <Col span={6}>
                    <div className="detail-column">
                      <span >
                        <FormattedMessage
                          id="modal.txDetails.inquiry.customerInquiryNumber"
                          defaultMessage=""
                        />
                      </span>
                    </div>
                  </Col>
                  <Col span={6}>{this.props.customerInquiryNumber}</Col>
                </>
              ) : null}

              {this.props.customerRFQNumber &&
                this.props.customerRFQNumber !== "0" ? (
                <>
                  <Col span={24}>
                    <div className="detail-column">
                      <span className="tooltip-title">
                        <FormattedMessage
                          id="customerRfqNumber.text"
                          defaultMessage=""
                        />
                      </span>
                    </div>
                  </Col>
                  <Col span={24}>{this.props.customerRFQNumber}</Col>
                </>
              ) : null}

              {this.props.customerPONumber ? (
                <>
                  <Col span={24}>
                    <div className="detail-column">
                      <span className="tooltip-title">
                        <FormattedMessage
                          id="customerPONumber.text"
                          defaultMessage=""
                        />
                      </span>
                    </div>
                  </Col>
                  <Col span={24}>{this.props.customerPONumber}</Col>
                </>
              ) : null}

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.projectDropdown.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  items={this.props.projectList || []}
                  valueKeyName={"projectName"}
                  value={this.props.projectName}
                  placeholder={intl.formatMessage(
                    { id: 'common.select.placeholder', defaultMessage: '' }
                  )}
                  optionKeyName={"projectMasterId"}
                  projectIdKey={"projectMasterId"}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.PROJECT,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      projectName: payload.projectName,
                    };
                    this.handleNewProjectAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.PROJECT
                    );
                  }}
                  onSelect={(selectedValue, option) => {
                    const selectedProject =
                      find(this.props.projectList, {
                        projectMasterId: Number(option.key),
                      }) || {};
                    this.props.updateState({
                      projectMasterId: selectedProject.projectMasterId,
                      projectName: selectedProject.projectName,
                      projectNumber: selectedProject.projectNumber,
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({
                      projectMasterId: undefined,
                      projectName: undefined,
                      projectNumber: undefined,
                    });
                  }}
                />
              </Col>
            </Row>
            </Skeleton>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const getAddressInfoFragment2 = (obj, customerContactObj, customerObj, props) => {
  // if (!obj || !Object.keys(obj).length) {
  //     return '';
  // }


  let contactName = "";
  if (customerContactObj.salutationName) contactName += customerContactObj.salutationName + ' ';
  if (customerContactObj.firstName) contactName += customerContactObj.firstName;
  if (customerContactObj.middleName) contactName += ' ' + customerContactObj.middleName;
  if (customerContactObj.lastName) contactName += ' ' + customerContactObj.lastName;
  const fragmentObj = <Fragment>
    <div className="billing-address first-caps">
        {obj.streetAddress1 || obj.streetAddress2 ? (
          <div>
            {obj.streetAddress1} {obj.streetAddress2}{", "}
          </div>
        ) : ""}
        {obj.cityName || obj.stateName || obj.zipCode ? (
          <div>
            {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
          </div>
        ) : ""}
        {obj.countryName ? (
          <div>{obj.countryName}</div>
        ) : ""}
      </div>
    {
      customerContactObj.firstName
        ? <div className='billing-address'>
          {contactName}
        </div> :
        ''
    }
    {
      customerContactObj.workPhone
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.phone.label' defaultMessage='' /> - {customerContactObj.workPhone}
        </div> :
        ''
    }
    {
      customerContactObj.email
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.email.label' defaultMessage='' /> - {customerContactObj.email}
        </div> :
        ''
    }
    {/* {
      customerObj.gstNumber
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.gstIn.label' defaultMessage='' /> - {customerObj.gstNumber}
        </div> :
        ''
    }
    {
      customerObj.panNumber
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.pan.label' defaultMessage='' /> - {customerObj.panNumber}
        </div> :
        ''
    } */}

  </Fragment>

  return fragmentObj;
}
export default injectIntl(PurchaseRequestDetails);
