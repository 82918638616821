import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StudentProfileComp from '../../../../../components/modal/modalBody/schoolManagement/StudentProfile';
import {getSchoolAttendanceByStudentId, getSchoolCourseById, getContactEducation, saveContactEducation, deleteContactEducation, fetchPaymentsByCustomer, updateStudentProfilePic} from './action';
import { getSchoolAssignment } from '../../../../schoolManagement/assignment/action';
import { fetchCustomerPayments } from '../../../../customer/payment/Listing/action';
import EducationDrawer from "../../../../drawer/schoolManagement/education";
import { fetchOrgInvoiceProforma } from '../../../../B2B/Register/action';
import { getCurrentDateForBackend } from '../../../../../utils';


class StudentProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMonth: (new Date()).getMonth(),
            educationDrawerVisible: false,
        }
    }

componentDidMount(){
    const currentDate = new Date();
    const payload = {
        relationshipId: this.props.companyInfo.relationshipId,
        studentId:Number(this.props.studentData.schoolStudentId || 0),
        startDate: getCurrentDateForBackend(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
        endDate: getCurrentDateForBackend(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)),

      }
    this.props.getSchoolAttendanceByStudentId(payload);
}

    render() {
        return <>
            {this.state.educationDrawerVisible ? <EducationDrawer {...this.props} {...this.state} updateModalState={(data) => { this.setState(data) }} /> : ''}
            <StudentProfileComp {...this.props} {...this.state} updateModalState={(data) => { this.setState(data) }} />
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        userInfo: state.common.userInfo,
        studentAttendance: state.school.studentAttendance,
        assignmentList: state.school.assignmentList,
        customerPaymentList: state.customerPayment.customerPaymentList,
        schoolCourseDetail: state.school.schoolCourseDetail,
        contactEducationList: state.common.contactEducationList,
        allCustomerPayments: state.customerPayment.allCustomerPayments,
        feesInvoiceProformas: state.studentRegistration.registrationInvoiceProforma,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getSchoolAttendanceByStudentId,
    getSchoolAssignment,
    fetchCustomerPayments,
    getSchoolCourseById,
    getContactEducation,
    saveContactEducation,
    deleteContactEducation,
    fetchPaymentsByCustomer,
    updateStudentProfilePic,
    fetchOrgInvoiceProforma,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (StudentProfile);