import React, { useEffect, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import "@ant-design/compatible/assets/index.css";
import { Drawer, Row, Col, Checkbox, Input, Empty, Pagination } from "antd";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { Dropdown } from "../../../general/Dropdown";
import * as find from "lodash.find";
import * as debounce from "lodash.debounce";
import { LIST_CONSTANTS } from "../../../../static/constants";
import { fetchDataIfNeeded, getUserDefinedName } from "../../../../utils";
import filter from "lodash.filter";
import { MultipleSelectDropdown } from "../../../general/MultipleSelectDropdown";

const { Search } = Input;

function DistributionGroupDrawer(props) {
  const {
    dgName,
    allEmployee,
    pageNumber,
    customerContacts,
    supplierContacts,
    selectedClassList,
    updateState,
    selectedType,
    selectedSectionList,
    subscribers,
    getSchoolStudents,
    sectionList,
    selectedUsers,
    classList,
    schoolStudentList,
    schoolStudentsCount,
    pageSize,
    getSection,
    selectedContacts = [],
    getClassList,
  } = props;
  const selectRole = (checkedValues) => {
    let filteredContacts =[];
    filteredContacts = allEmployee.filter((employee) => {
      return checkedValues.includes(employee.jobTitleName);
    });
    if((checkedValues||[])?.length){
      
      props.updateState({
        filteredContacts,
        
      });
    }
    else{
      props.updateState({
        filteredContacts: allEmployee,
        
      });
    }
  };
  
  useEffect(() => {
    let val = (selectedUsers || [])?.map((item) => item?.selectedContactId);
    props.updateState({
      selectedContacts: val,
    });
  }, [selectedUsers]);
  

  const handleDeselect = (
    value,
    selectedList,
    list,
    updateState,
    
  ) => {
    let updatedList = selectedList.includes(value)
      ? filter(selectedList, (id) => id !== value)
      : selectedList;

    let tempList = [];
    if (updatedList.length === 1) {
      tempList = list.find((obj) => obj.classId === updatedList[0]);
    }

    updateState({
      selectedClassList: updatedList,
      sectionList: tempList?.classSectionList || [],
      selectedSectionList: [],
    });

    const payload ={ 
      relationshipId: props.companyInfo.relationshipId,
      classId: updatedList,
    
     }

     props.getSchoolStudentsContact(payload ,()=>{ props.updateState({
      isLoading: true,
      filteredContacts:[],
     })})
    
  };

  const handleSelect = (
    selectedId,
    selectedList,
    list,
    updateState,
   
  ) => {
    let updatedList = selectedList.includes(selectedId)
      ? filter(selectedList, (id) => id !== selectedId)
      : [...selectedList, selectedId];

    const tempList = list.find((obj) => obj.classId === selectedId);

    updateState({
      selectedClassList: updatedList,
      sectionList: tempList?.classSectionList || [],
    });
     const payload ={
      relationshipId: props.companyInfo.relationshipId,
      classId: updatedList,
    
     }

     props.getSchoolStudentsContact(payload,()=>{ props.updateState({
      isLoading: true,
      filteredContacts:[],
     })})
    
  };
 
  const selectContact = (value) => {
   
    const contactTypeMap = {
      Customer: customerContacts,
      Suppliers: supplierContacts,
      Subscriber: subscribers,
      Team: allEmployee,
      Student: schoolStudentList[1] || [],
    };

    const filteredContacts =
      (contactTypeMap[value] || [])?.filter((user) =>
        value === "Customer" ? user.contactType === "Customer" : true
      ) || [];

    // Uncomment and modify the payload and fetch operations as needed
    // if (value === "Student") {
    //   const payload = {
    //     pageNumber: pageNumber,
    //     pageSize: pageSize,
    //     relationshipId: props.companyInfo?.relationshipId,
    //     companyInfo: props.companyInfo,
    //     relationshipEmployeeId: props.companyInfo?.relationshipEmployees.relationshipEmployeeId,
    //   };
    //   getSchoolStudents(payload);
    //   getSection(payload);
    //   getClassList(payload);
    //   props.updateState({
    //     pageNumber: 1,
    //     selectedType: value,
    //     isLoading: true,
    //   });
    // }

    props.updateState({
      filteredContacts,
      selectedType: value,
      searchedText: null,
    });
  };

  const handleSearchedText = (searchedText, props) => {
    let searchResult = [];

    if (searchedText.length) {
      searchResult = props.filteredContacts.filter((user) => {
        return (
          (user?.firstName &&
            user.firstName
              .toLowerCase()
              .includes(searchedText.toLowerCase())) ||
          (user?.emailAddress &&
            user.emailAddress
              .toLowerCase()
              .includes(searchedText.toLowerCase())) ||
          (user?.cellPhone && user.cellPhone.includes(searchedText)) ||
          (user?.primaryEmail &&
            user.primaryEmail
              .toLowerCase()
              .includes(searchedText.toLowerCase())) ||
          (user?.primaryMobileNo &&
            user.primaryMobileNo
              .toLowerCase()
              .includes(searchedText.toLowerCase())) ||
          (user?.studentName &&
            user.studentName.toLowerCase().includes(searchedText.toLowerCase()))
        );
      });

      props.updateState({
        searchResult: [...searchResult],
        selectedType: selectedType,
      });
    }
  };

  const onSearchedInputChange = debounce((searchedText, props) => {
    handleSearchedText(searchedText, props);
  }, 100);

  const onSelectionChange = (item, e) => {
    let selectUser = find(
      selectedUsers,
      (user) =>
        user.subscriberContactId === Number(item.subscriberContactId) ||
        user.contactId === Number(item.contactId) ||
        user.relationshipEmployeeId === Number(item.relationshipEmployeeId)
       
    );

    let updatedItems = [];
    if (selectUser) {
      updatedItems = selectedUsers.filter(
        (obj) =>
          obj.subscriberContactId !== item.subscriberContactId ||
          obj.contactId !== item.contactId ||
          obj.relationshipEmployeeId !== item.relationshipEmployeeId
      );
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedUsers));
      item.selectedContactId =
        Number(item?.subscriberContactId) ||
        Number(item?.contactId) ||
        Number(item?.relationshipEmployeeId) 
      item.selectedContactName =
        item?.firstName +" "+ (item?.lastName||"");
      updatedItems.push(item);
    }
    props.updateState({ selectedUsers: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedUsers.splice(0, selectedUsers.length);
      props.updateState({ selectedUsers });
      return;
    }

    const currentGroup = props.searchedText
      ? props.searchResult
      : props.filteredContacts || [];

    (currentGroup || []).forEach((item) => {
      let selectItem = find(
        selectedUsers,
        (user) =>
          user.subscriberContactId === Number(item?.subscriberContactId) ||
          user.contactId === Number(item?.contactId) ||
          user.relationshipEmployeeId ===
            Number(item?.relationshipEmployeeId) 
      );
      item.selectedContactId =
        Number(item?.subscriberContactId) ||
        Number(item?.contactId) ||
        Number(item?.relationshipEmployeeId) 
      item.selectedContactName =
        item?.firstName +" "+ (item?.lastName||"") ;
      if (!selectItem) {
        selectedUsers.push(item);
        selectedContacts.push(item?.selectedContactId);
      }
    });

    props.updateState({ selectedUsers });
  };

  const isCheckedAll = () => {
    const currentGroup = props.searchedText
      ? props.searchResult
      : props.filteredContacts || [];

    if ((currentGroup || []).length === 0) {
      return false;
    }

    for (let i = 0; i < (currentGroup || []).length; i++) {
      let item = currentGroup[i];
      let selectItem = find(
        selectedUsers,
        (user) =>
          user.subscriberContactId === Number(item.subscriberContactId) ||
          user.contactId === Number(item.contactId) ||
          user.relationshipEmployeeId === Number(item.relationshipEmployeeId) 
          
      );
      if (!selectItem) {
        return false;
      }
    }

    return true;
  };

  const closeDrawer = () => {
    props.updateHeaderState({
      communication: {
        addDGDrawerVisible: false,
      },
    });
  };

  const handleSubmit = (props) => {
    // if (!props.selectedUsers || props.selectedUsers.length === 0) {
    //   showToasterMessage({ messageType: 'error', description: props.intl.formatMessage({ id: 'contactIsNotSelected.error.message' }) });
    // }
    //  if (props.dgName && props.selectedUsers && props.selectedUsers.length > 0) {
    if (props.dgName) {
      let usrObj = [];
      for (let i = 0; i < props.selectedUsers.length; i++) {
        let d = {};
        d.relationshipId = props.relationshipId;
        d.contactId = props.selectedUsers[i].contactId;
        d.dgContactRefId = props.selectedUsers[i].dgContactRefId;
        d.version = props.selectedUsers[i].version;
        d.relationshipEmployeeId =
          props.selectedUsers[i].relationshipEmployeeId;
        d.subscriberContactId = props.selectedUsers[i].subscriberContactId;
        d.distributionGroupId = props.selectedUsers[i].distributionGroupId;
        d.dateCreated = props.selectedUsers[i].dateCreated;
      
        usrObj.push(d);
      }
      // {"dgContactRefId": 1,"relationshipId": 101,"contactId": 202,"distributionGroupId": 303,"subscriberContactId": 404,"version": 1,"dgIdList": [10, 20, 30],"dateCreated": "2023-12-01 15:30:00"}
      const payload = {
        dgName: props.dgName,
        dgContactRefList: usrObj,
        relationshipId: props.relationshipId,
        version: props.version,
        dateCreated: props.dateCreated,
        distributionGroupId: props.distributionGroupId,
      };

      props.pushModalToStack({
        modalBody: props.distributionGroupId ? (
          <FormattedMessage
            id="commons.edit.popup.confimation.msg"
            defaultMessage=""
          />
        ) : (
          <FormattedMessage
            id="commons.create.popup.confimation.msg"
            defaultMessage=""
          />
        ),
        handleSubmit: () => {
          props.saveDG(payload, props);
        },
      });
    } else {
      props.updateState({
        submittedOnce: true,
      });
    }
  };
 

  return (
    <Drawer
      title={
        <FormattedMessage
          id="communication.dgGroup.addDrawer.drawerName"
          defaultMessage=" "
        />
      }
      width={720}
      keyboard={false}
      zIndex={1}
      destroyOnClose={true}
      onClose={closeDrawer}
      maskClosable={false}
      className="custom-drawer"
      visible={props.addDGDrawerVisible}
      footer={
        <>
          {/* <div style={{display: "flex" ,width:"100%" ,gap:"10px" ,flexDirection:"column"}}>
          <div style={{display: "flex" ,justifyContent: "flex-end" ,alignItems:"center"}}>
            {
              selectedType === "Student" ?  <Pagination
               size="small"
              total={schoolStudentsCount||0}
              showTotal={(total) => `Total ${total} items`}
              defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
              onChange={(page ,pageSize)=>{
                props.updateState({
                  pageNumber: page,
                  pageSize: pageSize
                })
                getTotalContactCount(page ,pageSize)

              }}
              
              defaultCurrent={1}
            /> :''
            }
         
          </div>
        <div style={{display: "flex" ,justifyContent:"space-between" ,alignItems:"center"}}> */}
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => handleSubmit(props)}
            key={1}
          />
          {/* </div>
        </div> */}
        </>
      }
    >
     
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className="i-label">
            <FormattedMessage
              id="communication.dgGroup.addDrawer.field.name"
              defaultMessage=""
            />
            <span className="required">*</span>
          </div>

          <TextBox
            minLength={45}
            maxLength={100}
            countStyle={{ top: "40px" }}
            type="text"
            value={dgName}
            onChange={(e) => {
              props.updateState({
                dgName: e.target.value,
              });
            }}
            placeholder={props.intl.formatMessage({
              id: "communication.dgGroup.addDrawer.field.name",
              defaultMessage: "",
            })}
          />
          <ErrorMsg
            validator={() => {
              return !props.submittedOnce || props.dgName;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col span={24}>
          <div className="-i-label">Distribution Name</div>
          <Dropdown
            onChange={selectContact}
            value={selectedType}
            placeholder="Select Distribution Name"
            items={LIST_CONSTANTS.DISTRIBUTION_NAME_LIST}
          />
        </Col>

        {selectedType === "Team" && props.jobTitleList.length ? (
          <Col span={24}>
            <Checkbox.Group
              options={props.jobTitleList.map((jobRole) => jobRole.jobTitle)}
             
              onChange={selectRole}
            />
          </Col>
        ) : (
          ""
        )}

        {selectedType === "Student" ? (
          <>
            <Col span={12}>
              <label>{getUserDefinedName("Class", props)}</label>
              <MultipleSelectDropdown
                items={classList[1] || []}
                placeholder={getUserDefinedName("Select Class", props)}
                mode="multiple"
                onDeSelect={(value) =>
                  handleDeselect(
                    value,
                    selectedClassList,
                    classList[1],
                    updateState,
                   
                  )
                }
                valueKeyName="className"
                valueKeyName2="courseName"
                selectedValue={selectedClassList}
                optionKeyName="classId"
                onSelect={(selectedClassId) =>
                  handleSelect(
                    selectedClassId,
                    selectedClassList,
                    classList[1],
                    updateState,
                  
                  )
                }
              />
            </Col>
            {selectedClassList.length === 1 && sectionList.length >= 1 ? (
              <Col span={12}>
                <label>Section</label>
                <MultipleSelectDropdown
                  items={sectionList || []}
                  placeholder="Select Section"
                  mode="multiple"
                  onDeSelect={(value) => {
                    const updatedSectionList = filter(
                      selectedSectionList,
                      (id) => id !== value
                    );
                    updateState({ selectedSectionList: updatedSectionList });
                    const payload ={
                      relationshipId: props.companyInfo.relationshipId,
                      classId: props.selectedClassList,
                      sectionId: updatedSectionList,
                     }
                
                     props.getSchoolStudentsContact(payload , ()=>{ props.updateState({
                      isLoading: true,
                     filteredContacts:[],
                     })})
                    
                  }}
                  valueKeyName="sectionName"
                  selectedValue={selectedSectionList}
                  optionKeyName="classSectionId"
                  onSelect={(selectedSectionId) => {
                    let updatedSectionList = selectedSectionList.includes(
                      selectedSectionId
                    )
                      ? filter(
                          selectedSectionList,
                          (id) => id !== selectedSectionId
                        )
                      : [...selectedSectionList, selectedSectionId];

                    updateState({ selectedSectionList: updatedSectionList });
                    const payload ={
                      relationshipId: props.companyInfo.relationshipId,
                      classId: props.selectedClassList,
                      sectionId: updatedSectionList,
                     }
                
                     props.getSchoolStudentsContact(payload, ()=>{ props.updateState({
                      isLoading: true,
                      filteredContacts:[],
                     })})
                  
                   
                  }}
                />
              </Col>
            ) : (
              ""
            )}{" "}
          </>
        ) : (
          ""
        )}
          {
            (selectedUsers||[])?.length ? ( <Col span={24} className="selected-contacts">
              <label>Selected Contacts</label>
              <MultipleSelectDropdown
                items={(selectedUsers || [])}
                placeholder={"Selected Contacts"}
                mode="multiple"
                onDeSelect={(value) => {
                  let selectedValue = filter(selectedUsers, (val) => {
                    return val?.selectedContactId !== Number(value);
                  });
                  props.updateState({
                    selectedUsers: selectedValue,
                  });
                }}
                valueKeyName="selectedContactName"
                selectedValue={selectedContacts || []}
                optionKeyName="selectedContactId"
                onSelect={(selectedSectionId) => {
                  
                }}
              />
            </Col>):''
          }
        <Col span={24}>
          <Search
            placeholder="Search"
            value={props.searchedText}
            onChange={(e) => {
              props.updateState({
                searchedText: e.target.value,
                pageNumber: 1,
              });
              onSearchedInputChange(e.target.value, props);
            }}
            onSearch={(searchedText) => {
              props.updateState({ searchedText, pageNumber: 1 });
              onSearchedInputChange(searchedText, props);
              handleSearchedText(searchedText, props);
            }}
          />
        </Col>

        <Col span={24}>
          <div
            className="new-tables"
            style={{ marginBottom: "50px", borderRight: "none" }}
          >
            <table width={100}>
              <thead>
                <tr>
                  <th width="8%">
                    <Checkbox
                      checked={isCheckedAll()}
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                    />
                  </th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {(
                  (props.searchedText
                    ? props.searchResult
                    : props.filteredContacts) || []
                ).length ? (
                  (props.searchedText
                    ? props.searchResult
                    : props.filteredContacts
                  ).map((user, i) => (
                    <tr key={i}>
                      <td>
                        <Checkbox
                          checked={
                            find(
                              selectedUsers,
                              (val) =>
                                val.subscriberContactId ===
                                  Number(user.subscriberContactId) || val.selectedContactId === Number(user.selectedContactId)||
                                val.contactId ===
                                  Number(user.contactId) ||
                              
                                val.relationshipEmployeeId ===
                                  Number(user.relationshipEmployeeId)
                            )
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            onSelectionChange(user, e);
                          }}
                        />
                      </td>
                      <td>
                        {user?.firstName } {user?.lastName}
                      </td>
                      <td>
                        {user?.cellPhone }
                        {}
                      </td>
                      <td>{user?.emailAddress }</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={"100%"}>
                      <Empty>
                        {!selectedType ? "Please select distribution name" : ""}
                      </Empty>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </Drawer>
  );
}

export default injectIntl(DistributionGroupDrawer);
