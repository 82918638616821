import React from "react";
import { Card, Button, Col, Row, Tooltip } from "antd";
import envConfig from "../../../config/environmentConfig";
import AuthNetIcon from "../../../assets/images/authorizenet-logo.png";
// import RazorpayIcon from "../../../assets/images/razorpay-logo.png";
import { MODAL_TYPE } from "../../../static/constants";
// import PaypalIcon from '../../../assets/images/paypal-logo.png';
// import NeonIcon from '../../../assets/images/neon-logo.png';
import StripeIcon from "../../../assets/images/stripe-logo.png";
import CardpointeIcon from "../../../assets/images/cardpointe.png";
//import { usePlaidLink } from 'react-plaid-link';
import "./index.scss";
import { FormattedMessage } from "react-intl";
//import PaymentIntegration from '../../../components/modal/modalBody/common/paymentIntegrate/paymentIntegration';
import CardConnectAddNew from "./cardConnectIntegrate.js";
import StripeAddNew from "./stripeIntegrate.js";
import payIcon1 from "../../../assets/images/icon/razorPayIcon.png";
import payIcon2 from "../../../assets/images/icon/authNetIcon.png";
import payIcon3 from "../../../assets/images/icon/stripeIcon.png";
import payIcon4 from "../../../assets/images/icon/cardPointIcon.png";

const PaymentComponent = (props) => {
  // const  onSuccess = (token, metadata)=>{
  //   props.setAccessToken({publicToken: token})
  // }
  // const config = {
  //   token: props.plaidLinkToken,
  //   onSuccess,
  // };

  const {stripeConfigData} = props;

  const addItemConfirmationModal = (payload, text) => {
    const { showModal } = props;
    const labelName = <FormattedMessage id="customName.text" />;
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    payload.userDefinedGatewayName = text;
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.updateName(payload);
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const editName = (props, payload, modalType) => {
    const formData = { maxLength: 50 };
    const { showModal } = props;
    const data = {
      title: <FormattedMessage id="customName.text" />,
      formData,
      hideFooter: false,
      modalData: {
        modalType,
        data: { ...payload, formData },
        selectedValue: payload.userDefinedGatewayName,
      },
      handleSubmit: (formData = {}) => {
        if (formData.textBoxValue && formData.textBoxValue.length) {
          addItemConfirmationModal(payload, formData.textBoxValue, modalType);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      },
    };
    showModal(data);
  };
  // const { open, ready } = usePlaidLink(config);
  return (
    <div className="container">
      <Row>
          <Col span={24}>
            <div className="header">
              <h2>Payment Gateways</h2>
              <p>Manage your company payment gateways</p>
            </div>
          </Col>
      </Row>
      
      <Row>
        <Col xs={24} sm={24} md={24} lg={20} xl={12} xxl={8}>
            <div className="paymentOptionArea">
                  {props.AuthorizeNetGatewayList &&
                    props.AuthorizeNetGatewayList.length !== 0 &&
                    props.AuthorizeNetGatewayList.map((authrizeConfigData, i) => {
            
                      return (
                        <Card
                          key={"auth-" + i}
                          className="accounting-market-place integs"
                          title="">
                          <div className="nameArea">
                            <img src={payIcon2} alt="" />
                            <div>
                              <h2>Authorize.net</h2>
                              <div class="cardName">
                              {authrizeConfigData.userDefinedGatewayName ? (
                                authrizeConfigData.userDefinedGatewayName
                              ) : (
                                <FormattedMessage id="authorize.net.logo" />
                              )}
                              </div>
                            </div>
                          </div>
                          <div className="buttonArea">
                            <div className="activeBtn">Active</div>
                            <Button
                              className="ant-btn-primary editButton"
                              title="Edit"
                              onClick={() => {
                                editName(
                                  props,
                                  authrizeConfigData,
                                  MODAL_TYPE.CUSTOM_NAME
                                );
                              }}
                            >
                              Edit
                            </Button>
                          {authrizeConfigData.alpidePaymentGatewayId ? (
                            <Button
                              className="ant-btn-danger"
                              onClick={() => {
                                const modalData = {
                                  modalBody: (
                                    <FormattedMessage
                                      id="disconnect.confirmation"
                                      defaultMessage=""
                                    />
                                  ),
                                  handleSubmit: () => {
                                    props.disconnectConfiguration(
                                      authrizeConfigData,
                                      props
                                    );
                                  },
                                };
                                props.pushModalToStack(modalData);
                              }}
                            >
                              Disconnect{" "}
                            </Button>
                          ) : (
                            <Button
                              className="ant-btn-primary"
                              onClick={() => {
                                //props.getStripeAccountOnboardingLink();
                                window.location = `${envConfig.PAYMENT_SERVER_BASE_URL}/alpide_authorise/onboarding?callback_uri=${envConfig.CALLBACK_URI_AUTH_NET}&relationshipId=${props.companyInfo?.relationshipId}&apiBaseUrl=${envConfig.API_BASE_URL}`;
                              }}
                            >
                              Connect
                            </Button>
                          )}
                          </div>
                          
                          
                          {/* <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
                              <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
                              <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p> */}
                          {/* <div className="border-footer mt10 mb20"></div> */}
                          
                        </Card>
                      );
                    })}

                  <div className="paymentOption" title="">
                    <div className="nameArea">
                      <img src={payIcon2} alt="" />
                      <h2>Authorize.net</h2>
                    </div>
                    <div className="buttonArea">
                      <Button
                        className="cnant-btn ant-btn-primary"
                        onClick={() => {
                          //props.getStripeAccountOnboardingLink();
                          window.location = `${envConfig.PAYMENT_SERVER_BASE_URL}/alpide_authorise/onboarding?callback_uri=${window.location.href}&relationshipId=${props.companyInfo?.relationshipId}&apiBaseUrl=${envConfig.API_BASE_URL}`;
                        }}
                      >
                        Connect
                      </Button>
                    </div>
                    {/* <h2><i class="fa fa-plus"></i></h2> */}
                    {/* <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
                <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
                <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p> */}
                    {/* <div className="border-footer mt10 mb20"></div> */}
                  </div>

                  <div className="paymentOption" title="">
                    <div className="nameArea">
                      <img src={payIcon3} alt="" />
                      <div>
                        <h2>Stripe</h2>
                        <div className="cardName">
                          {stripeConfigData && stripeConfigData.alpidePaymentGatewayId && stripeConfigData.userDefinedGatewayName ? 
                            stripeConfigData.userDefinedGatewayName
                            : 
                            ""  
                          }
                        </div>
                      </div>
                      {stripeConfigData.alpidePaymentGatewayId ? (stripeConfigData?.webhookId ? 
                        <Tooltip placement="top" title="A webhook has been successfully set up on your Stripe account. Please ensure that it remains active, as it is essential for the proper functioning of the payment integration." trigger="hover">
                          <i className="fi fi-rr-link-alt"/>
                        </Tooltip>
                        :
                        <Tooltip placement="top" title="An error occurred during the Stripe integration process, which may have affected the successful completion of the payment. Please verify if the payment was processed correctly." trigger="hover">
                          <i className="fi fi-rr-link-alt required"/>
                        </Tooltip>
                        )
                        :
                        ""
                      }
                    </div>
                    <div className="buttonArea">
                      {stripeConfigData && stripeConfigData?.alpidePaymentGatewayId ? 
                        <div className="activeBtn">Active</div>
                        : 
                        ""
                      }
                      {stripeConfigData && stripeConfigData?.alpidePaymentGatewayId ? 
                        <Button
                          className="ant-btn-primary editButton"
                          title="Edit"
                          onClick={() => {
                            editName(
                              props,
                              stripeConfigData,
                              MODAL_TYPE.CUSTOM_NAME
                            );
                          }}
                        >
                          Edit
                        </Button>
                        :
                        ""
                      }
                      {props.stripeConfigData?.alpidePaymentGatewayId ? 
                        <Button
                          className="ant-btn-danger"
                          onClick={() => {
                            const modalData = {
                              modalBody: (
                                <FormattedMessage
                                  id="disconnect.confirmation"
                                  defaultMessage=""
                                />
                              ),
                              handleSubmit: () => {
                                props.disconnectConfiguration(
                                  props.stripeConfigData,
                                  props
                                );
                              },
                            };
                            props.pushModalToStack(modalData);
                          }}
                        >
                          Disconnect{" "}
                        </Button>
                       : 
                        <Button
                          type="primary"
                          onClick={() => {
                            //props.getStripeAccountOnboardingLink();
                            props.pushModalToStack({
                              modalBody: <StripeAddNew {...props} />,
                              title: (
                                <FormattedMessage
                                  id="stripe.logo"
                                  defaultMessage=""
                                />
                              ),
                              width: "40%",
                              hideFooter: true,
                            });
                          }}
                        >
                          Connect
                        </Button>
                      }
                    </div>
                    {/* <h2><FormattedMessage id='stripe.logo' defaultMessage='' /></h2> */}
                    {/* <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
                <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
                <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p>
                <div className="border-footer mt10 mb20"></div> */}
                  </div>

                  {/* <div className="paymentOption" title="">
                    <div className="nameArea">
                      <img src={payIcon3} alt="" />
                      <h2>Stripe</h2>
                    </div>
                    <div className="buttonArea">
                      {props.StripeOnboardingUrl ? 
                        <Button
                          type="primary"
                          loading={props.saveLoading}
                          onClick={() => {
                            window.open(props.StripeOnboardingUrl, '_blank');
                          }}
                        >
                          Onboard
                        </Button>
                        :
                        ''             
                      }
                      {props.stripeConfigData?.accountId ? 
                        <Button
                          className="ant-btn-danger"
                          onClick={() => {
                            const modalData = {
                              modalBody: (
                                <FormattedMessage
                                  id="disconnect.confirmation"
                                  defaultMessage=""
                                />
                              ),
                              handleSubmit: () => {
                                props.disconnectConfiguration(
                                  props.stripeConfigData,
                                  props
                                );
                              },
                            };
                            props.pushModalToStack(modalData);
                          }}
                        >
                          Disconnect{" "}
                        </Button>
                        : 
                        <Button
                          type="primary"
                          loading={props.saveLoading}
                          onClick={() => {
                            props.createConnectedAccount(props);
                          }}
                        >
                          Connect
                        </Button>
                      }

                    </div>
                  </div> */}

                  {props.cardPointGatewayList &&
                    props.cardPointGatewayList.length !== 0 &&
                    props.cardPointGatewayList.map((cardPointConfigData, i) => {
                      return (
                        <div className="paymentOption" title="">
                          {/* <h2><FormattedMessage id='stripe.logo' defaultMessage='' /></h2> */}
                          {/* <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
                              <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
                              <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p> */}
                          <div className="nameArea">
                            <img src={payIcon4} alt="" />
                            <div>
                              <h2>Cardpoint</h2>
                              <div className="cardName">
                                {cardPointConfigData?.alpidePaymentGatewayId && cardPointConfigData?.userDefinedGatewayName ? (
                                  cardPointConfigData.userDefinedGatewayName
                                ) : (
                                  <FormattedMessage id="authorize.net.logo" />
                                )}
                              </div>
                            </div>
                            
                          </div>
                          <div className="buttonArea">
                            {cardPointConfigData &&
                            cardPointConfigData.alpidePaymentGatewayId ? (
                              <div className="activeBtn">Active</div>
                            ) : (
                              ""
                            )}
                            <Button
                              className="ant-btn-primary editButton"
                              title="Edit"
                              onClick={() => {
                                editName(
                                  props,
                                  cardPointConfigData,
                                  MODAL_TYPE.CUSTOM_NAME
                                );
                              }}
                            >
                              Edit
                            </Button>

                            {cardPointConfigData &&
                            cardPointConfigData.alpidePaymentGatewayId ? (
                              <Button
                                className="ant-btn-danger"
                                onClick={() => {
                                  const modalData = {
                                    modalBody: (
                                      <FormattedMessage
                                        id="disconnect.confirmation"
                                        defaultMessage=""
                                      />
                                    ),
                                    handleSubmit: () => {
                                      props.disconnectConfiguration(
                                        cardPointConfigData,
                                        props
                                      );
                                    },
                                  };
                                  props.pushModalToStack(modalData);
                                }}
                              >
                                Disconnect{" "}
                              </Button>
                            ) : (
                              <Button
                                className="ant-btn-primary"
                                onClick={() => {
                                  //props.getStripeAccountOnboardingLink();
                                  props.pushModalToStack({
                                    modalBody: <CardConnectAddNew {...props} />,
                                    title: (
                                      <FormattedMessage
                                        id="cardConnect.logo"
                                        defaultMessage=""
                                      />
                                    ),
                                    width: "30%",
                                    hideFooter: true,
                                  });
                                }}
                              >
                                Connect
                              </Button>
                            )}
                          </div>

                          {/* <div className="border-footer mt10 mb20"></div> */}
                        </div>
                      );
                    })}
                  <div className="paymentOption" title="">
                    {/* {props.cardConnectConfigData &&
                    props.cardConnectConfigData.alpidePaymentGatewayId ? (
                      <div className="cr cr-top cr-left cr-green">Integrated</div>
                    ) : (
                      ""
                    )} */}
                    {/* <h2><FormattedMessage id='stripe.logo' defaultMessage='' /></h2> */}
                    <div className="nameArea">
                      <img src={payIcon4} alt="" />
                      <h2>Cardpoint</h2>
                    </div>
                    <div className="buttonArea">
                      {props.cardConnectConfigData &&
                      props.cardConnectConfigData.alpidePaymentGatewayId ? (
                        <Button
                          style={{ marginLeft: "80px" }}
                          className="ant-btn-danger"
                          onClick={() => {
                            const modalData = {
                              modalBody: (
                                <FormattedMessage
                                  id="disconnect.confirmation"
                                  defaultMessage=""
                                />
                              ),
                              handleSubmit: () => {
                                props.disconnectConfiguration(
                                  props.cardConnectConfigData,
                                  props
                                );
                              },
                            };
                            props.pushModalToStack(modalData);
                          }}
                        >
                          Disconnect{" "}
                        </Button>
                      ) : (
                        <Button
                          style={{ marginLeft: "0px" }}
                          className="ant-btn-primary"
                          onClick={() => {
                            //props.getStripeAccountOnboardingLink();
                            props.pushModalToStack({
                              modalBody: <CardConnectAddNew {...props} />,
                              title: (
                                <FormattedMessage
                                  id="cardConnect.logo"
                                  defaultMessage=""
                                />
                              ),
                              width: "30%",
                              hideFooter: true,
                            });
                          }}
                        >
                          Connect
                        </Button>
                      )}
                    </div>
                    {/* <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
                  <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
                  <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p> */}

                    {/* <div className="border-footer mt10 mb20"></div> */}
                  </div>

                  {props.RazorpayGatewayList &&props.RazorpayGatewayList.length !== 0 &&props.RazorpayGatewayList.map((razorpayConfigData, i) => {
                      return (
                        <Card
                          key={"auth-" + i}
                          className="accounting-market-place integs"
                          title="">
                            <div className="nameArea">
                              <img src={payIcon1} alt="" />
                              <div>
                                <h2>Razorpay</h2>
                                <div class="cardName">
                                    {razorpayConfigData.userDefinedGatewayName ? (
                                    razorpayConfigData.userDefinedGatewayName
                                      ) : (
                                        <FormattedMessage id="razorpay.logo" />
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="buttonArea">
                              <div className="activeBtn">Active</div>
                              <Button className="editButton"
                                onClick={() => {
                                  editName(
                                    props,
                                    razorpayConfigData,
                                    MODAL_TYPE.CUSTOM_NAME
                                  );
                                }}
                              >
                                Edit
                              </Button>
                                {razorpayConfigData.alpidePaymentGatewayId ? (
                                <Button
                                  className="ant-btn-danger"
                                  onClick={() => {
                                    const modalData = {
                                      modalBody: (
                                        <FormattedMessage
                                          id="disconnect.confirmation"
                                          defaultMessage=""
                                        />
                                      ),
                                      handleSubmit: () => {
                                        props.disconnectConfiguration(
                                          razorpayConfigData,
                                          props
                                        );
                                      },
                                    };
                                    props.pushModalToStack(modalData);
                                  }}
                                >
                                  Disconnect{" "}
                                </Button>
                              ) : (
                                <Button
                                  style={{ marginLeft: "0px" }}
                                  className="ant-btn-primary"
                                  onClick={() => {
                                    //props.getStripeAccountOnboardingLink();
                                    window.location = `${envConfig.PAYMENT_SERVER_BASE_URL}/alpide_authorise/onboarding?callback_uri=${envConfig.CALLBACK_URI_AUTH_NET}&relationshipId=${props.companyInfo?.relationshipId}&apiBaseUrl=${envConfig.API_BASE_URL}`;
                                  }}
                                >
                                  Connect
                                </Button>
                              )}
                            </div>
                            
                            
                          {/* <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
                              <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
                              <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p> */}
                          {/* <div className="border-footer mt10 mb20"></div> */}
                          
                        </Card>
                      );
                    })}
                  <div className="paymentOption" title="">
                    <div className="nameArea">
                      <img src={payIcon1} alt="" />
                      <h2>Razorpay</h2>
                    </div>
                    <div className="buttonArea">
                      <Button
                        style={{ marginLeft: "0px" }}
                        className="ant-btn-primary"
                        onClick={() => {
                          props.generateRazorpayAuthLink({
                            relationshipId: props.companyInfo?.relationshipId,
                          });
                          //  window.location = `${envConfig.PAYMENT_SERVER_BASE_URL}/alpide_authorise/onboarding?callback_uri=${window.location.href}&relationshipId=${props.companyInfo?.relationshipId}&apiBaseUrl=${envConfig.API_BASE_URL}`;
                        }}
                      >
                        Connect
                      </Button>
                    </div>
                    {/* <h2><i class="fa fa-plus"></i></h2> */}
                    {/* <p>
                      <FormattedMessage
                        id="createUpdateInvoice.text"
                        defaultMessage=""
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        id="createUpdateNotes.text"
                        defaultMessage=""
                      />
                    </p>
                    <p>
                      <FormattedMessage id="applyPayment.text" defaultMessage="" />
                    </p> */}
                    {/* <div className="border-footer mt10 mb20"></div> */}
                    
                  </div>

                  {/*


          <Card className= "accounting-market-place text-center integs" title="" extra={<img src={PaypalIcon} alt="" style={{ height: "50px" }}/>} style={{ width: 300 }}>
                {props.paypalConfigData && props.paypalConfigData.relationshipConfigId ? <div className="cr cr-top cr-left cr-green">Integrated</div> : ''}
                <h2><FormattedMessage id='paypal.logo' defaultMessage='' /></h2>
                  <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
                  <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
                  <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p>
                  <div className="border-footer mt10"></div>
            {props.paypalConfigData  && props.paypalConfigData.relationshipConfigId ? <Button style={{marginLeft: "80px"}} className="cnant-btn but-geray" onClick={
                () =>{
                  const modalData = {
                    modalBody: <FormattedMessage id='disconnect.confirmation' defaultMessage='' />,
                    handleSubmit: () => {
                      props.disconnectConfiguration(props.paypalConfigData, props);
                    }
                  };
                  props.pushModalToStack(modalData);
                }
            }>Disconnect </Button> : <Button style={{marginLeft: "0px"}} className="cnant-btn ant-btn-primary" onClick={()=>{
              props.generatePaypalAuthLink();
          }}>Integrate</Button>}
          </Card>




          <Card className= "accounting-market-place text-center integs" title="" extra={<img src={PaypalIcon} alt="" style={{ height: "50px" }}/>} style={{ width: 300 }}>
                {props.paypalDirectConfigData && props.paypalDirectConfigData.relationshipConfigId ? <div className="cr cr-top cr-left cr-green">Integrated</div> : ''}
                <h2><FormattedMessage id='paypalDirect.logo' defaultMessage='' /></h2>
                  <p><FormattedMessage id='doDirect.text' defaultMessage='' /></p>
                  <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
                  <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p>
                  <div className="border-footer mt10"></div>
            {props.paypalDirectConfigData  && props.paypalDirectConfigData.relationshipConfigId ? <Button style={{marginLeft: "80px"}} className="cnant-btn but-geray" onClick={
                () =>{
                  const modalData = {
                    modalBody: <FormattedMessage id='disconnect.confirmation' defaultMessage='' />,
                    handleSubmit: () => {
                      props.disconnectConfiguration(props.paypalDirectConfigData, props);
                    }
                  };
                  props.pushModalToStack(modalData);
                }
            }>Disconnect </Button> : <Button style={{marginLeft: "0px"}} className="cnant-btn ant-btn-primary" onClick={()=>{
              props.pushModalToStack({
                modalBody: <PaymentIntegration {...props} />,
                title:<FormattedMessage id='paypal.logo' defaultMessage='' />,
                width: '30%',
                hideFooter: true,
            })
              
          }}>Integrate</Button>}
          </Card>



              <Card className="accounting-market-place text-center integs" title="" extra={<img src={NeonIcon} alt="" style={{ height: "50px" }} />} style={{ width: 300 }}>
                {props.xeroConfigData && props.xeroConfigData.relationshipConfigId ? <div className="cr cr-top cr-left cr-green">Integrated</div> : ''}
                <h2><FormattedMessage id='neonPay.logo' defaultMessage='' /></h2>
                  <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
                  <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
                  <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p>
                  <div className="border-footer mt10"></div>
            {props.xeroConfigData  && props.xeroConfigData.relationshipConfigId ? <Button disabled style={{marginLeft: "80px"}} className="cnant-btn but-geray" onClick={
                () =>{
                  props.disconnectConfiguration({relationshipId: (props.companyInfo || {}).relationshipId}, props);
                }
            }>Disconnect </Button> : <Button style={{marginLeft: "0px"}} className="cnant-btn ant-btn-primary" onClick={()=>{
            
          }}>Integrate</Button>}
          </Card>


          <Card className= "accounting-market-place text-center integs" title="" extra={<img src={PlaidIcon} alt="" style={{ height: "50px" }}/>} style={{ width: 300 }}>
                {props.xeroConfigData && props.xeroConfigData.relationshipConfigId ? <div className="cr cr-top cr-left cr-green">Integrated</div> : ''}
                <h2><FormattedMessage id='bank.Integration' defaultMessage='' /></h2>
                  <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
                  <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
                  <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p>
                  <div className="border-footer mt10"></div>
            {props.plaidLinkToken && <Button onClick={() => open()} disabled={!ready} style={{marginLeft: "80px"}} className="cnant-btn ant-btn-primary" >Integrate</Button>}
          </Card> */}
            </div>
          </Col>
      </Row>
      
    </div>
  );
};

export default PaymentComponent;