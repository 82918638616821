import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {showModal, pushModalToStack, popModalFromStack, hideModal} from '../../../../actions/commonActions';
import LeadSourceDrawerComp from '../../../../components/drawer/leads/leadSourceList';
import {createLeadSource} from './action';

class LeadSourceDrawer extends Component {
    constructor(props) {
        super(props);
        let parentData = {}
        let addAsSubcategory = false;
        
        if(Object.keys(this.props.parentLeadStageItem || {})?.length){
            parentData =  this.props.parentLeadStageItem;
             addAsSubcategory = true;
        }
        this.state = {
            parentData
            ,addAsSubcategory
        };
      }

    componentWillMount() {
        // const companyInfo = this.props.companyInfo || {};

    }


    render() {
        return <LeadSourceDrawerComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
      crmLeadSourceList: state.crm.crmLeadSourceList

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal, 
    pushModalToStack,
    popModalFromStack,
    createLeadSource,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadSourceDrawer);