import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import MenusComp from "../../../components/eStore/menus";
import { fetchCategory } from "../../drawer/inventory/action";
import { updateOrCreateEcomSettings,updateHomePageSettings } from "./action";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { fetchProducts } from "../../product/Listing/action";
import { getPageAllListData } from "../pages/action";
import { fetchE_StoreData } from "../../../actions/commonActions";
class Menus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
      displayName:'',
      isHeaderMenuLoaded: false,
      sections:[
        {"label":"On Sale","sectionId":1,"isVisible":0,"type":"Sections"}
      ,{"label":"Clearance","sectionId":2,"isVisible":0,"type":"Sections"}
      ,{"label":"New Arrival's","sectionId":3,"isVisible":0,"type":"Sections"}
      ,{"label":"Best Selling Item","sectionId":4,"isVisible":0,"type":"Sections"}
      ,{"label":"Deal of the Day","sectionId":5,"isVisible":0,"type":"Sections"}
    ],
      filters:[{"label":"On Sale","flagId":112,"isVisible":1,"type":"Flags"},{"label":"Clearance","flagId":114,"isVisible":1,"type":"Flags"},{"label":"New Arrival's","flagId":116,"isVisible":1,"type":"Flags"},{"label":"Best Selling Item","flagId":118,"isVisible":1,"type":"Flags"},{"label":"Deal of the Day","flagId":120,"isVisible":1,"type":"Flags"}],
      selectedCategoryItems:[],
      footerFilters:[{"label":"On Sale","flagId":112,"isVisible":1,"type":"Flags"},{"label":"Clearance","flagId":114,"isVisible":1,"type":"Flags"},{"label":"New Arrival's","flagId":116,"isVisible":1,"type":"Flags"},{"label":"Best Selling Item","flagId":118,"isVisible":1,"type":"Flags"},{"label":"Deal of the Day","flagId":120,"isVisible":1,"type":"Flags"}],
      selectedFooterCategoryItems:[],
      AdditionalSection:[{blogId: 23 , type:"blog" ,isBlogVisible:false , label:"Blogs"}]
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100
    };
    const payloadValue ={
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: 100,
      isPublish:1,
     
    }
    this.props.fetchCategory(payload);
    this.props.fetchProducts(payload);
    this.props.getPageAllListData(payloadValue)
    this.props.fetchE_StoreData(payload)
  }

  componentWillReceiveProps(props){
    if(!this.state.isHeaderMenuLoaded && Object.keys(props.storeValues || {}) && (props.storeValues.headerMenu|| props.storeValues.footerMenu) ){
      
      let HeaderMenuData=JSON.parse(props.storeValues.headerMenu || '{}');
      let FooterMenuData = JSON.parse(props.storeValues?.footerMenu || '{}');
      let sectiondata = JSON.parse(props.storeValues?.homePageSetting || "[]");
      const combinedArray = Object.values(HeaderMenuData.headerMenu || {}).reduce((acc, item) => {
        return [...acc, ...item];
      }, []);
      const combinedFooterArray = Object.values(FooterMenuData.footerMenu || {}).reduce((acc, item) => {
        return [...acc, ...item];
      }, []);

       const filter = combinedArray.filter(item=>item.type === "Flags");
       
      this.setState({
          selectedCategoryItems:combinedArray,
          filters:filter,
          sections:sectiondata?.length ? sectiondata : this.state.sections,
           isHeaderMenuLoaded: true ,
           selectedFooterCategoryItems:combinedFooterArray
        })
    }

  }

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <MenusComp
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />
      </DndProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
  
    productCategories: state.inventory.productCategories,
    products: state.product.products,
    allPageListData: state.eStore.allPageListData,
    storeValues: state.common.store_values,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  fetchCategory,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  updateOrCreateEcomSettings, 
  fetchProducts,
  getPageAllListData,
  fetchE_StoreData,
  updateHomePageSettings
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Menus);
