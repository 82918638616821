import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {showModal, pushModalToStack, popModalFromStack, hideModal} from '../../../../actions/commonActions';
import LeadCompaignDrawerComp from '../../../../components/drawer/leads/leadCompaign';
import { createCrmCampaignType,fetchCrmCampaignType,deleteCampaignType,fetchCrmActiveSource,fetchCrmCampainStatus,createCrmCampainStatus,deleteCampaignStatus,createCrmLeadCampaign } from './action';
import { fetchCrmLeadCampaign } from '../../../leads/LeadCampaigns/action';
import { fetchProjects } from '../../../../actions/commonActions';
import { getAllProjectWithSubProject } from '../../../company/Project/action';
import { getAllLeadStage } from '../../../leads/LeadStageList/action';
class LeadCompaignDrawer extends Component {
    constructor(props) {
        super(props);
        const dataAvailIfEdit = props.campaignData?.isUpdate ? props.campaignData?.data :{}
        this.state = {
            isUpdate:props.campaignData ? props.campaignData.isUpdate :false,
            ...dataAvailIfEdit,
            selectedProjectList:dataAvailIfEdit?.crmLeadCampaignProjectList?.map((item)=>item.projectId)
        };
      }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload ={relationshipId:companyInfo.relationshipId}
        this.props.fetchCrmCampaignType(payload);
        this.props.fetchCrmActiveSource(payload);
        this.props.fetchProjects(payload);
        this.props.fetchCrmCampainStatus(payload);
        this.props.getAllProjectWithSubProject(payload);
        this.props.getAllLeadStage(payload);
    }


    render() {
        return <LeadCompaignDrawerComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        crmCampaignType: state.crm.crmCampaignType,
        crmActiveSource:state.crm.crmActiveSource,
        projectList: state.common.projectList,
        crmCampaignStatusList:state.crm.crmCampaignStatusList,
        projectWithSubProject: state.project.projectWithSubProject,
        detailProjectList: state.project.projectsDetails,
        crmLeadStageList: state.crm.crmLeadStatusList,


    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal, 
    pushModalToStack,
    popModalFromStack,
    fetchCrmCampaignType,
    createCrmCampaignType,
    deleteCampaignType,
    fetchCrmActiveSource,
    fetchCrmCampainStatus,
    createCrmCampainStatus
    ,deleteCampaignStatus
    ,createCrmLeadCampaign
    ,fetchCrmLeadCampaign,
    fetchProjects,getAllProjectWithSubProject,getAllLeadStage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadCompaignDrawer);