import React, { Fragment, useState } from "react";
import { Row, Col, Drawer, Checkbox, Tabs, Upload, Modal, Button, Card, Skeleton, Radio } from "antd";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";
import config from "../../../../config/environmentConfig";

// import { MODAL_TYPE, CONSTANTS } from '../../../../../static/constants';
import { TextBox } from "../../../general/TextBox";

import storeDef from "../../../../assets/images/store-def.jpg";

// import { getLabelName } from '../../customerInfo';
import {
  base64Toblob,
  getAWSFileURL,
  showToasterMessage,
} from "../../../../utils";
import { ImageCropper } from "../../../../components/general/ImageCropper";
import { CustomUploader } from "../../../../components/general/CustomUploader";

import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import ReactCropper from "react-cropper";
import Search from "antd/lib/input/Search";
import { ErrorMsg } from "../../../general/ErrorMessage";
const { TabPane } = Tabs;


const CategoryModal = (props)=>{
  const {blogCategoryList} = props;
  const [searchText ,setSearchText] = useState('')
  const getsubcategories = (subCategories,parentCategory ,parentId , depth = 1) => {
    const indentStyle = {
      paddingLeft: `${depth * 10}px`,
    };
    return subCategories?.map((childSubcategories, i) => (
      <div
        key={childSubcategories.categoryName + i}
        className="radio-button-Wrapper"
        style={indentStyle}
      >
        <Radio value={childSubcategories}>
          <div className="parent-category-sub-wrapper">
            {childSubcategories.categoryName}
          </div>
        </Radio>
        {childSubcategories.subCategories &&
        childSubcategories.subCategories.length
          ? getsubcategories(childSubcategories.subCategories,parentCategory ,parentId, depth + 1)
          : null}
      </div>
    ));
  };

  const handleCategoryChange = (e) => {
    props.updateState({
      selectedCategory: e.target.value
    })
    
  };


  return(
    <>
        <div className="searchBarArea">
          <Search
            placeholder="Search Category..."
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
        <div className="categoryArea">
          <Radio.Group
            onChange={handleCategoryChange}
            value={props.selectedCategory}
          >
            {(blogCategoryList[props.pageNumber||1] || []).map((category, i) => (
              <div className="subcategory" key={category.categoryName + i}>
                <Radio value={category}>{category.categoryName}</Radio>
                {(category.subCategories || []).length
                  ? getsubcategories(category.subCategories, category?.categoryName , category?.blogCategoryId)
                  : ""}
              </div>
            ))}
          </Radio.Group>
        </div>
    </>
  )
}




const BlogCreateDrawer = (props) => {
  
  const  {category,metaDescription ,metaTitle ,blogDescription,pageSlug,articleTitle} = props
    const closeDrawer = () => {
        props.updateDrawerState({
            blogCreateDrawerVisible: false
        })
      }
      const blogImage = getAWSFileURL(props.blogData?.awsKeyName,props.companyInfo.bucketName , props.blogData?.folderName)
      const [fileList, setFileList] = useState([]);
      
      const [visible, setVisible] = useState(false);

 

 
 
      
      const handleSaveBlog = () => {
          
          

            const payLoad = {
              ...props.blogData,
              "version": props.isUpdate ? props.blogData?.version : 0,
              "blogMasterId": props.isUpdate? props.blogData?.blogMasterId : 0,
              "title": props.articleTitle,
              "relationshipId": props.companyInfo.relationshipId,
              "content": props.blogDescription,
              "isPublished": props.isUpdate ? props.blogData?.isPublished : 1,
              "bucketName": props.companyInfo?.bucketName,
               "blogMetaTitle": props.metaTitle,
               "blogMetaDescription": props.metaDescription,
               "metaTags": props.metaTags,
               "blogPageSlug": props.pageSlug,
              "blogCategoryId": props.selectedCategory?.blogCategoryId || props.blogData?.blogCategoryId,
             
              "categoryName": props.selectedCategory?.categoryName || props.category,
            };
            props.postBlog(payLoad, fileList[0] ,props);
          }
    
      
      
      const handlePopUp =()=>{
        const modalData = {
          title: "confirmation",
          modalBody: <CategoryModal {...props}/>,
           width: 500,
          handleSubmit: () => {
            props.hideModal()
            
          },
        };
        props.showModal(modalData);
      }
 
      const handleRemove = () => {
        setFileList([]);
      };
    

      return (
        <Fragment>
           
          <Drawer
             title={
                
              <FormattedMessage id='Create Blog' defaultMessage='' /> 
                
              }
              width={720}
              destroyOnClose={true}
              onClose={closeDrawer}
              keyboard={false}
              maskClosable={false}
              className="custom-drawer"
              afterVisibleChange={() => {
                // props.initCalls();
              }}
              visible={props.blogCreateDrawerVisible}
              footer={
                <>
        
        
        
                 
                    <Button
        
                    
                    >
                      Cancel
                    </Button>
                 
        
               
                    <Button
                      type="primary"
                      onClick={() => {
                        const modalData = {
                          modalBody: "Are you sure you want to submit",
                       
                          handleSubmit: () => {
                             handleSaveBlog();
                            closeDrawer();
                          },
                        };
                        if(!(props.selectedCategory?.categoryName||props.category) && !(props.articleTitle)){
                          props.updateState({
                            submittedOnce: true,
                          })
                          return;
                        }
                        else{
                          props.pushModalToStack(modalData);
                        }
                      }}
                    >
                      <FormattedMessage
                        id="button.save.label"
                        defaultMessage=""
                      />
                    </Button>
                 
        
        
        
                </>
              }
          >
           {/* <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>  */}
          <Tabs type="line" className=" inventory-tabs">
          <TabPane
            tab={"Blog Details"}
            key="Blog"
          >
             <Row gutter={[16,16]}>
            <Col span={12}>
                  <div className="i-label">
                  Select Category
                    <span className="required">*</span>
                  </div>
                  <TextBox
                    value={props.selectedCategory?.categoryName||props.category}
                    placeholder="Select Category"
                    onClick={(e) => {
                      handlePopUp()
                      // props.updateState({
                      //   category: e.target.value,
                      // });
                    }}
                  />
                    <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.selectedCategory?.categoryName;
                }}
                message={"Category Name is reqiured"}
              />
                </Col>
                <Col span={12}>
                  <div className="i-label">
                    Article Title
                    <span className="required">*</span>
                  </div>
                  <TextBox
                    value={articleTitle}
                    placeholder="Enter Article"
                    onChange={(e) => {
                      props.updateState({
                        articleTitle: e.target.value,
                      });
                    }}
                  />
                     <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.articleTitle;
                }}
                message={"Article Title is reqiured"}
              />
                </Col>

                <Col span={12}>
                  <div className="i-label">
                   Page Slug(Custom Structure)
                  
                  </div>
                  <TextBox
                    value={pageSlug}
                    placeholder="Enter Custom EndPoint"
                    onChange={(e) => {
                      props.updateState({
                        pageSlug: e.target.value,
                      });
                    }}
                  />
                  
                </Col>
            </Row>
            <Row gutter={[16,16]}>

            <Col span={8}>
                  <label>Add Banner Image</label>
                 
                  {/* <div className="upload-container">
                    <div>

      <Upload
        accept="image/*"
        customRequest={({ file, onSuccess }) => {
          setTimeout(() => {
            onSuccess("ok");
            handleUpload({ file });
          }, 0);
        }}
        onRemove={handleRemove}
        listType="picture-card"
       
        multiple={false}
        showUploadList={{
         
          showRemoveIcon: true,
        }}
      >
        <Button icon={<UploadOutlined />}>Click to Upload Image</Button>
      </Upload>
                    </div>
                   
    </div> */}

<div className="upload-profile-img">
                {fileList.length > 0 ? (
                  <Card className="card-img-prev">
                    <img src={URL.createObjectURL(fileList[0])} alt="Preview" />
                    <Button className="close-btn-pre" onClick={handleRemove}>
                      <CloseOutlined />
                    </Button>
                  </Card>
                ) : (
                  <Card
                    className="card-img-prev"
                    cover={<img src={blogImage?blogImage:storeDef} alt="Placeholder" />}
                  />
                )}
                <Upload
                  fileList={fileList}
                  name="file"
                 
                  // onChange={handleChange}
                  beforeUpload={(file) => {
                    // Allow only a single file upload, you can adjust this as needed
                    setFileList([file]);
                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                </Upload>
              </div>
                </Col>
            </Row>
            <Row  gutter={[16,16]}>
            <Col span={24}>
                  <div>
                    <CKEditor
                      type="replace"
                      className="description-input"
                      key={`${"description"}`}
                      // data={description}
                      editor={props.editor}
                      onInit={(editor) => {
                        props.updateState({
                          editor,
                        });
                      }}
                      data={blogDescription}
                      onChange={(event) => {
                        const data = event.editor.getData();
                        props.updateState({
                            blogDescription: data,
                        });
                      }}
                      config={CUSTOM_CONFIGURATION}
                    />
                  </div>
                </Col>
            </Row>
            </TabPane>
           
            <TabPane
            tab={"Seo Details"}
            key="Seo"
          >
             <Row gutter={[16,16]}>
            <Col span={12}>
                  <div className="i-label">
                 
Meta Title
                   
                  </div>
                  <TextBox
                    value={metaTitle}
                    placeholder="Enter Title"
                    onChange={(e) => {
                      props.updateState({
                        metaTitle: e.target.value,
                      });
                    }}
                  />
                  
                </Col>
                <Col span={12}>
                  <div className="i-label">
                  Meta Description
                   
                  </div>
                  <TextBox
                    value={metaDescription}
                    placeholder="Enter Description"
                    onChange={(e) => {
                      props.updateState({
                        metaDescription: e.target.value,
                      });
                    }}
                  />
                  
                </Col>

             
            </Row>
            </TabPane>
          </Tabs>
        
          {/* </Skeleton> */}
         
    
          </Drawer>
          
        </Fragment>
      );
}

export default BlogCreateDrawer