import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmployeeDrawer from '../../../../../components/drawer/company/EmployeeManagement/Employee';
import { fetchCountries, fetchStates, showModal, hideModal } from '../../../../../actions/commonActions';
import { getDepartment } from '../../../../company/EmployeeMeta/Department/action';
import { fetchCompanyHierarchy } from '../../../../company/CompanyHierarchy/action';
import { getJobRole } from '../../../../company/EmployeeMeta/JobRole/action';
import { getEmployeeType } from '../../../../company/EmployeeMeta/EmployeeType/action';
import { getDesignation } from '../../../../company/EmployeeMeta/Designation/action';
import { getTeam } from '../../../../company/EmployeeMeta/Team/action';
import { getWorkShift } from '../../../../company/AttendanceMeta/WorkShift/action';
import DepartmentDrawer from '../../EmployeeMeta/Department';
import DesignationDrawer from '../../EmployeeMeta/Designation';
import JobRoleDrawer from '../../EmployeeMeta/JobRole';
import EmployeeTypeDrawer from '../../EmployeeMeta/Employee';
import TeamDrawer from '../../EmployeeMeta/Team';
import HierarchyDrawer from '../../EmployeeMeta/Hierarchy';
import { fetchAllEmployeesByPage } from '../../../../company/EmployeeManagement/Employee/action';
import { createEmployee } from './action';
import { getAllBranches } from '../../../../company/EmployeeMeta/Branch/action';
import BranchDrawer from '../../EmployeeMeta/Branch';
import { getTeacher } from '../../../../schoolManagement/teacher/action';




class EmployeeDrawerCon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTabKey: '1', 
            ...(this.props.departmentItem || {}),
            
            // countryCallingCode: (this.props.companyInfo || {}).countryCallingCode,          
        };
    }


    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 500 };
        this.props.fetchCountries(payload);
        this.props.getDepartment(payload);
        this.props.fetchCompanyHierarchy(payload);
        this.props.getJobRole(payload);
        this.props.getEmployeeType(payload);
        this.props.getDesignation(payload);
        this.props.getTeam(payload);
        this.props.getAllBranches(payload)
    }
componentWillReceiveProps(props){
    if(props.departmentList || props.companyHierarchyList || props.designationList || props.teamList || props.jobRoleList || props.employeeTypeList || props.branchList){
        this.setState({
            departmentList:props.departmentList,
            companyHierarchyList:props.companyHierarchyList,
            designationList:props.designationList,
            teamList:props.teamList,
            jobRoleList:props.jobRoleList,
            employeeTypeList:props.employeeTypeList,
            branchList:props.branchList
        })
    }
}
    render() {
        return <>
            <EmployeeDrawer {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
            {this.state.departmentDrawer && (<DepartmentDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />)}
            {this.state.designationDrawer && <DesignationDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.jobRoleDrawer && <JobRoleDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.employeeTypeDrawer && <EmployeeTypeDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.teamDrawer && <TeamDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.hierarchyDrawer && <HierarchyDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.branchDrawer && <BranchDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}

        </>
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo?.relationshipId,
        countries: state.common.countries,
        states: state.common.states,
        departmentList: state.employee.departmentList,
        companyHierarchyList: state.hierarchy.hierarchyList,
        jobRoleList: state.employee.jobRoleList,
        employeeTypeList: state.employee.employeeTypeList,
        designationList: state.employee.designationList,
        teamList: state.employee.teamList,
        workShift: state.employee.workShift,
        drawerLoading: state.common.drawerLoading,
        branchList: state.employee.branchList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchStates,
    fetchCountries,
    getDepartment,
    showModal,
    hideModal,
    fetchCompanyHierarchy,
    getJobRole,
    getEmployeeType,
    getDesignation,
    getTeam,
    getWorkShift,
    fetchStates,
    createEmployee,
    fetchAllEmployeesByPage,
    getAllBranches,
    getTeacher

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDrawerCon);