import React, { Fragment, useState } from "react";
import { Row, Col, Drawer, Checkbox, Tabs, Upload, Modal, Button, Card, Skeleton, Radio } from "antd";

import { TextBox } from "../../../general/TextBox";
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { ErrorMsg } from "../../../general/ErrorMessage";

import Search from "antd/lib/input/Search";
const { TabPane } = Tabs;


const CategoryModal = (props)=>{
  const {blogCategoryList} = props;
  const [searchText ,setSearchText] = useState()
  const getsubcategories = (subCategories,parentCategory ,parentId , depth = 1) => {
    const indentStyle = {
      paddingLeft: `${depth * 10}px`,
    };
    return subCategories?.map((childSubcategories, i) => (
      <div
        key={childSubcategories.categoryName + i}
        className="radio-button-Wrapper"
        style={indentStyle}
      >
        <Radio value={childSubcategories}>
          <div className="parent-category-sub-wrapper">
            {childSubcategories.categoryName}
          </div>
        </Radio>
        {
        childSubcategories?.subCategories?.length
          ? getsubcategories(childSubcategories.subCategories,parentCategory ,parentId, depth + 1)
          : null}
      </div>
    ));
  };

  const handleCategoryChange = (e) => {
    props.updateState({
      parentCategory: e.target.value,
    })
    props.hideModal()
  };


  return(
    <>
        <div className="searchBarArea">
          <Search
            placeholder="Search Category..."
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
        <div className="categoryArea">
  <Radio.Group
    onChange={handleCategoryChange}
    value={props.parentCategory} // Make sure this is a primitive value, like a string or number
  >
    {(blogCategoryList[props.pageNumber || 1] || []).map((category, i) => (
      <div className="subcategory" key={category.categoryName + i}>
     
        {/* Set the value to a unique identifier */}
        <Radio value={category}>{category.categoryName}</Radio>
        {(category.subCategories || []).length
          ? getsubcategories(category.subCategories, category?.categoryName, category?.blogCategoryId)
          : ""}
      </div>
    ))}
  </Radio.Group>
</div>
    </>
  )
}





const CatgeoryCreateDrawer = (props) => {
    const closeDrawer = () => {
        props.updateDrawerState({
            categoryCreateDrawerVisible: false
        })
      }
  
      
      
      const handleSaveCategory = () => {
       
        const payload = {
          version: props.isUpdate? props.categoryData?.version:0,
          categoryName: props.categoryName,
          isPublished: props.isUpdate? props.categoryData?.version:0,
          parentId: props.parentCategory?.blogCategoryId,
           relationshipId: props.companyInfo.relationshipId,
           blogCategoryId: props.isUpdate ? props.categoryData?.blogCategoryId :0,
        };
       
        props.postCategory(payload ,props);
      };
      
      const handlePopUp =()=>{
        const modalData = {
          title: "confirmation",
          modalBody: <CategoryModal {...props}/>,
           width: 500,
          handleSubmit: () => {
           
            
          },
        };
        props.showModal(modalData);
      }
    

      return (
        <Fragment>
           
          <Drawer
             title={
                
              <FormattedMessage id='Create Blog' defaultMessage='' /> 
                
              }
              width={720}
              destroyOnClose={true}
              onClose={closeDrawer}
              keyboard={false}
              maskClosable={false}
              className="custom-drawer"
              afterVisibleChange={() => {
                // props.initCalls();
              }}
              visible={props.categoryCreateDrawerVisible}
              footer={
                <>
        
        
        
                 
                    <Button
        
                      onClick={() => {
                        const modalData = {
                          modalBody: "Are you sure you want to submit",
                       
                          handleSubmit: () => {
                           
                            closeDrawer();
                          },

                        };
                        props.pushModalToStack(modalData);
                      }}
                    >
                      Cancel
                    </Button>
                 
        
               
                    <Button
                      type="primary"
                      onClick={() => {
                        const modalData = {
                          
                          modalBody: <div>Are you sure you want to submit?</div>,
                          handleSubmit:()=>{

                            handleSaveCategory();
                            closeDrawer()
                          }
                        }
                         if(!(props.categoryName)){
                          props.updateState(
                            {
                              submittedOnce: true,
                            }
                          )
                          return;
                         }
                         else{
                           props.showModal(modalData)
                         }
                      }}
                    >
                      <FormattedMessage
                        id="button.save.label"
                        defaultMessage=""
                      />
                    </Button>
                 
        
        
        
                </>
              }
          >
        <Row gutter={[16,16]}>
        <Col span={12}>
                  <div className="i-label">
                    <FormattedMessage
                      id={
                        props.addAsSubcategory
                          ? "drawer.inventory.subCategory"
                          : "drawer.inventory.category"
                      }
                      defaultMessage=""
                    />
                    <span className="required">*</span>
                  </div>
                  <TextBox
                    value={props.categoryName}
                    placeholder={'Enter Category'}
                    onChange={(e) => {
                      props.updateState({
                        categoryName: e.target.value,
                      });
                    }}
                  />
                  <div>
                  <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.categoryName;
                }}
                message={"Category Name is reqiured"}
              />
                  </div>
                  <div className="subcategory-checkBox">
                    <Checkbox
                      checked={props?.addAsSubcategory}
                      onChange={()=>{
                        props.updateState({
                          addAsSubcategory: !(props.addAsSubcategory)
                        })
                      }}
                    >
                      Add as Sub Category
                    </Checkbox>
                  </div>
                </Col>
        <Col span={12}>
                  {props.addAsSubcategory && (
                    <>
                      <div className="i-label">
                        <FormattedMessage
                          id={"Parent Category"}
                          defaultMessage=""
                        />
                        <span className="required">*</span>
                      </div>
                      <div onClick={() => handlePopUp()}>
                        <TextBox
                          value={
                            props.parentCategory?.parentName ? props?.parentCategory?.parentName  :props.parentCategory?.categoryName
                          }
                          placeholder={'Enter Parent Category'}
                        />
                      </div>
                      <div>
                      <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.parentCategory?.categoryName;
                }}
                message={"Parent Name is reqiured"}
              />
                      </div>
                    </>
                  )}
                </Col>
              
        </Row>
         
    
          </Drawer>
          
        </Fragment>
      );
}

export default CatgeoryCreateDrawer