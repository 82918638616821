import React, {  useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import {Col,Row, Switch,Input, Popover} from "antd";
import "@ant-design/compatible/assets/index.css";
import "./index.scss";
import { Dropdown } from "../../../general/Dropdown";
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { EMAIL_BODY_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";
import { PLACEHOLDER_CONSTANTS } from "../../../../static/placeholderConstants";
import { ErrorMsg } from "../../../general/ErrorMessage";


const EmailDetails = (props) => {
    const {updateState ,selectedTransactionName ,emailContent ,senderEmails,emailAutoTrigger,messageProvider,selectedProvider} = props;

  
  const transactionMode = [
    { trnMode: "Option 1", referCode: "OPT1" },
    { trnMode: "Option 2", referCode: "OPT2" },
    { trnMode: "Option 3", referCode: "OPT3" },
  ];
  
 

  const insertTextInSubject = (props, textToInsert) => {
    const val = emailContent;
   
    if (emailContent?.emailSubject) {
        let subject = (emailContent?.emailSubject).slice(0, props.subjectCursor) + textToInsert + (emailContent?.emailSubject).slice(props.subjectCursor + 1);
        val.emailSubject = subject;
        props.updateState({ emailContent: val, subjectCursor: props.subjectCursor+textToInsert?.length })
    } else {
      val.emailSubject =textToInsert;
        props.updateState({ emailContent: val, subjectCursor: props.subjectCursor+textToInsert?.length })
    }
}
  const getSubjectPlaceHolders = (props, editorName ) => {
    return <div style={{ width: '400px' }}>
        <Row type='flex' gutter={[8, 24]}>
            {(selectedTransactionName.transactionName === 'School Assignments') ? <Col span={14}>
                <div style={{ fontWeight: '700' }}>Student</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PARENT_NAME); }}>Father and Mother Name</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.STUDENT_NAME); }}>Student Name</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.TEACHER_NAME); }}>Teacher Name</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CLASS_NAME); }}>Class Name</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CLASS_SCHEDULE); }}>Class Schedule</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ASSIGNMENT_NAME); }}>Assignment Name</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ASSIGNMENT_DUE_DATE); }}>Assignment Due Date</div>
            </Col> : ''
            }
            {/* {(selectedTransactionName.transactionName === 'Pledge') ? <Col span={8}>
                <div style={{ fontWeight: '700' }}>Donor</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DONOR_NAME); }}>Name</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.MOBILE_NUMBER); }}>Mobile</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PLEDGE_AMOUNT); }}>Pledge Amount</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PLEDGE_DATE); }}>Pledge Date</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PAYMENT_LINK); }}>Payment URL</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PLEDGE_START_DATE); }}>Pledge Start Date</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.TOTAl_OCCURRENCES); }}>No. of Occurances</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.REMARKS); }}>Donor Remarks</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CAMPAIGN_NAME); }}>Campaign Name</div>
            </Col> : ''
            } */}
            {(selectedTransactionName.transactionName === 'Sales Invoice' || selectedTransactionName.transactionName === 'Recurring Sales Invoice') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Invoice</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_URL); }}>Invoice URL</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                    {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PAYMENT_LINK);}}>Payment Link</div> */}
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Sales Quote') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Quote</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.QUOTE_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.BID_CLOSING_DATE); }}>Expiration Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.QUOTE_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.QUOTE_NUMBER); }}>Quote #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Sales Order') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Order</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_DELIVERY_DATE); }}>Delivery Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_NUMBER); }}>SO #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Online Order Confirmation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Order</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_NUMBER); }}>SO #</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Customer Payment' ) ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Payment</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALES_PAYMENT_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALES_PAYMENT_DATE); }}>Payment Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALES_PAYMENT_NUMBER); }}>Payment #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Credit Memo') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Credit Memo</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CM_NUMBER); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CM_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CM_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CM_NUMBER); }}>Credit Memo #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Sales Inquiry') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Inquiry</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INQUIRY_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INQUIRY_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INQUIRY_NUMBER); }}>Inquiry #</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Sales Quote' || selectedTransactionName.transactionName === 'Sales Order' || selectedTransactionName.transactionName === 'Sales Invoice' || selectedTransactionName.transactionName === 'Customer Payment' || selectedTransactionName.transactionName === 'Credit Memo' || selectedTransactionName.transactionName === 'Sales Inquiry' || selectedTransactionName.transactionName === 'Recurring Sales Invoice' || selectedTransactionName.transactionName === 'School Payment' ) ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Customer</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                    {/* <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SHIPMENT_NUMBER); }}>Shipment Number</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SHIPMENT_DATE); }}>Shipment Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORDER_DATE); }}>Order Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORDER_NUMBER); }}>Order Number</div> */}
                </Col> : ''
            }

            {(selectedTransactionName.transactionName === 'Online Order Confirmation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Customer</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
                </Col> : ''
            }
             {(selectedTransactionName.transactionName === 'Purchase Order' || selectedTransactionName.transactionName === 'Purchase Invoice' || selectedTransactionName.transactionName === 'Supplier Payment' || selectedTransactionName.transactionName === 'Debit Memo') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Supplier</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SUPPLIER_NAME); }}>Supplier Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Kiosk Payment' || selectedTransactionName.transactionName === 'Online Payment' || selectedTransactionName.transactionName === 'Text2Donate Payment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Payment</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALES_PAYMENT_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALES_PAYMENT_DATE); }}>Payment Date</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Sales Shipment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Shipment</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SHIPMENT_NUMBER); }}>Shipment Number</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SHIPMENT_DATE); }}>Shipment Date</div>
                    {/* <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORDER_DATE); }}>Order Date</div> */}
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORDER_NUMBER); }}>Order Number</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.TRACKING_NUMBER); }}>Tracking #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CARRIER_NAME); }}>Carrier Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DELIVERY_DATE); }}>Delivery Date</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Sales Order Status Update' || selectedTransactionName.transactionName ===  'Sales Order Cancellation' || selectedTransactionName.transactionName ===  'Sales Order Update') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Online Order</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.OOC_ORDER_DATE); }}>Order Date</div>
                    {!selectedTransactionName.transactionName === 'Online Order Confirmation' ? <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.OOC_ORDER_NUMBER); }}>Order Number</div> : ""}
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.OOC_ORDER_AMOUNT); }}>Order Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ONLINE_ORDER_NUMBER); }}>Online Order Number</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Online Order Confirmation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Online Order</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ONLINE_ORDER_NUMBER); }}>Online Order Number</div>
                </Col> : ''
            }
            <Col span={8}>
                <div style={{fontWeight: '700'}}>Organization</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.STORE_NAME);}}>Name</div>
                {(selectedTransactionName.transactionName === 'Pledge') ?  <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.USER);}}>User</div> : ''}
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_EMAIL);}}>Email</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_PHONE);}}>Phone #</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_FAX);}}>Fax #</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.WEBSITE);}}>Website</div>
            </Col>
        </Row>
    </div>
}



const getPlaceHolders = (props, editorName ) => {
  return <div style={{ width: '400px' }}>
      <Row type='flex' gutter={[8, 24]}>
          {(selectedTransactionName.transactionName === 'School Assignments') ? <Col span={14}>
              <div style={{ fontWeight: '700' }}>Student</div>
              <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PARENT_NAME); }}>Father and Mother Name</div>
              <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.STUDENT_NAME); }}>Student Name</div>
              <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.TEACHER_NAME); }}>Teacher Name</div>
              <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CLASS_NAME); }}>Class Name</div>
              <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CLASS_SCHEDULE); }}>Class Schedule</div>
              <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ASSIGNMENT_NAME); }}>Assignment Name</div>
              <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ASSIGNMENT_DUE_DATE); }}>Assignment Due Date</div>
          </Col> : ''
          }
      {(selectedTransactionName.transactionName === 'Pledge') ? <Col span={8}>
              <div style={{ fontWeight: '700' }}>Donor</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.DONOR_NAME); }}>Name</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.MOBILE_NUMBER); }}>Mobile</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.PLEDGE_AMOUNT); }}>Pledge Amount</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.PLEDGE_DATE); }}>Pledge Date</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.PAYMENT_LINK); }}>Payment URL</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.PLEDGE_START_DATE); }}>Pledge Start Date</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.TOTAl_OCCURRENCES); }}>No. of Occurances</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.REMARKS); }}>Donor Remarks</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.CAMPAIGN_NAME); }}>Campaign Name</div>
          </Col> : ''
          }
          {(selectedTransactionName.transactionName === 'Sales Invoice' || selectedTransactionName.transactionName === 'Recurring Sales Invoice') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Invoice</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.BALANCE); }}>Amount</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INVOICE_URL); }}>Invoice URL</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                  {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PAYMENT_LINK);}}>Payment Link</div> */}
              </Col> : ''
          }
          {(selectedTransactionName.transactionName === 'Sales Quote') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Sales Quote</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.QUOTE_BALANCE); }}>Amount</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.BID_CLOSING_DATE); }}>Bid Closing Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.QUOTE_DATE); }}>Date Created</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.QUOTE_NUMBER); }}>Quote #</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              </Col> : ''
          }
          {(selectedTransactionName.transactionName === 'Sales Order') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Sales Order</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_BALANCE); }}>Amount</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_DELIVERY_DATE); }}>Delivery Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_DATE); }}>Date Created</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_NUMBER); }}>SO #</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              </Col> : ''
          }
          {(selectedTransactionName.transactionName === 'Online Order Confirmation') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Sales Order</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_BALANCE); }}>Amount</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_DATE); }}>Date Created</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_NUMBER); }}>SO #</div>
              </Col> : ''
          }
          {(selectedTransactionName.transactionName === 'Customer Payment') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Payment</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALES_PAYMENT_BALANCE); }}>Amount</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALES_PAYMENT_DATE); }}>Payment Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALES_PAYMENT_NUMBER); }}>Payment #</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              </Col> : ''
          }
          {(selectedTransactionName.transactionName === 'Credit Memo') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Credit Memo</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CM_BALANCE); }}>Amount</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CM_EXPIRY_DATE); }}>Expiry Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CM_DATE); }}>Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CM_NUMBER); }}>Credit Memo #</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              </Col> : ''
          }
          {(selectedTransactionName.transactionName === 'Sales Inquiry') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Inquiry</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INQUIRY_DATE); }}>Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INQUIRY_EXPIRY_DATE); }}>Expiry Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INQUIRY_NUMBER); }}>Inquiry #</div>
              </Col> : ''
          }
           {(selectedTransactionName.transactionName === 'Purchase Invoice') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Invoice</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PI_BALANCE); }}>Amount</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              </Col> : ''
          }
            {(selectedTransactionName.transactionName === 'Purchase Order') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Purchase Order</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PO_BALANCE); }}>Amount</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PO_DUE_DATE); }}>Due Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PO_DATE); }}>Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PO_NUMBER); }}>PO #</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              </Col> : ''
          }
          {(selectedTransactionName.transactionName === 'Supplier Payment') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Purchase Payment</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_BALANCE); }}>Amount</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_DATE); }}>Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_NUMBER); }}>Payment #</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              </Col> : ''
          }
          {(selectedTransactionName.transactionName === 'Debit Memo') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Debit Memo</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DM_BALANCE); }}>Amount</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DM_EXPIRY_DATE); }}>Expiry Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DM_DATE); }}>Date Created</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DM_NUMBER); }}>Debit Memo #</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              </Col> : ''
          }
           {(selectedTransactionName.transactionName === 'RFQ') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>RFQ</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.RFQ_DATE); }}>RFQ Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.RFQ_EXPIRY_DATE); }}>Expiration Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.RFQ_NUMBER); }}>RFQ #</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              </Col> : ''
          }
            {(selectedTransactionName.transactionName === 'Purchase Request') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Purchase Request</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PR_DATE); }}>PR Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PR_EXPIRY_DATE); }}>Expiry Date</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PR_NUMBER); }}>PR #</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              </Col> : ''
          }
          {(selectedTransactionName.transactionName === 'Sales Quote' || selectedTransactionName.transactionName === 'Sales Order' || selectedTransactionName.transactionName === 'Sales Invoice' || selectedTransactionName.transactionName === 'Customer Payment' || selectedTransactionName.transactionName === 'Credit Memo' || selectedTransactionName.transactionName === 'Sales Inquiry' || selectedTransactionName.transactionName === 'Recurring Sales Invoice' || selectedTransactionName.transactionName === 'School Payment' ) ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Customer</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
              </Col> : ''}
          {(selectedTransactionName.transactionName === 'Online Order Confirmation' ) ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Customer</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
              </Col> 
              :
              ''
          }
          {(selectedTransactionName.transactionName === 'Purchase Order' || selectedTransactionName.transactionName === 'Purchase Invoice' || selectedTransactionName.transactionName === 'Supplier Payment' || selectedTransactionName.transactionName === 'Debit Memo') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Supplier</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SUPPLIER_NAME); }}>Supplier Name</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
              </Col> : ''}
              {(selectedTransactionName.transactionName === 'Kiosk Payment' || selectedTransactionName.transactionName === 'Online Payment' || selectedTransactionName.transactionName === 'Text2Donate Payment') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Payment</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Name</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALES_PAYMENT_BALANCE); }}>Amount</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALES_PAYMENT_DATE); }}>Payment Date</div>
              </Col> : ''
          }
          {(selectedTransactionName.transactionName === 'Sales Shipment') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Shipment</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SHIPMENT_NUMBER); }}>Shipment Number</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SHIPMENT_DATE); }}>Shipment Date</div>
                  {/* <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ORDER_DATE); }}>Order Date</div> */}
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ORDER_NUMBER); }}>Order Number</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.TRACKING_NUMBER); }}>Tracking #</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CARRIER_NAME); }}>Carrier Name</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DELIVERY_DATE); }}>Delivery Date</div>
              </Col> : ''
          }
          {( selectedTransactionName.transactionName === 'Sales Order Status Update' || selectedTransactionName.transactionName ===  'Sales Order Cancellation' || selectedTransactionName.transactionName ===  'Sales Order Update') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Online Order</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.OOC_ORDER_DATE); }}>Order Date</div>
                  {!selectedTransactionName.transactionName === 'Online Order Confirmation' ? <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.OOC_ORDER_NUMBER); }}>Order Number</div> : ""}
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.OOC_ORDER_AMOUNT); }}>Order Amount</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ONLINE_ORDER_NUMBER); }}>Online Order Number</div>
              </Col> : ''
          }
          {(selectedTransactionName.transactionName === 'Online Order Confirmation') ?
              <Col span={8}>
                  <div style={{ fontWeight: '700' }}>Online Order</div>
                  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ONLINE_ORDER_NUMBER); }}>Online Order Number</div>
              </Col> : ''
          }
          <Col span={8}>
              <div style={{ fontWeight: '700' }}>Organization</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.STORE_NAME); }}>Name</div>
              {(selectedTransactionName.transactionName === 'Pledge') ?  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.USER); }}>User</div> : ''}
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ORG_EMAIL); }}>Email</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ORG_PHONE); }}>Phone #</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ORG_FAX); }}>Fax #</div>
              <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
          </Col>
      </Row>
  </div>
}
const handleSelect = (key, value) => {
    props.updateState({ [key]: value });
  };
  return (

    <>

   
      <Row gutter={[16, 8]}>
              <Col span={24} className="i-label">
                <FormattedMessage
                  id="drawer.labelName.communicationTemplate.emailProvider"
                  defaultMessage=""
                />
                <span className="required">*</span>
                
              </Col>
              <Col span={24}>
              
            <Dropdown
              items={messageProvider || []}
              value={emailContent.emailProvider || "Amazon SES"}
              placeholder="Select ProviderName"
              onSelect={(val) => {
                const item = emailContent;
                emailContent.whatsaapProvider = val;

                updateState({
                    emailContent: item,
                })
              }}
              disabled={true}
            />
         
               
                  {/* <ErrorMsg
                    message={"Transaction Mode is required"}
                  /> */}
              </Col>
      </Row>
      <Row gutter={[16, 8]}>
              <Col span={24} className="i-label">
                <FormattedMessage
                  id="drawer.labelName.communicationTemplate.emailId"
                  defaultMessage=""
                />
                <span className="required">*</span>
                
              </Col>
              <Col span={24}>
                <Dropdown
                  items={senderEmails}
                  value={''}
                  onSelect={
                      (optionValue) => {
                          updateState({
                              clientRelationshipFromEmailId: optionValue
                          });
                      }
                  }
                      // items={transactionMode || []}
                      // valueKeyName="trnMode"
                      // optionKeyName="referCode"
                      // value={(props.transactionMode || {}).trnMode}
                      placeholder={"no-reply@alpide-email.com"}
                      // onSelect={(referCode) => {
                      //   const doc =
                      //     find(transactionMode, { referCode: referCode }) || {};
                      //   props.updateState({
                      //     transactionMode: doc,
                      //   });
                      // }}
                    />
                  {/* <ErrorMsg
                    message={"Transaction Mode is required"}
                  /> */}
              </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24} className="i-label">
          <FormattedMessage
            id="modal.settings.emailTemplate.emailSubject"
            defaultMessage=""
          />
         
         <span className="required">*</span>
        </Col>
        <Col span={24}>
                <Input type='text' style={{width:'85%', height:'34px'}}
                                     value={emailContent?.emailSubject}
                                    onClick={(e) => {
                                        updateState({
                                            subjectCursor: e.target.selectionStart,
                                            isPopoverVisible: false, isSubjectPopoverVisible: false
                                        }); 
                                    }}
                                     onChange={(e) => {
                                      const val = emailContent;
                                      val.emailSubject = e.target.value
                                         updateState({
                                          emailContent: val,
                                             subjectCursor: e.target.selectionStart,
                                             isPopoverVisible: false, isSubjectPopoverVisible: false
                                         });
                                     }}
                            /> 
                             <span className="placeholder-button">
                                            <Popover 
                                            content={getSubjectPlaceHolders(props, 'editorSubject')} 
                                            placement="bottom" trigger="click" onClick={()=>{ updateState({ isSubjectPopoverVisible: !props.isSubjectPopoverVisible, isPopoverVisible: false });}}>
                                                Placeholder <i className='fa fa-caret-square-o-down' />
                                            </Popover>
                                        </span> 
                                        <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!(emailContent?.emailSubject);
                }}
                message={"Email Subject is required"}
              />
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24} className="i-label">
            <FormattedMessage
              id="modal.settings.emailTemplate.emailMessage"
              defaultMessage=""
            />
            <span className="required">*</span>
            
        </Col>
        <Col span={24}>
          <div style={{ position:'relative'}}>
          <span className="placeholder-button" style={{ position:'absolute', right:'20px', top:'25px'}}>
                                            <Popover  content={getPlaceHolders(props, 'editor')} placement="bottom" trigger="click" onClick={()=>{ updateState({ isPopoverVisible: !props.isPopoverVisible, isSubjectPopoverVisible: false });}}>
                                                Placeholder <i className='fa fa-caret-square-o-down' />
                                            </Popover>
                                        </span> 
                                {
                                    <CKEditor
                                        //  editor={ClassicEditor}
                                        //  config={CUSTOM_CONFIGURATION}
                                        type='replace'
                                        onInit={editor => {
                                            updateState({
                                                editor: editor
                                            });
                                        }}
                                        data={emailContent?.emailMessage||""}
                                        key={`${"content"}`}
                                        onChange={(event, editor) => {
                                            const data = event.editor.getData();
                                            const val = emailContent;
                                            val.emailMessage = data;
                                            updateState({ emailContent: val, isPopoverVisible: false, isSubjectPopoverVisible: false });
                                        }}
                                         config={EMAIL_BODY_CONFIGURATION}
                                    />
                                }
          </div>

          <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!(emailContent?.emailMessage);
                }}
                message={"Email Message is required"}
              />
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <span>
            Automate trigger email when clicking on the {selectedTransactionName?.transactionName|| "module_name"} Submit Button.
          </span>  
        </Col>
        <Col span={24}>
        <Switch
  className="customAutomateSwitch"
  checkedChildren="Automate"
  unCheckedChildren="Manual"
  checked={props.emailAutoTrigger} // This should be a boolean value
  onChange={(checked) => {
    
    // Call the updateState function to update the parent component's state
    props.updateState({
      emailAutoTrigger: !(props.emailAutoTrigger),
    });
  }}
/>
        </Col>
      </Row>
    </>
  );
};

export default injectIntl(EmailDetails);
