import { SMS_ACTION_LIST } from "../static/constants";

const initialState = {
  schoolList: {
    1: [],
  },
  schoolCount: 0,
  subjectList: {
    1: []
  },
  subjectCount: 0,
  sectionList: {
    1: []
  },
  sectionCount: 0,
  courseList: {
    1: []
  },
  courseCount: 0,
  classList: {
    1: []
  },
  classCount: 0,
  gradeList: {
    1: []
  },
  gradeCount: 0,
  departmentList: {
    1: []
  },
  departmentCount: 0,
  courseTermList: {
    1: []
  },
  courseTermCount: 0,
  courseSubjectList: [],
  teacherCount: 0,
  teacherList: {
    1: []
  },
  studentCount: 0,
  studentList: {
    1: []
  },
  studentAttendanceList: [],

  subcourseList: [],
  schoolCommunicationList: {
    1: []
  },
  schoolCommunicationCount: 0,
  classScheduleList: [],
  onlineUserCount: 0,
  onlineUserList: {
    1: []
  },
  parentList: {
    1: [],
  },
  parentCount: 0,
  subuscriptionList: {
    1: [],
  },
  subuscriptionCount: 0,
  broadcastMessageList: {
    1: []
  },
  broadcastMessageCount: 0,

  financialReportCount: 0,
  financialReportList: {
    1: []
  },
  assignmentCount: 0,
  assignmentList: {
    1: []
  },
  assignmentResponseCount: 0,
  assignmentResponseList: {
    1: []
  },
  assignmentData: {},
  familyList: {
    1: []
  },
  familyCount: [],
  timeTableList: [],
  schoolStudentList: {
    1: [],
  },
  schoolFormDraftList: {
    1: [],
  },
  studentAttendance: {},
  schoolCourseDetail: {},
  subjectHeaderList: [],
  studentRegistrationCount:0,
  formRegistrations: {
    1: [],
  },
  formRegistrationCount: 0,
  schoolContactlist:[],
  classTeacherList:[]
};

const SchoolManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case SMS_ACTION_LIST.SCHOOL_LIST:
      return {
        ...state,
        schoolList: {
          ...state.schoolList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.SCHOOL_COUNT:
      return { ...state, schoolCount: action.data };
    case SMS_ACTION_LIST.SCHOOL_LIST_PAGE_RESET:
      return { ...state, schoolList: initialState.schoolList };

    case SMS_ACTION_LIST.SUBJECT_LIST:
      return {
        ...state,
        subjectList: {
          ...state.subjectList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.SUBJECT_COUNT:
      return { ...state, subjectCount: action.data };
    case SMS_ACTION_LIST.SUBJECT_LIST_PAGE_RESET:
      return { ...state, subjectList: initialState.subjectList };

    case SMS_ACTION_LIST.SECTION_LIST:
      return {
        ...state,
        sectionList: {
          ...state.sectionList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.SECTION_COUNT:
      return { ...state, sectionCount: action.data };
    case SMS_ACTION_LIST.SECTION_LIST_PAGE_RESET:
      return { ...state, subjectList: initialState.sectionList };

    case SMS_ACTION_LIST.SCHOOL_COURSE_LIST:
      return {
        ...state,
        courseList: {
          ...state.courseList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.SCHOOL_COURSE_COUNT:
      return { ...state, courseCount: action.data };
    case SMS_ACTION_LIST.SCHOOL_COURSE_LIST_PAGE_RESET:
      return { ...state, subjectList: initialState.courseList };


    case SMS_ACTION_LIST.GRADE_LIST:
      return {
        ...state,
        gradeList: {
          ...state.gradeList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.GRADE_COUNT:
      return { ...state, gradeCount: action.data };
    case SMS_ACTION_LIST.GRADE_LIST_RESET:
      return { ...state, gradeList: initialState.gradeList };

    case SMS_ACTION_LIST.CLASS_LIST:
      return {
        ...state,
        classList: {
          ...state.classList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.CLASS_COUNT:
      return { ...state, classCount: action.data };
    case SMS_ACTION_LIST.CLASS_LIST_RESET:
      return { ...state, classList: initialState.gradeList };

    case SMS_ACTION_LIST.DEPARTMENT_LIST:
      return {
        ...state,
        departmentList: {
          ...state.departmentList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.DEPARTMENT_COUNT:
      return { ...state, departmentCount: action.data };
    case SMS_ACTION_LIST.DEPARTMENT_LIST_RESET:
      return { ...state, departmentList: initialState.departmentList };

    case SMS_ACTION_LIST.COURSE_TERM_LIST:
      return {
        ...state,
        courseTermList: {
          ...state.courseTermList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.COURSE_TERM_COUNT:
      return { ...state, courseTermCount: action.data };
    case SMS_ACTION_LIST.COURSE_TERM_LIST_RESET:
      return { ...state, courseTermList: initialState.courseTermList };

    case SMS_ACTION_LIST.COURSE_SUBJECT_LIST:
      return { ...state, courseSubjectList: action.data };
    case SMS_ACTION_LIST.COURSE_SUBJECT_LIST_RESET:
      return { ...state, courseSubjectList: initialState.courseSubjectList };

    case SMS_ACTION_LIST.TEACHER_LIST:
      return {
        ...state,
        teacherList: {
          ...state.teacherList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.TEACHER_COUNT:
      return { ...state, teacherCount: action.data };
    case SMS_ACTION_LIST.TEACHER_LIST_PAGE_RESET:
      return { ...state, teacherList: initialState.teacherList };

    case SMS_ACTION_LIST.STUDENT_LIST:
      return {
        ...state,
        studentList: {
          ...state.studentList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.STUDENT_COUNT:
      return { ...state, studentCount: action.data };
    case SMS_ACTION_LIST.STUDENT_LIST_PAGE_RESET:
      return { ...state, studentList: initialState.studentList };
    case SMS_ACTION_LIST.SCHOOL_ATTENDENCE_BY_CLASS_ID:
      return { ...state, studentAttendanceList: action.data };

    case SMS_ACTION_LIST.SUBCOURSE_LIST:
      return { ...state, subcourseList: action.data }
    case SMS_ACTION_LIST.SUBCOURSE_LIST_ADDITION:
      return { ...state, subcourseList: [action.data, ...state.subcourseList] }
    case SMS_ACTION_LIST.SUBCOURSE_LIST_DELETION:
      return { ...state, subcourseList: state.subcourseList.filter(x => x.subcourseId !== action.data.id) }

    case SMS_ACTION_LIST.SCHOOL_COMMUNICATION_LIST:
      return {
        ...state,
        schoolCommunicationList: {
          ...state.schoolCommunicationList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.SCHOOL_COMMUNICATION_COUNT:
      return { ...state, schoolCommunicationCount: action.data };
    case SMS_ACTION_LIST.SCHOOL_COMMUNICATION_LIST_PAGE_RESET:
      return { ...state, schoolCommunicationList: initialState.schoolCommunicationList };

    case SMS_ACTION_LIST.CLASS_SCHEDULE_LIST:
      return { ...state, classScheduleList: action.data };
    case SMS_ACTION_LIST.ONLINE_USER_LIST:
      return {
        ...state,
        onlineUserList: {
          ...state.onlineUserList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.ONLINE_USER_COUNT:
      return { ...state, onlineUserCount: action.data };
    case SMS_ACTION_LIST.ONLINE_USER_LIST_PAGE_RESET:
      return { ...state, onlineUserList: initialState.onlineUserList };
    case SMS_ACTION_LIST.PARENT_LIST:
      return {
        ...state,
        parentList: {
          ...state.parentList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.PARENT_COUNT:
      return { ...state, parentCount: action.data };
    case SMS_ACTION_LIST.PARENT_LIST_PAGE_RESET:
      return { ...state, parentList: initialState.parentList };

    case SMS_ACTION_LIST.SUBSCRIPTION_PAYMENT_LIST:
      return {
        ...state,
        subuscriptionList: {
          ...state.subuscriptionList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.SUBSCRIPTION_PAYMENT_COUNT:
      return { ...state, subuscriptionCount: action.data };
    case SMS_ACTION_LIST.SUBSCRIPTION_PAYMENT_LIST_PAGE_RESET:
      return { ...state, subuscriptionList: initialState.subuscriptionList };
    case SMS_ACTION_LIST.BROADCAST_MESSAGE_LIST:
      return {
        ...state,
        broadcastMessageList: {
          ...state.broadcastMessageList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.BROADCAST_MESSAGE_COUNT:
      return { ...state, broadcastMessageCount: action.data };
    case SMS_ACTION_LIST.BROADCAST_MESSAGE_LIST_PAGE_RESET:
      return { ...state, broadcastMessageList: initialState.broadcastMessageList };

    case SMS_ACTION_LIST.FINANCIAL_REPORT_LIST:
      return {
        ...state,
        financialReportList: {
          ...state.financialReportList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.FINANCIAL_REPORT_COUNT:
      return { ...state, financialReportCount: action.data };
    case SMS_ACTION_LIST.FINANCIAL_REPORT_LIST_PAGE_RESET:
      return { ...state, financialReportList: initialState.financialReportList };

    case SMS_ACTION_LIST.ASSIGNMENT_LIST:
      return {
        ...state,
        assignmentList: {
          ...state.assignmentList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.ASSIGNMENT_COUNT:
      return { ...state, assignmentCount: action.data };
    case SMS_ACTION_LIST.ASSIGNMENT_LIST_PAGE_RESET:
      return { ...state, assignmentList: initialState.assignmentList };

    case SMS_ACTION_LIST.ASSIGNMENT_RESPONSE_LIST:
      return {
        ...state,
        assignmentResponseList: {
          ...state.assignmentResponseList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case SMS_ACTION_LIST.ASSIGNMENT_RESPONSE_COUNT:
      return { ...state, assignmentResponseCount: action.data };
    case SMS_ACTION_LIST.ASSIGNMENT_RESPONSE_LIST_PAGE_RESET:
      return { ...state, assignmentResponseList: initialState.assignmentResponseList };
    case SMS_ACTION_LIST.ASSIGNMENT_DATA:
      return { ...state, assignmentData: action.data };
    case SMS_ACTION_LIST.FAMILY_LIST:
      return {
        ...state,
        familyList: {
          ...state.familyList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SMS_ACTION_LIST.FAMILY_COUNT:
      return { ...state, familyCount: action.data };
    case SMS_ACTION_LIST.FAMILY_LIST_PAGE_RESET:
      return { ...state, familyList: initialState.familyList };
    case SMS_ACTION_LIST.TIME_TABLE_LIST:
      return { ...state, timeTableList: action.data };
    case SMS_ACTION_LIST.SCHOOL_STUDENT_LIST:
      return {
        ...state, schoolStudentList: {...state.schoolStudentList, [action.data.pageNo || 1]: action.data.list},
      };
    case SMS_ACTION_LIST.SCHOOL_STUDENT_COUNT:
      return { ...state, schoolStudentCount: action.data };
    case SMS_ACTION_LIST.SCHOOL_FORM_DRAFT_LIST:
      return { ...state, schoolFormDraftList: {...state.schoolFormDraftList, [action.data.pageNo || 1]: action.data.list} };
    case SMS_ACTION_LIST.SCHOOL_FORM_DRAFT_COUNT:
      return { ...state, schoolFormDraftCount: action.data };
    case SMS_ACTION_LIST.SCHOOL_ATTENDENCE_BY_STUDENT_ID:
      return { ...state, studentAttendance: action.data };
    case SMS_ACTION_LIST.SCHOOL_COURSE:
      return { ...state, schoolCourseDetail: action.data };
    case SMS_ACTION_LIST.SCHOOL_SUBJECT_HEADERS:
      return { ...state, subjectHeaderList: action.data };
    case SMS_ACTION_LIST.SCHOOL_COURSE_HEADERS:
      return { ...state, courseHeaderList: action.data };
    case SMS_ACTION_LIST.STUDENT_REGISTARTION_COUNT:
      return { ...state, studentRegistrationCount: action.data };
    case SMS_ACTION_LIST.FORM_REGISTRATION_LIST:
      return {
        ...state, formRegistrations: {...state.formRegistrations, [action.data.pageNo || 1]: action.data.list},
      };
    case SMS_ACTION_LIST.FORM_REGISTRATION_COUNT:
      return {
        ...state, formRegistrationCount: action.data,
      };
      case SMS_ACTION_LIST.SCHOOL_STUDENT_CONTACT_LIST:
        return {
          ...state, schoolContactlist: action.data,
        };
        case SMS_ACTION_LIST.TEACHER_LIST_BY_CLASS:
          return {
            ...state, classTeacherList: action.data,
          };
    default:
      return state;
  }
};

export default SchoolManagementReducer;
