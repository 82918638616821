import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import {Col,Row, Input, Popover, Switch} from "antd";
import { Dropdown } from "../../../general/Dropdown";
import "@ant-design/compatible/assets/index.css";
import { PLACEHOLDER_CONSTANTS } from "../../../../static/placeholderConstants";
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { EMAIL_BODY_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";
import { ErrorMsg } from "../../../general/ErrorMessage";

const { TextArea } = Input;


const WhatsappDetails = (props) => {
  const {updateState ,selectedTransactionName ,whatsappContent ,whatsappAutoTrigger,selectedProvider,messageProvider} = props;
  const transactionMode = [
    { trnMode: "Option 1", referCode: "OPT1" },
    { trnMode: "Option 2", referCode: "OPT2" },
    { trnMode: "Option 3", referCode: "OPT3" },
  ];

  const insertText = (props, textToInsert) => {
    const val = whatsappContent;
   
    if (whatsappContent?.whatsappMessage) {
        let subject = (whatsappContent?.whatsappMessage).slice(0, props.subjectCursor) + textToInsert + (whatsappContent?.whatsappMessage).slice(props.subjectCursor + 1);
        val.whatsappMessage = subject;
        props.updateState({ whatsappContent: val, subjectCursor: props.subjectCursor+textToInsert?.length })
    } else {
      val.whatsappMessage =textToInsert;
        props.updateState({ whatsappContent: val, subjectCursor: props.subjectCursor+textToInsert?.length })
    }
}
  const getPlaceHolders = (props, editorName ) => {
    return <div style={{ width: '400px' }}>
        <Row type='flex' gutter={[8, 24]}>
            {(selectedTransactionName.selectedTransactionName === 'School Assignments') ? <Col span={14}>
                <div style={{ fontWeight: '700' }}>Student</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.PARENT_NAME); }}>Father and Mother Name</div>
                <div className="ml10 pointer" onClick={() => {insertText(props,PLACEHOLDER_CONSTANTS.STUDENT_NAME); }}>Student Name</div>
                <div className="ml10 pointer" onClick={() => {insertText(props,PLACEHOLDER_CONSTANTS.TEACHER_NAME); }}>Teacher Name</div>
                <div className="ml10 pointer" onClick={() => {insertText(props,PLACEHOLDER_CONSTANTS.CLASS_NAME); }}>Class Name</div>
                <div className="ml10 pointer" onClick={() => {insertText(props,PLACEHOLDER_CONSTANTS.CLASS_SCHEDULE); }}>Class Schedule</div>
                <div className="ml10 pointer" onClick={() => {insertText(props,PLACEHOLDER_CONSTANTS.ASSIGNMENT_NAME); }}>Assignment Name</div>
                <div className="ml10 pointer" onClick={() => {insertText(props,PLACEHOLDER_CONSTANTS.ASSIGNMENT_DUE_DATE); }}>Assignment Due Date</div>
            </Col> : ''
            }
        {(selectedTransactionName.selectedTransactionName === 'Pledge') ? <Col span={8}>
                <div style={{ fontWeight: '700' }}>Donor</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.DONOR_NAME); }}>Name</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.MOBILE_NUMBER); }}>Mobile</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.PLEDGE_AMOUNT); }}>Pledge Amount</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.PLEDGE_DATE); }}>Pledge Date</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.PAYMENT_LINK); }}>Payment URL</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.PLEDGE_START_DATE); }}>Pledge Start Date</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.TOTAl_OCCURRENCES); }}>No. of Occurances</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.REMARKS); }}>Donor Remarks</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.CAMPAIGN_NAME); }}>Campaign Name</div>
            </Col> : ''
            }
            {(selectedTransactionName.selectedTransactionName === 'Sales Invoice' || selectedTransactionName.selectedTransactionName === 'Recurring Sales Invoice') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Invoice</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INVOICE_URL); }}>Invoice URL</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                    {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PAYMENT_LINK);}}>Payment Link</div> */}
                </Col> : ''
            }
            {(selectedTransactionName.selectedTransactionName === 'Sales Quote') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Quote</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.QUOTE_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.BID_CLOSING_DATE); }}>Bid Closing Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.QUOTE_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.QUOTE_NUMBER); }}>Quote #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.selectedTransactionName === 'Sales Order') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Order</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_DELIVERY_DATE); }}>Delivery Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_NUMBER); }}>SO #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.selectedTransactionName === 'Online Order Confirmation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Order</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_NUMBER); }}>SO #</div>
                </Col> : ''
            }
            {(selectedTransactionName.selectedTransactionName === 'Customer Payment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Payment</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALES_PAYMENT_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALES_PAYMENT_DATE); }}>Payment Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALES_PAYMENT_NUMBER); }}>Payment #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.selectedTransactionName === 'Credit Memo') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Credit Memo</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CM_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CM_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CM_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CM_NUMBER); }}>Credit Memo #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.selectedTransactionName === 'Sales Inquiry') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Inquiry</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INQUIRY_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INQUIRY_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INQUIRY_NUMBER); }}>Inquiry #</div>
                </Col> : ''
            }
             {(selectedTransactionName.selectedTransactionName === 'Purchase Invoice') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Invoice</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PI_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
              {(selectedTransactionName.selectedTransactionName === 'Purchase Order') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Purchase Order</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PO_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PO_DUE_DATE); }}>Due Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PO_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PO_NUMBER); }}>PO #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.selectedTransactionName === 'Supplier Payment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Purchase Payment</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_NUMBER); }}>Payment #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.selectedTransactionName === 'Debit Memo') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Debit Memo</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DM_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DM_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DM_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DM_NUMBER); }}>Debit Memo #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
             {(selectedTransactionName.selectedTransactionName === 'RFQ') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>RFQ</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.RFQ_DATE); }}>RFQ Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.RFQ_EXPIRY_DATE); }}>Expiration Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.RFQ_NUMBER); }}>RFQ #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
              {(selectedTransactionName.selectedTransactionName === 'Purchase Request') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Purchase Request</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PR_DATE); }}>PR Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PR_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PR_NUMBER); }}>PR #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.selectedTransactionName === 'Sales Quote' || selectedTransactionName.selectedTransactionName === 'Sales Order' || selectedTransactionName.selectedTransactionName === 'Sales Invoice' || selectedTransactionName.selectedTransactionName === 'Customer Payment' || selectedTransactionName.selectedTransactionName === 'Credit Memo' || selectedTransactionName.selectedTransactionName === 'Sales Inquiry' || selectedTransactionName.selectedTransactionName === 'Recurring Sales Invoice' || selectedTransactionName.selectedTransactionName === 'School Payment' ) ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Customer</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                </Col> : ''}
            {(selectedTransactionName.selectedTransactionName === 'Online Order Confirmation' ) ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Customer</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
                </Col> 
                :
                ''
            }
            {(selectedTransactionName.selectedTransactionName === 'Purchase Order' || selectedTransactionName.selectedTransactionName === 'Purchase Invoice' || selectedTransactionName.selectedTransactionName === 'Supplier Payment' || selectedTransactionName.selectedTransactionName === 'Debit Memo') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Supplier</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SUPPLIER_NAME); }}>Supplier Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                </Col> : ''}
                {(selectedTransactionName.selectedTransactionName === 'Kiosk Payment' || selectedTransactionName.selectedTransactionName === 'Online Payment' || selectedTransactionName.selectedTransactionName === 'Text2Donate Payment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Payment</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALES_PAYMENT_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALES_PAYMENT_DATE); }}>Payment Date</div>
                </Col> : ''
            }
            {(selectedTransactionName.selectedTransactionName === 'Sales Shipment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Shipment</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SHIPMENT_NUMBER); }}>Shipment Number</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SHIPMENT_DATE); }}>Shipment Date</div>
                    {/* <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ORDER_DATE); }}>Order Date</div> */}
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ORDER_NUMBER); }}>Order Number</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.TRACKING_NUMBER); }}>Tracking #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CARRIER_NAME); }}>Carrier Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DELIVERY_DATE); }}>Delivery Date</div>
                </Col> : ''
            }
            {( selectedTransactionName.selectedTransactionName === 'Sales Order Status Update' || selectedTransactionName.selectedTransactionName ===  'Sales Order Cancellation' || selectedTransactionName.selectedTransactionName ===  'Sales Order Update') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Online Order</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.OOC_ORDER_DATE); }}>Order Date</div>
                    {!selectedTransactionName.selectedTransactionName === 'Online Order Confirmation' ? <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.OOC_ORDER_NUMBER); }}>Order Number</div> : ""}
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.OOC_ORDER_AMOUNT); }}>Order Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ONLINE_ORDER_NUMBER); }}>Online Order Number</div>
                </Col> : ''
            }
            {(selectedTransactionName.selectedTransactionName === 'Online Order Confirmation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Online Order</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ONLINE_ORDER_NUMBER); }}>Online Order Number</div>
                </Col> : ''
            }
            <Col span={8}>
                <div style={{ fontWeight: '700' }}>Organization</div>
                <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.STORE_NAME); }}>Name</div>
                {(selectedTransactionName.selectedTransactionName === 'Pledge') ?  <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.USER); }}>User</div> : ''}
                <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ORG_EMAIL); }}>Email</div>
                <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ORG_PHONE); }}>Phone #</div>
                <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ORG_FAX); }}>Fax #</div>
                <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
            </Col>
        </Row>
    </div>
  }
  const handleSelect = (key, value) => {
    props.updateState({ [key]: value });
  };
  return (
    <>
      <Row gutter={[16, 8]}>
              <Col span={24} className="i-label">
                <FormattedMessage
                  id="drawer.labelName.communicationTemplate.whatsappProvider"
                  defaultMessage=""
                />
                <span className="required">*</span>
                
              </Col>
              <Col span={24}>
              <Dropdown
              items={messageProvider || []}
              value={whatsappContent.whatsaapProvider || "Amazon SES"}
              placeholder="Select ProviderName"
              onSelect={(val) => {
                const item = whatsappContent;
                whatsappContent.whatsaapProvider = val;

                updateState({
                  whatsappContent: item,
                })
              }}
              disabled={true}
            />
                  {/* <ErrorMsg
                    message={"Transaction Mode is required"}
                  /> */}
              </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24} className="i-label">
          <FormattedMessage
            id="drawer.labelName.communicationTemplate.whatsappId"
            defaultMessage=""
          />
          <span className="required">*</span>
          
        </Col>
        <Col span={24}>
          <Dropdown
                items={transactionMode || []}
                valueKeyName="trnMode"
                optionKeyName="referCode"
                value={(props.transactionMode || {}).trnMode}
                placeholder={"APMX90"}
                // onSelect={(referCode) => {
                //   const doc =
                //     find(transactionMode, { referCode: referCode }) || {};
                //   props.updateState({
                //     transactionMode: doc,
                //   });
                // }}
              />
            {/* <ErrorMsg
              message={"Transaction Mode is required"}
            /> */}
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24} className="i-label">
          <FormattedMessage
            id="drawer.labelName.communicationTemplate.whatsappMessage"
            defaultMessage=""
          />
          <span className="required">*</span>
          
        </Col>
        <Col span={24}>
        <TextArea
            showCount
            type='text'
            value={whatsappContent?.whatsappMessage||""}
            maxLength={100}
            style={{
              
              height: 120,
            }}
            onClick={(e) => {
                updateState({
                    subjectCursor: e.target.selectionStart,
                    isPopoverVisible: false, isSubjectPopoverVisible: false
                }); 
            }}
            onChange={(e) => {
              const data = e.target.value;
              const val = whatsappContent;
              val.whatsappMessage = data;
              updateState({ whatsappContent: val,  subjectCursor: e.target.selectionStart,
                isPopoverVisible: false, isSubjectPopoverVisible: false
           });
          }}
            placeholder="Type message here"
          />
               
                            <span className="placeholder-button" style={{ position:'absolute', right:'10px', top:'6px'}}>
                                            <Popover  content={getPlaceHolders(props, 'editor')} placement="bottom" trigger="click" onClick={()=>{ updateState({ isPopoverVisible: !props.isPopoverVisible, isSubjectPopoverVisible: false });}}>
                                                Placeholder <i className='fa fa-caret-square-o-down' />
                                            </Popover>
                                        </span> 
                                        <ErrorMsg
                validator={() => {
                  return !props.submittedOnce ||  !whatsappAutoTrigger || !!whatsappContent?.whatsappMessage;
                }}
                message={"WhatsApp Message Required"}
              />
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <span>
            Automate trigger email when clicking on the {selectedTransactionName?.selectedTransactionName|| "module_name"} Submit Button.
          </span>  
        </Col>
        <Col span={24}>
          <Switch className="customAutomateSwitch" checkedChildren="Automate" unCheckedChildren="Manual" checked={whatsappAutoTrigger ? true: false} onChange={(e)=>{

            updateState({
              whatsappAutoTrigger: !(whatsappAutoTrigger),
            })
          }} />
        </Col>
      </Row>
    </>
  );
};

export default injectIntl(WhatsappDetails);