import { Radio } from "antd";
import Search from "antd/lib/transfer/search";
import React, { useState } from "react";
import { useCallback,useMemo } from "react";

 export const SubSourceComp = React.memo(({ crmLeadSourceList, parentData, pageNumber, updateState, hideModal,callBack }) => {
    const [searchText, setSearchText] = useState('');
  
    const handleCategoryChange = useCallback((e) => {

        if(callBack){
            callBack(e.target.value)
          }
      updateState({ parentData: e.target.value });
      hideModal();
    }, [updateState, hideModal]);
  
    const renderSubcategories = useCallback((subLeadSource, depth = 1) => {
      const indentStyle = {
        paddingLeft: `${depth * 10}px`,
      };
      return subLeadSource?.map((subcategory, i) => (
        <div
          key={`${subcategory.leadSourceName}-${i}`}
          className="radio-button-Wrapper"
          style={indentStyle}
        >
          <Radio value={subcategory}>
            <div className="parent-category-sub-wrapper">
              {subcategory.leadSourceName}
            </div>
          </Radio>
          {subcategory.subLeadSource?.length > 0 &&
            renderSubcategories(subcategory.subLeadSource, depth + 1)}
        </div>
      ));
    }, []);
  
    const filteredProjectList = useMemo(() => {
      const currentPageList = crmLeadSourceList || [];
      if (!searchText) return currentPageList;
  
      return currentPageList.filter(category =>
        category.leadSourceName.toLowerCase().includes(searchText.toLowerCase())
      );
    }, [crmLeadSourceList, searchText]);
  
    return (
      <>
        <div className="searchBarArea">
          <Search
            placeholder="Search Category..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="categoryArea">
          <Radio.Group
            onChange={handleCategoryChange}
            value={parentData}
          >
            {filteredProjectList.map((category, i) => (
              <div className="subcategory" key={`${category.leadSourceName}-${i}`}>
                <Radio value={category}>
                  {category.leadSourceName}
                </Radio>
                {category.subLeadSource?.length > 0 &&
                  renderSubcategories(category.subLeadSource)}
              </div>
            ))}
          </Radio.Group>
        </div>
      </>
    );
  });