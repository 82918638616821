import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Button,
  Row,
  Col,
  Radio,
  Space,
  Divider,
  DatePicker,
  TimePicker
} from "antd";
import { CONSTANTS } from "../../../../../static/constants";
import {
  getMomentDateForUIReadOnly,
  getCurrentDateForBackend,
  getLocalDateFromUtcStr,
} from "../../../../../utils";
import { TextBox } from "../../../../general/TextBox";
import { Dropdown } from "../../../../general/Dropdown";
import { TextArea } from "../../../../general/TextAreaComponent";
import { MultipleSelectDropdown } from "../../../../general/MultipleSelectDropdown";
import moment from "moment-timezone";
import * as filter from 'lodash.filter';
import { StatusCategoryModal } from "../../subStageComp";

const Tasks = (props) => {
  const { leadData } = props;

  const [showReminder, setShowReminder] = useState(1);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [title, setTitle] = useState("");

  const findReminder = (e) =>{
    return (props.allLeadReminderList || []).find(obj => obj.crmLeadId === e.crmLeadId)||{};
  }

  const createNotesSubmit = () =>{
    if(!props.notesContent){
      props.updateModalState({
        errMsg: true
      })
      return
    }
    const payload = {
        relationshipId: props.companyInfo.relationshipId,
        crmLeadId: props.leadData?.crmLeadId,
        content: props.notesContent,
        crmLeadNotesId: props.crmLeadNotesId,
        createdByEmpId: props.userInfo.relationshipEmployeeId, 
        updatedByEmpId: props.userInfo.relationshipEmployeeId
    }
    props.saveOrUpdateNotes(payload, props);
    props.updateModalState({activitiesRef: true});
}

const createReminderSubmit = () =>{
  let dateAndTime = selectedDate;
  if(selectedDate && selectedTime){
     dateAndTime.set({"hour": selectedTime.get('hour'), "minute": selectedTime.get('minute'), "second": selectedTime.get('second')});
  }
  const payload = {
      relationshipId: props.companyInfo.relationshipId,
      crmLeadId: props.leadData?.crmLeadId,
      remiderDateAndTime: getCurrentDateForBackend(dateAndTime),
      reminderTitle:title,
      createdByEmpId: props.userInfo.relationshipEmployeeId, 
      updatedByEmpId: props.userInfo.relationshipEmployeeId
  }
  props.saveOrUpdateReminder(payload, props);
  props.updateModalState({activitiesRef: true});
  setShowReminder(1);
  setSelectedTime(null);
  setSelectedDate(null);
}
const handlePopUp = (callBack,parentData) => {
  const modalData = {
      title: "confirmation",
      modalBody: <StatusCategoryModal parentData={parentData} callBack={callBack} stopUpdate={true} {...props} />,
      width: 500,
      handleSubmit: () => {


      },
  };
  props.showModal(modalData);
}
const handleRadioChange = (e) => {
  setShowReminder(e.target.value);
};

const isOldDate = (pDateTime) =>{
  const currentDateTime = moment.utc();
  const providedDateTime = moment.utc(pDateTime, CONSTANTS.BACKEND_FORMAT);
  if(providedDateTime.isBefore(currentDateTime)) {
    return true;
  } 
  return false;
}



  return (
    <div className="lead-management p15" style={{height: 'calc(100vh - 63px)', overflowY: 'auto'}}>
      <div className="lead_sub_head">Lead Management</div>
      <p className="light-txt">Manage Lead Progression & Ownership</p>

      <div className="lead_sub_head mt5 mb5">Status</div>
        {/* <Dropdown
          style={{ minWidth: "100%" }}
          items={props.crmLeadStatusList}
          placeholder={"Select Status"}
          valueKeyName="statusName"
          optionKeyName="leadStatusId"
          value={leadData?.statusName || ""}
          onSelect={(id, options) => {
            let payload = leadData;
            payload.statusId = options.key;
            payload.statusName = options.name;
            payload.relEmpId = props.userInfo.relationshipEmployeeId;
            props.changeLeadStatus(payload, props);
            props.updateModalState({activitiesRef: true});
          }}
        /> */}
               <div onClick={() =>{
                        const callBack = (data)=>{
                          let payload = leadData;
                          payload.statusId = data.leadStatusId;
                          payload.statusName = data.statusName;
                          payload.relEmpId = props.userInfo.relationshipEmployeeId;
                          props.changeLeadStatus(payload, props);
                          props.updateModalState({activitiesRef: true});
                        }
                        
                        handlePopUp(callBack,leadData)}}>
                <TextBox
                        style={{ minWidth: "150px", maxHeight: "38px" }}

                    value={
                      leadData.statusName || ""
                    }
                    placeholder={'Select Project'}
                />
            </div>
   {props.isMyLeads?"":   <div>
        <div className="lead_sub_head mt10 mb5">Assign to</div>
          <MultipleSelectDropdown
            style={{ minWidth: "100%", maxHeight: "38px" }}
            items={props.leadAssignList || []}
            placeholder={"Assign to"}
            mode="multiple"
            allowClear={false}
            // onDeSelect={(empId, options) => {
            //     let tempList = props.selectedAssignList || [];
            //     const empExits = tempList.find((obj)=> obj.relEmployeeId === options.key)
            //     if(empExits){
            //         tempList = tempList.filter((obj) => obj.relEmployeeId !== options.key);
            //         props.updateModalState({selectedAssignList: tempList});
            //     }
            // }}
            selectedValue={(
              props.leadData?.crmLeadEmpAssignedList || []
            ).map((obj) => {
              return obj.relEmpId;
            })}
            valueKeyName="fullName"
            valueKeyName2="hierarchyName"
            optionKeyName="relationshipEmployeeId"
            onSelect={(empId, options) => {
              let tempList = [];
              tempList.push({
                relEmpId: Number(options.key),
                relationshipId: props.companyInfo.relationshipId,
                crmLeadId: props.leadData.crmLeadId,
              });
              props.changeCrmLeadEmpAssignedList(tempList, props);
              props.updateModalState({activitiesRef: true});
            }}
          />
      </div>}

      {!(Object.keys(findReminder(props.leadData)).length) ? (
        <>
          <div className="reminder-wrap">
            <div className="lead_sub_head mt10 mb5">Reminder</div>
            <Radio.Group onChange={handleRadioChange} value={showReminder}>
              <Space direction="vertical">
                <Radio value={1}>No reminder</Radio>
                <Radio value={2}>Schedule a reminder</Radio>
              </Space>
            </Radio.Group>
          </div>

          {showReminder === 2 && (
            <div className="reminder-section">
              <div className="title-wrap" style={{ marginTop: "15px" }}>
                <label className="input-label">Title</label>
                <TextBox
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>

              <div className="mt15">
                <label className="input-label">Select Time</label>
                <div className="datetime-picker-container">
                  <DatePicker
                    placeholder="Select date and time"
                    disabledDate={(current) => {
                      return current && current < moment().startOf("day");
                    }}
                    onChange={(time) => {
                      setSelectedDate(time);
                    }}
                    value={selectedDate}
                  />
                  <TimePicker
                    placeholder="Select time"
                    format='HH:mm'
                    onChange={(time) => {
                      setSelectedTime(time);
                    }}
                    value={selectedTime}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "15px",
                    gap: "10px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowReminder(1);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={createReminderSubmit}
                  >
                    <FormattedMessage id="lead.save.button" defaultMessage="" />
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="reminder-wrap">
          <div className="lead_sub_head mt10 mb5">Reminder</div>
          <div className="note-text">
            <Row>
              <Col span={20}>
                {findReminder(props.leadData).reminderTitle || '-'}
              </Col>
              <Col span={2}>
                <i
                  className="fi fi-rr-pencil pointer"
                  onClick={() => {
                    setShowReminder(2);
                    setTitle(findReminder(props.leadData).reminderTitle);
                  }}
                ></i>
              </Col>
              <Col span={2}>
                <i
                  className="fi fi-rr-trash pointer"
                  onClick={() => {
                    props.showModal({
                      title: "Confirmation",
                      modalBody: "Are you sure to delete reminder?",
                      handleSubmit: () => {
                        props.deleteLeadReminder(
                          findReminder(props.leadData),
                          props
                        );
                      },
                    });
                  }}
                ></i>
              </Col>
              <Col span={24}>
                <div className="required">{!isOldDate(findReminder(props.leadData).remiderDateAndTime) ? 'due' : 'expired'} on {getLocalDateFromUtcStr({date: findReminder(props.leadData).remiderDateAndTime, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT})}</div>
              </Col>
            </Row>
          </div>
        </div>
      )}

      <div className="note-wrap">
        <div className="lead_sub_head mt10">Notes</div>

        <p
          className="light-txt"
          style={{ lineHeight: "1.2em", marginTop: "2px !important" }}
        >
          Notes help you remember important details about your leads.
        </p>
        <TextArea
          className="textAreaStyle mt10"
          placeholder="Write a note..."
          value={props.notesContent}
          onChange={(e) => {
            props.updateModalState({
              notesContent: e.target.value,
              errMsg: false,
            });
          }}
        />
        {props.errMsg ? (
          <div className="required">Please enter content</div>
        ) : (
          ""
        )}
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button type="primary" className="mb10" onClick={createNotesSubmit}>
            <FormattedMessage id="lead.save.button" defaultMessage="" />
          </Button>
        </div>

        {(
          (props.allLeadNotesList &&
            filter(props.allLeadNotesList, {
              crmLeadId: props.leadData?.crmLeadId,
            }).sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1))) ||
          []
        ).map((e, i) => {
          return (
            <>
              <div className="light-txt">
                {/* {isTimeDifferenceGreaterThan30Seconds(? 'xxx':'yy'} */}
                {getMomentDateForUIReadOnly({
                  date: new Date(e.dateCreated),
                  format: CONSTANTS.DISPLAY_DATE_FORMAT_FULl,
                })}
                <span
                  className="pointer ml15"
                  onClick={() => {
                    props.updateModalState({
                      notesContent: e.content,
                      crmLeadNotesId: e.crmLeadNotesId,
                    });
                  }}
                >
                  Edit
                </span>
                <span
                  className="pointer ml15"
                  onClick={() => {
                    props.showModal({
                      title: "Confirmation",
                      modalBody: "Do you want to delete this note?",
                      handleSubmit: () => {
                        props.deleteCrmNotes(e, props);
                      },
                    });
                  }}
                >
                  Delete
                </span>
              </div>
              <p className="note-text">{e.content}</p>
              <Divider className="mt5 mb5" />
            </>
          );
        })}
      </div>
    </div>
  );
};

export default injectIntl(Tasks);
