import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as filter from "lodash.filter";
import * as find from "lodash.find";

import InboundDeliveryComponent from "../../../components/supplier/InboundDelivery";
import { fetchAllRFQ } from "../../../containers/supplier/rfq/Listing/action";
import {
  fetchPriceList,
  fetchPaymentTerms,
  addPaymentTerm,
  deletePaymentTerm,
  fetchProjects,
  fetchStates,
  fetchTaxes,
  fetchUOM,
  addUOM,
  deleteUOM,
  fetchExpenseTypes,
  addExpenseTypes,
  deleteExpenseTypes,
  fetchProducts,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  deleteProject,
  deleteAddress,
  deleteTax,
  deletePriceList,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  deleteContact,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchShippingInstructions,
  addShippingInstruction,
  deleteShippingInstruction,
  fetchRelationshipTaxIdentifications,
  deleteAuditTrail,
  createAuditTrail,
  deleteRelationshipTaxIdentifications,
  deleteSupplierTaxIdentifications,
  fetchSupplierSummary,
  fetchSupplierDetailsBySupplierId,
  resetSupplierDetail,
  getRelationshipAddress,
} from "../../../actions/commonActions";
import {
  createInboundDelivery,
  fetchIDDataForPo,
  resetIDPoData,
  createInboundDeliveryDraft,
  getNextTxNumber,
  fetchAllPOForInbound,
} from "./action";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import InboundDeliveryDetail from "../../modal/modalBody/supplier/InboundDeliveryDetail";
import {
  CONSTANTS,
  LEDGER_ACTION_LIST,
  LEDGER_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
} from "../../../static/constants";
import { GRID_PREFERENCE_CONSTANTS } from "../../../static/agGridConstants";
import { deleteSuppliers } from "../Listing/action";
import {
  fetchRemarksTemplate,
  resetRemarksData,
} from "../../modal/modalBody/settings/RemarksTemplate/action";
import {
  fetchPurchasedOrderDetail,
  resetPODetail,
} from "../../modal/modalBody/supplier/PurchaseOrderDetail/action";
import {
  fetchInboundryDetailDetail,
  resetIDDetail,
} from "../../modal/modalBody/supplier/InboundDeliveryDetail/action";
import AuditTimeLine from "../../../components/drawer/AuditTimeLine";
import { fetchAddresses } from "../Listing/ShowAddress/action";
import { fetchAllContacts } from "../Listing/ShowConact/action";
// import InboundDeliveryFilters from "../../../components/supplier/InboundDelivery/Listing/inboundDeliveryFilter";
import {
  fetchInboundDeliveriesDrafts,getInboundDeliveriesDraftCount,
  fetchAllInboundDeliveries,
} from "./Listing/action";
import { fetchPurchaseOrders } from "../purchaseOrder/Listing/action";

class InboundDeliveryContainer extends Component {
  constructor(props) {
    super(props);
    const defaultPurchaseLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.PURCHASE,
      }) || {};
    const defaultTaxLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASE_TAX,
      }) || {};
    const defaultDiscountLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DISCOUNT_RECIEVED,
      }) || {};
    const defaultExpenseLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.SUPPLIER_EXPENSE,
      }) || {};
    const defaultCreditorLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.CREDITORS,
      }) || {};
    const allRelationshipBillingAddress =
      filter(props.companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
      }) || [];
    const allRelationshipShippingAddress =
      filter(props.companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
      }) || [];
    const relationshipBillingAddress =
      allRelationshipBillingAddress.find((x) => x.isDefault) || {};
    const relationshipShippingAddress =
      allRelationshipShippingAddress.find((x) => x.isDefault) || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      inboundDeliveryDraftData: props.inboundDeliveryDataa,
      inboundDeliveryDraftId: props.inboundDeliveryDraftId,
      stateSupplierId: props.supplierId,
      pageNumber: linkProps.pageNumber || 1,
      inboundDeliveryMasterId: props.inboundDeliveryMasterId || 0,
      supplier: undefined,
      isToShowPO: props.type === "new" ? true : false,
      allRelationshipBillingAddress,
      allRelationshipShippingAddress,
      relationshipBillingAddress,
      relationshipShippingAddress,
      priceListName: undefined,
      inboundDeliveryDate: new Date(),
      pageSize: 100,
      purchaseLedgerId: defaultPurchaseLedger.ledgerAccountId,
      purchaseCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseDiscountLedgerId: defaultDiscountLedger.ledgerAccountId,
      discountCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      taxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      purchaseExpenseCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      companyInfo: props.companyInfo,
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      txPreferences: { txType: "inboundDelivery", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.QUANTITY_FROM_PO,
        GRID_PREFERENCE_CONSTANTS.QUANTITY_RECEIVED,
        GRID_PREFERENCE_CONSTANTS.UOM,
        "sno",
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        qtyFromPO: true,
        qtyReceived: true,
        description: true,
        uom: true,
        uomId: true,
        hsn: (props.companyInfo || {}).countryName === "India",
      },
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      poMasterId: this.props.poMasterId || linkProps.poMasterId,
      supplierId: this.props.supplierId || linkProps.supplierId,
      userId: (this.props.userInfo || {}).userId,
      inboundDeliveryMasterId: this.props.inboundDeliveryMasterId,
      pageNumber: 1,
      pageSize: 100,
    };
    this.props.resetPODetail();
    this.props.resetIDDetail();
    payload.poMasterId &&
      fetchDataIfNeeded(
        "fetchIDDataForPo",
        "idPoData",
        this.props,
        payload,
        true
      );
    this.props.resetSupplierDetail();
    payload.supplierId && this.props.fetchSupplierDetailsBySupplierId(payload);

    fetchPaginationDataIfNeeded(
      "fetchSupplierSummary",
      "supplierSummary",
      this.props,
      payload,
      true
    );
    //  fetchPaginationDataIfNeeded('fetchSuppliers', 'suppliers', this.props, payload, true);
    fetchDataIfNeeded("fetchPriceList", "priceList", this.props, payload);
    fetchDataIfNeeded("fetchProjects", "projectList", this.props, payload);
    fetchDataIfNeeded("fetchPaymentTerms", "paymentTerms", this.props, payload);
    fetchDataIfNeeded("fetchStates", "states", this.props, 1);
    fetchDataIfNeeded(
      "fetchUserPreferences",
      "allPreferences",
      this.props,
      payload
    );
    fetchDataIfNeeded("fetchProducts", "products", this.props, payload);
    fetchDataIfNeeded("fetchTaxes", "taxes", this.props, payload);
    fetchDataIfNeeded("fetchUOM", "uomList", this.props, payload);
    fetchDataIfNeeded("fetchExpenseTypes", "expenseTypes", this.props, payload);
    fetchDataIfNeeded("fetchDocuments", "documentList", this.props, payload);
    fetchDataIfNeeded(
      "fetchShippingInstructions",
      "allShippingInstruction",
      this.props,
      payload
    );
    this.props.fetchRemarksTemplate({
      relationshipId: companyInfo.relationshipId,
      docName: "Purchase Order",
    });
    fetchDataIfNeeded(
      "fetchRelationshipTaxIdentifications",
      "allRelationshipTaxIdentificatins",
      this.props,
      payload,
      true
    );
    payload.inboundDeliveryMasterId &&
      fetchDataIfNeeded(
        "fetchInboundryDetailDetail",
        "idPoData",
        this.props,
        payload,
        true
      );
    if (!this.props.update) {
      fetchDataIfNeeded("getNextTxNumber", "nextTxNumber", this.props, payload);
    }
    this.props.fetchAllPOForInbound({
      relationshipId: companyInfo.relationshipId,
    });
  }

  openIDDetails = (data) => {
    const poPayload = {
      supplierId: data.supplierId,
      inboundDeliveryMasterId: data.inboundDeliveryMasterId,
    };
    this.props.pushModalToStack({
      modalBody: (
        <InboundDeliveryDetail {...this.props} poPayload={poPayload} />
      ),
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  componentWillReceiveProps(props) {
    // const linkProps = (this.props.location && this.props.location.state) || {};
    if (
      !this.state.selectedRelationshipTaxIdentifications &&
      props.allRelationshipTaxIdentificatins &&
      props.allRelationshipTaxIdentificatins.length
    ) {
      const selectedList =
        (props.allRelationshipTaxIdentificatins &&
          filter(props.allRelationshipTaxIdentificatins, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedRelationshipTaxIdentifications = selectedList.map((obj) => {
        return obj.relationshipTaxIdentificationId;
      });
      this.setState({
        selectedRelationshipTaxIdentifications:
          selectedRelationshipTaxIdentifications,
      });
    }

    if (
      props.documentList &&
      props.documentList.length &&
      !this.state.docName
    ) {
      props.documentList.forEach((document) => {
        if (
          document.txType ===
            CONSTANTS_TRANSACTIONS_KEY[
              CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY
            ] &&
          document.isDefault
        ) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName,
          });
        }
      });
    }
    if (
      !this.state.remarksCustomer &&
      props.remarksData &&
      props.remarksData.content &&
      !this.state.isRemarksSet
    ) {
      this.setState({
        remarksCustomer: props.remarksData.content || "",
        isRemarksSet: true,
        footer: props.remarksData.footer || "",
      });
      setTimeout(() => {
        props.resetRemarksData();
      }, 500);
    }
    if (props.boRelationshipAddress && props.boRelationshipAddress.length) {
      const allRelationshipBillingAddress = [];
      const allRelationshipShippingAddress = [];
      props.boRelationshipAddress.forEach((e) => {
        if (e.locationType === "RelationshipBillingAddress") {
          allRelationshipBillingAddress.push(e);
        } else {
          allRelationshipShippingAddress.push(e);
        }
      });
      this.setState({
        allRelationshipBillingAddress,
        allRelationshipShippingAddress,
      });
    } else {
      const allRelationshipBillingAddress =
        filter(props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }) || [];
      const allRelationshipShippingAddress =
        filter(props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
        }) || [];
      this.setState({
        allRelationshipBillingAddress,
        allRelationshipShippingAddress,
      });
    }
    if (props.idPoData && props.idPoData.poMasterId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        // }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
      };
      props.idPoData.supplierPurchaseOrderDetailList &&
        props.idPoData.supplierPurchaseOrderDetailList.map((ele) => {
          const tax =
            find(ele.supplierPOCOATxList, (ele) => {
              return ele.txType === LEDGER_TYPE.TYPE_TAX;
            }) || {};
          _emptyColumnCheck(ele);
          const appliedTax =
            find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
          return detailList.push({
            version: ele.version,
            selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
            product: ele.itemName
              ? ele.itemName +
                ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") ||
                  "")
              : "",
            productObj: ele.itemName
              ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
              : {},
            itemVariantName: ele.itemVariantName,
            variant: {
              attributeId1: ele.attributeId1,
              attributeId2: ele.attributeId2,
              attributeId3: ele.attributeId3,
              attributeName1: ele.attributeName1,
              attributeName2: ele.attributeName2,
              attributeName3: ele.attributeName3,
              attributeValue1: ele.attributeValue1,
              attributeValue2: ele.attributeValue2,
              attributeValue3: ele.attributeValue3,
            },
            description: ele.description,
            scrollHeight: ele.rowHeight,
            discount: (
              find(ele.supplierPOCOATxList, {
                txType: LEDGER_TYPE.TYPE_DISCOUNT,
              }) || {}
            ).amountPercent,
            tax: tax.amountPercent || 0,
            taxApplied: appliedTax.taxNameDisplay,
            taxName: appliedTax.taxName,
            taxId: appliedTax.taxSingleRateId,
            quantity: ele.quantityToInvoice,
            qtyFromPO: ele.quantity,
            qtyReceived: ele.quantityInvoiced,
            rate: ele.anItemPurchasePrice,
            baseRate: ele.basePrice,
            uom: ele.uomName,
            hsnCode: ele.hsnCode,
            specialDiscount: ele.specialDiscount,
            poMasterId: props.idPoData.poMasterId,
            poNumber: props.idPoData.poNumber,
            reference: props.idPoData.reference,
            parentDetailsId: ele.poDetailsId,
          });
        });

      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
      //   forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      // }

      const rootDiscount =
        find(props.idPoData.supplierPOCOATxList || [], {
          txType: LEDGER_TYPE.TYPE_DISCOUNT,
        }) || {};
      const expenseAmount =
        (
          find(props.idPoData.supplierPOCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      const selectedContact =
        (props.idPoData.additionalInfoList || [])[0] || {};
      let selectedSupplierTaxIdentifications = [];
      if (selectedContact.taxIdentifications) {
        let taxIdentificationsList = JSON.parse(
          selectedContact.taxIdentifications
        );
        selectedSupplierTaxIdentifications = taxIdentificationsList.map(
          (obj, i) => {
            return obj.supplierTaxIdentificationId;
          }
        );
      }
      const customerName = props.idPoData.customerName;

      this.setState({
        selectedSupplierTaxIdentifications,
        version: props.idPoData.version,
        supplier: {
          id: props.idPoData.supplierId,
          supplierId: props.idPoData.supplierId,
          name: props.idPoData.supplierName,
        },
        relationshipBillingAddress: find(props.idPoData.boLocationPOList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }),
        supplierBillingAddress: find(props.idPoData.boLocationPOList, {
          locationType: CONSTANTS.BILLING_ADDRESS,
        }),
        selectedContact: selectedContact,
        supplierPaymentTerm: props.paymentTerms.length
          ? find(props.paymentTerms, {
              paymentTermId: props.idPoData.paymentTermId,
            }) || {}
          : {},
        poMasterId: props.idPoData.poMasterId,
        customerInquiryNumber: props.idPoData.customerInquiryNumber,
        customerPONumber: props.idPoData.customerPONumber,
        customerRFQNumber: props.idPoData.customerRFQNumber,
        customerId: props.idPoData.customerId,
        customerName: props.idPoData.customerName,
        customer: { customerId: props.idPoData.customerId, customerName },
        supplierPONumber: props.idPoData.supplierPONumber,
        exchangeRate: props.idPoData.exchangeRate,
        foreignCurrency: props.idPoData.foreignCurrency,
        poNumber: props.idPoData.poNumber,
        purchaseOrderId: props.idPoData.poId,
        rfqChildMasterId: props.idPoData.rfqChildMasterId,
        rfqMasterId: props.idPoData.rfqMasterId,
        rfqNumber: props.idPoData.rfqNumber,
        purchaseRequestNumber: props.idPoData.purchaseRequestNumber,
        reference: props.idPoData.reference,
        projectMasterId: props.idPoData.projectMasterId,
        projectName: props.idPoData.projectName,
        projectNumber: props.idPoData.projectNumber,
        internalRemarks: props.idPoData.remarksInternal,
        supplierRemarks: props.idPoData.remarksSupplier,
        footer: props.idPoData.footer,
        placeOfSupply: props.idPoData.placeOfSupply,
        isRootDiscount: rootDiscount ? true : false,
        rootDiscountPercent: rootDiscount.amountPercent || 0,
        expenseId: props.idPoData.expenseId,
        rootDiscountAmount: Number(rootDiscount.amount || 0),
        expenseAmount: expenseAmount,
        isRootExpense: expenseAmount ? true : false,
        poDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        supplierIDCOATxList: props.idPoData.supplierPOCOATxList,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        supplierQuoteNumber: props.idPoData.supplierQuoteNumber,
        inboundDeliveryDate: props.idPoData.poDate
          ? new Date(props.idPoData.poDate)
          : new Date(),
      });
      props.resetIDPoData();
    }

    if (
      props.inboundDeliveryData &&
      props.inboundDeliveryData.inboundDeliveryMasterId
    ) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        // }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
      };
      props.inboundDeliveryData.inboundDeliveryDetailsList &&
        props.inboundDeliveryData.inboundDeliveryDetailsList.map((ele) => {
          const tax =
            find(ele.supplierIDCOATxList, (ele) => {
              return ele.txType === LEDGER_TYPE.TYPE_TAX;
            }) || {};
          _emptyColumnCheck(ele);
          const appliedTax =
            find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
          return detailList.push({
            selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
            product: ele.itemName
              ? ele.itemName +
                ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") ||
                  "")
              : "",
            productObj: ele.itemName
              ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
              : {},
            itemVariantName: ele.itemVariantName,
            variant: {
              attributeId1: ele.attributeId1,
              attributeId2: ele.attributeId2,
              attributeId3: ele.attributeId3,
              attributeName1: ele.attributeName1,
              attributeName2: ele.attributeName2,
              attributeName3: ele.attributeName3,
              attributeValue1: ele.attributeValue1,
              attributeValue2: ele.attributeValue2,
              attributeValue3: ele.attributeValue3,
            },
            description: ele.description,
            scrollHeight: ele.rowHeight,
            discount: (
              find(ele.supplierIDCOATxList, {
                txType: LEDGER_TYPE.TYPE_DISCOUNT,
              }) || {}
            ).amountPercent,
            tax: tax.amountPercent || 0,
            taxApplied: appliedTax.taxNameDisplay,
            taxName: appliedTax.taxName,
            taxId: appliedTax.taxSingleRateId,
            quantity: ele.qtyReceived,
            qtyBeforeUpdate: ele.qtyReceived,
            qtyFromPO: ele.qtyFromPO,
            rate: ele.anItemPurchasePrice,
            baseRate: ele.basePrice,
            uom: ele.uomName,
            hsnCode: ele.hsnCode,
            specialDiscount: ele.specialDiscount,
            version: props.update ? ele.version : 0,
            poMasterId: props.inboundDeliveryData.poMasterId,
            poNumber: props.inboundDeliveryData.poNumber,
            reference: props.inboundDeliveryData.reference,
            parentDetailsId: props.update ? ele.parentDetailsId : 0,
            inboundDeliveryDetailsId: props.update
              ? ele.inboundDeliveryDetailsId
              : 0,
          });
        });

      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
      //   forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      // }

      const rootDiscount =
        find(props.inboundDeliveryData.supplierIDCOATxList || [], {
          txType: LEDGER_TYPE.TYPE_DISCOUNT,
        }) || {};
      const expenseAmount =
        (
          find(props.inboundDeliveryData.supplierIDCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      const supplier = this.state.supplier || {};
      const selectedContact =
        (props.inboundDeliveryData.deliveryAdditionalInfoList || [])[0] || {};
      let selectedSupplierTaxIdentifications = [];
      if (selectedContact.taxIdentifications) {
        let taxIdentificationsList = JSON.parse(
          selectedContact.taxIdentifications
        );
        selectedSupplierTaxIdentifications = taxIdentificationsList.map(
          (obj, i) => {
            return obj.supplierTaxIdentificationId;
          }
        );
      }
      this.setState({
        selectedSupplierTaxIdentifications,
        supplier: {
          ...supplier,
          id: props.inboundDeliveryData.supplierId,
          supplierId: props.inboundDeliveryData.supplierId,
          name: props.inboundDeliveryData.supplierName,
        },
        relationshipBillingAddress: find(
          props.inboundDeliveryData.boLocationInboundDeliveryList,
          { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }
        ),
        supplierBillingAddress: find(
          props.inboundDeliveryData.boLocationInboundDeliveryList,
          { locationType: CONSTANTS.BILLING_ADDRESS }
        ),
        selectedContact: selectedContact,
        supplierPaymentTerm: props.paymentTerms.length
          ? find(props.paymentTerms, {
              paymentTermId: props.inboundDeliveryData.paymentTermId,
            }) || {}
          : {},
        update: props.update,
        version: props.update ? props.inboundDeliveryData.version : 0,
        inboundDeliveryNumber: props.update
          ? props.inboundDeliveryData.inboundDeliveryNumber
          : 0,
        inboundDeliveryMasterId: props.update
          ? props.inboundDeliveryData.inboundDeliveryMasterId
          : 0,
        poMasterId: props.inboundDeliveryData.poMasterId,
        poNumber: props.inboundDeliveryData.poNumber,
        purchaseOrderId: props.inboundDeliveryData.poId,
        rfqChildMasterId: props.inboundDeliveryData.rfqChildMasterId,
        rfqMasterId: props.inboundDeliveryData.rfqMasterId,
        rfqNumber: props.inboundDeliveryData.rfqNumber,
        purchaseRequestNumber: props.inboundDeliveryData.purchaseRequestNumber,
        reference: props.inboundDeliveryData.reference,
        projectMasterId: props.inboundDeliveryData.projectMasterId,
        projectName: props.inboundDeliveryData.projectName,
        projectNumber: props.inboundDeliveryData.projectNumber,
        internalRemarks: props.inboundDeliveryData.remarksInternal,
        supplierRemarks: props.inboundDeliveryData.remarksSupplier,
        footer: props.inboundDeliveryData.footer,
        placeOfSupply: props.inboundDeliveryData.placeOfSupply,
        isRootDiscount: rootDiscount ? true : false,
        rootDiscountPercent: rootDiscount.amountPercent || 0,
        expenseId: props.inboundDeliveryData.expenseId,
        rootDiscountAmount: Number(rootDiscount.amount || 0),
        expenseAmount: expenseAmount,
        isRootExpense: expenseAmount ? true : false,
        poDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        inboundDeliveryDate: props.inboundDeliveryData.inboundDeliveryDate
          ? new Date(props.inboundDeliveryData.inboundDeliveryDate)
          : new Date(),
        conversionRefList: props.inboundDeliveryData.conversionRefList || [],
        oldPayload: props.update ? props.inboundDeliveryData : {},
        supplierQuoteNumber: props.inboundDeliveryData.supplierQuoteNumber,
      });
      if (props.update) {
        this.setState({
          nextTxNumber: props.inboundDeliveryData.inboundDeliveryNumber,
        });
      }
      props.resetIDDetail();
    }

    if (
      this.state.inboundDeliveryDraftId &&
      this.state.inboundDeliveryDraftData
    ) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        // }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
      };
      this.state.inboundDeliveryDraftData.inboundDeliveryDetailsList &&
        this.state.inboundDeliveryDraftData.inboundDeliveryDetailsList.map(
          (ele) => {
            const tax =
              find(ele.supplierIDCOATxList, (ele) => {
                return ele.txType === LEDGER_TYPE.TYPE_TAX;
              }) || {};
            _emptyColumnCheck(ele);
            const appliedTax =
              find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
            return detailList.push({
              selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
              product: ele.itemName
                ? ele.itemName +
                  ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") ||
                    "")
                : "",
              productObj: ele.itemName
                ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
                : {},
              itemVariantName: ele.itemVariantName,
              variant: {
                attributeId1: ele.attributeId1,
                attributeId2: ele.attributeId2,
                attributeId3: ele.attributeId3,
                attributeName1: ele.attributeName1,
                attributeName2: ele.attributeName2,
                attributeName3: ele.attributeName3,
                attributeValue1: ele.attributeValue1,
                attributeValue2: ele.attributeValue2,
                attributeValue3: ele.attributeValue3,
              },
              description: ele.description,
              scrollHeight: ele.rowHeight,
              discount: (
                find(ele.supplierIDCOATxList, {
                  txType: LEDGER_TYPE.TYPE_DISCOUNT,
                }) || {}
              ).amountPercent,
              tax: tax.amountPercent || 0,
              taxApplied: appliedTax.taxNameDisplay,
              taxName: appliedTax.taxName,
              taxId: appliedTax.taxSingleRateId,
              quantity: ele.qtyReceived,
              qtyFromPO: ele.qtyFromPO,
              rate: ele.anItemPurchasePrice,
              baseRate: ele.basePrice,
              uom: ele.uomName,
              hsnCode: ele.hsnCode,
              specialDiscount: ele.specialDiscount,
              poMasterId: this.state.inboundDeliveryDraftData.poMasterId,
              poNumber: this.state.inboundDeliveryDraftData.poNumber,
              reference: this.state.inboundDeliveryDraftData.reference,
              parentDetailsId: props.update ? ele.parentDetailsId : 0,
            });
          }
        );

      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
      //   forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      // }

      const rootDiscount =
        find(this.state.inboundDeliveryDraftData.supplierIDCOATxList || [], {
          txType: LEDGER_TYPE.TYPE_DISCOUNT,
        }) || {};
      const expenseAmount =
        (
          find(this.state.inboundDeliveryDraftData.supplierIDCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      const supplier = this.state.supplier || {};
      const selectedContact =
        (this.state.inboundDeliveryDraftData.deliveryAdditionalInfoList ||
          [])[0] || {};
      let selectedSupplierTaxIdentifications = [];
      if (selectedContact.taxIdentifications) {
        let taxIdentificationsList = JSON.parse(
          selectedContact.taxIdentifications
        );
        selectedSupplierTaxIdentifications = taxIdentificationsList.map(
          (obj, i) => {
            return obj.supplierTaxIdentificationId;
          }
        );
      }

      setTimeout(() => {
        if (this.state.inboundDeliveryDraftData) {
          this.setState({
            selectedSupplierTaxIdentifications,
            supplier: {
              ...supplier,
              id: this.state.inboundDeliveryDraftData.supplierId,
              supplierId: this.state.inboundDeliveryDraftData.supplierId,
              name: this.state.inboundDeliveryDraftData.supplierName,
            },
            relationshipBillingAddress: find(
              this.state.inboundDeliveryDraftData.boLocationInboundDeliveryList,
              { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }
            ),
            supplierBillingAddress: find(
              this.state.inboundDeliveryDraftData.boLocationInboundDeliveryList,
              { locationType: CONSTANTS.BILLING_ADDRESS }
            ),
            selectedContact: selectedContact,
            supplierPaymentTerm: props.paymentTerms.length
              ? find(props.paymentTerms, {
                  paymentTermId:
                    this.state.inboundDeliveryDraftData.paymentTermId,
                }) || {}
              : {},
            inboundDeliveryNumber:
              this.state.inboundDeliveryDraftData.inboundDeliveryNumber || 0,
            inboundDeliveryMasterId:
              this.state.inboundDeliveryDraftData.inboundDeliveryMasterId || 0,
            poMasterId: this.state.inboundDeliveryDraftData.poMasterId,
            poNumber: this.state.inboundDeliveryDraftData.poNumber,
            purchaseOrderId: this.state.inboundDeliveryDraftData.poId,
            rfqChildMasterId:
              this.state.inboundDeliveryDraftData.rfqChildMasterId,
            rfqMasterId: this.state.inboundDeliveryDraftData.rfqMasterId,
            rfqNumber: this.state.inboundDeliveryDraftData.rfqNumber,
            purchaseRequestNumber:
              this.state.inboundDeliveryDraftData.purchaseRequestNumber,
            reference: this.state.inboundDeliveryDraftData.reference,
            projectMasterId:
              this.state.inboundDeliveryDraftData.projectMasterId,
            projectName: this.state.inboundDeliveryDraftData.projectName,
            projectNumber: this.state.inboundDeliveryDraftData.projectNumber,
            internalRemarks:
              this.state.inboundDeliveryDraftData.remarksInternal,
            supplierRemarks:
              this.state.inboundDeliveryDraftData.remarksSupplier,
            footer: this.state.inboundDeliveryDraftData.footer,
            placeOfSupply: this.state.inboundDeliveryDraftData.placeOfSupply,
            isRootDiscount: rootDiscount ? true : false,
            rootDiscountPercent: rootDiscount.amountPercent || 0,
            expenseId: this.state.inboundDeliveryDraftData.expenseId,
            rootDiscountAmount: Number(rootDiscount.amount || 0),
            expenseAmount: expenseAmount,
            isRootExpense: expenseAmount ? true : false,
            poDetailsList: detailList,
            inheritedTxColumn: inheritedTxColumn,
            forceHideTxColumn: forceHideTxColumn,
            tableDataReRendered: true,
            inboundDeliveryDate: this.state.inboundDeliveryDraftData
              .inboundDeliveryDate
              ? new Date(
                  this.state.inboundDeliveryDraftData.inboundDeliveryDate
                )
              : new Date(),
            conversionRefList:
              this.state.inboundDeliveryDraftData.conversionRefList || [],
            inboundDeliveryDraftData: null,
            supplierQuoteNumber:
              this.state.inboundDeliveryDraftData.supplierQuoteNumber,
          });
        }
      }, 1000);
    }

    if (
      this.state.stateSupplierId &&
      props.supplierDetail &&
      props.supplierDetail.supplierId &&
      props.paymentTerms &&
      props.paymentTerms.length
    ) {
      const supplierObj = props.supplierDetail;
      const supplierPaymentTerm = find(props.paymentTerms, {
        paymentTermId: supplierObj.paymentTermId,
      });
      const allSupplierBillingAddress = filter(supplierObj.boLocationList, {
        locationType: CONSTANTS.BILLING_ADDRESS,
      });
      const defaultBillingAddress = find(allSupplierBillingAddress, {
        isDefault: 1,
      });
      const selectedList =
        (supplierObj &&
          filter(supplierObj.supplierTaxIdentificationsList, {
            populateOnTransaction: 1,
          })) ||
        [];
      const selectedContact =
        (supplierObj.boContactList || []).find((x) => x.isPrimaryContact) || {};
      let selectedSupplierTaxIdentifications = selectedList.map((obj) => {
        return obj.supplierTaxIdentificationId;
      });
      this.setState({
        stateSupplierId: null,
        supplierAddresses:
          (supplierObj.boLocationList || []).filter(
            (x) => x.locationType === CONSTANTS.TYPE_SHIPPING_ADDRESS
          ) || [],
        supplier: {
          id: supplierObj.supplierId,
          supplierId: supplierObj.supplierId,
          name: supplierObj.supplierStoreName,
          supplierTaxIdentificationsList:
            supplierObj.supplierTaxIdentificationsList,
        },
        supplierPaymentTerm,
        supplierBillingAddress: defaultBillingAddress,
        boContactList: supplierObj.boContactList,
        selectedSupplierTaxIdentifications,
        selectedContact,
        allBillingAddress: allSupplierBillingAddress,
      });
    }
    if (props.addresses) {
      if (
        props.addresses.BillingAddress &&
        props.addresses.BillingAddress.length
      ) {
        this.setState({
          allBillingAddress: props.addresses.BillingAddress,
        });
      }
    }
    if (props.supplierContactList && props.supplierContactList.length) {
      this.setState({
        boContactList: props.supplierContactList,
      });
    }

    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences =
        find(props.allPreferences, {
          txType: "inboundDelivery",
          templateName: "standard",
        }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnList || []
          : [];
        txColumns = txColumns.length
          ? txColumns
          : (this.state.txMandatoryColumns || []).concat(
              "product",
              "description"
            );
        const txColumnSetting = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnSetting || {}
          : [];
        const isColumnFitTable =
          Object.keys(txColumnSetting).length ||
          this.state.isColumnFitTable === false
            ? false
            : true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences,
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          ),
        });
      }
    }
  }

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 100,
      updateAllOptions,
      updateFetching,
    };
    this.props.fetchProducts(payload);
  }

  render() {
    return (
      <Fragment>
        {this.state.auditDrawerVisible && (
          <AuditTimeLine
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}

        <InboundDeliveryComponent
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching);
          }}
          {...this.props}
          {...this.state}
          openIDDetails={this.openIDDetails}
          updateState={(data) => {
            this.setState(data);
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    priceList: state.common.priceList,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    poList: state.inboundDelivery.poList,
    supplierAddresses: state.supplier.supplierAddresses,
    paymentTerms: state.common.paymentTerms,
    states: state.common.states,
    projectList: state.common.projectList,
    uomList: state.common.uomList,
    idPoData: state.inboundDelivery.idPoData,
    taxes: state.common.taxes,
    expenseTypes: state.common.expenseTypes,
    products: state.salesInvoice.products,
    documentList: state.common.documentList,
    nextTxNumber: state.inboundDelivery.nextTxNumber,
    allPreferences: state.common.allPreferences,
    allShippingInstruction: state.common.allShippingInstruction,
    remarksData: state.settings.remarksData,
    permissions: state.auth.permissions,
    poData: state.purchaseOrder.purchaseOrderDetail,
    allRelationshipTaxIdentificatins:
      state.common.allRelationshipTaxIdentificatins,
    inboundDeliveryData: state.inboundDelivery.inboundDeliveryData,
    supplierSummary: state.supplier.supplierSummary,
    supplierDetail: state.common.supplierDetail,
    addresses: state.supplier.addresses,
    supplierContactList: state.supplier.contacts,
    boRelationshipAddress: state.common.boRelationshipAddress,
    saveLoading: state.common.saveLoading,
    detailLoading: state.common.detailLoading,
    inboundDeliveryDraftCount: state.inboundDelivery.inboundDeliveryDraftCount,

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchIDDataForPo,
      resetIDPoData,
      fetchPriceList,
      fetchProjects,
      fetchPaymentTerms,
      addPaymentTerm,
      deletePaymentTerm,
      fetchStates,
      fetchProducts,
      fetchTaxes,
      fetchUOM,
      addUOM,
      deleteUOM,
      fetchExpenseTypes,
      addExpenseTypes,
      deleteExpenseTypes,
      showModal,
      hideModal,
      createInboundDelivery,
      pushModalToStack,
      popModalFromStack,
      deleteSuppliers,
      deleteProject,
      deleteAddress,
      deleteTax,
      deletePriceList,
      fetchDocuments,
      addDocumentName,
      deleteDocumentName,
      deleteContact,
      fetchUserPreferences,
      saveUserPrefrences,
      fetchShippingInstructions,
      addShippingInstruction,
      deleteShippingInstruction,
      resetRemarksData,
      fetchRemarksTemplate,
      fetchPurchasedOrderDetail,
      resetPODetail,
      createAuditTrail,
      deleteAuditTrail,
      fetchAllRFQ,
      fetchRelationshipTaxIdentifications,
      deleteRelationshipTaxIdentifications,
      deleteSupplierTaxIdentifications,
      fetchInboundryDetailDetail,
      resetIDDetail,
      fetchAllPOForInbound,
      createInboundDeliveryDraft,
      getNextTxNumber,
      fetchSupplierSummary,
      fetchSupplierDetailsBySupplierId,
      resetSupplierDetail,
      fetchAddresses,
      fetchAllContacts,
      getRelationshipAddress,
      fetchInboundDeliveriesDrafts,
      fetchAllInboundDeliveries,
      fetchPurchaseOrders,
      getInboundDeliveriesDraftCount
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InboundDeliveryContainer);
