import React, { useEffect, useState } from "react";
import "../../schoolManagement/StudentProfile/index.scss";
import { Tabs } from "antd";
import {
  getInitialsFromString,
  capitalizeFirstLetter,
} from "../../../../../utils";
import "./style.scss";
import Contact from "./Contact";
import * as debounce from "lodash.debounce";
import { Dropdown } from "../../../../general/Dropdown";
import Activities from "./Activities";
import Tasks from "./Tasks";
import Reminders from "./Reminders";
import Notes from "./Notes";
import LeadManagement from "./leadManagement";
import LeadProperties from "./LeadProp";
import LeadDetails from "./LeadDetails";
import { ICONS } from "../../../../../static/constants";

const { TabPane } = Tabs;

const LeadProfileComp = (props) => {
  const { searchedByText, searchedCustomers, leadData } = props;
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds + 1);
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, []);
  const formatTime = (totalSeconds) => {
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
    const seconds = String(totalSeconds % 60).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  const getAddressInfoFragment = (obj) => {
    return (
      <>
        {obj.streetAddress1 ? (
          <span className="billing-address">{obj.streetAddress1}</span>
        ) : (
          ""
        )}
        {obj.streetAddress2 ? (
          <span className="billing-address"> {obj.streetAddress2}</span>
        ) : (
          ""
        )}
        {obj.cityName ? (
          <div className="billing-address">
            {`${obj.cityName || ""}${
              obj.stateName ? ", " + obj.stateName : ""
            }${obj.zipCode ? ", " + obj.zipCode : ""}`}
          </div>
        ) : (
          ""
        )}
        {obj.countryName ? (
          <div
            className="billing-address"
            style={{ textTransform: "uppercase" }}
          >
            {`${capitalizeFirstLetter(obj.countryName) || ""}`}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  function callTabApi(key) {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      crmLeadId: props.leadData?.crmLeadId,
    };
    switch (key) {
      case "activityTab":
        props.updateModalState({
          activeTabKey:key
        });
        props.fetchLeadActivityTrack(payload);
        break;

      case "tasksTab":
        props.updateModalState({
          activeTabKey:key
        });
        props.fetchLeadTasks(payload);
        break;

        case "reminderTab":
          props.updateModalState({
            activeTabKey:key
          });
          break;
  
        case "notesTab":
          props.updateModalState({
            activeTabKey:key
          });
          break;

          case "detailsTab":
            props.updateModalState({
              activeTabKey:key
            });
            break;
    
      default:
        break;
    }
  }

  return (
    <>
      <div className="txn-header">
        <div className="left-itms" style={{gap:"20px"}}>
          <ul>
            <li className="title">Leads</li> 
          </ul>
          <div className="timer-lRight">
            <div className="align-div">
            <i className={ICONS.PLAY} />  {formatTime(seconds)}

            </div>
          </div>
        </div>
        <div className="right-itms">
          <Dropdown
            style={{ width: "250px" }}
            items={searchedCustomers || []}
            placeholder="Search by lead name"
            valueKeyName="companyName"
            optionKeyName="customerId"
            value={searchedByText}
            // onSearch={(searchedByText) => {
            //    updateModalState({ searchedByText, pageNumber: 1 });
            //    onSearchedInputChange(searchedByText, props);
            //    handleSearchedText(searchedByText, props);
            // }}
            onSelect={(optionValue, option) => {
              // updateModalState({searchedByText: optionValue})
              // updateModalState({
              //    customerId: Number(option.key),
              //    customerDetails: (searchedCustomers || []).find((obj)=>{return obj.customerId === Number(option.key)}),
              // })
            }}
          />
        </div>
      </div>

      <div className="lead-profile-container">
        <div className="left-container">
          <div className="sidenav-top">
            <div className="top-view">
              <div className="view1">
                <div className="name-icon">
                  {leadData.fullName
                    ? getInitialsFromString(leadData.fullName)
                    : "USER"}
                </div>
                <div>
                  <div className="profile-name">{leadData.fullName || ""}</div>
                  <div>{leadData.email || "abc@gmail.com"}</div>
                </div>
              </div>

              <div className="st-address">
                <i className="fi fi-rs-marker" style={{marginLeft:"5px"}}/>
                
                <div style={{marginLeft:"15px"}}>
                  
                  {
                  (leadData.boLocationList || []).length
                    ? getAddressInfoFragment(leadData.boLocationList[0])
                    : 
                    <>
                    <div style={{display:"flex",flexDirection:"row",gap:"8px"}}>
                    <div className="grey-box"></div>
                      {/* <div className="add-btn">Add</div> */}
                    </div>
                    

                    <div className="grey-box" style={{width:"143px"}}></div></>
                    }
                </div>
              </div>

              {leadData.statusName ? (
                <div
                  className="status-label label_only line-view ml30"
                  style={{ width: "auto", maxWidth: "120px",marginLeft:"72px" }}
                >
                  {leadData.statusName || ""}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

         

          <div className="sidenav-bottom">
            <div className="tabs-info">
              <Tabs type="line" className="custom-tab-st">
                <TabPane tab="Contact" key="2">
                  <Contact leadData={props.leadData} />
                </TabPane>
              </Tabs>
              <Tabs type="line" className="custom-tab-st">
                <TabPane tab="Lead Properties" key="leadProps">
                  <LeadProperties {...props} />
                </TabPane>
             
              </Tabs>
            </div>
          </div>
        </div>

        <div className="mid-container">
          <Tabs
            type="line"
            animated={false}
            tabBarGutter={24}
            style={{ margin: "0px !important" }}
            onChange={(key) => {
              callTabApi(key);
            }}
            defaultActiveKey="activityTab"
            activeKey={props.activeTabKey}
          >
            <TabPane tab="Activity" key="activityTab">
              <Activities {...props} />
            </TabPane>
            <TabPane tab="Tasks" key="tasksTab">
              <Tasks {...props} />
            </TabPane>
            <TabPane tab="Reminders" key="reminderTab">
              <Reminders {...props} />
            </TabPane>
            <TabPane tab="Notes" key="notesTab">
              <Notes {...props} />
            </TabPane>
            <TabPane tab="Lead Details" key="detailsTab">
              <LeadDetails {...props} />
            </TabPane>
          </Tabs>
        </div>

        <div className="right-container">
          <LeadManagement {...props} />
        </div>
      </div>
    </>
  );
};
export default LeadProfileComp;
