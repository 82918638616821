import { Checkbox, Col, Drawer, Radio, Row } from "antd";
import React, { useCallback, useState, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import Search from "antd/lib/transfer/search";

const CategoryModal = React.memo(({ crmLeadStageList, parentData, pageNumber, updateDrawerState, hideModal }) => {
  const [searchText, setSearchText] = useState('');

  const handleCategoryChange = useCallback((e) => {
    updateDrawerState({ parentData: e.target.value });
    hideModal();
  }, [updateDrawerState, hideModal]);

  const renderSubcategories = useCallback((subLeadStatus, depth = 1) => {
    const indentStyle = {
      paddingLeft: `${depth * 10}px`,
    };
    return subLeadStatus?.map((subcategory, i) => (
      <div
        key={`${subcategory.statusName}-${i}`}
        className="radio-button-Wrapper"
        style={indentStyle}
      >
        <Radio value={subcategory}>
          <div className="parent-category-sub-wrapper">
            {subcategory.statusName}
          </div>
        </Radio>
        {subcategory.subLeadStatus?.length > 0 &&
          renderSubcategories(subcategory.subLeadStatus, depth + 1)}
      </div>
    ));
  }, []);

  const filteredProjectList = useMemo(() => {
    const currentPageList = crmLeadStageList || [];
    if (!searchText) return currentPageList;

    return currentPageList.filter(category =>
      category.statusName.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [crmLeadStageList, searchText]);

  return (
    <>
      <div className="searchBarArea">
        <Search
          placeholder="Search Category..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className="categoryArea">
        <Radio.Group
          onChange={handleCategoryChange}
          value={parentData}
        >
          {filteredProjectList.map((category, i) => (
            <div className="subcategory" key={`${category.statusName}-${i}`}>
              <Radio value={category}>
                {category.statusName}
              </Radio>
              {category.subLeadStatus?.length > 0 &&
                renderSubcategories(category.subLeadStatus)}
            </div>
          ))}
        </Radio.Group>
      </div>
    </>
  );
});



const LeadStageDrawer = (props) => {
  const rowGutter = [24, 16];


  const closeDrawer = () => {
    props.updateState({
      leadStageListDrawer: false,

    });
  };

  const handlePopUp = () => {
    const modalData = {
      title: "confirmation",
      modalBody: <CategoryModal {...props} />,
      width: 500,
      handleSubmit: () => {
      },
    };
    props.showModal(modalData);
  }
  return (
    <Drawer
      title={<FormattedMessage id={props.leadStatusId ? "leads.leadForm.formMeta.leadStage.drawer.title.edit" : "leads.leadForm.formMeta.leadStage.drawer.title.create"} />}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.leadStageListDrawer}

      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />

          <CustomButton
            intlId="button.save.label"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              props.updateDrawerState({ submitted: true })
              if (!props.statusName) {
                return
              }
              const payload = {
                relationshipId: props.companyInfo.relationshipId,
                leadStatusId: props.leadStatusId || 0,
                statusName: props.statusName,
                createdByUserId: props.createdByUserId,
                updatedByUserId: props.updatedByUserId,
                parentId:props.parentData?.leadStatusId,
                subLeadStatus:props.subLeadStatus
              }
              props.createLeadStage(payload, props);
              closeDrawer();
            }}
            key={1}
          />
        </>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">Stage Name</div>
          <TextBox
            placeholder="Stage Name"
            type="text"
            maxLength={20}
            value={props.statusName}
            onChange={(e) => {
              props.updateDrawerState({ statusName: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.statusName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />

          <div className="subcategory-checkBox">
            <Checkbox
              checked={props?.addAsSubcategory}
              onChange={() => {
                props.updateDrawerState({
                  addAsSubcategory: !(props.addAsSubcategory)
                })
              }}
            >
              Add as Sub Category
            </Checkbox>
          </div>
        </Col>

        <Col span={12}>
          {props.addAsSubcategory && (
            <>
              <div className="i-label">
                <FormattedMessage
                  id={"Parent Category"}
                  defaultMessage=""
                />
                <span className="required">*</span>
              </div>
              <div onClick={() => handlePopUp()}>
                <TextBox
                  value={
                    props.parentData?.statusName || ""
                  }
                  placeholder={'Enter Parent Category'}
                />
              </div>
              <div>
                <ErrorMsg
                  validator={() => {
                    return !props.submittedOnce || !!props.parentData?.statusName;
                  }}
                  message={"Parent Name is reqiured"}
                />
              </div>
            </>
          )}
        </Col>

      </Row>
    </Drawer>
  );
};

export default LeadStageDrawer;
