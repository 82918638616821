import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CommunicationTemplateComp from "../../../components/communication/communicationTemplate";
import { fetchDataIfNeeded } from "../../../utils";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { createSubAccount, getSubAccount, getText2DonateConfig, updatePhoneNumber ,getAllCommunicationTemplate ,deleteCommunicationTemplate} from './action';

import CommunicationTemplateDrawer from "../../drawer/communication/communicationTemplate";
class CommunicationTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      communicationTemplateDrawerVisiable: false,
    }
  }

  componentDidMount() {
   const companyInfo = this.props.companyInfo;
   const payload ={
    relationshipId: companyInfo?.relationshipId,
   }
   this.props.getAllCommunicationTemplate(payload);
  }

  componentWillReceiveProps(props) {

  }

  render() {

    return <div>
      {this.state.communicationTemplateDrawerVisiable && <CommunicationTemplateDrawer {...this.props}
        {...this.state}
        updateDrawerState={(data) => {
          this.setState(data);
        }}
        />}
      { <CommunicationTemplateComp  {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
        updateDrawerState={(data) => {
          this.setState(data);
        }}
        /> 

      }
    </div>


  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    subAccount: state.communicationReducer.subAccount,
    accountConfigPh: state.communicationReducer.accountConfigPh,
    communicationTemplate: state.communicationReducer.communicationTemplateSettings,
    accountConfigWhatsApp: state.communicationReducer.accountConfigWhatsApp,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSubAccount,
      getSubAccount,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getText2DonateConfig,
      updatePhoneNumber,
      getAllCommunicationTemplate,
      deleteCommunicationTemplate
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationTemplate);
