import { Radio } from "antd";
import Search from "antd/lib/transfer/search";
import React, { useState } from "react";
import { useCallback,useMemo } from "react";

export const CategoryModal = React.memo(({ projectList, parentData, pageNumber, updateDrawerState, hideModal }) => {
    const [searchText, setSearchText] = useState('');

    const handleCategoryChange = useCallback((e) => {
        updateDrawerState({ parentData: e.target.value,projectName:e.target.value.projectName,projectMasterId:e.target.value.projectMasterId });
        hideModal();
    }, [updateDrawerState, hideModal]);

    const renderSubcategories = useCallback((subProjectList, depth = 1) => {
        const indentStyle = {
            paddingLeft: `${depth * 10}px`,
        };
        return subProjectList?.map((subcategory, i) => (
            <div
                key={`${subcategory.projectName}-${i}`}
                className="radio-button-Wrapper"
                style={indentStyle}
            >
                <Radio value={subcategory}>  
                    <div className="parent-category-sub-wrapper">
                        {subcategory.projectName}
                    </div>
                </Radio>
                {subcategory.subProjectList?.length > 0 &&
                    renderSubcategories(subcategory.subProjectList, depth + 1)}
            </div>
        ));
    }, []);

    const filteredProjectList = useMemo(() => {
        console.log(projectList,"dddddd");
        
        const currentPageList = projectList|| [];
        if (!searchText) return currentPageList;

        return currentPageList.filter(category =>
            category.projectName.toLowerCase().includes(searchText.toLowerCase())
        );
    }, [projectList, searchText, pageNumber]);

    return (
        <>
            <div className="searchBarArea">
                <Search
                    placeholder="Search Category..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
            <div className="categoryArea">
                <Radio.Group
                    onChange={handleCategoryChange}
                    value={parentData}
                >
                    {filteredProjectList.map((category, i) => (
                        <div className="subcategory" key={`${category.projectName}-${i}`}>
                            <Radio value={category}>  
                                {category.projectName}
                            </Radio>
                            {category.subProjectList?.length > 0 &&
                                renderSubcategories(category.subProjectList)}
                        </div>
                    ))}
                </Radio.Group>
            </div>
        </>
    );
});