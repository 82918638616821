import React from "react";
import { Checkbox} from "antd";
import { CustomAgGrid } from "../../../../general/CustomAgGrid";
import { FormattedMessage } from 'react-intl';
import { EDITABLE_MODULE_CODES } from '../../../../../static/constants';

const SchoolPermissions = (props) => {
    
    const updateStateForPermission = ()=>{
        const schoolPermission = []
        gridApi && gridApi.forEachNode((rowNode, index) => {
            const { data } = rowNode;
            schoolPermission.push(data);
        });
        
        props.updateState({
            schoolPermission,
           
        })
    }
    let gridApi;
  
    const onGridReady = (params) => {
        gridApi = params.api;
        gridApi.sizeColumnsToFit();
    }


    const columnDefs = [
        {
            headerName: "",
            field: "title",
            width: 300
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='modal.settings.manageUser.permissions.view' defaultMessage='' />;
              },
            field: "view",
            
            cellRendererFramework: (params) => {
                return <Checkbox disabled
                // disabled={props.toDisable}
                    onChange={(e) => {
                        params.node.setDataValue('view', e.target.checked)
                        updateStateForPermission();
                    }}
                    checked={params.data.view} />
            },
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='modal.settings.manageUser.permissions.create' defaultMessage='' />;
              },
            field: "create",
            
            cellRendererFramework: (params) => {
                return <Checkbox disabled
                // disabled={props.toDisable}
                    onChange={(e) => {
                        params.node.setDataValue('create', e.target.checked)
                        updateStateForPermission();
                    }}
                    checked={params.data.create} />
            }
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='modal.settings.manageUser.permissions.update' defaultMessage='' />;
              },
            field: "update",
            
            cellRendererFramework: (params) => {
                return <Checkbox disabled
                // disabled={props.toDisable}
                    onChange={(e) => {
                        params.node.setDataValue('update', e.target.checked);
                        updateStateForPermission();
                    }}
                    checked={params.data.update} />
            },
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='modal.settings.manageUser.permissions.delete' defaultMessage='' />;
              },
            field: "delete",
            
            cellRendererFramework: (params) => {
                return <Checkbox disabled
                // disabled={props.toDisable}
                    onChange={(e) => {
                        params.node.setDataValue('delete', e.target.checked)
                        updateStateForPermission();
                    }}
                    checked={params.data.delete} />
            },
        },
        {
            headerComponentFramework: () => {
                return <div><Checkbox disabled={props.toDisable} onChange={(e) => {
                    gridApi && gridApi.forEachNode((rowNode, index) => {
                        const { data } = rowNode;
                        data.view = e.target.checked;
                        data.create = e.target.checked;
                        data.update = e.target.checked;
                        data.delete = e.target.checked;
                        data.fullControl = e.target.checked;
                    });
                    updateStateForPermission();
                    gridApi.redrawRows();
                    
                }}
                
              >  </Checkbox> <FormattedMessage id='modal.settings.manageUser.permissions.fullControl' defaultMessage='' />
                </div>;
              },
            field: "fullControl",
            
            cellRendererFramework: (params) => {
                return <Checkbox disabled={props.toDisable && EDITABLE_MODULE_CODES.indexOf(params.data.moduleCode) < 0}
                    onChange={(e) => {
                        params.node.setDataValue('fullControl', e.target.checked);
                        params.node.setDataValue('view', e.target.checked);
                        params.node.setDataValue('create', e.target.checked);
                        params.node.setDataValue('update', e.target.checked);
                        params.node.setDataValue('delete', e.target.checked);
                        
                        updateStateForPermission();
                    }}
                    checked={params.data.view} />
            },
        }
    ];
    
    return (
        <CustomAgGrid
            columnDefs={columnDefs}
            rowData={props.schoolDataSource || []}
            rowDragManaged={true}
            onGridReady={onGridReady}
            domLayout={"autoHeight"}
            animateRows={true}
        />
    )
};

export default SchoolPermissions;
