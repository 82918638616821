import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UOMListingComp from '../../../../components/inventory/extras/uom/Listing';
import QuantityListingComp from '../../../../components/inventory/extras/quantity';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import QuantityDrawer from '../../../drawer/inventory/QuantityDrawer';
import { fetchOpeningQuantityCount , fetchOpeningQuantityList ,  deleteStockQuantity ,resetData} from './action';
import { fetchSuppliers } from '../../../supplier/Listing/action';
import { fetchAllLedgerAccounts } from '../../../finance/LedgerAccounts/action';
import {fetchProducts, fetchUOM, addUOM, deleteUOM, showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../../actions/commonActions';
// import { updateUOM } from './action';
class QuantityListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isQuantityDrawerVisible: false,
      pageSize: 25,
      pageNumber: 1,
      isDataRender: true,
      quantityList: []
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25
    };
    this.props.fetchOpeningQuantityList(payload);
    this.props.fetchOpeningQuantityCount(payload);
    fetchPaginationDataIfNeeded('fetchProducts', 'products', this.props, payload);
    fetchDataIfNeeded(
      "fetchAllLedgerAccounts",
      "allLedgerAccounts",
      this.props,
      payload
    );
    this.props.fetchSuppliers(payload);
    fetchDataIfNeeded('fetchUOM', 'allUOM', this.props, payload);
  }

 

  render() {
    return (
      <>
      {
        this.state.isQuantityDrawerVisible && <QuantityDrawer {...this.state} updateState={(data) => { this.setState(data) }} {...this.props} />
      }
         <QuantityListingComp {...this.state} {...this.props}  updateState={(data) => { this.setState(data) }} updateDrawerState={(data) => { this.setState(data) }} />
      </>
     
    )
  }
}

const mapStateToProps = (state) => {
  return {
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    allUOM: state.common.uomList,
    isDataRender: state.common.isDataRender,
    permissions: state.auth.permissions,
    openingQuantityList: state.inventory.openingQuantity ,
    openingQuantityCount: state.inventory.openingQuantityCount,
    products: state.salesInvoice.products,
    suppliers: state.supplier.suppliers,
    isLoading: state.common.isLoading,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchUOM,
  addUOM,
  // updateUOM,
  deleteUOM,
  showModal,
  popModalFromStack,
  pushModalToStack,
  fetchAllLedgerAccounts,
  hideModal,
  fetchOpeningQuantityList,
  fetchOpeningQuantityCount,
  fetchProducts,
  deleteStockQuantity,
  fetchSuppliers,
  resetData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuantityListing);
