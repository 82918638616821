/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, version } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FilterOutlined, MoreOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Skeleton, Menu, Switch } from 'antd';
// import { AgGridReact } from 'ag-grid-react';
import { ICONS, MODAL_TYPE } from '../../../static/constants';
import PageBreadcrumb from "../../PageBreadcrumb";
import { sortTable } from '../../../utils';
import SharePaymentURLComponent from './SharePaymentURL';
import DropdownAction from "antd/lib/dropdown";
import moment from 'moment-timezone';


const ProjectListingComp = (props) => {
    // const format = CONSTANTS.DISPLAY_DATE_FORMAT;
    // const companyInfo = props.companyInfo;
    // const onFirstDataRendered = (params) => {
    //     params.api.sizeColumnsToFit();
    // };
    // const finacialYear = getFinancialYear(companyInfo);
    const { pageNumber, projectWithSubProject =[], dir = 0, updateState } = props;

    // const fyStartDate = getMomentDateForUI({ date: new Date(finacialYear.fyStartDate), format: CONSTANTS.BACKEND_FORMAT });
    //const fyEndDate = getMomentDateForUI({ date: new Date(finacialYear.fyEndDate), format: CONSTANTS.BACKEND_FORMAT });

    // const columnDefs = [

    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="common.project" /></span>
    //             </div>
    //         },
    //         field: "projectName",
    //         resizable: true,
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="modal.common.project.projectNumber" /></span>
    //             </div>
    //         },
    //         field: "projectNumber",
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="banking.deposit" /></span>
    //             </div>
    //         },
    //         field: "totalDeposit",
    //         type: 'rightAligned' ,
    //         cellRendererFramework: (link) => <div>
    //             { Number(link.data.totalDeposit || 0).toFixed(2)}
    //         </div>,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="banking.withdrawal" /></span>
    //             </div>
    //         },
    //         field: "totalWithdrawal",
    //         resizable: true,
    //         type: 'rightAligned', 
    //         cellRendererFramework: (link) => <div>
    //            { Number(link.data.totalWithdrawal || 0).toFixed(2)}
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="balance.text" /></span>
    //             </div>
    //         },
    //         field: "balance",
    //         resizable: true,
    //         type: 'rightAligned', 
    //         cellRendererFramework: (link) => <div>
    //            { Number(link.data.totalDeposit || 0) >= Number(link.data.totalWithdrawal || 0) ? (Number(link.data.totalDeposit || 0) -  Number(link.data.totalWithdrawal || 0)).toFixed(2): '('+(Number(link.data.totalWithdrawal || 0) -  Number(link.data.totalDeposit || 0)).toFixed(2)+')'}
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.hierarchy.action' defaultMessage='' />;
    //         },
    //         field: "action",
    //         pinned: "right",
    //         lockPosition:true,
    //         cellRendererFramework: (params) => {
    //             return <div>
    //             <span className="cursor-pointer" title={<FormattedMessage id='common.edit.hierarchy' defaultMessage='' />} onClick={
    //                 () => {
    //                     projectAddOrUpdate(props, params.data)
    //                 }}>
    //                 <i className='fa fa-pencil' />
    //             </span>
    //                 <span style={{ marginLeft: '12px' }} className="cursor-pointer" title={<FormattedMessage id='common.delete.project' defaultMessage='' />} onClick={
    //                     () => {
    //                         deleteHandle(params.data);
    //                     }}>
    //                     <i className='fa fa-trash' />
    //                 </span>
    //                 <span style={{ marginLeft: '12px' }} className="cursor-pointer" title={props.intl.formatMessage({id: 'campaign.sharePaymentUrl.tooltip.msg' })} onClick={() => {
    //                   SharePaymentURLHandle(params.data);
    //                   }}>
    //                     <i className="fa fa-share" />
    //                 </span>
    //             </div>;
    //         },
    //         width: 100,
    //     }
    // ];

    const SharePaymentURLHandle = (project) => {
        props.pushModalToStack({
            title: <div>
                <FormattedMessage id='sharePaymentUrl.text' defaultMessage='' />
                <div style={{ 'fontSize': '12px' }}> {project.projectName || ''}</div>
            </div>,
            modalBody: <SharePaymentURLComponent {...props} projectData={project} />,
            width: '50%',
            hideFooter: true,
        })
    }

    const deleteHandle = (project) => {
        const modalBody = <FormattedMessage id='deleteItem.confirmation' defaultMessage='' values={{ value: project.name }} />;
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.deleteProject({
                    relationshipId: (props.companyInfo || {}).relationshipId,
                    id: project.projectMasterId,
                    version:project.version || 0
                }, props)
            },
        };
        props.showModal(modalData);
    }

    const projectAddOrUpdate = (props, projectData,parentItem={}) => {
        props.updateState({
            detailProjectDrawerVisible: true,
            projectData,
            parentItem
        })

        // const formData = {};
        // const { showModal, companyInfo } = props;
        // formData.relationshipId = companyInfo.relationshipId;
        // const data = {
        //   title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
        //   formData,
        //   hideFooter: true,
        //   modalData: {
        //     modalType: MODAL_TYPE.PROJECT,
        //     data: projectData || {},
        //   },
        //   handleSubmit: (formData = {}) => {
        //     data.formData.submittedOnce = true;
        //     showModal(data);
        //   }
        // };
        // showModal(data);
    }

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    const breadCrumbList = [
        {
            name: <FormattedMessage id='sidebar.menuItem.teamName' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='sidebar.menuItem.project' defaultMessage='' />,
        }
    ];

    const actionMenu = (e,parentItem) => {
        return (
            <Menu className="row-action-dropdown">

                <Menu.Item key="0" value="title">
                    Actions
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item key="1" value="edit" onClick={() => {
                    projectAddOrUpdate(props, e,parentItem);
                }}
                >
                    <i className={ICONS.EDIT} />
                    Edit
                </Menu.Item>

                <Menu.Item key="2" onClick={() => {
                    deleteHandle(e);
                }
                }>
                    <i className={ICONS.DELETE} />
                    Delete
                </Menu.Item>
            

            </Menu>
        )
    };

    const [childOnList, setChildOnList] = useState([]);

    const isChildOn = (id) => childOnList.includes(id);

    const toggleChild = (id) => {
        setChildOnList((prevList) =>
            prevList.includes(id) ? prevList.filter((itemId) => itemId !== id) : [...prevList, id]
        );
    };

    const getSubcategories = (subcategories = [], parentIndex = '0',item) => {
        return subcategories.map((subcategory, i) => {
            const index = `${parentIndex}.${i + 1}`;
            const isSubcategoryOpen = isChildOn(subcategory.projectMasterId);

            const nestingLevel = parentIndex.toString().split('.').length;
            console.log(nestingLevel, "level")
            const marginLeft = 30 + (nestingLevel) * 10;

            return (
                <React.Fragment key={index}>
                    <tr>
                        <td>
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: `${marginLeft}px` }}>
                                {subcategory?.subProjectList && subcategory.subProjectList.length > 0 && (
                                    <span

                                        onClick={() => toggleChild(subcategory.projectMasterId)}
                                        style={{ marginRight: '10px', marginLeft: '-20px' }}
                                    >
                                        {isSubcategoryOpen ? <i className="fi fi-br-minus"></i> : <i className="fi fi-br-plus"></i>}
                                    </span>
                                )}
                                <div>{subcategory.projectName}</div>
                            </div>
                        </td>
                        <td className="text-center">
                            <span>{(subcategory?.subProjectList || [])?.length}</span>
                        </td>
                        <td className="text-center" >
                            {moment(subcategory?.createdDate).format('DD/MM/YYYY')}
                        </td>
                        {/* <td className="switch-status text-center">
                  <Switch
                                // checkedChildren="Active"
                                // unCheckedChildren="Inactive"
                                defaultChecked={subcategory?.isPublished}
                                onChange={(checked)=>{ 
                                  console.log(checked)
                                  const data = {
                                    title: "Confirmation",
                                    
                                    // modalType: MODAL_TYPE.ACTIVATION_MODAL,
                                    modalBody:<div>Are you sure you want change the status?</div>,
                                    handleSubmit: () => {
                                      props.updateCategoryPublishStatus({categoryId: subcategory?.projectMasterId ,isPublished: checked ? 1: 0} ,props)
    
                                    },
                                  };
                                  
                                    props.pushModalToStack(data)
                                }}
                              />
                  </td> */}

                        <td className="text-center">
                            <div className="action-icon">
                                <DropdownAction
                                    overlay={actionMenu(subcategory,item)}
                                    trigger={["click"]}
                                >
                                    <i className={ICONS.MORE} />
                                </DropdownAction>
                            </div>

                        </td>


                    </tr>
                    {isSubcategoryOpen && getSubcategories(subcategory.subProjectList, index,subcategories)}
                </React.Fragment>
            );
        });
    };
    return (
        <>

            <>

                <div className="view-container">
                    <div className="view-container-actions">
                    <div className="left-actions">
                <div className="table-heading">
                  <FormattedMessage
                    id="organization.project.table.heading"
                    defaultMessage=""
                  />
                </div>
             
              </div>


                    </div>
                    <Skeleton loading={props.isLoading}
                        active
                        paragraph={{ rows: 12 }}
                    >

                        <div className='table-container'>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='text-left' width={"30%"}>Project Name</th>
                                        <th className="text-center">Count</th>
                                        <th className="text-center">Date Created</th>
                                        {/* <th className="text-center">Status</th> */}
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(projectWithSubProject || [])?.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td>
                                                    <div className="plus-minus-icon">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {item.subProjectList && item.subProjectList.length > 0 ? (
                                                                <>
                                                                    <span

                                                                        onClick={() => toggleChild(item.projectMasterId)}
                                                                    >
                                                                        {isChildOn(item.projectMasterId) ? <i class="fi fi-br-minus"></i> : <i class="fi fi-br-plus"></i>}
                                                                    </span>
                                                                    <div style={{ marginLeft: "10px" }}>{item?.projectName}</div>
                                                                </>

                                                            )
                                                                :
                                                                <div style={{ marginLeft: "22px" }}>{item?.projectName}</div>
                                                            }

                                                        </div>
                                                    </div>
                                                </td>

                                                <td className="text-center">
                                                    <span>{(item?.subProjectList || [])?.length}</span>
                                                </td>
                                                <td className="text-center">
                                                    {moment(item?.createdDate).format('DD/MM/YYYY')}
                                                </td>

                  
                                                <td className="text-center">
                                                    <div className="action-icon">
                                                        <DropdownAction
                                                            overlay={actionMenu(item)}
                                                            trigger={["click"]}
                                                        >
                                                            <i className={ICONS.MORE} />
                                                        </DropdownAction>
                                                    </div>

                                                </td>
                                            </tr>
                                            {isChildOn(item.projectMasterId) && getSubcategories(item.subProjectList, index + 1,item)}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </Skeleton>


                </div>
            </>
        </>
    );
};



export default injectIntl(ProjectListingComp);
