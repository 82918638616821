import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProductListingComp from '../../../components/product/Listing';
import { fetchProducts, getProductCount, resetPaginatedData, getOutOfStockCount, getLowStockCount, getReorderStockCount, getInventoryEvaluation, exportPdf, exportExcel, deleteProducts ,getInventoryItemByIds} from './action'
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../utils'
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchUserPreferences, saveUserPrefrences } from '../../../actions/commonActions';
import find from 'lodash.find';
import { fetchAllLedgerAccounts } from '../../finance/LedgerAccounts/action';
import { fetchSuppliers } from '../../supplier/Listing/action';
import { fetchInventoryItemDetails,resetInventoryItemDetails } from '../detail/action';
import { generateLabelPdf ,generatePDF} from '../../../actions/downloadFileAction';
class ProductListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 100,
      searchedText: '',
      txPreferences: { txType: "product", templateName: "listing" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['itemName', 'variants', 'sku', 'categoryName', 'hsnCodeRate', 'qtyCurrentStock'],
      txAvailableColumn: {
        hsnCodeRate: (props.companyInfo || {}).countryName === 'India' ? true : false,
        categoryName: true,
        sku: true,
        variants: true,
        itemName: true,
        warehouseName: true,
        brandName: true, 
        upc: true, 
        isbn: true, 
        manufacturerName: true, 
        ean: true,
        productImage: true
    },
    };
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "product", templateName: "listing" });
      if (txPreferences && txPreferences.gridPreference) {
        let txColumns = ((JSON.parse(txPreferences.gridPreference)) || {}).columnList || [];
        txColumns = txColumns.length ? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description');
        const txColumnSetting = ((JSON.parse(txPreferences.gridPreference)) || {}).columnSetting || {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false: true;
        let gridPreference = txPreferences.gridPreference;
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }
  }

  componentDidMount() {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      pageNumber: 1,
      userId: (this.props.userInfo || {}).userId,
      pageSize: 100
    };

    fetchPaginationDataIfNeeded('fetchProducts', 'products', this.props, payload);
    fetchDataIfNeeded('getProductCount', 'productCount', this.props, payload);
    fetchDataIfNeeded('getOutOfStockCount', 'outOfStockCount', this.props, payload);
    fetchDataIfNeeded('getReorderStockCount', 'reorderStockCount', this.props, payload);
    fetchDataIfNeeded('getLowStockCount', 'lowStockCount', this.props, payload);
    fetchDataIfNeeded('getInventoryEvaluation', 'inventoryValuation', this.props, payload);
    this.props.fetchSuppliers(payload);
    fetchDataIfNeeded(
      "fetchAllLedgerAccounts",
      "allLedgerAccounts",
      this.props,
      payload
    );
  }

  render() {
    return (
      <ProductListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.product.products,
    productCount: state.product.productCount,
    outOfStockCount: state.product.outOfStockCount,
    reorderStockCount: state.product.reorderStockCount,
    lowStockCount: state.product.lowStockCount,
    inventoryValuation: state.product.inventoryValuation,
    productDetailsByIds: state.product.productDetailsByIds,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    isLoading: state.common.isLoading,

    headerLoading:state.common.headerLoading,
    listLoading: state.common.listLoading,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    productDetails  : state.product.productDetails,
    suppliers: state.supplier.suppliers,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchProducts,
  resetPaginatedData,
  getProductCount,
  getOutOfStockCount,
  getLowStockCount,
  fetchUserPreferences,
  getReorderStockCount,
  getInventoryEvaluation,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  exportPdf,
  exportExcel,
  saveUserPrefrences,
  deleteProducts,
  fetchInventoryItemDetails,
  generateLabelPdf,getInventoryItemByIds,resetInventoryItemDetails,generatePDF,
  fetchAllLedgerAccounts,
  fetchSuppliers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductListing);
