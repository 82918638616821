import React from 'react';
import { FormattedMessage } from "react-intl";
import { Col, DatePicker, Divider, Row, Skeleton } from "antd";
import { Dropdown } from "../../../../general/Dropdown"
import { TextBox } from "../../../../general/TextBox";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { CONSTANTS } from '../../../../../static/constants';
import { getMomentDateForUI, getMomentDateForUIReadOnly } from '../../../../../utils';
import moment from 'moment-timezone';

function Official(props) {
  const { pageNumber, empInfo, setEmpInfo } = props;
  const format = CONSTANTS.DISPLAY_DATE_FORMAT;
  const teamList = props.teamList[pageNumber || 1].filter(obj => obj.departmentId === empInfo.departmentId)
  return <>
      <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>

    <Row gutter={[16, 16]}>
      <Col span={24}>
        <div className="i-label">
          Username<span className="required">*</span>
        </div>
        <TextBox
          placeholder="my_unique_username"
          type="text"
          maxLength={20}
          countStyle={{top: '40px'}}
          value={empInfo.userName}
          onChange={(e) => {
            setEmpInfo({...empInfo, userName: e.target.value});
          }}
        />
        <ErrorMsg
          validator={() => { return !empInfo.submittedOnce || empInfo.userName }}
          message={<FormattedMessage id='requiredField.missing.msg' />}
        />
      </Col>
      <Col span={12}>
        <div className="i-label">
          Password<span className="required">*</span>
        </div>
        <TextBox
          placeholder="Password"
          type="password"
          value={empInfo.password}
          onChange={(e) => {
            setEmpInfo({...empInfo, password: e.target.value});
          }}
        />
        <ErrorMsg
          validator={() => { return !empInfo.submittedOnce || empInfo.password }}
          message={<FormattedMessage id='requiredField.missing.msg' />}
        />
      </Col>
      <Col span={12}>
        <div className="i-label">Confirm Password
          <span className="required">*</span>
        </div>
        <TextBox
          placeholder="Confirm Password"
          type="password"
          value={empInfo.confirmPassword}
          onChange={(e) => {
            setEmpInfo({...empInfo, confirmPassword: e.target.value});
          }}
        />
        <ErrorMsg
          validator={() => { return !empInfo.submittedOnce || empInfo.confirmPassword }}
          message={<FormattedMessage id='requiredField.missing.msg' />}
        />
      </Col>
      <Divider />

      <Col span={12}>
        <div className="i-label">Employee Id
        </div>
        <TextBox
          placeholder="Employee Id"
          type="text"
          maxLength={20}
          value={empInfo.employeeCode}
          onChange={(e) => {
            setEmpInfo({...empInfo, employeeCode: e.target.value});
          }}
        />
     
      </Col>

      <Col span={12}>
        <div className="i-label">Date of joining
          <span className="required">*</span>
        </div>
        <DatePicker
          allowClear={false}
          format={format}
          defaultValue={empInfo?.dateOfJoining ? getMomentDateForUI({date:new Date(empInfo?.dateOfJoining)}) : getMomentDateForUI({date:new Date()})}
          onChange={(selectedDate) => {
            setEmpInfo({...empInfo, dateOfJoining: selectedDate})
          }} 
          disabledDate={(current)=>current && current.isAfter(moment().endOf('day'))}
        />
        <ErrorMsg
          validator={() => { return !empInfo.submittedOnce || empInfo.dateOfJoining }}
          message={<FormattedMessage id='requiredField.missing.msg' />}
        />
      </Col>
      <Col span={12}>
        <div className="i-label">Department
          <span className="required">*</span>
        </div>
        <Dropdown
          items={props.departmentList[pageNumber || 1] || []}
          valueKeyName="departmentName"
          optionKeyName="departmentId"
          placeholder="Select"
          value={empInfo.departmentName}
          canAddNew={true}
          onAddNewClick={() => {
            props.updateDrawerState({
              ...props,
              departmentDrawer: true,
              // departmentItem: {}
            });
          }}
          onSelect={(optionValue, option) => {
            setEmpInfo({
              ...empInfo,
              departmentId: option.value,
              departmentName: option.name,
             
            });
          }}
        />
        <ErrorMsg
          validator={() => { return !empInfo.submittedOnce || empInfo.departmentName }}
          message={<FormattedMessage id='requiredField.missing.msg' />}
        />
      </Col>

      <Col span={12}>
        <div className="i-label">Team
          <span className="required">*</span>
        </div>
        <Dropdown
          items={teamList || []}
          valueKeyName="teamName"
          optionKeyName="teamId"
          placeholder="Select"
          value={empInfo.teamName}
          canAddNew={true}
          onAddNewClick={() => {
            props.updateDrawerState({
              teamDrawer: true,
            });
          }}
          onSelect={(optionValue, option) => {
            setEmpInfo({
              ...empInfo,
              teamId: option.value,
              teamName: option.name,
            });
          }}
        />
<ErrorMsg
          validator={() => { return !empInfo.submittedOnce || empInfo.teamName }}
          message={<FormattedMessage id='requiredField.missing.msg' />}
        />
      </Col>

      <Col span={12}>
        <div className="i-label">Designation
          <span className="required">*</span>
        </div>
        <Dropdown
          items={props.designationList[pageNumber || 1] || []}
          valueKeyName="designationName"
          optionKeyName="designationId"
          placeholder="Select"
          canAddNew={true}
          value={empInfo.designationName}
          onSelect={(optionValue, option) => {
            setEmpInfo({
              ...empInfo,
              designationId: option.value,
              designationName: option.name,
            });
          }}
          onAddNewClick={() => {
            props.updateDrawerState({
              designationDrawer: true,
            });
          }}
        />
        <ErrorMsg
          validator={() => { return !empInfo.submittedOnce || empInfo.designationName }}
          message={<FormattedMessage id='requiredField.missing.msg' />}
        />
      </Col>

      <Col span={12}>
        <div className="i-label">Org Hierarchy
          <span className="required">*</span>
        </div>
        <Dropdown
          items={props.companyHierarchyList || []}
          valueKeyName="hierarchyName"
          optionKeyName="hierarchyId"
          placeholder="Select"
          value={empInfo.hierarchyName}
          canAddNew={true}
          onSelect={(optionValue, option) => {
            setEmpInfo({
              ...empInfo,
              hierarchyId: option.value,
              hierarchyName: option.name,
            });
          }}
          onAddNewClick={() => {
            props.updateDrawerState({
              ...props,
              hierarchyDrawer: true,
              
            });
          }}
        />
        <ErrorMsg
          validator={() => { return !empInfo.submittedOnce || empInfo.hierarchyName }}
          message={<FormattedMessage id='requiredField.missing.msg' />}
        />
      </Col>

      <Col span={12}>
        <div className="i-label">
          Reporting To
        </div>
        <Dropdown />
      </Col>

      <Col span={12}>
        <div className="i-label">
          Job Role<span className="required">*</span>
        </div>
        <Dropdown
          items={props.jobRoleList[pageNumber || 1] || []}
          valueKeyName="jobRoleName"
          optionKeyName="jobRoleId"
          placeholder="Select"
          canAddNew={true}
          value={empInfo.jobRoleName}
          onAddNewClick={() => {
            props.updateDrawerState({
                jobRoleDrawer: true,
            });
          }}
          onSelect={(optionValue, option) => {
            setEmpInfo({
              ...empInfo,
              jobRoleId: option.value,
              jobRoleName: option.name,
            });
          }}
        />
        <ErrorMsg
          validator={() => { return !empInfo.submittedOnce || empInfo.jobRoleName }}
          message={<FormattedMessage id='requiredField.missing.msg' />}
        />
      </Col>

      <Col span={12}>
        <div className="i-label">
          Employee Type<span className="required">*</span>
        </div>
        <Dropdown
          items={props.employeeTypeList[pageNumber || 1] || []}
          valueKeyName="employeeType"
          optionKeyName="empTypeId"
          placeholder="Select"
          canAddNew={true}
          value={empInfo.empType}
          onAddNewClick={(payload) => {
            props.updateDrawerState({
              employeeTypeDrawer: true,
            });
          }}
          onSelect={(optionValue, option) => {
            setEmpInfo({
              ...empInfo,
              empTypeId: option.value,
              empType: option.name,
            });
          }}
        />
        <ErrorMsg
          validator={() => { return !empInfo.submittedOnce || empInfo.empType }}
          message={<FormattedMessage id='requiredField.missing.msg' />}
        />
      </Col>

      <Col span={12}>
        <div className="i-label">
          Branch<span className="required">*</span>
        </div>
        <Dropdown
          items={props.branchList[pageNumber || 1] || []}
          valueKeyName="warehouseName"
          optionKeyName="warehouseMasterId"
          placeholder="Select"
          canAddNew={true}
          value={empInfo.warehouseMasterId}
          onAddNewClick={(payload) => {
            props.updateDrawerState({
              branchDrawer: true,
            });
          }}
          onSelect={(optionValue, option) => {
            setEmpInfo({
              ...empInfo,
              warehouseMasterId: option.value,
              warehouseName: option.name,
            });
          }}
        />
        <ErrorMsg
          validator={() => { return !empInfo.submittedOnce || empInfo.warehouseName }}
          message={<FormattedMessage id='requiredField.missing.msg' />}
        />
      </Col>

      {/* <Divider />

      <Col span={12}>
        <div className="i-label">Work Location
          <span className="required">*</span>
        </div>
        <Dropdown />

      </Col>

      <Col span={12}>
        <div className="i-label">Work Shift
          <span className="required">*</span>
        </div>
        <Dropdown
          items={props.employeeTypeList[pageNumber || 1] || []}
          valueKeyName="employeeType"
          optionKeyName="empTypeId"
          placeholder="Select"
          value={props.employeeType}
          onSelect={(optionValue, option) => {
            props.updateDrawerState({
              empTypeId: optionValue.value,
              employeeType: option.name,
            });
          }}
          onAddNewClick={(payload) => {
            props.updateDrawerState({
              employeeTypeDrawer: true,
            });

          }}
        />


      </Col>

      <Col span={12}>
        <div className="i-label">Pay Grade
          <span className="required">*</span>
        </div>
        <Dropdown
          canAddNew={true}

        />


      </Col>

      <Col span={12}>
        <div className="i-label">Band
          <span className="required">*</span>
        </div>
        <Dropdown />


      </Col>

      <Col span={12}>
        <div className="i-label">CTC
          <span className="required">*</span>
        </div>
        <TextBox />
      </Col> */}
    </Row>
</Skeleton>
  </>
}
export default Official;