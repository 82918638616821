import React, { Fragment, useCallback, useState,useMemo } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Checkbox, Radio } from 'antd';
import { CustomButton } from '../../general/CustomButton';
import { TextBox } from '../../general/TextBox';
import { ErrorMsg } from '../../general/ErrorMessage';
import { MultipleSelectDropdown } from '../../general/MultipleSelectDropdown';
import Search from 'antd/lib/transfer/search';
import { PROJECT_TYPE } from '../../../static/constants';

const CategoryModal = React.memo(({ detailProjectList, parentData, pageNumber, updateState, hideModal }) => {
    const [searchText, setSearchText] = useState('');

    const handleCategoryChange = useCallback((e) => {
        updateState({ parentData: e.target.value });
        hideModal();
    }, [updateState, hideModal]);

    const renderSubcategories = useCallback((subProjectList, depth = 1) => {
        const indentStyle = {
            paddingLeft: `${depth * 10}px`,
        };
        return subProjectList?.map((subcategory, i) => (
            <div
                key={`${subcategory.projectName}-${i}`}
                className="radio-button-Wrapper"
                style={indentStyle}
            >
                <Radio value={subcategory}>  
                    <div className="parent-category-sub-wrapper">
                        {subcategory.projectName}
                    </div>
                </Radio>
                {subcategory.subProjectList?.length > 0 &&
                    renderSubcategories(subcategory.subProjectList, depth + 1)}
            </div>
        ));
    }, []);

    const filteredProjectList = useMemo(() => {
        const currentPageList = detailProjectList[pageNumber || 1] || [];
        if (!searchText) return currentPageList;

        return currentPageList.filter(category =>
            category.projectName.toLowerCase().includes(searchText.toLowerCase())
        );
    }, [detailProjectList, searchText, pageNumber]);

    return (
        <>
            <div className="searchBarArea">
                <Search
                    placeholder="Search Category..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
            <div className="categoryArea">
                <Radio.Group
                    onChange={handleCategoryChange}
                    value={parentData}
                >
                    {filteredProjectList.map((category, i) => (
                        <div className="subcategory" key={`${category.projectName}-${i}`}>
                            <Radio value={category}>  
                                {category.projectName}
                            </Radio>
                            {category.subProjectList?.length > 0 &&
                                renderSubcategories(category.subProjectList)}
                        </div>
                    ))}
                </Radio.Group>
            </div>
        </>
    );
});

class DetailProjectAddComp extends React.Component {

    render() {
        const self = this;
        const packageObj = this.props.packageObj || {};
        const closeDrawer = () => {
            this.props.updateState({
                detailProjectDrawerVisible: false
            })
        }
        const handleAddProject = () => {

            this.props.updateDrawerState({
                submitted: true,
            })
            const payload = {
                projectDescription: this.props.projectDescription,
                projectName: this.props.projectName,
                relationshipId: this.props.companyInfo?.relationshipId,
                projectMasterId: this.props.projectMasterId,
                parentId:this.props.parentData?.projectMasterId,
                crmLeadTeamAssignedList:this.props.crmLeadTeamAssignedList,
                crmLeadEmpAssignedList:this.props.crmLeadEmpAssignedList,
                type:PROJECT_TYPE.CRM_PROJECT,
                subProjectList:this.props.subProjectList,
                version:this.props.version || 0
            }
            const modalData = {
                modalBody: "Are you sure you want to save?",
                handleSubmit: () => {
                    if (this.props.projectDescription && this.props.projectName) {

                        closeDrawer();
                        this.props.createOrUpdateProject(payload, this.props);
                    }
                },
            };

            if (this.props.projectDescription && this.props.projectName) {
                this.props.pushModalToStack(modalData);
            }
        }
        const rowGutter = [24, 16];
        console.log("dsncsdcsdcsdcsdc", this.props);
        const handlePopUp = () => {
            console.log("pgggyyy")
            const modalData = {
                title: "confirmation",
                modalBody: <CategoryModal {...this.props} />,
                width: 500,
                handleSubmit: () => {


                },
            };
            this.props.showModal(modalData);
        }
        return (
            <Fragment>

                <Drawer
                    title={
                        <div className='drawer-head-txt'>
                            Create Project
                        </div>
                    }
                    width={720}
                    zIndex={1}
                    className="custom-drawer"
                    maskClosable={false}
                    keyboard={false}
                    destroyOnClose={true}
                    onClose={closeDrawer}
                    visible={this.props.detailProjectDrawerVisible}
                    footer={<>
                        <CustomButton
                            intlId='confirmation.cancel'
                            defaultMessage=''
                            type='default'
                            key={0}
                            style={{ marginRight: 8 }}
                            onClick={closeDrawer}
                        />
                        <CustomButton
                            onClick={() => {
                                handleAddProject();
                            }}
                            type='primary'
                            htmlType='submit'
                            intlId='button.save.label'
                            defaultMessage=''
                        />
                    </>}

                >
                    <Row gutter={rowGutter}>


                        <Col span={24}>
                            <div className="i-label">Project Name</div>
                            <TextBox
                                placeholder="Project Name"
                                type="text"
                                maxLength={20}
                                value={this.props.projectName}
                                onChange={(e) => {
                                    this.props.updateDrawerState({ projectName: e.target.value });
                                }}
                            />
                            <ErrorMsg
                                validator={() => { return !this.props.submitted || this.props.projectName }}
                                message={<FormattedMessage id='requiredField.missing.msg' />}
                            />

                            <div className="subcategory-checkBox">
                                <Checkbox
                                    checked={this.props?.addAsSubcategory}
                                    onChange={() => {
                                        this.props.updateState({
                                            addAsSubcategory: !(this.props.addAsSubcategory)
                                        })
                                    }}
                                >
                                    Add as Sub Category
                                </Checkbox>
                            </div>
                        </Col>

                        <Col span={24}>
                            {this.props.addAsSubcategory && (
                                <>
                                    <div className="i-label">
                                        <FormattedMessage
                                            id={"Parent Category"}
                                            defaultMessage=""
                                        />
                                        <span className="required">*</span>
                                    </div>
                                    <div onClick={() => handlePopUp()}>
                                        <TextBox
                                            value={
                                                this.props.parentData?.projectName || ""
                                            }
                                            placeholder={'Enter Parent Category'}
                                        />
                                    </div>
                                    <div>
                                        <ErrorMsg
                                            validator={() => {
                                                return !this.props.submittedOnce || !!this.props.parentData?.projectName;
                                            }}
                                            message={"Parent Name is reqiured"}
                                        />
                                    </div>
                                </>
                            )}
                        </Col>

                        <Col span={24}>
                            <div className="i-label">Short Description</div>
                            <TextBox
                                placeholder="Short Description"
                                type="text"
                                maxLength={40}
                                value={this.props.projectDescription}
                                onChange={(e) => {
                                    this.props.updateDrawerState({ projectDescription: e.target.value });
                                }}
                            />
                            <ErrorMsg
                                validator={() => { return !this.props.submitted || this.props.projectDescription }}
                                message={<FormattedMessage id='requiredField.missing.msg' />}
                            />
                        </Col>


                        <Col span={24}>
                            <div> Select Department </div>
                            <MultipleSelectDropdown
                                items={this.props.departments || []}
                                placeholder={'select department'}
                                mode='multiple'
                                onDeSelect={(departmentId, options) => {
                                    let tempList = this.props?.selectedDepartment || [];
                                    let temp = this.props?.selectedDepartmentId || [];
                                    const empExits = tempList.find((obj) => Number(obj.departmentId) === Number(departmentId))
                                    const teamExits = temp.find((obj) => obj === options.key)
                                    if (empExits) {
                                        tempList = tempList.filter((obj) => Number(obj.departmentId) !== Number(departmentId));
                                        this.props.updateDrawerState({ selectedDepartment: tempList });
                                    }
                                    if (teamExits) {
                                        temp = temp.filter((obj) => obj !== options.key);
                                        this.props.updateDrawerState({ selectedDepartment: tempList, selectedDepartmentId: temp });

                                        const payload = {
                                            relationshipId: this.props.companyInfo?.relationshipId,
                                            departmentId: temp?.join(",")
                                        };
                                        this.props.getTeamByDepartmentId(payload);
                                    }


                                }}
                                valueKeyName='departmentName'
                                selectedValue={(this.props.selectedDepartment || []).map((obj) => { return obj.departmentId })}
                                optionKeyName='departmentId'
                                onSelect={(departmentId, options) => {
                                    let tem = this.props.selectedDepartmentId || [];
                                    let tempList = this.props.selectedDepartment || [];
                                    tempList.push({ departmentId: Number(options.key), relationshipId: this.props.companyInfo.relationshipId });
                                    tem.push(options.key);
                                    this.props.updateDrawerState({ selectedDepartment: tempList, selectedDepartmentId: tem });
                                    const payload = {
                                        relationshipId: this.props.companyInfo?.relationshipId,
                                        departmentId: tem?.join(",")
                                    };
                                    this.props.getTeamByDepartmentId(payload);
                                }}
                            />

                        </Col>

                        {((this.props.teamBydepartmentId || [])?.length || this.props.edit) ? <Col span={24}>
                            <div className='i-label'>Assign To Team<span className='required'>*</span></div>
                            <MultipleSelectDropdown
                                items={this.props.teamBydepartmentId || []}
                                placeholder={'Assign To Team'}
                                mode='multiple'
                                onDeSelect={(teamId, options) => {
                                    let tempList = this.props.crmLeadTeamAssignedList || [];
                                    let temp = this.props.selectedTeam || [];
                                    const empExits = tempList.find((obj) => Number(obj.teamId) === Number(teamId))
                                    const teamExits = temp.find((obj) => obj === options.name)
                                    if (empExits) {
                                        tempList = tempList.filter((obj) => Number(obj.teamId) !== Number(teamId));
                                        this.props.updateDrawerState({ crmLeadTeamAssignedList: tempList });
                                    }
                                    if (teamExits) {
                                        temp = temp.filter((obj) => obj !== options.name);
                                        this.props.updateDrawerState({ crmLeadTeamAssignedList: tempList, selectedTeam: temp });

                                        const payload = {
                                            relationshipId: this.props.companyInfo?.relationshipId,
                                            teamNames: temp?.join(",")
                                        };
                                        this.props.getTeamMembersByTeamName(payload);
                                    }


                                }}
                                valueKeyName='teamName'
                                selectedValue={(this.props.crmLeadTeamAssignedList || []).map((obj) => { return obj.teamId })}
                                optionKeyName='teamId'
                                onSelect={(teamId, options) => {
                                    let tem = this.props.selectedTeam || [];
                                    let tempList = this.props.crmLeadTeamAssignedList || [];
                                    tempList.push({ teamId: Number(options.key), relationshipId: this.props.companyInfo.relationshipId });
                                    tem.push(options.name);
                                    this.props.updateDrawerState({ crmLeadTeamAssignedList: tempList, selectedTeam: tem });
                                    const payload = {
                                        relationshipId: this.props.companyInfo?.relationshipId,
                                        teamNames: tem?.join(",")
                                    };
                                    this.props.getTeamMembersByTeamName(payload);
                                }}
                            />
                        </Col> : ""}

                        {this.props.employeeByTeamName?.length ? <Col span={24}>
                            <div className='i-label'>Assign Team Member<span className='required'>*</span></div>
                            <MultipleSelectDropdown
                                items={this.props.employeeByTeamName || []}
                                placeholder={'Assign to'}
                                mode='multiple'
                                onDeSelect={(empId, options) => {
                                    let tempList = this.props.crmLeadEmpAssignedList || [];
                                    let empExits = tempList.filter((obj) => obj.relEmpId === empId);

                                    if (empExits) {
                                        tempList = tempList.filter((obj) => Number(obj.relEmpId) !== Number(empId));
                                        this.props.updateDrawerState({ crmLeadEmpAssignedList: tempList });
                                    }
                                }}
                                valueKeyName='fullName'
                                valueKeyName2='hierarchyName'
                                selectedValue={(this.props.crmLeadEmpAssignedList || []).map((obj) => { return obj.relEmpId })}
                                optionKeyName='relationshipEmployeeId'
                                onSelect={(empId, options) => {
                                    let tempList = this.props.crmLeadEmpAssignedList || [];
                                    tempList.push({ relEmpId: Number(options.key), relationshipId: this.props.companyInfo.relationshipId });
                                    this.props.updateDrawerState({ crmLeadEmpAssignedList: tempList });
                                }}
                            />
                        </Col> : ""}







                    </Row>


                </Drawer>
            </Fragment>
        );
    }
}

export default injectIntl(DetailProjectAddComp);
