import { Checkbox, Col, Drawer, Radio, Row } from "antd";
import React, { useCallback, useState ,useMemo} from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import Search from "antd/lib/transfer/search";

const CategoryModal = React.memo(({ crmLeadSourceList, parentData, pageNumber, updateDrawerState, hideModal }) => {
  const [searchText, setSearchText] = useState('');

  const handleCategoryChange = useCallback((e) => {
    updateDrawerState({ parentData: e.target.value });
    hideModal();
  }, [updateDrawerState, hideModal]);

  const renderSubcategories = useCallback((subLeadSource, depth = 1) => {
    const indentStyle = {
      paddingLeft: `${depth * 10}px`,
    };
    return subLeadSource?.map((subcategory, i) => (
      <div
        key={`${subcategory.leadSourceName}-${i}`}
        className="radio-button-Wrapper"
        style={indentStyle}
      >
        <Radio value={subcategory}>
          <div className="parent-category-sub-wrapper">
            {subcategory.leadSourceName}
          </div>
        </Radio>
        {subcategory.subLeadSource?.length > 0 &&
          renderSubcategories(subcategory.subLeadSource, depth + 1)}
      </div>
    ));
  }, []);

  const filteredProjectList = useMemo(() => {
    const currentPageList = crmLeadSourceList || [];
    if (!searchText) return currentPageList;

    return currentPageList.filter(category =>
      category.leadSourceName.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [crmLeadSourceList, searchText]);

  return (
    <>
      <div className="searchBarArea">
        <Search
          placeholder="Search Category..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className="categoryArea">
        <Radio.Group
          onChange={handleCategoryChange}
          value={parentData}
        >
          {filteredProjectList.map((category, i) => (
            <div className="subcategory" key={`${category.leadSourceName}-${i}`}>
              <Radio value={category}>
                {category.leadSourceName}
              </Radio>
              {category.subLeadSource?.length > 0 &&
                renderSubcategories(category.subLeadSource)}
            </div>
          ))}
        </Radio.Group>
      </div>
    </>
  );
});

const LeadSourceDrawer = (props) => {
  const rowGutter = [24, 16];


  const closeDrawer = () => {
    props.updateState({
        leadSourceListDrawer: false,
    });
  };
  const handlePopUp = () => {
    const modalData = {
      title: "confirmation",
      modalBody: <CategoryModal {...props} />,
      width: 500,
      handleSubmit: () => {
      },
    };
    props.showModal(modalData);
  }
  return (
    <Drawer
      title={<FormattedMessage id={props.crmLeadSourceId ? "leads.leadForm.formMeta.leadSource.drawer.title.edit"  : "leads.leadForm.formMeta.leadSource.drawer.title.create"} />}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.leadSourceListDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />

          <CustomButton
            intlId="button.save.label"
            defaultMessage=""
            htmlType="submit"
            onClick={()=>{
                props.updateDrawerState({submitted: true})
                if(!props.leadSourceName){
                    return
                }
                const payload = {
                    relationshipId: props.companyInfo.relationshipId,
                    crmLeadSourceId: props.crmLeadSourceId || 0,
                    leadSourceName: props.leadSourceName,
                    isActive: props.isActive || 1,
                    parentId:props.parentData.crmLeadSourceId,
                    subLeadSource:props.subLeadSource
                }
                props.createLeadSource(payload, props);
                closeDrawer();
            }}
            key={1}
          />
        </>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">Source Name</div>
          <TextBox
            placeholder="Source Name"
            type="text"
            maxLength={20}
            value={props.leadSourceName}
            onChange={(e) => {
              props.updateDrawerState({ leadSourceName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
       <div className="subcategory-checkBox">
            <Checkbox
              checked={props?.addAsSubcategory}
              onChange={() => {
                props.updateDrawerState({
                  addAsSubcategory: !(props.addAsSubcategory)
                })
              }}
            >
              Add as Sub Category
            </Checkbox>
          </div>
        </Col>

        <Col span={12}>
          {props.addAsSubcategory && (
            <>
              <div className="i-label">
                <FormattedMessage
                  id={"Parent Category"}
                  defaultMessage=""
                />
                <span className="required">*</span>
              </div>
              <div onClick={() => handlePopUp()}>
                <TextBox
                  value={
                    props.parentData?.leadSourceName || ""
                  }
                  placeholder={'Enter Parent Category'}
                />
              </div>
              <div>
                <ErrorMsg
                  validator={() => {
                    return !props.submittedOnce || !!props.parentData?.leadSourceName;
                  }}
                  message={"Parent Name is reqiured"}
                />
              </div>
            </>
          )}
        </Col>
      </Row>
    </Drawer>
  );
};

export default LeadSourceDrawer;
