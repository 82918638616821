import React, { useState } from "react";
import { Input } from "antd";
import "./general.scss";
export function TextBox(props) {
  let className = props.className || "input-text";
  if (props.value && props.validator && !props.validator(props.value)) {
    className = props.invalidClassName || "input-text-error text-red";
  }

  let [isFocus, setIsFocus] = useState(false);

  return (
    <div style={props.parentStyle}>
      
      <Input
        style={{ ...props.style, position: "relative" }}
        placeholder={props.placeholder}
        type={props.type || "text"}
        maxLength={props.maxLength}
        minLength={props.minLength}
        defaultValue={
          props.type === "number" ? props.value || "" : props.value || ""
        }
        className={className}
        onFocus={() => {
          setIsFocus(true);
        }}
        onBlur={() => {
          props.onBlur && props.onBlur();
          setIsFocus(false);
        }}
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChange}
        addonAfter={props.addonAfter}
        onPressEnter={props.onPressEnter}
        required={props.required}
        onKeyUp={props.onKeyUp}
        onKeyDown={props.onKeyDown}
        ref={props.ref}
        prefix={props.prefix}
        showCount={props.showCount}
        autoFocus={props.autoFocus}
        addonBefore={props.addonBefore}
        suffix={props.suffix}
        onClick={props.onClick}
      />
      {isFocus ? (
        <div
          style={{
            right: "20px",
            top: "auto",
            textAlign: "right",
            position: "absolute",
            ...props.countStyle,
          }}
        >
          {props.maxLength && (props.value || "").length
            ? props.value.length + " / " + props.maxLength + " chars"
            : ""}
        </div>
      ) : null} 
    </div>
  );
}
