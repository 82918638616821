import React, { Fragment, useState } from "react";
import { injectIntl } from "react-intl";
import { Tabs,DatePicker } from "antd";
// import { CONSTANTS } from "../../../static/constants";
import TableView from "../../../containers/modal/modalBody/schoolManagement/TimeTable";
import CalenderView from "./calenderView";
import moment from "moment-timezone";
import { formatDateForBackend, getMomentDateForUI ,getCurrentDateForBackend} from "../../../utils";
import { CONSTANTS } from "../../../static/constants";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const TimeTableComp = (props) => {

  const format = CONSTANTS.DISPLAY_DATE_FORMAT;
  // const breadCrumbList = [
    //   {
      //     name: <FormattedMessage id="sidebar.menuItem.school" defaultMessage="" />,
      //   },
      //   {
        //     name: (
          //       <FormattedMessage id="sidebar.menuItem.timeTable" defaultMessage="" />
          //     ),
  //   },
  // ];
  
  const [activeTab, setActiveTab] = useState("1");
  const [selectedRange, setSelectedRange] = useState([]);
 

const handleDateChange = (dates) => {
  if (dates && dates.length > 0) {
    const startOfWeek = dates[0].clone().startOf('week').add(1, 'day');
    const endOfWeek = startOfWeek.clone().endOf('week').add(1, 'day');
    const newRange = [startOfWeek, endOfWeek];
    const payload ={
      relationshipId:props.companyInfo.relationshipId,
      classId:props.eData?.classId,
      sectionId: props.eData?.classSectionId,
      startDate:formatDateForBackend(startOfWeek),
      endDate:formatDateForBackend(endOfWeek),

    }

    props.getTimeTableByClassSection(payload);
    setSelectedRange(newRange);
  
  }
};
  return (
    <Fragment>
      {/* <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItem.timeTable"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">

        </div>
      </div> */}
       <div className="time_table_actions">

         <RangePicker style={{width:"300px"}}
            value={selectedRange}
           
         onChange={handleDateChange}  />
         
        </div>
      <div
        className="view-container table-height"
      >
        <Tabs
          activeKey={activeTab || "1"}
          onChange={(key) => {
            setActiveTab(key);
          }}
        >
          <TabPane tab="Table View" key="1">
            <TableView {...props} />
          </TabPane>
          <TabPane tab="Calendar View" key="2">
            <CalenderView {...props} />
          </TabPane>
        </Tabs>

        {/* <div className="footer">
          <div className="f-left"></div>
          <div className="f-right"></div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default injectIntl(TimeTableComp);
