const configuration = {
    API_BASE_URL: 'http://localhost:8080',
    API_VERSION: '',
    AWS_BASE_URL:'https://service.alpidedev.com',
    PDF_API_BASE_URL: 'http://localhost:8000/api/',
    PDF_API_VERSION: 'v1',
    REDIRECT_URI_FOR_AMAZON: 'https://uat.alpide.com/app/admin/sales-channels',
    rootContext: 'alpide',
    rootContextTwilio: 'alpide-tcom',
    rootContextInvoiceService: 'alpide-invoice',
    INVOICE_SERVICE_BASE_URL: 'https://service.alpidedev.com',
    rootContextPaymentGateway: 'alpide-paypal',
    PAYMENT_GATEWAY_BASE_URL: 'https://service.alpidedev.com',
    rootContextPlaid: 'alpide-plaid',
    rootContextMWS: 'alpide-mws',
    rootContextSMS: 'alpide-school',
    rootContextBrevo:'brevo-sms-app',
    rootContextCC:'alpide-cc',
    rootContextSupport:'alpide-support',
    rootContextRazorpay:'alpide-razorpay',
    rootContextStripe: 'alpide-stripe',
    rootContextHRMS: 'alpide',
    rootContextCMS: 'alpide-cms',
    API_VERSION_CMS:'api/v1',
    CMS_BASE_URL: 'https://service.alpidedev.com',
    MWS_BASE_URL: 'https://service.alpidedev.com',
    PLAID_BASE_URL: 'https://service.alpidedev.com',
    UI_BASE_URL: 'http://localhost:3000/app/',
    COUPON_BASE_URL: 'http://localhost:8081',
    COUPON_CONTEXT: 'alpide-comm',
    CALLBACK_URI_AUTH_NET: 'http://localhost:3000/app/admin/payment-integration',
    PAYMENT_SERVER_BASE_URL: 'https://uat-app.alpide.com',
    CALLBACK_URI_MARKTPLACE: 'http://localhost:3000/app/admin/sales-channels',
    CALLBACK_URI_MARKTPLACE_AMAZON: 'http://localhost:3000/app/admin/sales-channels-amazon',
    CALLBACK_URI_MARKTPLACE_EBAY: 'http://localhost:3000/app/admin/sales-channels-ebay',
    PAYMENT_FORM_ACTION: 'https://test.authorize.net/payment/payment',
    CC_TOKENIZER_URL: 'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html',
    //  SSO_URL: 'http://acc.alpide.com:3000',
    WP_DOMAIN: 'https://alpide.lmpx.com',
    ROOT_DOMAIN: 'alpide.com',
    ENCRYPT_SECRET: 'Unl0ck!!!',
    CB_PUBLIC_KEY: 'test_9KdLB3laV1fzmNupMYMQyQRwUioTPYVL',
    STRIPE_PUBLISHABLE_KEY: 'pk_test_51IdxxgL81zohSYdnTW0V88mWnfCIhqk8pgZdYgD9S2Xbut00JH58Ac7QHce64AWM0GUbKrcvlv6G5M3BHCp6dOGJ00Ja0AiJgF',
    BUCKET_NAME: {
        BO_RELATIONSHIP: 'relationship-u',
        BO_SALES_INQUIRY: "inquiry-u",
        BO_SALES_QUOTE: "sales-quote-u",
        BO_SALES_ORDER: 'sales-order-u',
        BO_CUSTOMER_INVOICE: "sales-invoice-u",
        BO_CREDIT_MEMO: "credit-memo-u",
        BO_PURCHASE_REQUEST: "purchase-request-u",
        BO_RFQ: "supplier-rfq-u",
        BO_PURCHASE_ORDER: "purchase-order-u",
        BO_INBOUND_DELIVERY: "inbound-delivery-u",
        BO_SUPPLIER_INVOICE: "purchase-invoice-u",
        BO_DEBIT_MEMO: "debit-memo-u",
        BO_SAMPLE_RESOURCES: "sample-resources-u",
    }
};
export default configuration;