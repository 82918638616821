import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import PageBreadcrumb from "../../../PageBreadcrumb";
import { Button, Card, Col, Dropdown, Menu, Pagination, Row, Skeleton, Switch } from "antd";
import { ICONS } from "../../../../static/constants";
import DropdownAction from "antd/lib/dropdown";
import storeDef from "../../../../assets/images/store-def.jpg";
import moment from "moment-timezone";
import './index.scss'
import { getAWSFileURL } from "../../../../utils";
const truncateContent = (content, wordLimit) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    const textContent = tempDiv.textContent || tempDiv.innerText || "";
    const words = textContent.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return textContent;
  }


const BlogList = (props) => {
   
  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.eStores" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.blogs" defaultMessage="" />
      ),
    },
   
  ];
  const handleCreateBlog =() =>{
    console.log("mikkkkkkeeee")
  }


  const actionMenu = (blog) => (
    <Menu className="row-action-dropdown">
      <Menu.Item  key="0" value="title" disabled={true}>
        Actions
      </Menu.Item>
      <Menu.Divider />
      {/* <Menu.Item key="1" 
      onClick={() => handleViewClick(blog)}>
        <i className="fi fi-tr-overview"></i>
        View
      </Menu.Item> */}
      <Menu.Item key="2" 
       onClick={() => {
        props.updateState({ blogCreateDrawerVisible: true , isUpdate: true ,blogData: (blog||{})});

       }}>
        <i className="fi fi-rr-edit"></i>
        Edit
      </Menu.Item>
      <Menu.Item key="3" onClick={() => {
         const data = {
          title: "Confirmation",
          
       
          modalBody:<div>Are you sure you want to delete?</div>,
          handleSubmit: () => {
           props.deleteBlog(blog ,props)
          },
        };
          props.pushModalToStack(data)
        console.log(props ,"chekcinggg")
        
      
      }}
        
        >
        <i className="fi fi-rr-trash"></i>
        Delete
      </Menu.Item>
    </Menu>
  );

  const blogListData = ((props.blogPostList)[props.pageNumber||1] || []);

    return (
        <>
          <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItem.blogs"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
        <button
            className="create-action-btn"
            onClick={() => {
              props.updateState({ blogCreateDrawerVisible: true , isUpdate: false});
            }}
          >
            <i className={ICONS.ADD} />{" "}
            <FormattedMessage id="button.create.label" defaultMessage="" />
          </button>
        </div>
      </div>
        <div className="view-container">
        <div className="view-container-actions">
                <div className="left-actions">
                    <div className="table-heading">Blog List</div>
                   
                           
                                <div className="vertical-sep" />
                                <div>{props.blogListCount||0}</div>
                            
                        
                </div>

              
            </div>
            <Skeleton loading={props.isLoading}
                active
                paragraph={{ rows: 12 }}
            >


               





              
                <div className='table-container'>
                <table>
                <thead>
                  <tr>
                    <th className='text-left'>Title</th>
                    <th className='text-left'>Category</th>
                    <th>Images(Thumbnails)</th>
                    <th>Description</th>
                    <th>Date Created</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {(blogListData||[]).map(blog => {
                      const image = getAWSFileURL(blog?.awsKeyName,props.companyInfo.bucketName , blog?.folderName)

                    return(
                      <tr key={blog.blogMasterId}>
                      <td>{blog.title}</td>
                      <td>{blog.categoryName}</td>
                      <td>
                        {image ? (
                           <img
                          
                           src={image}
                           alt="BlogThumbnailImage"
                           width={40}
                           height={40}
                           
                         />
                        ) : (
                          <img
                            src={storeDef}
                            alt="No Thumbnail Available"
                            width={40}
                            height={40}
                          />
                        )}
                      </td>
                      <td>
                        <div
                          dangerouslySetInnerHTML={{ __html: truncateContent(blog.content, 20) }}
                        />
                      </td>
                      <td>
                        {moment(blog.createdDate).format('DD/MM/YYYY')}
                      </td>
                      <td className="switch-status">
                      <Switch
                               
                                checked={blog?.isPublished}
                                onChange={(checked)=>{ 
                                  console.log(checked)
                                  const data = {
                                    title: "Confirmation",
                                    
                                    // modalType: MODAL_TYPE.ACTIVATION_MODAL,
                                    modalBody:<div>Are you sure you want change the status?</div>,
                                    handleSubmit: () => {
                                      props.updateBlogPublishStatus({blogId: blog?.blogMasterId ,isPublished: checked ? 1 : 0} ,props)
    
                                    },
                                  };
                                  
                                    props.pushModalToStack(data)
                                }}
                              />
                      </td>
                      <td className='text-center'>
                      <div className="action-icon">
                              <DropdownAction
                                overlay={actionMenu(blog)}
                                trigger={["click"]}
                              >
                                <i className={ICONS.MORE} />
                              </DropdownAction>
                            </div>
                       
                      </td>
                    </tr>
                    )
                  }
                    
                  )}
                </tbody>
              </table>
                </div>

            </Skeleton>
            <Pagination
                size="small"
                total={32}
                
                defaultPageSize={props.pageSize} 
                showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                // onChange={(pageNumber, pageSize) => {
                //     loadPageData(pageNumber, pageSize);
                 
                //     updateState({ pageSize: pageSize, pageNumber: pageNumber })
                // }}
                // onShowSizeChange={(pageNumber, pageSize) => {
                //     handlePageSizeChange(pageNumber||1, pageSize, props)
                //     updateState({ pageSize: pageSize, pageNumber: pageNumber })
                // }}
            />
       
        </div>
        </>
      );
 
};

export default BlogList;
