import axios from "axios";
import config from "../../../../config/environmentConfig";
//import { MEMBERSHIP_ACTION_LIST } from "../../../../static/constants";
import { showToasterMessage } from "../../../../utils";
import { COMMON_ACTIONS,SMS_ACTION_LIST } from "../../../../static/constants";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const saveTimeTable = (payload, props) => {
  return (dispatch) => {
    return axios
      .post( `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/timetable/saveOrUpdate`, payload)
      .then((response) => {

        showToasterMessage({
            messageType: "success",
            description: "Created Successfully!",
        });
        
        props.updateHeaderState({
          schoolDrawers: {
            ...props.schoolDrawers,
            addTimeTableDrawerVisible: false
          }
        });
        })
      .catch((err) => {
        
        showToasterMessage({
        messageType: "error",
        description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
        
      });
  };
};

export const getTeacherByClassId = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/instructorClassRef/getTeachersByClass?relationshipId=${payload.relationshipId}&classId=${payload.classId}&classSectionId=${payload.classSectionId}`)

      .then(response => {
       
        dispatch({ type: SMS_ACTION_LIST.TEACHER_LIST_BY_CLASS, data :response.data});

      })
      .catch(err => {
       
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}


