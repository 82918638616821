import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StudentDashboardComp from '../../../components/schoolManagement/dashboard';
import { fetchStudentRegistration, getStudentRegistrationCount, resetPaginatedData, deleteRegistration, deleteStudent,
 getTotalParentsCount, getTotalStudentsCount, getFormNames, getRegistrationDataForExcel, updateRegistrationUserStatus, updateStudentUserStatus, getStudentsCountByStatus, getTotalStudentcCountByGrade,
 getTotalRevenueForecast, getTotalAmountReceived, getTotalInvoiceGenerated, getTotalBalanceDue, getTotalExpenseBalanceDue,
getTotalFeesDue, getTotalFeesPaid, getRecentSchoolPayments, getTotalFinancialAssisatance, getPaymentReminder } from './action';
import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../actions/commonActions';
import { fetchCustomers, getCustomerCount } from '../../../containers/customer/Listing/action';
import { getRegisterParentsData, resetRegisterParentsData } from '../../modal/modalBody/customer/TotalParents/action';
import { getAllStudentForm } from '../../../containers/Student/studentFormList/action';
import EmailComponent from '../../../containers/drawer/recurringInvoiceEmail';
import { FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import { fetchProformaInvoices } from '../../customer/invoice/Listing/action';
import { fixedDecimalNumber } from '../../../utils';

class StudentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      isLoaded:false
      
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.schoolManagement.schoolDashboard' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
      {
        name: <FormattedMessage id='sidebar.menuItem.schoolManagement' defaultMessage='' />
      },
      {
          name: <FormattedMessage id='sidebar.menuItem.dashboard' defaultMessage='' />,
      }
    ]})
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 25
    };
    this.props.getTotalParentsCount(payload); 
    this.props.getTotalStudentsCount(payload);
    //this.props.getStudentsCountByStatus(payload);
   // this.props.getTotalStudentcCountByGrade(payload);
    //this.props.getFormNames(payload);
    
    this.props.getAllStudentForm(payload);
  }


  componentWillReceiveProps(props) {
    if (props.studentRegistrationList && props.studentRegistrationList[this.state.pageNumber] && props.studentRegistrationList[this.state.pageNumber].length) {
      this.setState({
        selectedFormId: props.studentRegistrationList[1][0].registrationFormSettingId,
      });
    }
    if (props.studentFormList && props.studentFormList.length) {
      if (!this.state.isStudentLoaded) {
        let selectedForm ;
        if(this.props.defaultSchoolForm){
          selectedForm = find(props.studentFormList, { registrationFormSettingId: this.props.defaultSchoolForm }) || {};
        }
        const companyInfo = this.props.companyInfo || {};
        const payload = {
          companyInfo,
          relationshipId: companyInfo.relationshipId,
          relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
          pageNumber: 1,
          pageSize: 25,
          formId: this.props.defaultSchoolForm || props.studentFormList[0].registrationFormSettingId
        };

        this.props.getStudentsCountByStatus(payload);
        this.props.getTotalStudentcCountByGrade(payload);
        // this.props.getTotalRevenueForecast(payload);
        // this.props.getTotalAmountReceived(payload);
        // this.props.getTotalInvoiceGenerated(payload);
        // this.props.getTotalBalanceDue(payload);
        // this.props.getTotalExpenseBalanceDue(payload);

        // this.props.getTotalFeesDue(payload);
        // this.props.getTotalFeesPaid(payload);
        this.props.getRecentSchoolPayments(payload);
        // this.props.getTotalFinancialAssisatance(payload);
        // this.props.getPaymentReminder(payload);
        this.props.fetchProformaInvoices({...payload,pageSize:5000});
        

      this.setState({
        isStudentLoaded: true,
        selectedForm: selectedForm || props.studentFormList[0]
      });
      }
    }
    if(!this.state.isLoaded && (props.proformaInvoiceList[1] || []).length){
      let totalFeesDue = (props.proformaInvoiceList[1]
        .filter(invoice => invoice.module === "school" && invoice.isInvoiceConverted === 0)
        .reduce((sum, invoice) => sum + (invoice.invoiceTotalAmount || 0),0));

      let totalFeesPaid = ((props.proformaInvoiceList[1]
        .filter(invoice => invoice.module === "school" && invoice.isInvoiceConverted === 1)
        .reduce((sum, invoice) => sum + (invoice.invoiceTotalAmount || 0),0)));

       let totalInvoiceGenerated = (props.proformaInvoiceList[1]
        .reduce((sum, invoice) => sum + (invoice.invoiceTotalAmount || 0),0))   

        this.setState({
          isLoaded:true,
          totalFeesDue:totalFeesDue,
          totalFeesPaid:totalFeesPaid,
          totalInvoiceGenerated:totalInvoiceGenerated
        })
    }
  }

  render() {
    return ( <div>
      {this.state.emailDetailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
    
      <StudentDashboardComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    studentRegistrationList: state.studentRegistration.studentRegistrationList,
    studentRegistrationCount: state.studentRegistration.studentRegistrationCount,
    isLoading: state.common.isLoading,
    totalParentsCount: state.studentRegistration.totalParentsCount,
    totalStudentCount: state.studentRegistration.totalStudentCount,
    allForms: state.studentRegistration.allForms,
    registerParentsData: state.studentRegistration.registerParentsData,
    studentFormList: state.membershipReducer.studentFormList,
    totalStudentCountByGrade: state.studentRegistration.totalStudentCountByGrade,
    totalStudentCountByStatus: state.studentRegistration.totalStudentCountByStatus,

    totalRevenueForecast: state.studentRegistration.totalRevenueForecast,
    // totalInvoiceGenerated: state.studentRegistration.totalInvoiceGenerated,
    totalAmountReceived: state.studentRegistration.totalAmountReceived,
    totalInvoiceDue: state.studentRegistration.totalInvoiceDue,
    totalExpenseDue: state.studentRegistration.totalExpenseDue,
    totalFeesDue: state.studentRegistration.totalFeesDue,
    totalFeesPaid: state.studentRegistration.totalFeesPaid,
    defaultSchoolForm: state.common.defaultSchoolForm,
    recentSchoolPayments: state.studentRegistration.recentSchoolPayments,
    totalFinancialAssisatance: state.studentRegistration.totalFinancialAssisatance,
    paymentReminder: state.studentRegistration.paymentReminder,
    proformaInvoiceList: state.salesInvoice.proformaInvoiceList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetPaginatedData,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
  fetchStudentRegistration, 
  getStudentRegistrationCount,
  deleteRegistration,
  fetchCustomers, 
  getCustomerCount,
  deleteStudent,
  getTotalParentsCount, 
  getTotalStudentsCount,
  getFormNames,
  getRegistrationDataForExcel,
  getRegisterParentsData, 
  resetRegisterParentsData,
  updateRegistrationUserStatus, 
  updateStudentUserStatus,
  getAllStudentForm,
  getStudentsCountByStatus, getTotalStudentcCountByGrade,
  getTotalRevenueForecast, getTotalAmountReceived, getTotalInvoiceGenerated, getTotalBalanceDue, getTotalExpenseBalanceDue,
  getTotalFeesDue, getTotalFeesPaid,
  getRecentSchoolPayments,
  getTotalFinancialAssisatance, 
  getPaymentReminder,
  fetchProformaInvoices
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentDashboard);
