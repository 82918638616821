import React, { Fragment,useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button,Empty,Skeleton,DatePicker } from "antd";
import {
  formatDateForBackend,
  getUserDefinedName,
} from "../../../utils";
import PageBreadcrumb from "../../PageBreadcrumb";
import ModalIndex from "./modalIndex";
import { ICONS } from "../../../static/constants";
import moment from 'moment';


const TimeTableComp = (props) => {

  const { timeTableClasses,classList, pageNumber,pageSize } = props;

  const combinedList = (classList[pageNumber] || []).map(item => item.classSectionList)?.flat();

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItem.school" defaultMessage="" />,
    },
    {
      name: <FormattedMessage id="sidebar.menuItem.timeTable" defaultMessage="" />,
    },
  ];

  const openTimeTable = (eData)=>{
    const payload = {
        relationshipId: props.companyInfo.relationshipId,
        classId: eData.classId,
        sectionId: eData.classSectionId,
        startDate:formatDateForBackend(moment().startOf('week')),
        endDate:formatDateForBackend(moment().endOf('week')) 
    }
    props.getTimeTableByClassSection(payload);
    props.pushModalToStack({
        title:      
         ` Time Table (${eData.className}-${eData.sectionName || ''})`,
        modalBody: <ModalIndex {...props} eData={eData} />,
        width: '100%',
        hideFooter: true,
        wrapClassName: 'new-transaction-wrapper'
    })
}




  return (
    <Fragment>
      <div className="view-top-bar">

        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItem.timeTable"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
            <button className="create-action-btn" onClick={()=>{
                props.updateHeaderState({
                  schoolDrawers: {
                    ...props.schoolDrawers,
                    addTimeTableDrawerVisible: true
                  }
                });
            }}>
                <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
        </div>
      </div>
      
      <div className="view-container">
        {/* <Tabs activeKey={props.activeTabKey || "1"} onChange={(key) => { props.updateState({ activeTabKey: key }) }}>
            <TabPane tab="Time Table View" key="1">
                <TableView {...props}/>
            </TabPane>
            <TabPane tab="Calender View" key="2">
                <CalenderView {...props}/>
            </TabPane>
        </Tabs> */}
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

            <div className="left-actions">
                <div className="table-heading">
                    <FormattedMessage
                        id='schoolManagement.timeTable.tableList.heading'
                        defaultMessage=""
                    />
                </div>
                <div className="vertical-sep" />
                {
                    // (timeTableClasses[props.pageNumber || 1]||[])?.length ? <>
                        
                    //     <div>{(timeTableClasses[props.pageNumber || 1]||[])?.length}</div>
                    // </>: 0
                    (combinedList || [])?.length
                }
            </div>
            <div className="right-actions">
            </div>
        </Skeleton>
        </div>
        <Skeleton loading={props.listLoading} active paragraph={{ rows: 12 }}>

        <div className="table-container no-pagination">
            <table>
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th width='40%'>{getUserDefinedName('Class Name', props)}</th>
                        <th width='40%'>{getUserDefinedName('Section Name', props)}</th>
                        <th width='8%'>Time Table</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    (combinedList || [])?.length ? ( combinedList.map((rowData, i)=>{
                      return <tr key={'tt'+i}>
                              <td>{(pageNumber-1)*pageSize+(i+1)}</td>
                              <td>{rowData.className || '-'}</td>
                              <td>{rowData.sectionName}</td>
                              <td>
                                  <Button 
                                      type="primary"
                                      style={{width: '80px'}}
                                      onClick={()=>openTimeTable(rowData)}
                                  > 
                                      <i className="fi fi-rr-eye"></i> &nbsp; View
                                  </Button>
                              </td>
                          </tr>
                  })):( <tr key="empty">
                    <td colSpan={"100%"}>
                      <Empty />
                    </td>
                  </tr>)
                    
                   }
                   
                </tbody>
            </table>
        </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right"></div>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(TimeTableComp);
