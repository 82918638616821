import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../actions/commonActions';


import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import CatgoryCreate from "../../../drawer/eStore/CatgoryCreate";
import CategoryList from "../../../../components/eStore/blogs/categoryList";
import { fetchAllCategoriesWithSubCategories , updateCategoryPublishStatus , countCatgeoryList ,deleteCategory} from "./action";
class Category extends Component {
  constructor(props) {
    super(props);
    console.log("peeeppp")
    this.state = {
      pageNumber: 0,
      pageSize: 25,
      categoryCreateDrawerVisible: false,
    };
  }

  componentDidMount() {
  
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: 25
    };
    console.log("memmesss")
    this.props.fetchAllCategoriesWithSubCategories(payload)
    this.props.countCatgeoryList(payload)
  }

  componentWillReceiveProps(props){
  

  }

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        {
            this.state.categoryCreateDrawerVisible && <CatgoryCreate {...this.props} {...this.state}   updateState={(data) => {
                this.setState(data);

                
              }}
              updateDrawerState ={(data) => {
                this.setState(data);
              }}
              />
        }
        <CategoryList
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />
      </DndProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    categoryListCount: state.eStore.categoryListCount,
    blogCategoryList: state.eStore.blogCategoryList,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,

  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchAllCategoriesWithSubCategories,
  countCatgeoryList,
  updateCategoryPublishStatus,
  deleteCategory,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Category);
